import React from 'react';
import {Pressable, View} from 'react-native';
import {CustomText} from './Texts';
import {FontSize} from '@utils/sizes';

const OptionSwitchTab = ({
  option1,
  option2,
  option1Count,
  option2Count,
  setActiveTab,
  activeTab,
}: {
  option1: String;
  option2: String;
  option1Count?: number;
  option2Count?: number;
  setActiveTab?: any;
  activeTab?: any;
}) => {
  return (
    <View
      style={{
        backgroundColor: '#FFFFFF',
        // marginTop: 2,
      }}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: 36,
        }}>
        <Pressable
          onPress={() => setActiveTab(option1)}
          style={{width: '50%', justifyContent: 'center'}}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-evenly',
            }}>
            <CustomText
              color={activeTab === option1 ? 'primary' : 'lightText'}
              style={{
                alignSelf: 'center',
              }}>
              {option1}
            </CustomText>
            {!!option1Count && option1Count > 0 && (
              <CustomText
                color={'white'}
                size={FontSize.small}
                style={{
                  alignSelf: 'center',
                  padding: 5,
                  borderRadius: 10,
                  backgroundColor: '#9B51E0',
                }}>
                {option1Count}
              </CustomText>
            )}
          </View>
        </Pressable>
        <Pressable
          onPress={() => setActiveTab(option2)}
          style={{width: '50%', justifyContent: 'center'}}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-evenly',
            }}>
            <CustomText
              color={activeTab === option2 ? 'primary' : 'lightText'}
              style={{
                alignSelf: 'center',
              }}>
              {option2}
            </CustomText>
            {!!option2Count && option2Count > 0 && (
              <CustomText
                color={'white'}
                size={FontSize.small}
                style={{
                  alignSelf: 'center',
                  padding: 5,
                  borderRadius: 50,
                  backgroundColor: '#9B51E0',
                }}>
                {option2Count}
              </CustomText>
            )}
          </View>
        </Pressable>
      </View>
      <View
        style={{
          display: 'flex',
          alignSelf: activeTab === option2 ? 'flex-end' : 'flex-start',
          flexDirection: 'row',
          width: '50%',
          height: 2,
          backgroundColor: '#9B51E0',
        }}></View>
    </View>
  );
};

export default OptionSwitchTab;
