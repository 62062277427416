import DeviceInfo from 'react-native-device-info';
import {getSessionId, track, updateSession} from './utils.web';
import type {IEnvironment, IEventData} from './utils.web';
import {
  EventTypeEnum,
  LoginAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import {getWebDeviceId} from './utils.web';

/**
 * getDeviceId
 *
 * gets the device id with respect to the platform
 * @returns device id
 */
const getDeviceId = () => {
  return getWebDeviceId();
};

class _Analytics {
  private isInitialized = false;
  private environment: IEnvironment;
  private deviceName: string;
  private deviceId: string;
  private sessionId: string;
  private phoneNumber?: string;
  private userId?: string;

  public async init(environment: IEnvironment, userId?: string) {
    if (environment == undefined) {
      throw new Error('Please pass an enviroment value');
    }
    if (this.isInitialized) {
      console.warn('Analytics is already initialized');
      return false;
    }

    this.environment = environment;
    this.isInitialized = true;
    if (!!userId) {
      this.userId = userId;
    }
    try {
      const deviceName = DeviceInfo.getDeviceNameSync();
      const deviceId = await getDeviceId();
      console.log('device id, ', deviceId);

      this.deviceName = deviceName;
      this.deviceId = deviceId;
      const sessionId = await getSessionId(environment, deviceId, userId);
      if (!!sessionId) {
        this.sessionId = sessionId;
      } else {
        this.sessionId = 'sessionError';
      }
      const appInitiatePayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: LoginAnalEventValue.APP_INITIATED,
        thing_type: ThingTypeEnum.ANALYTICS,
      };
      this.trackEvent(appInitiatePayload);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   *
   * cheks weather library is initialized or not
   * @returns `boolean` returns true if initialized else returns false
   */
  private checkInitialized() {
    if (!this.isInitialized) {
      console.warn(
        'Analytics Service is not initialized, please initilize it using init() method in root level',
      );
      return false;
    }
    return true;
  }

  /**
   *
   * Sets Session id
   * @param `string` Session Id
   * @returns `void`
   */
  public setSessionId(sessionId: string) {
    if (!this.checkInitialized()) {
      return;
    }
    if (!sessionId) {
      console.warn('Session Id is not passed');
      return;
    }
    this.sessionId = sessionId;
  }

  /**
   * Sets Phone Number
   * @param `string` Phone Number
   * @returns `void`
   */
  public setPhoneNumber(value: string) {
    if (!this.checkInitialized()) {
      return;
    }
    if (!value) {
      console.warn('Phone Number is not passed');
      return;
    }
    this.phoneNumber = value;
  }

  /**
   *
   * Sets User Id
   * @param value `string` User Id
   * @returns `void`
   */
  public setUserId(value: string) {
    if (!this.checkInitialized()) {
      return;
    }
    if (!value) {
      console.warn('User Id is not passed');
      return;
    }
    this.userId = value;
    updateSession(this.environment, this.sessionId, value);
  }

  public trackEvent(data: IEventData) {
    if (!this.checkInitialized()) {
      return;
    }
    if (this.environment == undefined) {
      throw new Error('Please privide an Environment');
    }

    if (!data.thing_id) {
      data.thing_id = this.userId || 'nonloggedInUser';
    }
    console.log(data);
    if (!this.sessionId) {
      throw new Error(
        'Please privide an Session Id using setSessionId() method',
      );
    }
    track(
      this.environment,
      this.sessionId,
      data,
      this.deviceName,
      this.deviceId,
      this.phoneNumber,
      this.userId,
    );
  }

  /**
   * reset data function
   * removes the phone nuber and user id
   */
  public reset() {
    this.phoneNumber = undefined;
    this.userId = undefined;
  }

  // used for testing to delete all local data for events
  private async deleteAllData() {}
}

const Analytics = new _Analytics();

export default Analytics;
