/*eslint-disable */
import React, {useCallback} from 'react';
import type {FC} from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {useSelector} from 'react-redux';
import Icon from 'react-native-vector-icons/Ionicons';
import {
  commentTypeSelector,
  createCommentParentIdSelector,
  createVoteTypeSelector,
  createVotesCountSelector,
} from '@redux/selectors/feedSelector';
import {addDownVote, addUpVote} from '@redux/slices/feedSlice';
import {dispatch} from '@redux/store';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {createCommentEvent} from '@redux/actions/comments';
import {
  EventTypeEnum,
  EventTypeValueEnum,
  FeedEnagagementAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import type {CreatePostEventPayload} from '@models/Feed/CreatePostEventPayload';
import {FontFamily, FontSize} from '@utils/sizes';
import {CreateCommentEventPayload} from '@models/Comment/CreateCommentEventPayload';
import Analytics, {IEventData} from '@wyse/analytics';
import LocalStorage from '@services/storage';
import NavigationService from '@services/navigationService';
import {useRoute} from '@react-navigation/native';
import {AuthScreens} from '@navigations/ScreenNames';
import {CustomText} from '@components/Texts';
import {CustomIcon, Icons} from '@components/icon';
import Colors from '@constants/Colors';

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    flexDirection: 'row',
  },
  voteCounts: {
    paddingHorizontal: 4,
  },
  votingButton: {
    alignItems: 'center',
  },
  voteBarContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    paddingRight: 14,
    alignItems: 'center',
    justifyContent: 'center',
  },
  replyContainer: {flexDirection: 'row'},
  replyText: {
    marginLeft: 8,
    marginRight: 10,
    alignSelf: 'center',
  },
  replyCountContainer: {
    fontWeight: '400',
    fontSize: 10,
    lineHeight: 12,
    color: '#4F4F4F',
    marginRight: 10,
    fontFamily: FontFamily.lato_regular,
  },
});

const VoteCounts: FC<{id: string}> = ({id}) => {
  const count = useSelector(createVotesCountSelector(id));
  return (
    <CustomText style={styles.voteCounts} color="text">
      {count}
    </CustomText>
  );
};

const VoteBar: FC<{
  id: string;
  type: string;
  loggedInUser?: string;
  children: any;
}> = ({id, type, children, loggedInUser}) => {
  const voteType = useSelector(createVoteTypeSelector(id));
  const userThingId = useSelector(userAccountThingIdSelector);
  const route = useRoute();
  const upVoteComment = useCallback(async () => {
    if (!loggedInUser) {
      await LocalStorage.setData('redirect', route);
      NavigationService.getInstance().replaceToAuth({
        page: AuthScreens.OtpScreen,
      });
      return;
    }
    dispatch(addUpVote(id));
    const postEventPayload: CreateCommentEventPayload = {
      ev_typ: EventTypeEnum.UPVOTE,
      ev_val: EventTypeValueEnum.UPVOTE,
      thing_id: id,
      thing_type: ThingTypeEnum.COMMENT,
      user_uid: userThingId!,
    };
    dispatch(createCommentEvent(postEventPayload));
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val:
        type == 'comment'
          ? FeedEnagagementAnalEventValue.COMMENT_UPVOTE
          : FeedEnagagementAnalEventValue.REPLY_UPVOTE,
      thing_type:
        type == 'comment' ? ThingTypeEnum.COMMENT : ThingTypeEnum.REPLY,
      thing_id: id,
    };
    Analytics.trackEvent(analyticsEventPayload);
  }, [id, userThingId, loggedInUser]);

  const downVoteComment = useCallback(async () => {
    if (!loggedInUser) {
      await LocalStorage.setData('redirect', route);
      NavigationService.getInstance().replaceToAuth({
        page: AuthScreens.OtpScreen,
      });
      return;
    }
    dispatch(addDownVote(id));
    const postEventPayload: CreateCommentEventPayload = {
      ev_typ: EventTypeEnum.DOWNVOTE,
      ev_val: EventTypeValueEnum.DOWNVOTE,
      thing_id: id,
      thing_type: ThingTypeEnum.COMMENT,
      user_uid: userThingId!,
    };
    dispatch(createCommentEvent(postEventPayload));
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val:
        type == 'comment'
          ? FeedEnagagementAnalEventValue.COMMENT_DOWNVOTE
          : FeedEnagagementAnalEventValue.REPLY_DOWNVOTE,
      thing_type:
        type == 'comment' ? ThingTypeEnum.COMMENT : ThingTypeEnum.REPLY,
      thing_id: id,
    };
    Analytics.trackEvent(analyticsEventPayload);
  }, [id, userThingId, loggedInUser]);

  return (
    <View style={styles.voteBarContainer}>
      <TouchableOpacity
        disabled={voteType === 'up'}
        onPress={upVoteComment}
        style={styles.votingButton}>
        <CustomIcon
          name={voteType === 'up' ? Icons.VoteUpFilled : Icons.VoteUp}
          size={18}
          color={voteType === 'up' ? Colors.primary : Colors.mediumDarkGrey}
        />
      </TouchableOpacity>
      {children}
      <TouchableOpacity
        disabled={voteType === 'down'}
        onPress={downVoteComment}
        style={styles.votingButton}>
        <CustomIcon
          name={voteType === 'down' ? Icons.VoteDownFilled : Icons.VoteDown}
          size={18}
          color={voteType === 'down' ? Colors.primary : Colors.mediumDarkGrey}
        />
      </TouchableOpacity>
    </View>
  );
};

const ReplyButton: FC<{
  id: string;
  type: string;
  onReplyPress: (
    parentId: string,
    commentType: string,
    commentId: string,
  ) => void;
  loggedInUser?: string;
}> = ({id, type, loggedInUser, onReplyPress}) => {
  const parentId = useSelector(createCommentParentIdSelector(id));
  // if (type !== 'comment') return null;
  const route = useRoute();
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Pressable
        onPress={async () => {
          if (!loggedInUser) {
            await LocalStorage.setData('redirect', route);
            NavigationService.getInstance().replaceToAuth({
              page: AuthScreens.OtpScreen,
            });
            return;
          }
          onReplyPress(parentId, type, id);
        }}
        style={styles.replyContainer}>
        <Icon name="arrow-redo-outline" color="#4F4F4F" size={18} />
        <CustomText size={FontSize.small} style={styles.replyText}>
          Reply
        </CustomText>
      </Pressable>
      <Text style={styles.replyCountContainer}>
        {/* {commentData.replies.length} Replies */}
      </Text>
    </View>
  );
};
const CommentCardFooter: FC<{
  id: string;
  loggedInUser?: string;
  onReplyPress: (
    parentId: string,
    commentType: string,
    commentId: string,
  ) => void;
}> = ({id, onReplyPress, loggedInUser}) => {
  const type = useSelector(commentTypeSelector(id));
  return (
    <View style={styles.container}>
      <VoteBar {...{id, type, loggedInUser}}>
        <VoteCounts {...{id}} />
      </VoteBar>
      <ReplyButton {...{id, type, onReplyPress, loggedInUser}} />
    </View>
  );
};

export default CommentCardFooter;
