const roles = [
  'CEO (Chief Executive Officer)',
  'CTO (Chief Technology Officer)',
  'COO (Chief Operating Officer)',
  'CFO (Chief Financial Officer)',
  'CMO (Chief Marketing Officer)',
  'CIO (Chief Information Officer)',
  'Co-Founder',
  'VP of Engineering',
  'VP of Product',
  'VP of Sales',
  'VP of Operations',
  'Director of Engineering',
  'Director of Product Management',
  'Director of Marketing',
  'Director of Sales',
  'Director of Operations',
  'Software Engineer',
  'Member of Technical Staff (MTS)',
  'Front-end Developer',
  'Back-end Developer',
  'Full Stack Developer',
  'Mobile App Developer',
  'Data Scientist',
  'Data Analyst',
  'UX/UI Designer',
  'Product Manager',
  'Associate Product Manager (APM)',
  'Senior Product Manager (SPM)',
  'Project Manager',
  'Scrum Master',
  'QA Engineer (Quality Assurance)',
  'DevOps Engineer',
  'Systems Administrator',
  'Network Engineer',
  'IT Manager',
  'IT Support Specialist',
  'Business Analyst',
  'Technical Writer',
  'Solutions Architect',
  'Database Administrator',
  'Security Analyst',
  'AI Engineer (Artificial Intelligence)',
  'Machine Learning Engineer',
  'Cloud Architect',
  'People Lead',
  'Human Resources (HR)',
  'Data Engineer',
  'Systems Analyst',
  'Technical Support Specialist',
  'Product Marketing Manager',
  'Growth Hacker',
  'Agile Coach',
  'Content Strategist',
  'Sales Engineer',
  'Customer Success Manager',
  'Content Manager',
  'Content Writer',
  'CRM Specialist',
  'Business Development Manager',
  'Systems Integration Engineer',
  'Customer Experience Manager',
  'Big Data Engineer',
  'Product Analyst',
  'IT Operations Director',
  'IT Systems Manager',
  'AI Developer',
  'Web Analyst',
  'Data Mining Specialist',
  'IT Service Manager',
  'IT Security Officer',
  'Digital Marketing Specialist',
  'IT Recruiter',
  'IT Contracts Manager',
  'IT Business Systems Analyst',
  'Chatbot Developer',
  'Business Analyst Manager',
  'Engineering Manager',
  'SDE I (Software Development Engineer I)',
  'SDE II (Software Development Engineer II)',
  'SDE III (Software Development Engineer III)',
  'SDE Manager (Software Development Engineer Manager)',
  'Technical Lead',
  'Staff Engineer',
  'Principle Engineer',
  'Technical Architect',
  'Architect',
  'Systems Architect',
  'Database Administrator',
  'Site Reliability Engineer (SRE)',
  'Site Reliability Engineer 2 (SRE 2)',
  'Site Reliability Engineer (SRE)',
  'Security Engineer',
  'Network Architect',
  'Infrastructure Engineer',
  'Release Engineer',
  'UI Engineer',
  'Test Automation Engineer',
  'Embedded Systems Engineer',
  'Cloud Engineer',
  'Full Stack Web Developer',
  'Mobile Application Engineer',
  'Android Developer',
  'iOS Developer',
  'App Developer',
  'Mobile Developer',
];

export default roles;
