import moment from 'moment';
import type {LinkedInParsedDataEducationDateInstance} from '@models/AccountData/LinkedInModels/LinkedInParsedDataEducationDateInstance';
import firestore, {FirebaseFirestoreTypes} from '@libs/Firebase/firestore';
import uuid from 'react-native-uuid';
import {useRef} from 'react';
import {OpenPickerResponse} from '@services/DeviceService';
import {
  MessageType,
  TextMessage,
  ImageMessage,
  UrlMessage,
  VideoMessage,
  RequestMessage,
  Message,
} from '@models/Conversation/Message';
import {Platform, Image, ImageSourcePropType} from 'react-native';
import {resolveAssetSource} from './image-web';
import {dispatch} from '@redux/store';
import {updateNotificationReadStatus} from '@redux/actions/notifications';
import {NotificationData} from '@models/Notification/NotificationData';
import {NotificationType} from '@constants/constantsEnum';
import NavigationService from '@services/navigationService';

export const convertToCamelCaseString = (text?: string) => {
  if (!text) return '';
  const textArray = text.split(' ');
  let modifiedString = '';
  textArray.map((textEle: string, index: number) => {
    if (index == 0) {
      modifiedString = textEle.charAt(0).toUpperCase() + textEle.slice(1);
    } else {
      modifiedString =
        modifiedString +
        ' ' +
        textEle.charAt(0).toUpperCase() +
        textEle.slice(1);
    }
  });
  return modifiedString;
};

export const timeago = (dateText: string) => {
  var timeMap = {
    sec: 1000,
    min: 60 * 1000,
    h: 60 * 1000 * 60,
    d: 24 * 60 * 1000 * 60,
    w: 7 * 24 * 60 * 1000 * 60,
    mo: 30 * 24 * 60 * 1000 * 60,
    yr: 365 * 24 * 60 * 1000 * 60,
  };

  let ts = Date.now() - new Date(dateText).getTime();
  let prevTimeKey: string | null = null;

  for (let i in timeMap) {
    if (Math.round(ts) < timeMap[i]) {
      if (prevTimeKey) {
        return Math.round(ts / timeMap[prevTimeKey]) + prevTimeKey;
      } else {
        return '1sec';
      }
    }
    prevTimeKey = i;
  }
  //TODO nullcheck
  return Math.round(ts / timeMap[prevTimeKey!]).toString() + prevTimeKey;
};

export const imageContainerSize = (
  imageHeight: number,
  imageWidth: number,
  maxScreenWidth: number,
) => {
  const aspectRatio = imageHeight / imageWidth;
  let containerHeight: number = 0;
  let containerWidth: number = 0;
  if (aspectRatio <= 1) {
    if (imageWidth > maxScreenWidth) {
      containerWidth = maxScreenWidth;
      containerHeight = aspectRatio * containerWidth;
    } else {
      containerWidth = imageWidth;
      containerHeight = aspectRatio * imageWidth;
    }
  } else {
    containerHeight = 526;
    containerWidth = containerHeight / aspectRatio;
    if (containerWidth > maxScreenWidth) {
      containerWidth = maxScreenWidth;
      containerHeight = aspectRatio * containerWidth;
    }
  }

  return {
    containerHeight: containerHeight,
    containerWidth: containerWidth,
  };
};

const monthMap = new Map([
  [1, 'Jan'],
  [2, 'Feb'],
  [3, 'Mar'],
  [4, 'Apr'],
  [5, 'May'],
  [6, 'Jun'],
  [7, 'Jul'],
  [8, 'Aug'],
  [9, 'Sep'],
  [10, 'Oct'],
  [11, 'Nov'],
  [12, 'Dec'],
]);

export const getDateFromDateInstance = (
  data: LinkedInParsedDataEducationDateInstance,
) => (data ? new Date(data.year!, data.month! - 1) : undefined);

export const getDateMonthString = (date?: Date) => {
  const currentDate = date || new Date();

  return `${monthMap.get(
    currentDate.getMonth() + 1,
  )},${currentDate.getFullYear()}`;
};

export const getDateInstanceFromDate = (
  date: Date,
): LinkedInParsedDataEducationDateInstance => ({
  month: date.getMonth(),
  year: date.getFullYear(),
});

export const getLastUpdatedTime = (
  createdAt: FirebaseFirestoreTypes.Timestamp,
) => {
  if (!createdAt) {
    return 'Just Now';
  }
  const now = moment(new Date());
  const createdAtDate = createdAt.toDate();
  const createdAtDateMoment = moment(createdAtDate);
  const daysDiffFromMsgCreatedAt = now.diff(createdAtDate, 'days');
  const minutesDiffFromMsgCreatedAt = now.diff(createdAtDate, 'minutes');
  if (minutesDiffFromMsgCreatedAt < 2) {
    return 'Just Now';
  } else if (daysDiffFromMsgCreatedAt === 1) {
    return 'Yesterday';
  } else if (daysDiffFromMsgCreatedAt < 7 && daysDiffFromMsgCreatedAt > 1) {
    return createdAtDateMoment.format('dddd');
  } else if (daysDiffFromMsgCreatedAt === 0) {
    return createdAtDateMoment.format('hh:mm a');
  } else {
    return createdAtDateMoment.format('Do MMM YY');
  }
};

export const useInputDebounce = (callback: (str: string) => void, delay) => {
  const timeout = useRef<any>();
  return function (str: string) {
    if (!!timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => callback(str), delay);
  };
};

export const usePressDebounce = (callback: () => void, delay) => {
  const pressed = useRef<boolean>(false);
  const timeout = useRef<any>();
  return function () {
    if (!pressed.current) {
      pressed.current = true;
      callback();
      timeout.current = setTimeout(() => {
        pressed.current = false;
      }, delay);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  };
};

export function isSameDay(
  messageCreatedAt: Date,
  diffMessageCreatedAt: Date | undefined,
) {
  if (!diffMessageCreatedAt) {
    return false;
  }

  const currentCreatedAt = moment(messageCreatedAt);
  const diffCreatedAt = moment(diffMessageCreatedAt);

  if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) {
    return false;
  }

  return currentCreatedAt.isSame(diffCreatedAt, 'day');
}

export function isSameUser(
  currentUser: {_id: string},
  diffUser: {_id: string} | undefined,
) {
  return !!(diffUser && currentUser && diffUser._id === currentUser._id);
}

function isValidURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
}
export const getMessageType = (
  text?: string,
  data?: OpenPickerResponse,
  subject?: string,
): MessageType => {
  if (!data) {
    const url = isValidURL(text);
    if (!!url) return MessageType.Text;
    if (!text && !!subject) return MessageType.Request;
    return MessageType.Url;
  }
  if (data.type.startsWith('image')) return MessageType.Image;
  if (data.type.startsWith('video')) return MessageType.Video;
  return MessageType.Document;
};

export const getMessage = (
  _id: string,
  text?: string,
  data?: OpenPickerResponse,
  subject?: string,
  message?: string,
): Message => {
  const type = getMessageType(text, data, subject);
  switch (type) {
    case MessageType.Image:
    case MessageType.Video:
      return {
        _id: uuid.v4().toString(),
        createdAt: firestore.Timestamp.now(),
        received: false,
        sent: false,
        type,
        text,
        user: {
          _id,
        },
        done: true,
        local: {
          uri: data!.uri,
        },
      } as VideoMessage | ImageMessage;
    case MessageType.Url:
      return {
        _id: uuid.v4().toString(),
        createdAt: firestore.Timestamp.now(),
        received: false,
        sent: false,
        type: MessageType.Url,
        text,
        user: {
          _id,
        },
        done: true,
      } as UrlMessage;
    case MessageType.Post:
      return {} as any;
    case MessageType.Request:
      return {
        _id: uuid.v4().toString(),
        createdAt: firestore.Timestamp.now(),
        received: false,
        sent: false,
        type: MessageType.Request,
        user: {
          _id,
        },
        done: true,
        message,
        subject,
      } as RequestMessage;

    default:
      return {
        _id: uuid.v4().toString(),
        createdAt: firestore.Timestamp.now(),
        received: false,
        sent: false,
        type: MessageType.Text,
        text,
        user: {
          _id,
        },
        done: true,
      } as TextMessage;
  }
};

export const resolveImageAssetSource = (source: ImageSourcePropType) => {
  if (Platform.OS == 'web') {
    return resolveAssetSource(source as any);
  } else {
    return Image.resolveAssetSource(source);
  }
};

export const onNotificationClick = (notification: NotificationData) => {
  dispatch(
    updateNotificationReadStatus({
      notificationId: notification.notification_id,
    }),
  );

  if (
    notification.type === NotificationType.CommPostNew ||
    notification.type === NotificationType.WelcomePost
  ) {
    NavigationService.getInstance().openCommunityDetails(
      notification.data.community_id!,
    );
    NavigationService.getInstance().openFeedDetails(notification.data.post_id!);
  } else {
    NavigationService.getInstance().openFeedDetails(notification.data.post_id!);
  }
};
