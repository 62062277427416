import * as React from 'react';
import {Pressable, Linking, StyleSheet} from 'react-native';
import {CustomText} from './Texts';
import {FontFamily, FontSize} from '@utils/sizes';

const WhatsappSupport = ({mobileNumber}: {mobileNumber: string}) => {
  const openWhatsApp = () => {
    let url = 'whatsapp://send?phone=91' + mobileNumber;
    Linking.openURL(url)
      .then(data => {
        console.log('WhatsApp Opened successfully ' + data);
      })
      .catch(() => {
        console.log('Unable to open WhatsApp');
      });
  };

  return (
    <Pressable onPress={openWhatsApp}>
      <CustomText
        size={FontSize.small}
        fontFamily={FontFamily.FigtreeMedium}
        style={style.labelText}>
        HELP
      </CustomText>
    </Pressable>
  );
};

const style = StyleSheet.create({
  labelText: {
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
});

export default WhatsappSupport;
