/*eslint-disable */
import React from 'react';
import type {FC} from 'react';
import {View, Text, StyleSheet, Pressable} from 'react-native';
import {useSelector} from 'react-redux';
import Hyperlink from 'react-native-hyperlink';
import CustomAvatar from '@components/Avatar';
import {timeago} from '@utils/utilityFunctions';
import {
  createCommentAvatarSelector,
  createCommentByWorkDetailsSelector,
  createCommentBySelector,
  createCommentCreatedAtSelector,
  createCommentTextSelector,
  commentTypeSelector,
  createCommentAuthorIdSelector,
  createCommentAnonymitySelector,
} from '@redux/selectors/feedSelector';
import NavigationService from '@services/navigationService';

import CommentCardFooter from './CommentCardFooter';
import {
  EventTypeEnum,
  FeedEnagagementAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import Analytics, {IEventData, IExtraData} from '@wyse/analytics';
import {FontFamily} from '@utils/sizes';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {useRoute} from '@react-navigation/native';
import {AuthScreens} from '@navigations/ScreenNames';
import LocalStorage from '@services/storage';
import {CustomText} from '@components/Texts';
import {RandomAvatar} from '@assets';
const MAIN_PADDING = 16;
const AVATAR_RIGHT_MARGIN = 8;
const AVATAR_SIZE = 36;
const styles = StyleSheet.create({
  container: {
    padding: MAIN_PADDING,
    borderRadius: 10,
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  replyContainer: {
    paddingLeft: MAIN_PADDING + AVATAR_RIGHT_MARGIN + AVATAR_SIZE,
  },
  sunContainer: {
    alignItems: 'flex-start',
    flex: 1,
    backgroundColor: '#E0E0E0',
    paddingTop: 4,
    paddingBottom: 8,
    paddingHorizontal: 12,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#E0E0E0',
  },
  avatarContainer: {marginRight: AVATAR_RIGHT_MARGIN},
  commentByDetailsText: {},
  createdAtText: {
    fontSize: 10,
    fontWeight: '400',
    lineHeight: 12,
    color: '#828282',
    fontFamily: FontFamily.lato_regular,
  },
  commentByText: {},
  commentTextContainer: {alignSelf: 'stretch', marginBottom: 10},
  commentText: {
    letterSpacing: 0.3,
  },
  stretch: {alignSelf: 'stretch'},
  commentHyperLinkContainer: {color: '#2980b9'},
});

const redirectToProfileScreen = (
  commentAuthorId: string,
  commentId: string,
  commentType: string,
) => {
  const extraData: IExtraData = {
    commentId: commentId,
  };
  const analyticsEventPayload: IEventData = {
    ev_typ: EventTypeEnum.ANALYTICS,
    ev_val:
      commentType == 'comment'
        ? FeedEnagagementAnalEventValue.PROFILE_BANNER_CLICK_COMMENT
        : FeedEnagagementAnalEventValue.PROFILE_BANNER_CLICK_REPLY,
    thing_type: ThingTypeEnum.PROFILE,
    thing_id: commentAuthorId,
    extra_data: JSON.stringify(extraData),
  };
  Analytics.trackEvent(analyticsEventPayload);
  NavigationService.getInstance().openProfileDetails(commentAuthorId);
};

type IdProps = {id: string};
const CommentAvatar: FC<{id: string; commentType: string}> = ({
  id,
  commentType,
}) => {
  const anonymity = useSelector(createCommentAnonymitySelector(id));
  const avatar = useSelector(createCommentAvatarSelector(id));
  const commentAuthorId = useSelector(createCommentAuthorIdSelector(id));
  return (
    <Pressable
      onPress={() =>
        !anonymity
          ? !!commentAuthorId
            ? redirectToProfileScreen(commentAuthorId, id, commentType)
            : null
          : null
      }>
      <View style={styles.avatarContainer}>
        <CustomAvatar
          height={AVATAR_SIZE}
          width={AVATAR_SIZE}
          borderRadius={AVATAR_SIZE / 2}
          source={avatar || RandomAvatar}
        />
      </View>
    </Pressable>
  );
};

const CommentBy: FC<IdProps> = ({id}) => {
  const by = useSelector(createCommentBySelector(id));
  return (
    <CustomText
      fontFamily={FontFamily.FigtreeMedium}
      style={styles.commentByDetailsText}>
      {by}
    </CustomText>
  );
};

const CommentByWork: FC<IdProps> = ({id}) => {
  const work = useSelector(createCommentByWorkDetailsSelector(id));
  return (
    <CustomText color="lightText" style={styles.commentByText}>
      {work}
    </CustomText>
  );
};

const CommentCreatedAt: FC<IdProps> = ({id}) => {
  const createdAt = useSelector(createCommentCreatedAtSelector(id));
  return (
    <CustomText style={styles.createdAtText}>{timeago(createdAt)}</CustomText>
  );
};

const CommentText: FC<IdProps> = ({id}) => {
  const text = useSelector(createCommentTextSelector(id));
  return (
    <View style={styles.commentTextContainer}>
      <Hyperlink
        linkDefault={true}
        style={styles.stretch}
        linkStyle={styles.commentHyperLinkContainer}>
        <CustomText style={styles.commentText}>{text}</CustomText>
      </Hyperlink>
    </View>
  );
};

const CommentContainer: FC<IdProps & {children: any}> = ({children, id}) => {
  const type = useSelector(commentTypeSelector(id));
  return (
    <View style={[styles.container, type === 'reply' && styles.replyContainer]}>
      {children}
    </View>
  );
};

const CommentCard = ({
  id,
  onReplyPress,
}: {
  id: string;
  onReplyPress: (
    parentId: string,
    commentType: string,
    commentId: string,
  ) => void;
}) => {
  const route = useRoute();
  const loggedInUser = useSelector(userAccountThingIdSelector);
  const commentAuthorId = useSelector(createCommentAuthorIdSelector(id));
  const anonymity = useSelector(createCommentAnonymitySelector(id));
  const commentType = useSelector(commentTypeSelector(id));
  return (
    <CommentContainer {...{id}}>
      <CommentAvatar {...{id, commentType}} />
      <View style={styles.sunContainer}>
        <Pressable
          onPress={async () => {
            if (!loggedInUser) {
              await LocalStorage.setData('redirect', route);
              NavigationService.getInstance().replaceToAuth({
                page: AuthScreens.OtpScreen,
              });
              return;
            }
            if (!anonymity) {
              if (!!commentAuthorId) {
                redirectToProfileScreen(commentAuthorId, id, commentType);
              }
            }
          }}>
          <View style={{marginBottom: 10, width: '100%'}}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              <CommentBy {...{id}} />
              {/* <CommentCreatedAt {...{id}} /> */}
            </View>
            <CommentByWork {...{id}} />
          </View>
        </Pressable>
        <CommentText {...{id}} />
        <CommentCardFooter {...{id, loggedInUser, onReplyPress}} />
      </View>
    </CommentContainer>
  );
};

export default CommentCard;
