import React from 'react';
import {View, Platform, Pressable, StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';
import LinearGradient from '@libs/LinearGradient';
import CustomAvatar from '@components/Avatar';
import {CustomIcon, Icons} from '@components/icon';
import {
  convertToCamelCaseString,
  resolveImageAssetSource,
} from '@utils/utilityFunctions';
import {
  communityAvatarColorSelector,
  communityAvatarUrlSelector,
} from '@redux/selectors/metaSelector';
import Colors from '@constants/Colors';
import ProfileOptionSheet, {
  type ProfileSheetOpenProps,
} from '@components/Modals/ProfileOptionSheet';
import {RandomAvatar, Logo} from '@assets';
const anonymousURI = resolveImageAssetSource(RandomAvatar).uri;
const unavailableCommunityURI = resolveImageAssetSource(Logo).uri;

import {FontFamily, FontSize} from '@utils/sizes';
import PostOptionSheet, {
  PostSheetOpenProps,
} from '@components/Modals/PostOptionSheet';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {CustomText} from '@components/Texts';

const styles = StyleSheet.create({
  moreButtonContainer: {
    height: '100%',
    width: 30,

    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  container: {
    alignSelf: 'stretch',
  },
  communityContainer: {
    alignSelf: 'stretch',
    borderRadius: 8,
    marginBottom: 12,
    overflow: 'hidden',
    marginHorizontal: 12,
  },
  communityInner: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  profileContainer: {
    height: 36,
    alignSelf: 'stretch',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 16,
  },
});

const FeedCardHeader = ({
  authorImage,
  authorFullName,
  authorCurrentRole,
  authorCurrentOrgName,
  communityName,
  communityId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  feedCreatedAt,
  isAnonPost,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  postLabelThingId,
  moreOptions,
  profileOptions,
  redirectToCommunityDetail,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  postThingId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  userThingId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  feedCardIndex,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleShare,
  hideCommunityHeader,
}: {
  authorImage: string;
  authorFullName: string;
  moreOptions: PostSheetOpenProps;
  profileOptions: ProfileSheetOpenProps;

  authorCurrentRole: string | null;
  authorCurrentOrgName: string | null;
  communityName: string;
  communityId: string;
  feedCreatedAt: string;
  isAnonPost?: boolean;
  postLabelThingId?: string[];
  redirectToCommunityDetail: any;
  postThingId?: string;
  userThingId?: string;
  feedCardIndex?: number;
  handleShare?: any;
  hideCommunityHeader?: boolean;
}) => {
  const loggedInUser = useSelector(userAccountThingIdSelector);
  const communityUrl = useSelector(communityAvatarUrlSelector(communityId));
  const communityColor = useSelector(communityAvatarColorSelector(communityId));

  const renderAuthorNameEle = () => (
    <CustomText
      color="text"
      size={FontSize.title}
      fontFamily={FontFamily.FigtreeSemiBold}>
      {isAnonPost
        ? 'Works as ' + convertToCamelCaseString(authorCurrentRole || '')
        : authorFullName}
    </CustomText>
  );

  const renderAuthorHeadlineEle = () => {
    return (
      <CustomText fontFamily={FontFamily.FigtreeRegular} color="lightText">
        {isAnonPost
          ? 'Anonymous'
          : authorCurrentRole + ' | ' + authorCurrentOrgName}
      </CustomText>
    );
  };

  const renderCommunityDescEle = () => (
    <LinearGradient
      colors={[communityColor || 'rgba(255,255,255,0)', 'rgba(255,255,255,0)']}
      start={{x: Platform.OS == 'ios' ? -2.5 : -15, y: 0}}
      end={{x: 1, y: 0}}
      style={styles.communityContainer}>
      <Pressable
        style={styles.communityInner}
        onPress={redirectToCommunityDetail}>
        <View style={{marginRight: 5}}>
          <CustomAvatar
            width={20}
            height={20}
            borderRadius={20}
            source={communityUrl || unavailableCommunityURI}
          />
        </View>

        <CustomText fontFamily={FontFamily.FigtreeSemiBold} color="text">
          {communityName}
        </CustomText>
      </Pressable>
    </LinearGradient>
  );

  return (
    <View style={styles.container}>
      {!hideCommunityHeader ? renderCommunityDescEle() : null}
      <ProfileOptionSheet.ProfileOptionsheetButton
        {...profileOptions}
        loggedInUser={loggedInUser}
        disabled={isAnonPost}
        style={styles.profileContainer}>
        <CustomAvatar
          width={36}
          height={36}
          borderRadius={18}
          source={authorImage || anonymousURI}
        />
        <View
          style={{
            flex: 1,
            paddingHorizontal: 8,
            justifyContent: 'space-between',
          }}>
          {renderAuthorNameEle()}
          {renderAuthorHeadlineEle()}
        </View>

        <PostOptionSheet.PostOptionsheetButton
          {...moreOptions}
          loggedInUser={loggedInUser}
          style={styles.moreButtonContainer}>
          <CustomIcon
            size={20}
            name={Icons.MoreHoriz}
            color={Colors.mediumDarkGrey}
          />
        </PostOptionSheet.PostOptionsheetButton>
      </ProfileOptionSheet.ProfileOptionsheetButton>
    </View>
  );
};

export default FeedCardHeader;
