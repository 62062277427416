/*eslint-disable */
import React, {useEffect, useState, useRef} from 'react';
import {
  View,
  Linking,
  TextInput,
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  SafeAreaView,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  TouchableOpacity,
} from 'react-native';
import Spinner from 'react-native-loading-spinner-overlay';
import {Route, StackActions} from '@react-navigation/native';
import {Button} from 'native-base';
import auth, {FirebaseAuthTypes} from '@libs/Firebase/auth';
import Icon from 'react-native-vector-icons/Ionicons';
import {useSelector, RootState, dispatch} from '@redux/store';
import {AccountInfoRequestPayload} from '@models/AccountData/AccountInfoRequestPayload';
import ConstantsEnum from '@constants/constantsEnum';
import {AppScreens, AuthScreens, MainScreens} from '@navigations/ScreenNames';
import {
  confirmOtp,
  createUserAccount,
  fetchUserAccountDataByPhoneNumber,
  requestOtp,
} from '@redux/actions/userAccount';
import {
  hasUserAccountSelector,
  userAccountNameSelector,
} from '@redux/selectors/userAccountSelectors';
import {CustomIcon} from '@components/icon';
import {TextInputMask} from 'react-native-masked-text';
const {width} = Dimensions.get('window');
import WhatsappSupport from '@components/WhatsappSupport';
import Analytics, {IEventData, IExtraData} from '@wyse/analytics';
import {
  EventTypeEnum,
  LoginAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import signInWithPhoneNumber, {ReCaptchaContainer} from '@utils/signIn';
import WebContainer from '@components/WebContainer';
import NavigationService from '@services/navigationService';
import AppsFlyerService from '@services/appsFlyerService';
import {textInputStyles} from '@components/Inputs';
import LocalStorage from '@services/storage';
import {CustomText} from '@components/Texts';
import {FontFamily, FontSize} from '@utils/sizes';
import {isWeb} from '@utils/useColors';

export const OtpScreen = ({navigation}: {navigation: any}) => {
  const hasUserAccount = useSelector(hasUserAccountSelector);
  const userFullName = useSelector(userAccountNameSelector);
  const fetchAccountDataByNumStatus: string = useSelector(
    (state: RootState) => state.userAccountData.fetchAccountDataByNumStatus,
  );

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('######');
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [confirm, setConfirm] =
    useState<FirebaseAuthTypes.ConfirmationResult>();
  const [_, setPhoneAuthConfirmationPending] = useState<boolean>(false);
  const [otpVerificationPending, setOtpVerificationPending] =
    useState<boolean>(false);
  const [fetchAccountDataByNumPending, setFetchAccountDataByNumPending] =
    useState<boolean>(false);

  const inputRefs = useRef<TextInput[]>([]);
  const [borderColors, setBorderColors] = useState<string[]>(
    Array(6).fill('#BDBDBD'),
  );
  const [deletePressCount, setDeletePressCount] = useState<number>(0);
  const [counter, setCounter] = useState(45);
  const [isActive, setIsActive] = useState(false);
  const [otpError, setOtpError] = useState(false);

  useEffect(() => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: LoginAnalEventValue.LOGIN_PAGE_IMP,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
  }, []);

  useEffect(() => {
    if (isActive) {
      if (counter > 0) {
        const interval = setInterval(() => {
          setCounter(prevCounter => prevCounter - 1);
        }, 1000);
        return () => {
          clearInterval(interval);
        };
      } else {
        setIsActive(false);
      }
    } else {
      setIsActive(false);
    }
  }, [isActive, counter]);

  useEffect(() => {
    (async () => {
      if (
        fetchAccountDataByNumStatus == ConstantsEnum.ApiStatusEnum.SUCCEEDED ||
        fetchAccountDataByNumStatus == ConstantsEnum.ApiStatusEnum.FAILED
      ) {
        if (!hasUserAccount) {
          const userAccountInfo: AccountInfoRequestPayload = {
            linkedinProfileUrl: null,
            thingId: null,
            phoneNumber: phoneNumber.replace(/\s/g, ''),
            uuid: null,
          };
          await dispatch(createUserAccount(userAccountInfo));
          navigation.dispatch(
            StackActions.replace(AuthScreens.LinkedinImportScreen),
          );
        } else {
          const analyticsEventPayload: IEventData = {
            ev_typ: EventTypeEnum.ANALYTICS,
            ev_val: LoginAnalEventValue.LOGIN_SUCCESS,
            thing_type: ThingTypeEnum.NA,
          };
          Analytics.trackEvent(analyticsEventPayload);
          if (!userFullName) {
            navigation.dispatch(
              StackActions.replace(AuthScreens.LinkedinImportScreen),
            );
          } else {
            const data = await LocalStorage.getData<
              Route<
                AppScreens,
                {
                  feedThingId?: string;
                  isProfileCard: boolean;
                  isDynamicLink?: boolean;
                  feedDataIndex?: number;
                  isFromComment?: boolean;
                }
              >
            >('redirect');

            console.log('data', data?.name);

            navigation.dispatch(
              StackActions.replace(MainScreens.App, {
                page: data?.name,
                params: data?.params,
              }),
            );
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFullName, hasUserAccount, fetchAccountDataByNumStatus]);

  const onSignInPressed = async () => {
    setCounter(45);
    const phNumber = `+91${phoneNumber.replace(/\s/g, '')}`;
    const extraData: IExtraData = {
      mobile: phoneNumber.replace(/\s/g, ''),
    };
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: LoginAnalEventValue.MOBILE_NUMBER_ENTERED,
      thing_type: ThingTypeEnum.NA,
      extra_data: JSON.stringify(extraData),
    };
    Analytics.trackEvent(analyticsEventPayload);
    try {
      setShowOtp(true);
      if (
        phoneNumber.replace(/\s/g, '') == '9407302716' ||
        phoneNumber.replace(/\s/g, '') == '4545454715'
      ) {
        const confirmation = await signInWithPhoneNumber(phNumber);
        setConfirm(confirmation);
      } else {
        dispatch(requestOtp(phoneNumber.replace(/\s/g, '')));
      }
      setIsActive(true);
      setPhoneAuthConfirmationPending(false);
    } catch (err) {
      console.log('error', err);
      setPhoneAuthConfirmationPending(false);
    }
  };

  const confirmOTP = async () => {
    const extraData: IExtraData = {
      mobile: phoneNumber.replace(/\s/g, ''),
      otp: verificationCode,
    };
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: LoginAnalEventValue.OTP_ENTERED_CLICK,
      thing_type: ThingTypeEnum.NA,
      extra_data: JSON.stringify(extraData),
    };
    Analytics.trackEvent(analyticsEventPayload);
    AppsFlyerService.registerInAppEvents(
      LoginAnalEventValue.OTP_ENTERED_CLICK,
      'na',
    );
    setOtpVerificationPending(true);
    if (
      phoneNumber.replace(/\s/g, '') == '9407302716' ||
      phoneNumber.replace(/\s/g, '') == '4545454715' ||
      phoneNumber.replace(/\s/g, '') == '6281954542'
    ) {
      await confirm?.confirm(verificationCode);
      setOtpVerificationPending(false);
      const userAccountInfo: AccountInfoRequestPayload = {
        linkedinProfileUrl: null,
        thingId: null,
        phoneNumber: phoneNumber.replace(/\s/g, ''),
        uuid: null,
      };
      dispatch(fetchUserAccountDataByPhoneNumber(userAccountInfo));
      setFetchAccountDataByNumPending(true);
    } else {
      const response = await dispatch(
        confirmOtp({
          mobile: phoneNumber.replace(/\s/g, ''),
          otp: verificationCode,
        }),
      );
      if (confirmOtp.fulfilled.match(response)) {
        const extraData: IExtraData = {
          mobile: phoneNumber.replace(/\s/g, ''),
          otp: verificationCode,
        };
        const analyticsEventPayload: IEventData = {
          ev_typ: EventTypeEnum.ANALYTICS,
          ev_val: LoginAnalEventValue.OTP_CORRECT,
          thing_type: ThingTypeEnum.NA,
          extra_data: JSON.stringify(extraData),
        };
        Analytics.trackEvent(analyticsEventPayload);
        AppsFlyerService.registerInAppEvents(
          LoginAnalEventValue.OTP_CORRECT,
          'na',
        );
        setOtpVerificationPending(false);
        setFetchAccountDataByNumPending(true);
      } else if (confirmOtp.rejected.match(response)) {
        setOtpVerificationPending(false);
        setBorderColors(Array(6).fill('#EB5757'));
        setOtpError(true);
      }
    }
  };

  const onOTPChange = (text: string, index: number) => {
    if (otpError) {
      setOtpError(false);
    }
    let otp =
      verificationCode.substring(0, index) +
      text +
      verificationCode.substring(index + 1);
    setVerificationCode(otp);
    const updatedBorderColors = [...borderColors];
    updatedBorderColors[index] = text.length > 0 ? '#9B51E0' : '#BDBDBD';
    setBorderColors(updatedBorderColors);

    if (text.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const onKeyPress = (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>,
    index: number,
  ) => {
    if (event.nativeEvent.key === 'Backspace') {
      if (index > 0) {
        if (deletePressCount === 1) {
          inputRefs.current[index - 1].focus();
          setDeletePressCount(0);
        } else {
          setDeletePressCount(prevCount => prevCount + 1);
        }
      }
    } else {
      setDeletePressCount(0);
    }
  };

  const handleResendOTP = () => {
    if (!isActive) {
      setCounter(60);
      setIsActive(true);
      onSignInPressed();
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}.${formattedSeconds}`;
  };

  return (
    <SafeAreaView style={{flex: 1, paddingHorizontal: 16}}>
      <WebContainer style={{flex: 1}}>
        <ScrollView
          contentContainerStyle={{flexGrow: Platform.OS == 'android' ? 1 : 0}}
          showsVerticalScrollIndicator={true}
          automaticallyAdjustKeyboardInsets={
            Platform.OS === 'ios' ? true : false
          }
          keyboardShouldPersistTaps="handled">
          <Spinner
            visible={otpVerificationPending || fetchAccountDataByNumPending}
            textContent={
              fetchAccountDataByNumPending
                ? 'Fetching account'
                : 'Verifying OTP'
            }
            textStyle={styles.spinnerTextStyle}
          />
          <View
            style={[
              styles.navigationContainer,
              {display: Platform.OS === 'web' ? 'none' : 'flex'},
            ]}>
            <Icon
              name="arrow-back"
              size={23}
              onPress={() => {
                if (showOtp) {
                  setShowOtp(false);
                } else {
                  if (NavigationService.getInstance().cangoBack()) {
                    NavigationService.getInstance().goBack();
                    return;
                  }
                  navigation.dispatch(
                    StackActions.replace(AuthScreens.WelcomeScreen),
                  );
                }
              }}
              color="#333333"
            />
            <WhatsappSupport
              mobileNumber={ConstantsEnum.WhatsappSupportNumber}
            />
          </View>
          <View
            style={{flex: 1, flexDirection: 'column', paddingHorizontal: 10}}>
            <View
              style={{
                width: width * 0.91,
                marginTop: 57,
              }}>
              <CustomText
                size={FontSize.largeHeading}
                fontFamily={FontFamily.FigtreeBold}
                style={styles.title}>
                {!showOtp
                  ? 'Enter your mobile number to get OTP'
                  : 'Please Enter the OTP'}
              </CustomText>
            </View>
            {showOtp && (
              <View style={styles.otpSentTextContainer}>
                <CustomText
                  fontFamily={FontFamily.FigtreeMedium}
                  style={styles.otpSentText}>
                  {`OTP has been sent to +91-${phoneNumber}`}
                </CustomText>
                <CustomIcon
                  size={11}
                  name="edit"
                  color={'#9B51E0'}
                  style={{marginLeft: 14}}
                  onPress={() => setShowOtp(false)}
                />
              </View>
            )}

            {!showOtp && (
              <View
                style={[
                  styles.phoneNumberInputContainer,
                  {
                    borderColor:
                      phoneNumber.replace(/\s/g, '').length == 10
                        ? '#9B51E0'
                        : '#BDBDBD',
                  },
                ]}>
                <CustomText
                  size={FontSize.title}
                  fontFamily={FontFamily.FigtreeMedium}
                  color="text"
                  style={styles.countryCodeText}>
                  IN +91
                </CustomText>
                <TextInputMask
                  type="custom"
                  options={{mask: '99999 99999'}}
                  style={[styles.phoneNumberText, textInputStyles.webInput]}
                  selectionColor="#9B51E0"
                  textContentType="telephoneNumber"
                  keyboardType="numeric"
                  returnKeyType="done"
                  placeholder={'Mobile Number'}
                  placeholderTextColor="#BDBDBD"
                  maxLength={11}
                  value={phoneNumber}
                  onChangeText={text => setPhoneNumber(text)}
                />
              </View>
            )}
            {showOtp && (
              <View style={styles.otpContainer}>
                {[...Array(6)].map((_, index) => (
                  <TextInput
                    ref={ref => (inputRefs.current[index] = ref!)}
                    style={[
                      styles.otpBlock,
                      {
                        borderColor: borderColors[index],
                        lineHeight: Platform.OS === 'ios' ? 22 : 36,
                        width:
                          Platform.OS === 'web' ? 0.035 * width : 0.123 * width,
                      },
                      textInputStyles.webInput,
                    ]}
                    textContentType="oneTimeCode"
                    keyboardType="numeric"
                    returnKeyType="done"
                    placeholder={'-'}
                    placeholderTextColor="#BDBDBD"
                    maxLength={1}
                    onChangeText={text => onOTPChange(text, index)}
                    key={index}
                    onKeyPress={event => onKeyPress(event, index)}
                    selectionColor="#9B51E0"
                  />
                ))}
              </View>
            )}
            {otpError && (
              <View style={{marginTop: 8, display: otpError ? 'flex' : 'none'}}>
                <CustomText size={FontSize.small} style={styles.invalidOtpText}>
                  Incorrect or Expired OTP
                </CustomText>
              </View>
            )}

            {showOtp && (
              <View style={styles.resendOtpContainer}>
                <TouchableOpacity
                  onPress={handleResendOTP}
                  disabled={isActive}
                  activeOpacity={0.6}
                  style={{opacity: isActive ? 0.5 : 1}}>
                  <CustomText style={styles.resendOtpText}>
                    Resend OTP
                  </CustomText>
                </TouchableOpacity>
                <CustomText style={styles.timerText}>
                  | {formatTime(counter)}
                </CustomText>
              </View>
            )}

            <View style={styles.buttonContainer}>
              <Button
                style={{
                  backgroundColor: !showOtp
                    ? phoneNumber.replace(/\s/g, '').length == 10
                      ? '#9B51E0'
                      : '#E0E0E0'
                    : !verificationCode.includes('#') &&
                      verificationCode.length === 6
                    ? '#9B51E0'
                    : '#E0E0E0',
                  borderRadius: 8,
                }}
                onPress={!showOtp ? onSignInPressed : confirmOTP}
                disabled={
                  !showOtp
                    ? phoneNumber.replace(/\s/g, '').length == 10
                      ? false
                      : true
                    : !verificationCode.includes('#') &&
                      verificationCode.length === 6
                    ? false
                    : true
                }>
                <CustomText
                  style={{
                    color: !showOtp
                      ? phoneNumber.replace(/\s/g, '').length == 10
                        ? '#F4F4F4'
                        : '#BDBDBD'
                      : !verificationCode.includes('#') &&
                        verificationCode.length === 6
                      ? '#F4F4F4'
                      : '#BDBDBD',
                  }}>
                  {!showOtp ? 'Continue' : 'Verify and Proceed'}
                </CustomText>
              </Button>
            </View>

            {!showOtp && (
              <View style={styles.termsContainer}>
                <CustomText style={styles.termsText}>
                  By Clicking, I accept the
                </CustomText>
                <CustomText
                  fontFamily={FontFamily.FigtreeBold}
                  style={styles.linkText}
                  onPress={() => {
                    Linking.openURL('https://api.bewyse.in/privacy');
                  }}>
                  Terms of Service
                </CustomText>
                <CustomText style={styles.termsText}>and</CustomText>
                <CustomText
                  fontFamily={FontFamily.FigtreeBold}
                  style={styles.linkText}
                  onPress={() => {
                    Linking.openURL('https://api.bewyse.in/privacy');
                  }}>
                  Privacy Policy
                </CustomText>
              </View>
            )}
          </View>
        </ScrollView>
        {Platform.OS == 'web' ? <ReCaptchaContainer /> : null}
      </WebContainer>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  spinnerTextStyle: {
    color: '#FFF',
  },
  title: {},
  otpSentTextContainer: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  otpSentText: {},
  phoneNumberInputContainer: {
    marginTop: 24,
    borderWidth: 1,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
  },
  countryCodeText: {
    alignItems: 'center',
    marginRight: 14,
  },
  phoneNumberText: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 16,
    alignItems: 'center',
    color: '#4F4F4F',
    fontFamily: isWeb ? 'Figtree' : FontFamily.FigtreeMedium,
    padding: 1,
    flex: 1,
  },
  otpContainer: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
  },
  otpBlock: {
    fontSize: 20,
    fontWeight: '500',
    textAlign: 'center',
    color: '#4F4F4F',
    fontFamily: isWeb ? 'Figtree' : FontFamily.FigtreeMedium,
    height: 44,
    flex: 1,
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 4,
    paddingHorizontal: 6,
    marginRight: 8,
  },
  invalidOtpText: {
    lineHeight: 16,
    color: '#EB5757',
  },
  resendOtpContainer: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  resendOtpText: {
    fontSize: 11,
    fontWeight: '400',
    lineHeight: 16,
    color: '#828282',
    marginRight: 2,
    textDecorationLine: 'underline',
  },
  timerText: {
    fontSize: 11,
    fontWeight: '400',
    lineHeight: 16,
    color: '#828282',
  },
  buttonContainer: {
    height: 44,
    alignSelf: 'stretch',
    marginTop: 24,
  },
  termsContainer: {
    marginTop: 12,
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
  },
  termsText: {
    textAlign: 'center',
    marginHorizontal: 5,
  },
  linkText: {
    textDecorationLine: 'underline',
  },
  navigationContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 31,
    marginHorizontal: 10,
  },
});

export default OtpScreen;
