import {DynamicLinkThingTypeEnum} from '@constants/constantsEnum';
import axios from 'axios';

const backgroundDynamicLinkHandler = async () => {};

const foregroundDynamicLinkHandler = () => {};

const createDynamicLink = async (
  thingId: string,
  thingType: DynamicLinkThingTypeEnum,
) => {
  console.log('createDynamicLink', thingId, thingType);

  try {
    const response = await axios.post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBsk0ztFH-_qD_CKl3b8K_F8ia6Amoaht8`,
      {
        dynamicLinkInfo: {
          domainUriPrefix: 'https://wyse.page.link',
          link: `https://web.bewyse.in${thingType}/${thingId}`, //`https://bewyse.in/${thingType}/?thing_id=${thingId}`,
          iosInfo: {
            iosBundleId: 'com.wyse.app',
            iosAppStoreId: '6446937173',
          },
          androidInfo: {
            androidPackageName: 'com.wyse.app',
          },
          navigationInfo: {
            enableForcedRedirect: true,
          },
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data.shortLink;
  } catch (error) {
    console.log('createDynamicLink error', error);

    return undefined;
  }
};

const DynamicLinkService = {
  backgroundDynamicLinkHandler,
  foregroundDynamicLinkHandler,
  createDynamicLink,
};

export default DynamicLinkService;
