import React, {useMemo} from 'react';
import type {
  BottomSheetBackdropProps,
  BottomSheetModal,
} from '@gorhom/bottom-sheet';
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedStyle,
} from 'react-native-reanimated';
import {Pressable} from 'react-native';

export const CustomBackdrop = ({
  animatedIndex,
  style,
}: BottomSheetBackdropProps) => {
  // animated variables
  const containerAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: interpolate(
        animatedIndex.value,
        [0, 1],
        [0, 1],
        Extrapolate.CLAMP,
      ),
    };
  }, []);

  // styles
  const containerStyle = useMemo(
    () => [
      style,
      {
        backgroundColor: '#a8b5eb',
      },
      containerAnimatedStyle,
    ],
    [style, containerAnimatedStyle],
  );

  return <Animated.View style={containerStyle} />;
};

export const CustomSimpleBackdrop = ({
  animatedIndex,
  style,
  sheetRef,
  disabled,
}: BottomSheetBackdropProps & {
  disabled?: boolean;
  sheetRef: React.RefObject<BottomSheetModal>;
}) => {
  // animated variables
  const containerAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: interpolate(
        animatedIndex.value,
        [-1, 0],
        [0, 1],
        Extrapolate.CLAMP,
      ),
    };
  }, []);

  // styles
  const containerStyle = useMemo(
    () => [
      style,
      {
        backgroundColor: '#00000040',
      },
      containerAnimatedStyle,
    ],
    [style, containerAnimatedStyle],
  );

  return (
    <Animated.View style={containerStyle}>
      <Pressable
        disabled={disabled}
        style={{flex: 1}}
        onPress={() => {
          sheetRef.current?.close();
        }}
      />
    </Animated.View>
  );
};
