import type {AccountData} from '@models/AccountData/AccountData';
import type {AccountDataEventCountUpdatePayload} from '@models/AccountData/AccountDataEventCountUpdatePayload';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {LinkedInParsedDataEducation} from '@models/AccountData/LinkedInModels/LinkedInParsedDataEducation';
import {
  ApiStatusEnum,
  BlockEventEnum,
  EventTypeEnum,
} from '@constants/constantsEnum';
import {
  blockAccountEventHandler,
  createUserAccount,
  editUserAccountData,
  fetchUserAccountDataByPhoneNumber,
  importUserAccountDataFromLinkedIn,
  onboardUser,
  uploadUserProfileImage,
} from '@redux/actions/userAccount';
import {LinkedInParsedDataProfilePosition} from '@models/AccountData/LinkedInModels/LinkedInParsedDataProfilePosition';
import {BlockAccountPayload} from '@models/AccountData/BlockAccountPayload';

export interface UserAccountData {
  accountData?: AccountData;
  status: ApiStatusEnum;
  fetchAccountDataByNumStatus: ApiStatusEnum;
  linkedinImportStatus: ApiStatusEnum;
  editUserAccountStatus: ApiStatusEnum;
  editUserAccountError?: string;
  profileImageUploadStatus: ApiStatusEnum;
  error?: string;
  blockAccountStatus: string;

  addEditEducationVisible: boolean;
  selectedEducation?: LinkedInParsedDataEducation;
  seelectedEducationIndex?: number;
  addEditWorkVisible: boolean;
  selectedWork?: LinkedInParsedDataProfilePosition;
  selectedWorkIndex?: number;
}

const initialState: UserAccountData = {
  status: ApiStatusEnum.IDLE,
  fetchAccountDataByNumStatus: ApiStatusEnum.IDLE,
  linkedinImportStatus: ApiStatusEnum.IDLE,
  editUserAccountStatus: ApiStatusEnum.IDLE,
  profileImageUploadStatus: ApiStatusEnum.IDLE,
  addEditEducationVisible: false,
  addEditWorkVisible: false,
  blockAccountStatus: ApiStatusEnum.IDLE,
};

export const userAccountDataSlice = createSlice({
  name: 'user/account',
  initialState: initialState,
  reducers: {
    setAccountData: (state, action: PayloadAction<AccountData>) => {
      state.accountData = action.payload;
    },
    // setOtherUserAccountData: (
    //   state,
    //   action: PayloadAction<AccountData | null>,
    // ) => {
    //   state.otherUserAccountData = action.payload;
    // },
    setFetchAccoutDataByNumStatus: (state, action) => {
      state.fetchAccountDataByNumStatus = action.payload;
    },
    updateThingsCountWithData: (
      state,
      action: PayloadAction<{
        t_downs: string[];
        t_ups: string[];
      }>,
    ) => {
      state.accountData!.t_downs = action.payload.t_downs;
      state.accountData!.t_ups = action.payload.t_ups;
    },
    updateThingsCount: (
      state,
      action: PayloadAction<AccountDataEventCountUpdatePayload>,
    ) => {
      if (action.payload.event_type == EventTypeEnum.DOWNVOTE) {
        if (state.accountData?.t_ups?.includes(action.payload.thing_id)) {
          let newUpVotedIdsArray = state.accountData?.t_ups?.filter(
            id => id != action.payload.thing_id,
          );
          state.accountData.t_ups = newUpVotedIdsArray;
        }
        let newDownVoteIdsArray = [
          ...state.accountData!.t_downs!,
          action.payload.thing_id,
        ];
        state.accountData!.t_downs = newDownVoteIdsArray;
      } else if (action.payload.event_type == EventTypeEnum.UPVOTE) {
        if (state.accountData?.t_downs?.includes(action.payload.thing_id)) {
          let newDownVoteIdsArray = state.accountData.t_downs.filter(
            id => id != action.payload.thing_id,
          );
          state.accountData.t_downs = newDownVoteIdsArray;
        }
        let newUpVoteIdsArray = [
          ...state.accountData!.t_ups!,
          action.payload.thing_id,
        ];
        state.accountData!.t_ups = newUpVoteIdsArray;
      }
    },

    updateBlockedUsers: (state, action: PayloadAction<BlockAccountPayload>) => {
      if (
        !!state.accountData &&
        !!state.accountData.blocked_users &&
        action.payload.action == BlockEventEnum.BLOCK
      ) {
        state.accountData.blocked_users.push(action.payload.resource_id);
      }
    },

    openAddEditEducation: (
      state,
      action: PayloadAction<
        | {
            data: LinkedInParsedDataEducation;
            index: number;
          }
        | undefined
      >,
    ) => {
      state.seelectedEducationIndex = action.payload?.index;
      state.selectedEducation = action.payload?.data;
      state.addEditEducationVisible = true;
    },
    closeAddEditEducation: state => {
      state.addEditEducationVisible = false;
    },
    cleaarAddEditEducationState: state => {
      state.selectedEducation = undefined;
      state.seelectedEducationIndex = undefined;
    },
    setLinkedinImportStatusIdle: state => {
      state.linkedinImportStatus = ApiStatusEnum.IDLE;
    },

    openAddEditWork: (
      state,
      action: PayloadAction<
        | {
            data: LinkedInParsedDataProfilePosition;
            index: number;
          }
        | undefined
      >,
    ) => {
      state.selectedWork = action.payload?.data;
      state.selectedWorkIndex = action.payload?.index;
      state.addEditWorkVisible = true;
    },
    closeAddEditWork: state => {
      state.addEditWorkVisible = false;
    },
    cleaarAddEditWorkState: state => {
      state.selectedWork = undefined;
      state.selectedWorkIndex = undefined;
    },
  },

  extraReducers: builder => {
    // importUserAccountDataFromLinkedIn
    builder.addCase(importUserAccountDataFromLinkedIn.pending, state => {
      if (!!state.error) state.error = undefined;
      // TODO: make the state to loading
      state.linkedinImportStatus = ApiStatusEnum.LOADING;
    });
    builder.addCase(
      importUserAccountDataFromLinkedIn.fulfilled,
      (state, action) => {
        // TODO: update the state to successful
        state.linkedinImportStatus = ApiStatusEnum.SUCCEEDED;
        state.accountData = action.payload;
      },
    );
    builder.addCase(
      importUserAccountDataFromLinkedIn.rejected,
      (state, action) => {
        // TODO: update the state to failure
        state.linkedinImportStatus = ApiStatusEnum.FAILED;
        state.error = action.payload as string;
      },
    );

    // fetchUserAccountDataByPhoneNumber
    builder.addCase(fetchUserAccountDataByPhoneNumber.pending, state => {
      if (!!state.error) state.error = undefined;
      state.fetchAccountDataByNumStatus = ApiStatusEnum.LOADING;
    });
    builder.addCase(
      fetchUserAccountDataByPhoneNumber.fulfilled,
      (state, action) => {
        state.fetchAccountDataByNumStatus = ApiStatusEnum.SUCCEEDED;
        state.accountData = action.payload;
      },
    );
    builder.addCase(
      fetchUserAccountDataByPhoneNumber.rejected,
      (state, action) => {
        state.fetchAccountDataByNumStatus = ApiStatusEnum.FAILED;
        state.error = action.payload as string;
      },
    );

    // createUserAccount
    builder.addCase(createUserAccount.pending, state => {
      if (!!state.error) state.error = undefined;
      state.status = ApiStatusEnum.LOADING;
    });
    builder.addCase(createUserAccount.fulfilled, (state, action) => {
      state.status = ApiStatusEnum.SUCCEEDED;
      state.accountData = action.payload;
    });
    builder.addCase(createUserAccount.rejected, (state, action) => {
      state.status = ApiStatusEnum.FAILED;
      state.error = action.payload as string;
    });

    // uploadUserProfileImage
    builder.addCase(uploadUserProfileImage.pending, state => {
      state.profileImageUploadStatus = ApiStatusEnum.LOADING;
    });
    builder.addCase(uploadUserProfileImage.fulfilled, (state, action) => {
      state.profileImageUploadStatus = ApiStatusEnum.SUCCEEDED;
      state.accountData = action.payload;
    });
    builder.addCase(uploadUserProfileImage.rejected, state => {
      state.profileImageUploadStatus = ApiStatusEnum.FAILED;
    });

    // editUserAccountData
    builder.addCase(editUserAccountData.pending, state => {
      if (!!state.editUserAccountError) state.editUserAccountError = undefined;
      state.editUserAccountStatus = ApiStatusEnum.LOADING;
    });
    builder.addCase(editUserAccountData.fulfilled, (state, action) => {
      state.editUserAccountStatus = ApiStatusEnum.SUCCEEDED;
      state.accountData = action.payload;
    });
    builder.addCase(editUserAccountData.rejected, (state, action) => {
      state.editUserAccountStatus = ApiStatusEnum.FAILED;
      state.editUserAccountError = action.payload as string;
    });
    // onboardUser
    builder.addCase(onboardUser.pending, state => {
      if (!!state.editUserAccountError) state.editUserAccountError = undefined;
      state.editUserAccountStatus = ApiStatusEnum.LOADING;
    });
    builder.addCase(onboardUser.fulfilled, (state, action) => {
      state.editUserAccountStatus = ApiStatusEnum.SUCCEEDED;
      state.accountData = action.payload;
    });
    builder.addCase(onboardUser.rejected, (state, action) => {
      state.editUserAccountStatus = ApiStatusEnum.FAILED;
      state.editUserAccountError = action.payload as string;
    });
    builder.addCase(blockAccountEventHandler.pending, state => {
      state.blockAccountStatus = ApiStatusEnum.LOADING;
    });
    builder.addCase(blockAccountEventHandler.fulfilled, state => {
      state.blockAccountStatus = ApiStatusEnum.SUCCEEDED;
    });
    builder.addCase(blockAccountEventHandler.rejected, state => {
      state.blockAccountStatus = ApiStatusEnum.FAILED;
    });
  },
});

export const {
  setAccountData,
  // setOtherUserAccountData,
  setFetchAccoutDataByNumStatus,
  updateThingsCount,
  openAddEditEducation,
  closeAddEditEducation,
  cleaarAddEditEducationState,
  openAddEditWork,
  closeAddEditWork,
  cleaarAddEditWorkState,
  updateBlockedUsers,
  setLinkedinImportStatusIdle,
} = userAccountDataSlice.actions;

export default userAccountDataSlice.reducer;

// How asyncThunk is calling extraReducers in toDoSlice?
