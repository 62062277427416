import React, {memo, useCallback, useEffect, useMemo} from 'react';
import type {FC} from 'react';
import {Keyboard, Platform} from 'react-native';
import type {KeyboardEventListener} from 'react-native';
import {useSharedValue, withTiming} from 'react-native-reanimated';
import type {SharedValue} from 'react-native-reanimated';
import {createContext, useContextSelector} from 'use-context-selector';

// const MIN_COMPOSER_HEIGHT = Platform.select({
//   ios: 33,
//   android: 41,
//   default: 33,
// });

// const MAX_COMPOSER_HEIGHT = 200;

type LayoutContext = {
  keyboardHeight: SharedValue<number>;
  toolBarHeight: SharedValue<number>;
  keyboarValue: SharedValue<number>;
};

class _LayoutContext {
  private context = createContext<LayoutContext>({
    toolBarHeight: 0 as any,
    keyboardHeight: 0 as any,
    keyboarValue: 0 as any,
  });

  LayoutProvider: FC<{
    children: any;
  }> = memo(({children}) => {
    const toolBarHeight = useSharedValue(0);
    const keyboardHeight = useSharedValue(0);
    const keyboarValue = useSharedValue(0);

    const keyboardWillShowListener = useCallback<KeyboardEventListener>(
      e => {
        keyboarValue.value = withTiming(1, {
          duration: e.duration,
        });
        keyboardHeight.value = withTiming(e.endCoordinates.height, {
          duration: e.duration,
        });
      },
      [keyboarValue, keyboardHeight],
    );

    const keyboardDidShowListener = useCallback<KeyboardEventListener>(
      e => {
        if (Platform.OS === 'ios') return;
        keyboardWillShowListener(e);
      },
      [keyboardWillShowListener],
    );

    const keyboardWillHideListener = useCallback<KeyboardEventListener>(
      e => {
        keyboarValue.value = withTiming(0, {
          duration: e.duration,
        });
        keyboardHeight.value = withTiming(0, {
          duration: e.duration,
        });
      },
      [keyboarValue, keyboardHeight],
    );

    const keyboardDidHideListener = useCallback<KeyboardEventListener>(
      e => {
        if (Platform.OS === 'ios') return;
        keyboardWillHideListener(e);
      },
      [keyboardWillHideListener],
    );

    useEffect(() => {
      const willShowListener = Keyboard.addListener(
        'keyboardWillShow',
        keyboardWillShowListener,
      );
      const willHideListener = Keyboard.addListener(
        'keyboardWillHide',
        keyboardWillHideListener,
      );
      const didShowListener = Keyboard.addListener(
        'keyboardDidShow',
        keyboardDidShowListener,
      );
      const didHideListener = Keyboard.addListener(
        'keyboardDidHide',
        keyboardDidHideListener,
      );
      return () => {
        willShowListener.remove();
        willHideListener.remove();
        didShowListener.remove();
        didHideListener.remove();
      };
    }, [
      keyboardDidHideListener,
      keyboardDidShowListener,
      keyboardWillHideListener,
      keyboardWillShowListener,
    ]);

    return useMemo(
      () => (
        <this.context.Provider
          value={{toolBarHeight, keyboardHeight, keyboarValue}}>
          {children}
        </this.context.Provider>
      ),
      [toolBarHeight, keyboardHeight, children, keyboarValue],
    );
  });
  useLayout = () => {
    return useContextSelector(this.context, v => ({
      keyboardHeight: v.keyboardHeight,
      toolBarHeight: v.toolBarHeight,
      keyboarValue: v.keyboarValue,
    }));
  };
  useKeyboardHeight = () => {
    return useContextSelector(this.context, v => v.keyboardHeight);
  };
  useKeyboardValue = () => {
    return useContextSelector(this.context, v => v.keyboarValue);
  };
  useToolBarHeight = () => {
    return useContextSelector(this.context, v => v.toolBarHeight);
  };
}

const LayoutContext = new _LayoutContext();

export default LayoutContext;
