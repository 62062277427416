import {ApiStatusEnum} from '@constants/constantsEnum';
import type {RootState} from '@redux/store';
import {resolveImageAssetSource} from '@utils/utilityFunctions';

const anonImage = require('@assets/anon-incognito.png');
const anonymousURI = resolveImageAssetSource(anonImage).uri;
const randomAvatarImage = require('@assets/profile-unvailable-avatar.png');
const randomAvatarURI = resolveImageAssetSource(randomAvatarImage).uri;

/**
 * feedDataIdSelector
 * selector which gives back feed data array with only thing_id
 * @param {RootState} state root state of redux
 * @returns {string[]} feed data id array
 */
export const feedDataIdSelector = (state: RootState) => state.feed.feedDataIds;
// .map(id => state.feed.feeds[id]);

/**
 * feedDataItemSelector
 * selector which gives back item with respect to thing_id passed oin the function
 * @param {string } thingId thing_id of the feed data
 * @returns {FeedData} feed data
 */
export const feedDataItemSelector = (thingId: string) => (state: RootState) => {
  if (!thingId) return null;
  return state.feed.feeds[thingId];
};

/**
 * totalNumberOfPagesInFeedSelector
 * selector which gives back total number of pages
 * @param {RootState} state root state of redux
 * @returns {number} total number of pages
 */
export const totalNumberOfPagesInFeedSelector = (state: RootState) =>
  state.feed.totalNumberOfPagesInFeed;

/**
 * homeFeedsPagesAndTotalNumbersSelector
 * selector which gives back current page and total number of pages
 * @param {RootState} state root state of redux
 * @returns {[number, number]} [page, totlaPages] current page, total number of pages
 */
export const homeFeedsPagesAndTotalNumbersSelector = (state: RootState) => [
  state.feed.page,
  state.feed.totalNumberOfPagesInFeed,
];

/**
 * userFeedsPagesAndTotalNumbersSelector
 * selector which gives back user current page and user total number of pages
 * @param {RootState} state root state of redux
 * @returns {[number, number]} [userPage, numPagesInFeedPostedByUser] current page, total number of pages
 */
// export const userFeedsPagesAndTotalNumbersSelector = (state: RootState) => [
//   state.feed.userPage,
//   state.feed.numPagesInFeedPostedByUser,
// ];

/**
 * selectedFliarSelector
 * selector which gives back selected flair
 * @param {RootState} state root state of redux
 * @returns {string | null} selected flair
 */
export const selectedFliarSelector = (state: RootState) =>
  state.feed.selectedFlair;

/**
 * feedFilterModalVisible
 * selector which gives back visiblity of feed filter modal
 * @param {RootState} state root state of redux
 * @returns {boolean} modal visibilty
 */
export const feedFilterModalVisible = (state: RootState) =>
  state.feed.feedFilterModalVisible;

// export const myFeedDataIdSelector = (state: RootState) =>
//   state.feed.userFeedIds;

export const createVotesCountSelector = (id: string) => (state: RootState) =>
  (state.feed.upVotes[id] || 0) + (state.feed.downVotes[id] || 0);

export const createVoteTypeSelector = (id: string) => (state: RootState) =>
  state.feed.voteTypes[id];

export const createCommentsCountSelector = (id: string) => (state: RootState) =>
  state.feed.commentsCounts[id] || 0;

export const createAmaDataselector = (id: string) => (state: RootState) => {
  const feed = state.feed.feeds[id];
  if (!feed) return undefined;
  if (!(feed.ama_start && feed.ama_till)) return undefined;
  return {start: feed.ama_start, end: feed.ama_till};
};

export const createShouldCallCommentsApiOnLaunch =
  (id: string) => (state: RootState) => {
    console.log(
      'shouldCallApi',
      id,
      (state.feed.commentsIds[id] || []).length,
      state.feed.commentsCounts[id],
    );
    return (
      (state.feed.commentsIds[id] || []).length !==
      state.feed.commentsCounts[id]
    );
  };

export const createFeedTitleSelector = (id: string) => (state: RootState) =>
  state.feed.feeds[id]?.title;

export const createCommentsLoadingSelector =
  (id: string) => (state: RootState) =>
    state.feed.feedDetails[id].commentStatus === ApiStatusEnum.LOADING;

export const createHasFeedSelector = (id?: string) => (state: RootState) =>
  !id ? undefined : !!state.feed.feedDetails[id];

export const createHasFeedDataSelector = (id?: string) => (state: RootState) =>
  !id ? undefined : !!state.feed.feeds[id];

export const createFeedDetailsLoadingSelector =
  (id?: string) => (state: RootState) =>
    !id
      ? true
      : !state.feed.feeds[id]
      ? true
      : state.feed.feedDetails[id].status === ApiStatusEnum.LOADING;

export const feedEndReachedSelector = (state: RootState) => {
  const item = state.feed.feedDataIds;

  if (item.length < 10) return true;
  if (!state.feed.totalNumberOfPagesInFeed) return true;
  return state.feed.page + 1 >= state.feed.totalNumberOfPagesInFeed;
};

// export const userFeedEndReachedSelector = (state: RootState) => {
//   const item = state.feed.userFeedIds;

//   if (item.length <= 10) return true;
//   if (!state.feed.numPagesInFeedPostedByUser) return true;
//   return state.feed.userPage + 1 >= state.feed.numPagesInFeedPostedByUser;
// };

// export const userFeedEndReachedSelector =
//   (postId?: string) => (state: RootState) => {
//     const item = state.feed.feedDataIds;

//     if (state.feed.userFeedIds.length <= 10) return true;
//     if (!state.feed.totalNumberOfPagesInFeed) return true;
//     return state.feed.page + 1 >= state.feed.totalNumberOfPagesInFeed;
//   };

export const createCommentPageEndReachedSelector =
  (postId?: string) => (state: RootState) => {
    if (!postId) return true;
    const item = state.feed.feedDetails[postId];

    if (!item || !state.feed.commentsIds[postId]) return true;

    if (state.feed.commentsIds[postId].length <= 10) return true;
    if (!item.totalCommentPages) return true;
    return item.commentPage + 1 >= item.totalCommentPages;
  };

export const createCommentPageEndReachedSelected =
  (postId?: string) => (state: RootState) => {
    if (!postId) return true;
    const item = state.feed.feedDetails[postId];

    if (!item || !state.feed.commentsIds[postId]) return true;

    if (state.feed.commentsIds[postId].length <= 10) return true;
    if (!item.totalCommentPages) return true;
    return item.commentPage + 1 >= item.totalCommentPages;
  };
export const createCommentsIdsSelector =
  (postId: string) => (state: RootState) =>
    state.feed.commentsIds[postId] || [];

export const createCommentsPageSelector =
  (postId: string) => (state: RootState) =>
    state.feed.feedDetails[postId].commentPage;

export const createCommentAvatarSelector =
  (postId: string) => (state: RootState) =>
    state.feed.comments[postId].anon_comment
      ? anonymousURI
      : state.feed.comments[postId]?.author_mini?.avatar || randomAvatarURI;

export const createCommentAnonymitySelector =
  (postId: string) => (state: RootState) =>
    state.feed.comments[postId]
      ? state.feed.comments[postId].anon_comment
      : true;

export const commentTypeSelector = (id: string) => (state: RootState) =>
  state.feed.comments[id].parent_thing_id ? 'reply' : 'comment';

export const createCommentParentIdSelector =
  (id: string) => (state: RootState) =>
    state.feed.comments[id].parent_thing_id
      ? state.feed.comments[id].parent_thing_id!
      : state.feed.comments[id].thing_id;

export const createCommentTextSelector = (id: string) => (state: RootState) =>
  state.feed.comments[id].comment_text;

export const createCommentAuthorIdSelector =
  (id: string) => (state: RootState) =>
    state.feed.comments[id].author_mini.thing_id;

export const createCommentBySelector = (id: string) => (state: RootState) => {
  const page = state.feed.comments[id];

  return page.anon_comment
    ? 'Works as ' + page.author_mini.user_current_role
    : page.author_mini.user_fullname;
};

export const createCommentByWorkDetailsSelector =
  (id: string) => (state: RootState) => {
    const page = state.feed.comments[id];

    return page.anon_comment
      ? 'Anonymous'
      : page.author_mini.user_current_role +
          ' | ' +
          page.author_mini.user_current_org;
  };

export const createCommentCreatedAtSelector =
  (id: string) => (state: RootState) =>
    state.feed.comments[id].created_at;

export const userPollVotesSelector = (state: RootState) =>
  state.feed.userPollVotes;
export const pollVotesSelector = (feedId: string) => (state: RootState) =>
  state.feed.pollVotes[feedId];

export const postDeleteStatusSelector = (state: RootState) =>
  state.feed.deletePostByThingIdStatus;
