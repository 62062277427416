/*eslint-disable */
import React, {useMemo, useRef} from 'react';
import type {FC} from 'react';
import {
  Pressable,
  type StyleProp,
  StyleSheet,
  View,
  type ViewStyle,
  useWindowDimensions,
  Platform,
} from 'react-native';
import {
  Menu,
  MenuOptions,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import FastImage from '@libs/FastImage';
import {useSelector} from 'react-redux';
import type {FeedAuthorMiniDetails} from '@models/Feed/FeedAuthorMiniDetails';
import {CustomText, MediumBoldText, RegularText} from '@components/Texts';
import CustomAvatar from '@components/Avatar';
import {CustomIcon} from '@components/icon';
import {convertToCamelCaseString} from '@utils/utilityFunctions';
import Colors from '@constants/Colors';
import NavigationService from '@services/navigationService';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {AuthScreens} from '@navigations/ScreenNames';
import LocalStorage from '@services/storage';
import {useRoute} from '@react-navigation/native';
import {FontFamily, FontSize} from '@utils/sizes';

type ProfileSheetOpenProps = FeedAuthorMiniDetails & {
  anon_post: boolean;
};

const COMMUNITY_AVATAR_SIZE = 80;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    overflow: 'hidden',
  },
  line: {alignSelf: 'stretch', height: 1, backgroundColor: Colors.background},
  profileContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
  },
  profileDetailsContainer: {
    alignSelf: 'stretch',
    padding: 16,
  },

  avatarContainer: {
    position: 'absolute',
    left: 16,
    bottom: 0,
  },
  avatarBorderContainer: {
    borderWidth: 2,
    height: COMMUNITY_AVATAR_SIZE,
    width: COMMUNITY_AVATAR_SIZE,
    overflow: 'hidden',
    borderColor: Colors.white,
    borderRadius: COMMUNITY_AVATAR_SIZE / 2,
  },
  backButton: {
    position: 'absolute',
    right: 16,
    top: 16,
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    backgroundColor: Colors.white,
  },
  buttonContainer: {
    marginTop: 10,
    paddingHorizontal: 16,
    height: 36,
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    flexDirection: 'row',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
  },
  roleText: {
    marginTop: 4,
  },
});

class _ProfileOptionSheet {
  public ProfileOptionsheetButton: FC<
    {
      children: any;
      style?: StyleProp<ViewStyle>;
      disabled?: boolean;
      loggedInUser?: string;
    } & ProfileSheetOpenProps
  > = ({children, style, loggedInUser, ...options}) => {
    return (
      <SheetComponent loggedInUser={loggedInUser} style={style} data={options}>
        {children}
      </SheetComponent>
    );
  };
}

const ProfileOptionSheet = new _ProfileOptionSheet();

const SheetComponent: FC<{
  data: ProfileSheetOpenProps;
  style: StyleProp<ViewStyle>;
  loggedInUser?: string;
  children: any;
}> = ({data, children, loggedInUser, style}) => {
  const route = useRoute();
  const menuRef = useRef<Menu>(null);
  const {bottom} = useSafeAreaInsets();
  const {width} = useWindowDimensions();
  const userThingId = useSelector(userAccountThingIdSelector);
  const {role, name} = useMemo(
    () => ({
      name: data.anon_post
        ? 'Works as ' + convertToCamelCaseString(data.user_current_org || '')
        : data.user_fullname,
      role: data.anon_post
        ? 'at ' + convertToCamelCaseString(data.user_current_org || '')
        : data.user_current_role + ' | ' + data.user_current_org,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data.user_current_role,
      data.user_fullname,
      data.anon_post,
      data.user_current_org,
    ],
  );

  return (
    <View>
      <Pressable
        onPress={async () => {
          console.log('loggedInUser', loggedInUser);
          if (!loggedInUser) {
            await LocalStorage.setData('redirect', route);

            NavigationService.getInstance().replaceToAuth({
              page: AuthScreens.OtpScreen,
            });
            return;
          }
          menuRef.current?.open();
        }}
        style={style}>
        {children}
      </Pressable>

      <Menu
        ref={menuRef}
        renderer={width <= 500 ? renderers.SlideInMenu : renderers.Popover}>
        <MenuTrigger></MenuTrigger>
        <MenuOptions
          customStyles={{optionsWrapper: {width: width <= 500 ? width : 300}}}>
          <View
            style={[
              styles.container,
              {
                paddingBottom:
                  (bottom || (Platform.OS === 'web' ? 0 : 20)) + 10,
              },
            ]}>
            <View
              style={{
                alignSelf: 'stretch',
                height:
                  (width <= 500 ? width : 300) * (78 / 360) +
                  COMMUNITY_AVATAR_SIZE / 2,
              }}>
              <View
                style={{
                  width: '100%',
                  height: (width <= 500 ? width : 300) * (78 / 360),
                }}>
                <FastImage
                  source={{uri: ''}}
                  resizeMode="stretch"
                  style={{
                    flex: 1,
                    width: '100%',
                    backgroundColor: Colors.background,
                  }}
                />
              </View>
              <View style={styles.avatarContainer}>
                <View style={styles.avatarBorderContainer}>
                  <CustomAvatar style={{flex: 1}} source={data.avatar} />
                </View>
              </View>
              <Pressable
                style={styles.backButton}
                onPress={() => {
                  menuRef.current?.close();
                }}>
                <CustomIcon name="remove" color={Colors.lightText} size={10} />
              </Pressable>
            </View>
            <View style={styles.profileDetailsContainer}>
              <CustomText
                color="text"
                size={FontSize.title}
                fontFamily={FontFamily.FigtreeSemiBold}>
                {name}
              </CustomText>
              <CustomText
                fontFamily={FontFamily.FigtreeRegular}
                color="lightText">
                {role}
              </CustomText>
            </View>
            <View style={styles.line} />
            {/* <View style={{alignSelf: 'stretch'}}>
        <IconTextButton
          onPress={() => {
            ProfileOptionSheet.sheetRef.current?.close();
            NavigationService.getInstance().openProfileDetails(data.thing_id);
          }}
          text="View Profile"
          Icon="profile-deselect"
        />

        <IconTextButton
          onPress={() => {
            ProfileOptionSheet.sheetRef.current?.close();
          }}
          text="Message"
          Icon="message"
        />
      </View> */}
            <View style={styles.buttonContainer}>
              <Pressable
                onPress={() => {
                  menuRef.current?.close();
                  setTimeout(() => {
                    NavigationService.getInstance().openProfileDetails(
                      data.thing_id,
                    );
                  }, 200);
                }}
                style={styles.button}>
                <CustomIcon
                  size={14}
                  color={Colors.primary}
                  name="profile-deselect"
                />
                <View style={{width: 8}} />
                <CustomText color="primary">View Profile</CustomText>
              </Pressable>
              {data.thing_id === userThingId ? null : (
                <View style={{width: 8}} />
              )}
              {data.thing_id === userThingId ? null : (
                <Pressable
                  onPress={() => {
                    menuRef.current?.close();
                    setTimeout(() => {
                      NavigationService.getInstance().openChat(
                        data.thing_id,
                        {
                          user_avatar: data.avatar,
                          user_thing_id: data.thing_id,
                          user_fullname: data.user_fullname,
                        } as any,
                        data.user_current_role,
                        data.user_current_org,
                      );
                    }, 200);
                  }}
                  style={styles.button}>
                  <CustomIcon size={14} color={Colors.primary} name="message" />
                  <View style={{width: 8}} />
                  <CustomText color="primary">Message</CustomText>
                </Pressable>
              )}
            </View>
          </View>
        </MenuOptions>
      </Menu>
    </View>
  );
};

export const ProfileOptionSheetComponent: FC = () => {
  return <View />;
};

export default ProfileOptionSheet;
