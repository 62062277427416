export enum ThingTypeEnum {
  COMMENT = 'comment',
  REPLY = 'reply',
  POST = 'post',
  ACCOUNT = 'account',
  ANALYTICS = 'analytics',
  COMMUNITY = 'community',
  PROFILE = 'profile',
  REPORT = 'report',
  NA = 'na',
}

export enum ApiStatusEnum {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum CommentTypeEnum {
  TEXT = 'txt',
  MEDIA = 'media',
}

export enum FeedCardBodyTypeEnum {
  TEXT = 'text',
  TXT = 'txt',
  MEDIA_IMG = 'media_img',
  POLL = 'poll',
}

export enum FlairValuesEnum {}

export enum EventTypeEnum {
  UPVOTE = 'upv',
  DOWNVOTE = 'dv',
  ANALYTICS = 'ana',
  REPORT = 'report',
}

export enum UserProfileEventValue {
  USER_HEADER_SAVE_CLICK = '',
  USER_HEADER_CANCEL_CLICK = '',
}

export enum CommentParentScreenEnum {
  HOME = 'home',
  DISCOVER = 'discover',
  POST = 'post',
  PROFILE = 'profile',
  FEED = 'feed',
}

export enum EventTypeValueEnum {
  UPVOTE = 'upvote event',
  DOWNVOTE = 'downvote event',
  APP_OPEN = 'app opening',
  MOBILE_ENTER = 'mobile number entered login',
  OTP_ENTER = 'opt entered login',
  LINKEDIN_IMPORT_PAGE = 'import your linkedin profile page',
  LINKEDIN_IMPORT = 'import linked profile',
  REVIEW_SCREEN = 'profile for review',
  SUBMIT_PROFILE = 'submit profile',
  EDIT_PROFILE = 'edit profile',
  BIO_UPDATE = 'Bio Edit/Submit',
  NAME_UPDATE = 'Name Edit/Submit',
  COMPANY_UPDATE = 'Companies Edit/Submit',
  EDUCATION_UPDATE = 'Educaton Edit/Submit',
  COMMENT = 'Comment Click',
  DISCOVER_IMPRESSION = 'Discover Impression',
  PROFILE_IMPRESSION = 'Profile Impression',
  HOME_CLICK = 'Home Clicked',
  DISCOVER_CLICK = 'Discover Clicked',
  PROFILE_CLICK = 'Profile Clicked',
  CREATE_COMMUNITY_EXP = 'create_comm_exp',
  REPORT_POST = 'report_post',
  REPORT_USER = 'report_user',
}

export enum LoginAnalEventValue {
  APP_INITIATED = 'app_initiated',
  APP_OPENED = 'app_opened',
  LOGIN_PAGE_IMP = 'login_page_impr',
  MOBILE_NUMBER_ENTERED = 'mobile_number_entered',
  OTP_SENT = 'otp_sent',
  OTP_ENTERED_CLICK = 'otp_entered_click',
  OTP_INCORRECT = 'otp_incorrect',
  OTP_CORRECT = 'otp_correct',
  LOGIN_SUCCESS = 'login_succ',
  LOGIN_FAILED = 'login_failed',
  SIGNUP_SUCCESS = 'signup_succ',
  SIGNUP_FAILED = 'signup_failed',
  ONBOARD_NEXT_CLICK = 'onboard_next_click',
}

export enum ProfileCreationAnalEventValue {
  ONB_LINK_IMPORT_ADD_DETAILS_MANUAL_CLICK = 'onb_link_import_add_details_manual_click',
  MANUAL_DETAILS_PAGE_IMP = 'man_det_page_impr',
  MANUAL_DETAILS_SUBMITTED_CLICK = 'man_det_submit_click',
  MANUAL_DETAILS_SUBMITTED_SUCCESS = 'man_det_submit_succ',
  MANUAL_DETAILS_SUBMITTED_ERROR = 'man_det_submit_error',
  DP_UPLOAD_CLICK = 'dp_upload_click',
  DP_UPLOAD_GALLERY_CLICK = 'dp_upload_gallery_click',
  DP_UPLOAD_CAMERA_CLICK = 'dp_upload_camera_click',
  DP_UPLOAD_SUCCESS = 'dp_upload_succ',
  DP_UPLOAD_ERROR = 'dp_upload_error',
  DP_SKIP_CLICK = 'dp_skip_click',
  DP_SKIP_SUCCESS = 'dp_skip_succ',
  DP_SKIP_ERROR = 'dp_skip_error',
  INTERESTED_PAGE_IMPR = 'interested_page_impression',
  INTEREST_PAGE_CLICK = 'interested_page_click',
  SPACES_LIST_ONBOARDING_IMPR = 'spaces_list_onboarding_impr',
  SPACES_LIST_NEXT_CLICK = 'spaces_list_next_click',
  SPACES_NOT_AVAILABLE_IMPR = 'spaces_not_available_impression',
  ONB_UNJOIN_COMMUNITY = 'onb_unjoin_community',
  ONB_UNJOIN_COMMUNITY_SUCCESS = 'onb_unjoin_community_succ',
  ONB_UNJOIN_COMMUNITY_FAILED = 'onb_unjoin_community_failed',
  ONB_JOIN_COMMUNITY = 'onb_join_community',
  ONB_JOIN_COMMUNITY_SUCCESS = 'onb_join_community_succ',
  ONB_JOIN_COMMUNITY_FAILED = 'onb_join_community_failed',
  ONB_LINK_IMPORT_IMPR = 'onb_link_import_impr',
  ONB_LINK_IMPORT_INITIATED = 'onb_link_import_initiated',
  ONB_LINK_IMPORT_SUCCESS = 'onb_link_import_succ',
  ONB_LINK_IMPORT_ERROR = 'onb_link_import_error',
  ONB_LINK_REVIEW_IMPR = 'onb_link_review_impr',
  ONB_LINK_REVIEW_UPLOAD_CLICK = 'onb_link_review_upload_click',
  ONB_LINK_REVIEW_GALLERY_CLICK = 'onb_link_review_gallery_click',
  ONB_LINK_REVIEW_CAMERA_CLICK = 'onb_link_review_camera_click',
  ONB_LINK_REVIEW_UPLOAD_SUCCESS = 'onb_link_review_upload_succ',
  ONB_LINK_REVIEW_UPLOAD_FAILURE = 'onb_link_review_upload_failed',
  ONB_LINK_REVIEW_SUBMIT_CLICK = 'onb_link_review_submit_click',
  ONB_LINK_REVIEW_UPDATE_SUCCESS = 'onb_link_review_update_succ',
  ONB_LINK_REVIEW_UPDATE_ERROR = 'onb_link_review_update_error',
}

export enum FeedEnagagementAnalEventValue {
  FEED_PAGE_IMPR = 'feed_page_impr',
  HOME_CLICK = 'home_click',
  POST_CLICK = 'post_click',
  POST_UPVOTE = 'post_upvote',
  POST_DOWNVOTE = 'post_downvote',
  POST_COMMENT_CLICK = 'post_comment_click',
  POST_COMMENT_SUBMIT_CLICK = 'post_comment_submit_click',
  POST_COMMENT_SUCCESS = 'post_comment_succ',
  POST_COMMENT_FAILED = 'post_comment_failed',
  POST_COMMENT_REPLY_CLICK = 'post_comment_reply_click',
  POST_REPLY_REPLY_CLICK = 'post_reply_reply_click',
  POST_REPLY_SUBMIT_CLICK = 'post_reply_submit_click',
  POST_REPLY_SUCCESS = 'post_reply_succ',
  POST_REPLY_FAILED = 'post_reply_failed',
  POST_SHARE_CLICK = 'post_share_click',
  COMMENT_UPVOTE = 'comment_upvote',
  COMMENT_DOWNVOTE = 'comment_downvote',
  REPLY_UPVOTE = 'reply_upvote',
  REPLY_DOWNVOTE = 'reply_downvote',
  COMMENT_REPLY_SUCCESS = 'comment_reply_submitted',
  COMMUNITY_BANNER_CLICK = 'community_banner_click',
  PROFILE_BANNER_CLICK_POST = 'profile_banner_click_post',
  PROFILE_BANNER_CLICK_COMMENT = 'profile_banner_click_comment',
  PROFILE_BANNER_CLICK_REPLY = 'profile_banner_click_reply',
}

export enum SpacesAnalEventValue {
  SPACES_GLOBAL_CLICKED = 'spaces_global_click',
  SPACES_JOINED_IMP = 'spaces_joined_imp',
  SPACES_EXPLORED_IMP = 'spaces_explored_imp',
  SPACES_JOINED_COMMUNITY_CLICK = 'spaces_joined_community_click',
  SPACES_EXPLORED_COMMUNITY_CLICK = 'spaces_explored_community_click',
  SPACES_JOINED_COMMUNITY_UNJOIN = 'spaces_joined_community_unjoin',
  SPACES_EXPLORED_COMMUNITY_JOIN = 'spaces_explored_community_join',
  SPACES_JOINED_COMMUNITY_UNJOIN_SUCCESS = 'spaces_joined_community_unjoin_succ',
  SPACES_EXPLORED_COMMUNITY_JOIN_SUCCESS = 'spaces_explored_community_join_succ',
  SPACES_JOINED_COMMUNITY_UNJOIN_FAILED = 'spaces_joined_community_unjoin_failed',
  SPACES_EXPLORED_COMMUNITY_JOIN_FAILED = 'spaces_explored_community_join_failed',
}

export enum UserProfileAnalEventValue {
  PROFILE_GLOBAL_CLICKED = 'profile_global_click',
  PROFILE_VIEW_IMP = 'profile_view_impr',
  USER_HEADER_EDIT_CLICK = 'user_header_edit_click',
  USER_HEADER_SAVE_CLICK = 'user_header_save_click',
  USER_HEADER_SAVE_CLICK_SUCCESS = 'user_header_save_click_succ',
  USER_HEADER_SAVE_CLICK_FAILED = 'user_header_save_click_failed',
  USER_HEADER_CANCEL_CLICK = 'user_header_cancel_click',
  USER_EXPERIENCE_VIEW_ALL = 'user_experience_view_all',
  USER_EDUCATION_VIEW_ALL = 'user_education_view_all',
  USER_EDUCATION_ADD_CLICK = 'user_education_add_click',
  USER_EXPERIENCE_ADD_CLICK = 'user_experience_add_click',
  USER_EDUCATION_ADD_SAVE_CLICK = 'user_education_add_save_click',
  USER_EDUCATION_ADD_CANCEL_CLICK = 'user_education_add_cancel_click',
  USER_EXPERIENCE_ADD_SAVE_CLICK = 'user_experience_add_save_click',
  USER_EXPERIENCE_ADD_CANCEL_CLICK = 'user_experience_add_cancel_click',
  USER_SETTING_CLICK = 'user_setting_click',
  USER_LOGOUT_CLICK = 'user_logout_click',
  USER_DELETE_CLICK = 'user_delete_click',
  POFILE_IMPORT_LINKEDIN_CLICKED = 'profile_import_linkedin_click',
  PROFILE_IMPORT_LINKEDIN_SUCCESS = 'profile_import_linkedin_succ',
  PROFILE_IMPORT_LINKEDIN_FAILED = 'profile_import_linkedin_failed',
}

export enum CreatePostAnalEvent {
  CREATE_POST_GLOBAL_CLICK = 'create_post_global_click',
  CREATE_POST_ANONYMOUS_TOGGLE = 'create_post_anonymous_toggle',
  CREATE_POST_TAG_CLICKED = 'create_post_tag_click',
  CREATE_POST_ATTACH_CLICK = 'create_post_attach_click',
  CREATE_POST_GALLERY_CLICK = 'create_post_gallery_click',
  CREATE_POST_CAMERA_CLICK = 'create_post_camera_click',
  CREATE_POST_ATTACH_SUCCESS = 'create_post_attach_succ',
  CREATE_POST_ATTACH_FAILED = 'create_post_attach_failed',
  CREATE_POST_ATTACH_REMOVED = 'create_post_attach_removed',
  CREATE_POST_NEXT_CLICK = 'create_post_next_click',
  CREATE_POST_SPACE_IMPR = 'create_post_space_impr',
  CREATE_POST_SPACE_SELECT = 'create_post_space_select',
  CREATE_POST_SUBMIT_CLICK = 'create_post_submit_click',
  CREATE_POST_SUBMIT_SUCCESS = 'create_post_submit_succ',
  CREATE_POST_SUBMIT_FAILED = 'create_post_submit_failed',
}

export enum NotificationAnalEvent {
  NOTIFICATION_GLOBAL_CLICKED = 'notification_global_click',
}

export enum LocalStorageKeysEnum {
  USER_ACCOUNT_DATA = 'userAccountData',
}

export enum ManualImportDataFieldEnum {
  USER_FULLNAME = 'user_fullname',
  USER_CURRENT_ORG = 'user_current_org',
  USER_CURRENT_ROLE = 'user_current_role',
  AVATAR = 'avatar',
}

export enum ProfileScreenTabOptions {
  DETAILS_OPTION = 'Details',
  MY_POST_OPTION = 'My Posts',
  OTHER_USER_POST_OPTION = 'Posts',
}

export enum SpacesScreenTabOptions {
  EXPLORE_OPTION = 'Explore',
  JOINED_OPTION = 'Joined',
}

export enum ActivatedInterests {
  SOFTWARE_DEVELOPMENT = 'Software Development',
  PRODUCT_MANAGEMENT = 'Product Management',
}

export enum CommunityCategoryEnum {
  META = 'meta',
}

export enum RemoteConfigAppVersionEnum {
  CUR_VERSION_AND = 'cur_version_and',
  CUR_VERSION_IOS = 'cur_version_ios',
  MIN_VERSION_AND = 'min_version_and',
  MIN_VERSION_IOS = 'min_version_ios',
  UPD_TRIGGER_VERSION_AND = 'upd_trigger_version_and',
  UPD_TRIGGER_VERSION_IOS = 'upd_trigger_version_ios',
}

export enum DynamicLinkThingTypeEnum {
  FEED_DETAIL = '/posts',
  COMMUNITY_DETAIL = '/communities',
  PROFILE = '/users',
}

export enum ConversationScreenTabOptions {
  DIRECT_MESSAGES = 'Direct Messages',
  REQUESTS = 'Requests',
}

export enum SpacesDetailsScreenTabOptions {
  GROUP_OPTION = 'Group Feed',
  MEMBER_OPTION = 'Members',
}

export enum TabNameEnum {
  HOME = 'home',
  DISCOVER = 'discover',
  POST = 'post',
  PROFILE = 'profile',
}

export enum JoinEventEnum {
  SUBSCRIBE = 'sub',
  UNSUBSCRIBE = 'unsub',
}

export enum BlockEventEnum {
  BLOCK = 'block',
  UNBLOCK = 'unblock',
}

export enum FeedCardParentScreenEnum {
  HOME_FEED = 'Feed',
  HOME_PROFILE = 'ProfileHome',
  HOME_COMMUNITY = 'CommunityDetailHome',
  DISCOVER_COMMUNITY = 'CommunityDetailDiscover',
  DISCOVER_PROFILE = 'ProfileDiscover',
  PROFILE_FEED = 'Profile',
  PROFILE_COMMUNITY = 'CommunityDetailProfile',
}

export enum NavigationAction {
  POP = 'pop',
  PUSH = 'push',
}
export enum ReportTypeEnum {
  POST = 'post',
  USER = 'user',
}

const WhatsappSupportNumber = '9795637067';

const WyseUpdateCommunityId = '9ZIuXvXqNH';

const AppsFlyerDevKey = 'XAUHYwcuEZH5Wgqx3irK5c';

const devAMAFlairId = 'q6RpGdQo7F';
const prodAMAFlairId = 'UPuNubMWXz';

const AppStoreId = '6446937173';

export enum NotificationType {
  CommPostNew = 'comm_post_new',
  PostUpvote = 'post_upvote',
  PostCommentNew = 'post_comment_new',
  PostCommentReply = 'post_comment_reply',
  CommentUpvote = 'comment_upvote',
  ReplyUpvote = 'reply_upvote',
  WelcomePost = 'welcome_post',
  FirebaseTriggered = 'firebase_triggered',
}

const ConstantsEnum = {
  ThingTypeEnum,
  DynamicLinkThingTypeEnum,
  ApiStatusEnum,
  EventTypeEnum,
  EventTypeValueEnum,
  LoginAnalEventValue,
  ProfileCreationAnalEventValue,
  FeedEnagagementAnalEventValue,
  SpacesAnalEventValue,
  UserProfileAnalEventValue,
  LocalStorageKeysEnum,
  ManualImportDataFieldEnum,
  FeedCardBodyTypeEnum,
  ProfileScreenTabOptions,
  SpacesScreenTabOptions,
  ConversationScreenTabOptions,
  SpacesDetailsScreenTabOptions,
  CommentParentScreenEnum,
  ActivatedInterests,
  CommunityCategoryEnum,
  RemoteConfigAppVersionEnum,
  TabNameEnum,
  JoinEventEnum,
  FeedCardParentScreenEnum,
  NavigationAction,
  WhatsappSupportNumber,
  BlockEventEnum,
  ReportTypeEnum,
  NotificationType,
  WyseUpdateCommunityId,
  AppsFlyerDevKey,
  AppStoreId,
  prodAMAFlairId,
  devAMAFlairId,
};

export default ConstantsEnum;
