import axios from 'axios';
import uuid from 'react-native-uuid';
import Cookies from 'js-cookie';
import type {IExtraData} from './database';

export type IEventData = {
  ev_typ: string;
  ev_val: string;
  thing_id?: string;
  thing_type: string;
  extra_data?: IExtraData;
};

export enum IEnvironment {
  Development = 'Development',
  Production = 'Production',
}

export const getWebDeviceId = () => {
  return new Promise<string>(async resolve => {
    const key = await Cookies.get('device-key');
    if (!!key) {
      resolve(key);
    }
    const newKey = uuid.v4.toString();
    await Cookies.set('device-key', newKey);
    resolve(newKey);
  });
};

const BaseUrl: Record<IEnvironment, string> = {
  [IEnvironment.Development]: 'https://api-dev.bewyse.in',
  [IEnvironment.Production]: 'https://api-dev.bewyse.in',
};

const getBaseUrl = (environment: IEnvironment) => {
  if (!environment) {
    throw new Error('Get Url -> environment is not passed');
  }
  return BaseUrl[environment];
};

const sendData = async (url: string, params: any) => {
  try {
    const response = await axios.get(url, {
      params,
    });
    if (response.status == 200) {
      return true;
    }
    return false;
  } catch (err) {
    console.log('Analytics Send Data Error -->', JSON.stringify(err));
    return false;
  }
};

/**
 * TODO: make this function send data in diffrent thread than js
 */
export const track = async (
  environment: IEnvironment,
  id: string,
  data: IEventData,
  deviceName: string,
  deviceId: string,
  mobileNumber?: string,
  userId?: string,
) => {
  const innerData = {
    ...data,
    user_uid: userId,
    session_id: id,
    created_at: new Date().toISOString(),
    device_info: {
      device_id: deviceId,
      mobile_number: mobileNumber,
      device_name: deviceName,
    },
  };

  // TODO: write the event to local database so that
  // when api fails it can be sent next time
  // realm.write(() => {
  //   realm.create('Event', innerData, Realm.UpdateMode.Modified) as any;
  // });
  const url = `${getBaseUrl(environment)}/nexum/event`;
  try {
    await sendData(url, innerData);
  } catch (error) {}

  // TODO: check weather there are events to send this includes the event just added above
  // const events = realm.objects<Event>('Event');
  // if (events.length > 0) {
  //   for (let i = 0; i < events.length; i++) {
  //     const event = events[i];
  //     // TODO: send the local event to server
  //     const res = await sendData(url, event.toJSON());
  //     if (!res) continue;
  //     // TODO: delete the local event after event has sent
  //     realm.write(() => {
  //       console.log('issue here');
  //       realm.delete(event);
  //       console.log('iisue here after');
  //     });
  //   }
  // }
};

export const getSessionId = async (
  environment: IEnvironment,
  deviceId: string,
  userId?: string,
) => {
  try {
    const url = `${getBaseUrl(environment)}/nexum/create/session`;
    const response = await axios.post(url, {
      thing_type: 'session',
      kwargs: {user_id: userId, device_id: deviceId},
    });
    if (response.status == 200) {
      return response.data.instance.session_id;
    }
  } catch (error) {
    return undefined;
  }
};

export const updateSession = async (
  environment: IEnvironment,
  sessionId: string,
  userId: string,
) => {
  try {
    const url = `${getBaseUrl(environment)}/nexum/edit/session`;
    const response = await axios.post(url, {
      session_id: sessionId,
      attrs: ['user_id', userId, 'replace'],
    });
    if (response.status == 200) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
