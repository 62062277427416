import React from 'react';
import {Pressable, type StyleProp, type ViewStyle} from 'react-native';

export type CheckBoxProps = {
  style?: StyleProp<ViewStyle>;
  value: boolean;
  onValueChange: (checked: boolean) => void;
  tintColors?: {true: string; false: string};
  lineWidth?: number;
  hideBox?: boolean;
  boxType?: string;
  onCheckColor?: string;
};

const CheckBox = () => {
  return <Pressable></Pressable>;
};

export default CheckBox;
