import {extendTheme} from 'native-base';

const nexumTheme = extendTheme({
  colors: {
    primary: {
      '50': '#e9d5fc',
      '100': '#d6b2f7',
      '200': '#c291f0',
      '300': '#af73e6',
      '400': '#9b51e0',
      '500': '#9044d6',
      '600': '#8439ca',
      '700': '#7838b3',
      '800': '#6c399b',
      '900': '#5f3884',
    },
  },
  components: {
    Button: {
      variants: {
        outline: ({colorScheme}) => {
          return {
            _hover: {
              bg: `${colorScheme}.500`,
            },
          };
        },
      },
      defaultProps: {
        colorScheme: 'purple',
      },
    },
  },
});

export default nexumTheme;
