import React from 'react';
import type {StyleProp} from 'react-native';
import FastImage, {ResizeMode, ImageStyle} from '@libs/FastImage';

const CustomAvatar = ({
  width,
  height,
  borderRadius,
  source,
  backgroundColor,
  resizeMode,
  style,
}: {
  width?: number;
  height?: number;
  borderRadius?: number;
  source: string;
  backgroundColor?: string;
  resizeMode?: ResizeMode;
  style?: StyleProp<
    Pick<
      ImageStyle,
      | 'borderRadius'
      | 'borderColor'
      | 'borderWidth'
      | 'flex'
      | 'backgroundColor'
      | 'marginTop'
      | 'margin'
      | 'marginBottom'
      | 'marginLeft'
      | 'marginRight'
    >
  >;
}) => {
  return (
    <FastImage
      resizeMode={resizeMode}
      source={{uri: source}}
      style={[
        {
          backgroundColor,
          width: width,
          height: height,
          borderRadius: borderRadius,
        },
        style,
      ]}
    />
  );
};

export default CustomAvatar;
