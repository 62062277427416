import {View, StyleSheet, SafeAreaView} from 'react-native';
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Button as Btn, ScrollView} from 'native-base';
import Icon from 'react-native-vector-icons/Ionicons';
import {AppDispatch, RootState} from '../../redux/store';
import {CreatePostPayload} from '../../models/Feed/CreatePostPayload';
import ConstantsEnum from '../../constants/constantsEnum';
import ToasterService from '../../services/toasterService';
import {MetaDataCommunity} from '../../models/MetaData/metaDataCommunity';
import Spinner from 'react-native-loading-spinner-overlay';
import CommunityCard from '../../components/Card/CommunityCard';
import {Route, TabActions} from '@react-navigation/native';
import {AppScreens, TabScreens} from '@navigations/ScreenNames';
import {createPost} from '@redux/actions/feed';
import {createCommunitiesJoinedByUserSelector} from '@redux/selectors/communitySelector';
import {communitiesSelector} from '@redux/selectors/metaSelector';
import Analytics, {IEventData} from '@wyse/analytics';
import {
  EventTypeEnum,
  CreatePostAnalEvent,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import {FontFamily, FontSize} from '@utils/sizes';
import NavigationService from '@services/navigationService';
import LocalStorage from '@services/storage';
import {CustomText} from '@components/Texts';

const SelectSpaces = ({route, navigation}: {route: any; navigation: any}) => {
  const {
    flairs,
    title,
    body,
    authorName,
    authorId,
    anonymousPost,
    imageUri,
    resetCreatePostData,
    polls,
    defaultSpace,
    file,
  }: {
    flairs: string[];
    title: string;
    body: string;
    authorName: string;
    authorId: string;
    anonymousPost: boolean;
    imageUri: string | null;
    resetCreatePostData: any;
    polls?: any;
    defaultSpace?: string;
    file?: File;
  } = route.params;

  let createPostStatus: string = useSelector(
    (state: RootState) => state.feed.createPostStatus,
  );

  const [createPostPending, setCreatePostPending] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const joinedCommunityIds: string[] = useSelector(
    createCommunitiesJoinedByUserSelector,
  );

  const metaCommunities = useSelector(communitiesSelector);
  const [selectedCommunityIndex, setSelectedCommunityIndex] = useState<
    number | null
  >(null);
  const [joinedCommunities, setJoinedCommunities] = useState<
    MetaDataCommunity[]
  >([]);

  useEffect(() => {
    let joinedCommunityList: MetaDataCommunity[] = [];
    let index: number = -1;
    let tempIndex = 0;
    for (let i = 0; i < metaCommunities.length; i++) {
      const community = metaCommunities[i];
      if (joinedCommunityIds.includes(community.thing_id)) {
        joinedCommunityList.push(community);
        if (community.thing_id == defaultSpace) {
          index = tempIndex;
        }
        tempIndex++;
      }
    }
    if (!!defaultSpace && index !== -1) {
      setSelectedCommunityIndex(index);
    }
    setJoinedCommunities(joinedCommunityList);
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: CreatePostAnalEvent.CREATE_POST_SPACE_IMPR,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createPostStatus == ConstantsEnum.ApiStatusEnum.SUCCEEDED) {
      ToasterService.showToast('You have successfully posted', '#27AE60');
      setCreatePostPending(false);
      setSelectedCommunityIndex(null);
      resetCreatePostData();
      (async () => {
        const data = await LocalStorage.getData<
          Route<
            AppScreens,
            {
              feedThingId?: string;
              isProfileCard: boolean;
              isDynamicLink?: boolean;
              feedDataIndex?: number;
              isFromComment?: boolean;
            }
          >
        >('redirect');
        if (NavigationService.getInstance().cangoBack()) {
          navigation.goBack();
          navigation.dispatch(TabActions.jumpTo(TabScreens.Home));
          if (!!data) {
            NavigationService.getInstance().openFeedDetails(
              data.params.feedThingId!,
              data.params.feedDataIndex,
              data.params.isProfileCard,
              data.params.isFromComment,
              data.params.isDynamicLink,
            );
          }
        } else {
          NavigationService.getInstance().replaceToApp(
            !!data
              ? {
                  page: data?.name,
                  params: data?.params,
                }
              : undefined,
          );
        }
      })();
    } else if (createPostStatus == ConstantsEnum.ApiStatusEnum.FAILED) {
      ToasterService.showToast(
        'An error occured !  Please try after sometime ',
        '#EB5757',
      );
      setCreatePostPending(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPostStatus]);

  const createTextPost = async () => {
    setCreatePostPending(true);
    const createPostPayload: CreatePostPayload = {
      createTextPostPayload: {
        thing_type: ConstantsEnum.ThingTypeEnum.POST,
        kwargs: {
          community_id: [joinedCommunities![selectedCommunityIndex!].thing_id],
          flairs: flairs,
          title: title,
          anon_post: anonymousPost,
          community_name: joinedCommunities![selectedCommunityIndex!].title,
          author_name: authorName,
          author_id: authorId,
        },
        thing_data: {
          key: !polls.options.some(ele => ele === null)
            ? ConstantsEnum.FeedCardBodyTypeEnum.POLL
            : ConstantsEnum.FeedCardBodyTypeEnum.TXT,
          value: body,
          dict: {
            options: [...polls.options],
            answer: polls.answer,
          },
        },
      },
      uploadImageInPostPayload:
        imageUri == null
          ? null
          : {
              imageUri: imageUri,
              thingId: null,
              file,
            },
    };

    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: CreatePostAnalEvent.CREATE_POST_SUBMIT_CLICK,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);

    dispatch(createPost(createPostPayload));
  };

  const handleCommunitySelection = (communityIndex: number) => {
    setSelectedCommunityIndex(communityIndex);
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: CreatePostAnalEvent.CREATE_POST_SPACE_SELECT,
      thing_type: ThingTypeEnum.COMMUNITY,
      thing_id: joinedCommunities![communityIndex].thing_id,
    };
    Analytics.trackEvent(analyticsEventPayload);
  };

  return (
    <SafeAreaView style={{flex: 1}}>
      <Spinner
        visible={createPostPending}
        textContent={'Creating Post...'}
        textStyle={styles.spinnerTextStyle}
      />
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: 16,
          paddingVertical: 12,
          marginBottom: 8,
          backgroundColor: '#FFFFFF',
        }}>
        <View style={{display: 'flex', flexDirection: 'row'}}>
          <View style={{marginRight: 8}}>
            <Icon
              name="arrow-back"
              size={23}
              onPress={() => navigation.goBack()}
            />
          </View>
          <View>
            <CustomText
              size={FontSize.heading}
              style={{
                color: '#333333',
              }}>
              Post to
            </CustomText>
          </View>
        </View>
        <View style={{width: 80}}>
          <Btn
            size="sm"
            style={{
              backgroundColor:
                selectedCommunityIndex == null ? '#F2F2F2' : '#9B51E0',
              borderRadius: 8,
            }}
            disabled={selectedCommunityIndex == null}
            onPress={createTextPost}>
            <CustomText
              style={{
                color: selectedCommunityIndex == null ? '#333333' : '#FFFFFF',
              }}>
              Post
            </CustomText>
          </Btn>
        </View>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: 10,
        }}>
        <View>
          <CustomText
            size={FontSize.title}
            fontFamily={FontFamily.FigtreeMedium}>
            Select a space to post
          </CustomText>
        </View>
      </View>
      <ScrollView style={{height: '80%'}}>
        {joinedCommunities &&
          joinedCommunities.length > 0 &&
          joinedCommunities.map((item: MetaDataCommunity, index: number) => (
            <CommunityCard
              key={index}
              communityThingId={item.thing_id}
              //TODO null Check
              communityAvatar={item.community_avatar!}
              communityTitle={item.title}
              communityHeadline={item.community_headline}
              communitySubCount={item.sub_count}
              joinOption={false}
              isJoinedByUser={false}
              checkBoxOption={false}
              radioButtonOption={true}
              navigation={navigation}
              communityCardIndex={index}
              isCommunityCardSelected={selectedCommunityIndex == index}
              handleCommunitySelection={handleCommunitySelection}
              redirectToCommunityPage={false}
            />
          ))}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  spinnerTextStyle: {
    color: '#FFF',
  },
});

export default SelectSpaces;
