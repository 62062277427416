import AsyncStorage from '@react-native-async-storage/async-storage';

type Nullable<T = any> = T | null;

/**
 * getData
 * gets the data from async storage and returns it if the data is not pressent returns null
 */
const getData = async <T = any>(key: string): Promise<T | null> => {
  try {
    const stringValue = await AsyncStorage.getItem(key);
    return stringValue ? JSON.parse(stringValue) : null;
  } catch (error) {
    return null as Nullable;
  }
};

const setData = async (key: string, value: any) => {
  try {
    const stringValue = JSON.stringify(value);
    await AsyncStorage.setItem(key, stringValue);
    return true;
  } catch (e) {
    return false;
  }
};

const removeData = async (key: string) => {
  try {
    await AsyncStorage.removeItem(key);
    return true;
  } catch (error) {
    return false;
  }
};

const LocalStorage = {
  getData,
  setData,
  removeData,
};

export default LocalStorage;
