import React from 'react';
import type {FC} from 'react';
import {ActivityIndicator, Pressable, StyleSheet, View} from 'react-native';
import type {PressableProps} from 'react-native';
import {CustomIcon} from './icon';
import Colors from '@constants/Colors';
import {FontFamily, FontSize, FontWeight, LineHeight} from '@utils/sizes';
import {CustomText, CustomTextStyle, TextColors} from './Texts';
import useColors from '@utils/useColors';

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    height: 50,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  textStyle: {
    fontSize: FontSize.medium,
    lineHeight: LineHeight.medium,
    fontWeight: FontWeight.regular,
    color: Colors.lightText,
    paddingLeft: 20,
  },
  textContainer: {
    flex: 1,
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
});

type IconTextButtonProps = PressableProps & {
  text: string;
  color?: string;
  iconSize?: number;
  Icon: React.FunctionComponent<{size: number; color: string}> | string;
  textStyle?: CustomTextStyle;
  isLoading?: boolean;
  fontSize?: FontSize;
  fontFamily?: FontFamily;
  fontColor?: TextColors;
};

const IconTextButton: FC<IconTextButtonProps> = props => {
  const {
    text,
    Icon,
    style,
    isLoading,
    color = Colors.lightText,
    textStyle,
    iconSize = 18,
    fontSize,
    fontColor = 'lightText',
    fontFamily = FontFamily.FigtreeRegular,
    ...rest
  } = props;
  const colors = useColors();
  return (
    <Pressable
      style={({hovered}: any) => {
        return [
          styles.container,
          style as any,
          {backgroundColor: hovered ? Colors.hover : colors.white},
        ];
      }}
      {...rest}>
      {typeof Icon === 'string' ? (
        <CustomIcon size={iconSize} color={color} name={Icon} />
      ) : (
        <Icon size={iconSize} color={color} />
      )}
      <View style={styles.textContainer}>
        <CustomText
          style={textStyle}
          size={fontSize}
          fontFamily={fontFamily}
          color={fontColor}>
          {text}
        </CustomText>
      </View>
      {isLoading ? <ActivityIndicator color={Colors.lightText} /> : null}
    </Pressable>
  );
};

export default IconTextButton;
