import ConstantsEnum from '@constants/constantsEnum';
import type {FeedList} from '@models/Feed/FeedList';
import type {CreateTextPostPayload} from '@models/Feed/CreateTextPostPayload';
import type {CreatePostEventPayload} from '@models/Feed/CreatePostEventPayload';
import type {UploadImageInPostPayload} from '@models/Feed/UploadImageInPostPayload';
import type {PostDataMediaUploadResponse} from '@models/Feed/PostDataMediaUploadResponse';
import type {FeedPostByUserPayload} from '@models/Feed/FeedPostByUserPayload';
import type {FilterFeedByFlairPayload} from '@models/Feed/FilterFeedByFlairPayload';
import {UpVotePollPayload} from '@models/Feed/UpVotePollPayload';
import axiosInstance from './axios';
import AuthenticationService from './authenticationService';
import {DeletePostPayload} from '@models/Feed/DeletePostPayload';

const fetchUserFeedsList = async (payload: {
  page: number;
  userId: string;
  pass?: boolean;
}) => {
  let accessToken = await AuthenticationService.getAccessToken();
  if (!accessToken && !!payload.pass) {
    accessToken = 'khuljasimsim';
  }
  return axiosInstance.get<FeedList>('/nexum/get/user/feed', {
    params: {
      user_tid: payload.userId,
      page: payload.page,
    },
    headers: {
      Authorization:
        accessToken == 'khuljasimsim' ? accessToken : `Bearer ${accessToken}`,
    },
  });
};

const fetchFeedDataByThingId = async (
  feedDataThingId: string,
  passAuth?: boolean,
) => {
  let accessToken = await AuthenticationService.getAccessToken();

  if (!accessToken && !!passAuth) {
    accessToken = 'khuljasimsim';
  }
  return axiosInstance.get<FeedList>('/nexum/get/list', {
    params: {
      thing_type: ConstantsEnum.ThingTypeEnum.POST,
      thing_id: feedDataThingId,
    },
    headers: {
      Authorization:
        accessToken == 'khuljasimsim' ? accessToken : `Bearer ${accessToken}`,
    },
  });
};

const fetchFeedsListPostedByUser = async (payload: FeedPostByUserPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.get<FeedList>('/nexum/get/list', {
    params: {
      thing_type: ConstantsEnum.ThingTypeEnum.POST,
      filter: JSON.stringify({
        author_id: payload.authorId,
      }),
      page: payload.page,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchFeedPostDetail = async (postId: string) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.get<FeedList>('/nexum/get/list', {
    params: {
      thing_type: ConstantsEnum.ThingTypeEnum.POST,
      thing_id: postId,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchFeedsListByLabel = async (payload: FilterFeedByFlairPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.get<FeedList>('/nexum/get/user/feed', {
    params: {
      user_tid: payload.userId,
      page: payload.page,
      filter: JSON.stringify({
        flairs__contains: payload.flair,
      }),
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const createTextPost = async (payload: CreateTextPostPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  let formData = new FormData();
  formData.append('thing_type', ConstantsEnum.ThingTypeEnum.POST);
  formData.append('kwargs', JSON.stringify(payload.kwargs));
  formData.append('thing_data', JSON.stringify(payload.thing_data));

  return axiosInstance.post<FeedList>('/nexum/create/post', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const updateImageInPost = async (payload: UploadImageInPostPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  let formData = new FormData();
  formData.append('thing_id', payload.thingId!);
  formData.append('thing_type', ConstantsEnum.ThingTypeEnum.POST);
  formData.append('key', 'media_img');
  formData.append('mime_type', 'image/jpeg');
  const fileData: any = {
    uri: payload.imageUri,
    name: `${payload.thingId}_image.jpeg`,
    type: 'image/jpeg',
  };
  formData.append('file', !!payload.file ? payload.file : fileData);
  return axiosInstance.post<PostDataMediaUploadResponse>(
    '/nexum/data/media/upload',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

const createPostEvent = async (payload: CreatePostEventPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.get<any>('/nexum/event', {
    params: payload,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const votePollOption = async (payload: UpVotePollPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  let formData = new FormData();
  formData.append('thing_id', payload.thing_id);
  formData.append('option_id', payload.option_id);
  formData.append('user_tid', payload.user_tid);

  return axiosInstance.post<any>('/nexum/poll/vote', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const deletePost = async (payload: DeletePostPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.delete<any>('/nexum/delete/post', {
    params: {
      thing_type: payload.thing_type,
      thing_id: payload.thing_id,
      user_tid: payload.user_tid,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const FeedService = {
  fetchUserFeedsList,
  fetchFeedDataByThingId,
  fetchFeedPostDetail,
  fetchFeedsListByLabel,
  createTextPost,
  updateImageInPost,
  createPostEvent,
  fetchFeedsListPostedByUser,
  votePollOption,
  deletePost,
};

export default FeedService;
