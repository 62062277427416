import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ApiStatusEnum} from '@constants/constantsEnum';
import {
  fetchUserNotificationsList,
  updateNotificationReadStatus,
} from '@redux/actions/notifications';
import {NotificationData} from '@models/Notification/NotificationData';

export interface Notification {
  fetchAllNotificationStatus: string;
  notifications: Record<string, NotificationData>;
  notificationReadStatus: string;
  page: number;
  totalNumberOfPagesInNotification: number;
  refreshFeedDetailsOnNotification: boolean;
  unreadCount: number;
}

const initialState: Notification = {
  fetchAllNotificationStatus: ApiStatusEnum.IDLE,
  notifications: {},
  notificationReadStatus: ApiStatusEnum.IDLE,
  page: 0,
  totalNumberOfPagesInNotification: 0,
  refreshFeedDetailsOnNotification: false,
  unreadCount: 0,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    markAsRead: (state, action: PayloadAction<string>) => {
      const {notifications} = state;
      const id = action.payload;
      notifications[id].is_read = true;
      state.unreadCount = state.unreadCount - 1;
    },
    setNotificationPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetNotificationList: state => {
      state.page = 0;
      state.notifications = {};
      state.totalNumberOfPagesInNotification = 0;
    },
    setRefreshFeedDetailsOnNotification: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.refreshFeedDetailsOnNotification = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUserNotificationsList.pending, state => {
      state.fetchAllNotificationStatus = ApiStatusEnum.LOADING;
    });
    builder.addCase(fetchUserNotificationsList.fulfilled, (state, action) => {
      state.fetchAllNotificationStatus = ApiStatusEnum.SUCCEEDED;

      const {
        payload: {results, num_unread},
      } = action;
      const {notifications} = state;
      for (let i = 0; i < results.length; i++) {
        const notification = results[i];
        const {notification_id} = notification;
        notifications[notification_id] = notification;
      }
      state.totalNumberOfPagesInNotification = action.payload.num_pages;
      state.unreadCount = num_unread;
    });
    builder.addCase(fetchUserNotificationsList.rejected, state => {
      state.fetchAllNotificationStatus = ApiStatusEnum.FAILED;
    });
    builder.addCase(updateNotificationReadStatus.pending, state => {
      state.notificationReadStatus = ApiStatusEnum.LOADING;
    });
    builder.addCase(updateNotificationReadStatus.fulfilled, state => {
      state.notificationReadStatus = ApiStatusEnum.SUCCEEDED;
    });
    builder.addCase(updateNotificationReadStatus.rejected, state => {
      state.notificationReadStatus = ApiStatusEnum.FAILED;
    });
  },
});
export const {
  markAsRead,
  setNotificationPage,
  resetNotificationList,
  setRefreshFeedDetailsOnNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
