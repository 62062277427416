import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import ConstantsEnum, {ApiStatusEnum} from '../../constants/constantsEnum';
import {MetaData} from '../../models/MetaData/metaData';
import {MetaDataCommunity} from '../../models/MetaData/metaDataCommunity';
import {fetchMetaData, fetchOrganistationData} from '@redux/actions/meta';

export interface MetaDataState {
  data: MetaData | null;
  status: string;
  error: string | null;
  communityAvatarMap: any;
  communityAvatarColorMap: Record<string, string | undefined>;
  communities: Record<string, MetaDataCommunity>;
  organisation: string[];
}
const initialState: MetaDataState = {
  data: {
    thing_type: 'meta',
    flairs: [],
    interests: [],
    community: [],
  },
  status: ConstantsEnum.ApiStatusEnum.IDLE,
  error: null,
  communityAvatarMap: null,
  communityAvatarColorMap: {},
  communities: {},
  organisation: [],
};

export const metaDataSlice = createSlice({
  name: 'metaData',
  initialState: initialState,
  reducers: {
    addCommunityColorsMap: (
      state,
      action: PayloadAction<Record<string, string>>,
    ) => {
      state.communityAvatarColorMap = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchMetaData.pending, state => {
      state.status = ApiStatusEnum.LOADING;
    });
    builder.addCase(fetchMetaData.fulfilled, (state, action) => {
      state.status = ApiStatusEnum.SUCCEEDED;
      state.data = action.payload;
      const communityMap = {};
      const communities = {};
      action.payload.community.map((com: MetaDataCommunity) => {
        communityMap[com.thing_id] = com.community_avatar;
        communities[com.thing_id] = com;
      });
      state.communityAvatarMap = communityMap;
      state.communities = communities;
    });
    builder.addCase(fetchMetaData.rejected, (state, action) => {
      state.status = ApiStatusEnum.FAILED;
      state.error = action.payload as any;
    });
    builder.addCase(fetchOrganistationData.pending, state => {
      state.status = ApiStatusEnum.LOADING;
    });
    builder.addCase(fetchOrganistationData.fulfilled, (state, action) => {
      state.status = ApiStatusEnum.SUCCEEDED;
      state.organisation = action.payload;
    });
    builder.addCase(fetchOrganistationData.rejected, (state, action) => {
      state.status = ApiStatusEnum.FAILED;
      state.error = action.payload as any;
      state.organisation = [];
    });
  },
});

export const {addCommunityColorsMap} = metaDataSlice.actions;

export default metaDataSlice.reducer;
