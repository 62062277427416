/* eslint-disable */
import React, {memo, useCallback, useEffect, useMemo, useRef} from 'react';
import type {FC} from 'react';
import {Spinner} from 'native-base';
import {View, StyleSheet, Text, FlatList} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {shallowEqual, useSelector} from 'react-redux';
import {useNavigation, useRoute} from '@react-navigation/native';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {createAnalyticsEvent} from '@redux/slices/analyticsSlice';
import {dispatch} from '@redux/store';
import {fetchCommunityFeed} from '@redux/actions/community';
import {
  createCommunityFeedEndReachedSelector,
  createCommunityFeedIdsSelector,
  createCommunityFeedPageAndTotalPageSelector,
} from '@redux/selectors/communitySelector';
import {
  resetCommunityFeedList,
  setCommunityFeedPage,
} from '@redux/slices/communitySlice';
import FeedCard from '@components/Card/FeedCard/FeedCard';
import {
  FeedListLoaderItem,
  ListLoaderProvider,
  LOADING_DATA,
} from '@components/FeedListLoader';
import ShareService from '@services/shareService';
import DynamicLinkService from '@services/dynamicLinkService';
import type {FeedData} from '@models/Feed/FeedData';
import type {AnalyticsEventPayload} from '@models/Analytics/AnalyticsEventPayload';
import type {CommunityUtilPayload} from '@models/Community/CommunityUtilPayload';
import {
  DynamicLinkThingTypeEnum,
  EventTypeEnum,
  EventTypeValueEnum,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import CommunityLayoutContext from './CommunitJoin';
import {FontFamily} from '@utils/sizes';
import {withTokenRefresh} from '@utils/webhooks';

const styles = StyleSheet.create({
  list: {height: '100%'},
  contentContainerStyle: {},
  emptyContainer: {
    alignItems: 'center',
    alignSelf: 'stretch',
    marginTop: 150,
  },
  button: {
    backgroundColor: '#9B51E0',
    borderRadius: 8,
    // height: 30,
  },
});

const CommunityFeedCard: FC<{
  thingId: string;
  index: number;
  userThingId: string;
}> = memo(props => {
  const navigation = useNavigation() as any;

  const handleComment = useCallback(
    (feedCardIndex: number) => {
      navigation.navigate('FeedDetail', {
        feedDataIndex: feedCardIndex,
        // userAccountData: userAccountData,
        isSeeMoreClicked: false,
        isProfileCard: false,
      });
      const analyticsEventPayload: AnalyticsEventPayload = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: EventTypeValueEnum.COMMENT,
        thing_id: props.thingId,
        thing_type: ThingTypeEnum.ANALYTICS,
        uid: props.userThingId,
      };

      dispatch(createAnalyticsEvent(analyticsEventPayload));
    },
    [props.thingId, props.userThingId],
  );

  const handleShare = useCallback(async (data: FeedData) => {
    const link = await DynamicLinkService.createDynamicLink(
      data.thing_id,
      DynamicLinkThingTypeEnum.FEED_DETAIL,
    );
    const options = {
      message: `Check out this post on Wyse App ${data.title}: ${link} \n\nJoin the most meaningful discussions by industry leaders and your peers on Wyse app now.\nInnovation starts from conversation`,
      title: '',
    };
    await ShareService.openShare(options);
  }, []);

  return (
    <FeedCard
      hideCommunityHeader
      thingId={props.thingId}
      loggedInUserId={props.userThingId}
      feedCardIndex={props.index}
      isFeedDetailCard={false}
      isProfileCard={false}
      seeMore
      navigation={navigation}
      handleComment={handleComment}
      handleShare={handleShare}
    />
  );
});

const CommunityFeed: FC<{communityThingId: string}> = ({}) => {
  const params = useRoute().params as {communityThingId: string};
  console.log('params', params);
  const communityThingId = params?.communityThingId!;
  const loadingRef = useRef(null);
  const listRef = useRef(null);
  const {bottom} = useSafeAreaInsets();
  const feedData = useSelector(
    createCommunityFeedIdsSelector(communityThingId),
    shallowEqual as any,
  );
  const loading = useRef(false);
  const handleMore = useRef(false);
  const userThingId = useSelector(userAccountThingIdSelector)!;

  const [page, totalNumberOfPagesinFeed] = useSelector(
    createCommunityFeedPageAndTotalPageSelector(communityThingId),
    shallowEqual,
  );

  const feedEndReached = useSelector(
    createCommunityFeedEndReachedSelector(communityThingId),
  );
  /**
   * functional effect to call everytime page updates
   */
  useEffect(() => {
    if (!communityThingId) return;
    (async () => {
      if (!!loading.current) return;
      loading.current = true;
      if (
        (page == undefined && totalNumberOfPagesinFeed == undefined) ||
        handleMore.current
      ) {
        const communityUtilPayload: CommunityUtilPayload = {
          communityId: communityThingId,
          page: page == undefined ? 0 : page,
        };
        await dispatch(fetchCommunityFeed(communityUtilPayload));
        if (handleMore.current) handleMore.current = false;
      }
      loading.current = false;
    })();
  }, [page, communityThingId, loading, handleMore]);

  const loadingData =
    communityThingId == null || (feedData.length <= 0 && !!loading);

  /**
   * renderItem of Flatlist
   */
  const renderItem = useCallback(
    ({item, index}: {item: string | number; index: number}) => {
      if (typeof item === 'string' && !loadingData) {
        return (
          <CommunityFeedCard {...{userThingId}} thingId={item} index={index} />
        );
      }
      if (typeof item === 'number' && loadingData) {
        return <FeedListLoaderItem {...{item}} />;
      }
      return null;
    },
    [userThingId, loadingData],
  );

  /**
   * Reset Feed List
   */
  const resetData = useCallback(() => {
    dispatch(resetCommunityFeedList(communityThingId));
  }, [communityThingId]);

  /**
   * Handle Load more
   */
  const handleLoadMore = useCallback(
    ({distanceFromEnd}: {distanceFromEnd: number}) => {
      if (loadingData) return;
      if (!!loading.current) return;
      if (distanceFromEnd <= 0) return;
      if (feedEndReached) return;
      if (!!handleMore.current) return;
      handleMore.current = true;
      dispatch(
        setCommunityFeedPage({
          communityId: communityThingId,
          page: page + 1,
        }),
      );
    },
    [page, communityThingId, feedEndReached, loading, loadingData, handleMore],
  );

  /**
   * REfresh feed function
   */
  const refreshFeed = useCallback(() => {
    if (loadingData) return;
    if (!communityThingId) return;
    resetData();
    const communityUtilPayload: CommunityUtilPayload = {
      communityId: communityThingId,
      page: 0,
    };
    dispatch(fetchCommunityFeed(communityUtilPayload));
  }, [communityThingId, resetData, loadingData]);

  /**
   * List Footer Component
   */
  const listFooter = useCallback(
    () => (
      <View style={{alignSelf: 'stretch', alignItems: 'center', marginTop: 10}}>
        {loadingData ? null : feedData.length ===
          0 ? null : totalNumberOfPagesinFeed == page + 1 ? null : (
          <Spinner size="sm" color="#9B51E0" />
        )}
        <CommunityLayoutContext.BottomPadding />
      </View>
    ),
    [page, totalNumberOfPagesinFeed, feedData.length, loadingData],
  );

  /**
   * key extractor important for flatlist to render cells for batch update
   */
  const keyExtractor = useCallback(
    (item: string | string, index: number) =>
      loadingData ? `loading-item-${item}` : `community-post-${item}-${index}`,
    [loadingData],
  );

  const empty = useMemo(
    () =>
      loading ? null : (
        <View style={styles.emptyContainer}>
          <Text style={{marginBottom: 20, fontFamily: FontFamily.lato_regular}}>
            There are no posts in this community
          </Text>
        </View>
      ),
    [loading],
  );

  return (
    <View style={{flex: 1}}>
      <ListLoaderProvider loading={loadingData}>
        {useMemo(
          () => (
            <FlatList
              showsVerticalScrollIndicator={false}
              ref={loadingData ? loadingRef : listRef}
              keyExtractor={keyExtractor}
              data={loadingData ? LOADING_DATA : feedData}
              contentContainerStyle={[
                styles.contentContainerStyle,
                {paddingBottom: bottom || 16},
              ]}
              initialNumToRender={10}
              renderItem={renderItem}
              onEndReached={handleLoadMore}
              onEndReachedThreshold={0.5}
              style={styles.list}
              refreshing={false}
              ListEmptyComponent={empty}
              onRefresh={refreshFeed}
              ListFooterComponent={listFooter}
            />
          ),
          [
            bottom,
            renderItem,
            handleLoadMore,
            empty,
            refreshFeed,
            listFooter,
            loadingData,
            feedData.length,
          ],
        )}
      </ListLoaderProvider>
    </View>
  );
};

export default withTokenRefresh(CommunityFeed);
