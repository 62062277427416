/* eslint-disable */
import React, {useCallback, useRef, useState} from 'react';
import type {FC} from 'react';
import {
  ActivityIndicator,
  FlatList,
  Pressable,
  StyleSheet,
  TextInput,
  View,
} from 'react-native';
import type {ListRenderItem, StyleProp, ViewStyle} from 'react-native';
import Header, {
  CancelIcon,
  HeaderBackButton,
  SearchIcon,
} from '@components/Header/Header';
import {
  MediumThinText,
  MediumText,
  SmallText,
  textStyles,
  CustomText,
} from '@components/Texts';
import Colors from '@constants/Colors';
import {
  ConversationStateProvider,
  usePendingConversationsCount,
  useConversations,
  usePendingConversationsLoading,
  useConversationsLoading,
  useSetState,
} from './context';
import {
  ConversactionLoaderItem,
  LOADING_DATA_LARGE,
  ListLoaderProvider,
} from '@components/FeedListLoader';
import {Conversation} from '@models/Conversation';
import ConversationCard from '@components/Card/ConversationCard';
import {useSelector} from 'react-redux';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import NavigationService from '@services/navigationService';
import RequestsList from './RequestsList';
import {useInputDebounce} from '@utils/utilityFunctions';
import {FontFamily, FontSize, SCREEN_SIZE, TAB_BAR_SIZE} from '@utils/sizes';
import {textInputStyles} from '@components/Inputs';
import {withTokenRefresh} from '@utils/webhooks';
import Screen from '@components/Screen';
import BigScreenRightBar from '@components/BigScreenRightBar';
import BigScreenTabBar from '@components/BigScreenTabBar';
import {AppScreens} from '@navigations/ScreenNames';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  headerRightContainer: {
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  line: {alignSelf: 'stretch', height: 1, backgroundColor: Colors.background},
  counTitleContainer: {
    alignSelf: 'stretch',
    height: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
  },

  requestsButton: {},
  requestsText: {},
  searchContainer: {
    flex: 1,
    marginVertical: 4,
    marginHorizontal: 4,
    justifyContent: 'center',
    paddingHorizontal: 16,
    backgroundColor: Colors.background,
    borderRadius: 8,
    borderLeftColor: Colors.lightText,
  },
});

const Line = ({
  style,
}: {
  style?: Omit<StyleProp<ViewStyle>, 'marginVertical'>;
}) => <View style={[styles.line, style]} />;

const SearchInput = () => {
  const setState = useSetState();
  const onChangeText = useInputDebounce(str => {
    setState(st => ({...st, str}));
  }, 300);

  return (
    <View style={styles.searchContainer}>
      <TextInput
        autoFocus
        style={[
          textStyles.regular,
          {lineHeight: undefined},
          textInputStyles.webInput,
        ]}
        placeholder="Search"
        placeholderTextColor={Colors.veryLightText}
        onChangeText={onChangeText}
      />
    </View>
  );
};
const PageHeader: FC = () => {
  const [showSearch, setShowSearch] = useState(false);

  const headerLeft = useCallback(
    () => (
      <HeaderBackButton
        onPress={() => {
          NavigationService.getInstance().goBack();
        }}
      />
    ),
    [],
  );

  const headerRight = useCallback(
    () => (
      <View style={styles.headerRightContainer}>
        {showSearch ? (
          <CancelIcon
            onPress={() => {
              setShowSearch(false);
            }}
          />
        ) : (
          <SearchIcon
            onPress={() => {
              setShowSearch(true);
            }}
          />
        )}
      </View>
    ),
    [showSearch],
  );

  return (
    <Header
      title={!showSearch ? 'Direct Messages' : () => <SearchInput />}
      {...{headerLeft, headerRight}}
    />
  );
};

const Subtitle = () => {
  const isLoading = usePendingConversationsLoading();
  const pendingCount = usePendingConversationsCount();
  const setState = useSetState();
  return (
    <View style={styles.counTitleContainer}>
      <CustomText size={FontSize.medium} color="lightText">
        Your connections
      </CustomText>
      <Pressable
        onPress={() => {
          setState(st => ({...st, requestPageVisible: true}));
        }}
        disabled={isLoading}
        style={styles.requestsButton}>
        {isLoading ? (
          <ActivityIndicator color={Colors.primary} />
        ) : (
          <CustomText size={FontSize.medium} color="primary">
            Requests ({pendingCount})
          </CustomText>
        )}
      </Pressable>
    </View>
  );
};

const Seperator = () => <View style={styles.line} />;

const List = () => {
  const isLoading = useConversationsLoading();
  const loadingList = useRef<FlatList>(null);
  const list = useRef<FlatList>(null);
  const data = useConversations();

  const userThingId = useSelector(userAccountThingIdSelector);
  const {bottom} = useSafeAreaInsets();
  const keyExtractor = useCallback(
    (item: number | Conversation, index: number) => {
      if (typeof item !== 'number') {
        return `conversation-${item.conversation_id}-index-${index}`;
      }
      return `loading-${index}`;
    },
    [],
  );

  const renderItem = useCallback<ListRenderItem<number | Conversation>>(
    ({item, index}) => {
      if (typeof item !== 'number') {
        return <ConversationCard conversation={item} />;
      }
      return <ConversactionLoaderItem />;
    },
    [userThingId!],
  );
  return (
    <ListLoaderProvider loading={isLoading}>
      <FlatList
        contentContainerStyle={{paddingBottom: bottom || 10}}
        ItemSeparatorComponent={Seperator}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ref={isLoading ? loadingList : list}
        data={isLoading ? LOADING_DATA_LARGE : data}
      />
    </ListLoaderProvider>
  );
};

const Inner = () => {
  return (
    <View style={styles.container}>
      <Subtitle />
      <Line />
      <List />
    </View>
  );
};

const ConversationsList: FC = () => {
  return (
    <Screen
      screen={AppScreens.Conversations}
      RightComponent={BigScreenRightBar}
      LeftComponent={BigScreenTabBar}
      maxWidth={TAB_BAR_SIZE + SCREEN_SIZE}
      style={{flex: 1, backgroundColor: Colors.white}}>
      <ConversationStateProvider>
        <View style={styles.container}>
          <PageHeader />
          <Inner />
          <RequestsList />
        </View>
      </ConversationStateProvider>
    </Screen>
  );
};

export default withTokenRefresh(ConversationsList);
