/*eslint-disable */
import React, {useCallback, PureComponent} from 'react';
import type {FC} from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {dispatch, useSelector} from '@redux/store';
import {addDownVote, addUpVote} from '@redux/slices/feedSlice';
import {
  createCommentsCountSelector,
  createVoteTypeSelector,
  createVotesCountSelector,
} from '@redux/selectors/feedSelector';
import {createPostEvent} from '@redux/actions/feed';
import type {CreatePostEventPayload} from '@models/Feed/CreatePostEventPayload';
import {CustomIcon, Icons} from '@components/icon';
import {
  EventTypeEnum,
  EventTypeValueEnum,
  FeedEnagagementAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import NavigationService from '@services/navigationService';
import Analytics, {IEventData} from '@wyse/analytics';
import {FontFamily} from '@utils/sizes';
import LocalStorage from '@services/storage';
import {useRoute} from '@react-navigation/native';
import {AuthScreens} from '@navigations/ScreenNames';
import {CustomText} from '@components/Texts';
import Colors from '@constants/Colors';

const styles = StyleSheet.create({
  votingButton: {
    // borderWidth: 1,
    width: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  voteCountText: {
    paddingHorizontal: 3,
  },
});

const VoteCounts: FC<{id: string}> = ({id}) => {
  const count = useSelector(createVotesCountSelector(id));
  return <CustomText style={styles.voteCountText}>{count}</CustomText>;
};

const VoteBar: FC<{id: string; userId: string; children: any}> = ({
  id,
  userId,
  children,
}) => {
  const route = useRoute();
  const voteType = useSelector(createVoteTypeSelector(id));

  const upVotePost = useCallback(async () => {
    if (!userId) {
      await LocalStorage.setData('redirect', route);
      NavigationService.getInstance().replaceToAuth({
        page: AuthScreens.OtpScreen,
      });
      return;
    }
    dispatch(addUpVote(id));
    const postEventPayload: CreatePostEventPayload = {
      ev_typ: EventTypeEnum.UPVOTE,
      ev_val: EventTypeValueEnum.UPVOTE,
      thing_id: id,
      thing_type: ThingTypeEnum.POST,
      user_uid: userId,
    };
    dispatch(createPostEvent(postEventPayload));
  }, [id, userId]);

  const downVotePost = useCallback(async () => {
    if (!userId) {
      await LocalStorage.setData('redirect', route);
      NavigationService.getInstance().replaceToAuth({
        page: AuthScreens.OtpScreen,
      });
      return;
    }
    dispatch(addDownVote(id));
    const postEventPayload: CreatePostEventPayload = {
      ev_typ: EventTypeEnum.DOWNVOTE,
      ev_val: EventTypeValueEnum.DOWNVOTE,
      thing_id: id,
      thing_type: ThingTypeEnum.POST,
      user_uid: userId,
    };
    dispatch(createPostEvent(postEventPayload));
  }, [id, userId]);

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <TouchableOpacity
        disabled={voteType === 'up'}
        onPress={upVotePost}
        style={styles.votingButton}>
        <CustomIcon
          size={18}
          name={voteType === 'up' ? Icons.VoteUpFilled : Icons.VoteUp}
          color={voteType === 'up' ? Colors.primary : Colors.mediumDarkGrey}
        />
      </TouchableOpacity>
      {children}
      <TouchableOpacity
        disabled={voteType === 'down'}
        onPress={downVotePost}
        style={styles.votingButton}>
        <CustomIcon
          size={18}
          name={voteType == 'down' ? Icons.VoteDownFilled : Icons.VoteDown}
          color={voteType === 'down' ? Colors.primary : Colors.mediumDarkGrey}
        />
      </TouchableOpacity>
    </View>
  );
};

const CommentsCount: FC<{id: string}> = ({id}) => {
  const count = useSelector(createCommentsCountSelector(id));
  return <CustomText style={styles.voteCountText}>{count}</CustomText>;
};

type FeedCardFooterProps = {
  feedCardIndex: number;
  isFeedDetailCard: boolean;
  userId: string;
  postId: string;
  handleComment?: () => void;
  handleShare: () => void;
  isProfileCard: boolean;
  navigateToDMScreen: () => void;
};

const FeedCardFooter = ({
  feedCardIndex,
  isFeedDetailCard,
  userId,
  postId,
  handleComment,
  handleShare,
  isProfileCard,
}: FeedCardFooterProps) => {
  const route = useRoute();
  const handleCommentInner = useCallback(() => {
    NavigationService.getInstance().openFeedDetails(
      postId,
      feedCardIndex!,
      isProfileCard,
      true,
    );

    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: FeedEnagagementAnalEventValue.POST_COMMENT_CLICK,
      thing_type: ThingTypeEnum.POST,
      thing_id: postId,
    };
    Analytics.trackEvent(analyticsEventPayload);
  }, [isProfileCard, postId, feedCardIndex]);

  return (
    <View
      style={{
        flexDirection: 'row',
        paddingTop: 8,
        paddingBottom: 4,
        paddingHorizontal: 16,
      }}>
      <View style={{flex: 1, alignItems: 'center', flexDirection: 'row'}}>
        <VoteBar id={postId} userId={userId}>
          <VoteCounts id={postId} />
        </VoteBar>
        <Pressable
          onPress={async () => {
            if (!userId) {
              if (!userId) {
                await LocalStorage.setData('redirect', route);
                NavigationService.getInstance().replaceToAuth({
                  page: AuthScreens.OtpScreen,
                });
                return;
              }
            }

            if (isFeedDetailCard) {
              handleComment?.();
            } else {
              handleCommentInner();
            }
          }}
          style={{
            flexDirection: 'row',
            marginLeft: 24,
            marginRight: 10,
            alignItems: 'center',
          }}>
          <CustomIcon
            size={18}
            name={Icons.Comments}
            color={Colors.mediumDarkGrey}
          />
          <CommentsCount id={postId} />
        </Pressable>
      </View>
      <Pressable onPress={handleShare}>
        <CustomIcon
          size={18}
          name={Icons.ShareNew}
          color={Colors.mediumDarkGrey}
        />
      </Pressable>
      {/* <Pressable
        onPress={navigateToDMScreen}
        style={({pressed}) => [
          {
            opacity: pressed ? 0.4 : 1,
          },
        ]}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: 2,
          }}>
          <FontAwesomeIcon icon={faMessage} color="#4F4F4F" size={15} />

          <Text
            style={{
              fontWeight: '400',
              fontSize: 12,
              lineHeight: 15,
              marginHorizontal: 6,
              alignSelf: 'center',
              color: '#4F4F4F',
            }}>
            DM
          </Text>
        </View>
      </Pressable> */}
    </View>
  );
};

export default class FeedCardFoorPure extends PureComponent<FeedCardFooterProps> {
  render(): React.ReactNode {
    return <FeedCardFooter {...this.props} />;
  }
}
