/*eslint-disable */
import {View, Pressable, Platform, Dimensions, StyleSheet} from 'react-native';
import React, {useEffect, useState} from 'react';
import CheckBox from '@libs/Checkbox';
import {RadioButton} from 'react-native-paper';
import CustomAvatar from '../Avatar';
import millify from 'millify';
import ConstantsEnum, {
  EventTypeEnum,
  SpacesAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import NavigationService from '@services/navigationService';
import {Button as Btn} from 'native-base';
import {FontFamily, FontSize} from '@utils/sizes';
import Colors from '@constants/Colors';
import Analytics, {IEventData} from '@wyse/analytics';
import {CustomText} from '@components/Texts';

const CommunityCard = ({
  communityThingId,
  communityAvatar,
  communityTitle,
  communityHeadline,
  communitySubCount,
  joinOption,
  isJoinedByUser,
  checkBoxOption,
  radioButtonOption,
  communityCardIndex,
  isCommunityCardSelected,
  handleCommunitySelection,
  handleJoinEvent,
  redirectToCommunityPage,
}: {
  communityThingId: string;
  communityAvatar: string;
  communityTitle: string;
  communityHeadline: string;
  communitySubCount: number;
  joinOption: boolean;
  isJoinedByUser: boolean;
  checkBoxOption?: boolean;
  radioButtonOption?: boolean;
  communityCardIndex: number;
  isCommunityCardSelected?: boolean;
  handleCommunitySelection?: any;
  navigation?: any;
  handleJoinEvent?: any;
  redirectToCommunityPage: boolean;
}) => {
  const [isChecked, setChecked] = useState(false);
  const [radioIsChecked, setRadioIsChecked] = useState(false);

  const onCommunitySelect = () => {
    handleCommunitySelection(communityCardIndex!);
  };

  useEffect(() => {
    setRadioIsChecked(isCommunityCardSelected!);
  }, [isCommunityCardSelected]);

  const onCommunityAvatarClick = () => {
    NavigationService.getInstance().openCommunityDetails(communityThingId);
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: isJoinedByUser
        ? SpacesAnalEventValue.SPACES_JOINED_COMMUNITY_CLICK
        : SpacesAnalEventValue.SPACES_EXPLORED_COMMUNITY_CLICK,
      thing_type: ThingTypeEnum.COMMUNITY,
      thing_id: communityThingId,
    };
    Analytics.trackEvent(analyticsEventPayload);
  };

  return (
    <Pressable onPress={radioButtonOption ? onCommunitySelect : () => {}}>
      <View style={styles.card}>
        <View style={styles.cardBody}>
          <Pressable
            onPress={
              radioButtonOption
                ? onCommunitySelect
                : redirectToCommunityPage
                ? onCommunityAvatarClick
                : null
            }>
            <View style={{marginRight: 10}}>
              <CustomAvatar
                width={40}
                height={40}
                borderRadius={20}
                source={communityAvatar}
              />
            </View>
          </Pressable>
          <Pressable
            onPress={
              radioButtonOption
                ? onCommunitySelect
                : redirectToCommunityPage
                ? onCommunityAvatarClick
                : null
            }
            style={styles.cardDescription}>
            <View style={{flex: 1}}>
              <CustomText
                color="text"
                size={FontSize.title}
                fontFamily={FontFamily.FigtreeBold}
                style={styles.cardTitle}>
                {communityTitle}
              </CustomText>
              <CustomText
                size={FontSize.small}
                color="lightText"
                style={styles.cardSubtitle}>
                {communityHeadline}
              </CustomText>
              <CustomText
                size={FontSize.small}
                color={'lightText'}
                style={styles.memberCount}>
                {communitySubCount > 9999
                  ? millify(communitySubCount, {precision: 3})
                  : communitySubCount + ' members'}
              </CustomText>
            </View>
            {joinOption && (
              <Pressable style={styles.joinButonContainer}>
                <Btn
                  size="sm"
                  style={[
                    styles.joinButton,
                    {
                      backgroundColor: isJoinedByUser ? '#f3f3f3' : '#9B51E0',
                    },
                  ]}
                  onPress={() => {
                    const action = isJoinedByUser
                      ? ConstantsEnum.JoinEventEnum.UNSUBSCRIBE
                      : ConstantsEnum.JoinEventEnum.SUBSCRIBE;
                    handleJoinEvent(communityThingId, action);
                  }}>
                  <CustomText
                    size={FontSize.small}
                    color={isJoinedByUser ? 'lightText' : 'white'}
                    style={[styles.joinButtonText]}>
                    {isJoinedByUser ? 'Joined' : 'Join'}
                  </CustomText>
                </Btn>
              </Pressable>
            )}
          </Pressable>

          {checkBoxOption && (
            <View style={styles.checkBoxContainer}>
              <CheckBox
                style={styles.checkBox}
                value={isChecked}
                onValueChange={setChecked}
                tintColors={{true: '#9B51E0', false: 'black'}}
              />
            </View>
          )}
          {radioButtonOption && (
            <View style={styles.checkBoxContainer}>
              {Platform.OS === 'android' && (
                <RadioButton
                  value="first"
                  status={radioIsChecked ? 'checked' : 'unchecked'}
                  onPress={onCommunitySelect}
                  color="#9B51E0"
                />
              )}
              {Platform.OS === 'ios' && (
                <CheckBox
                  style={styles.radioButton}
                  disabled={isCommunityCardSelected}
                  value={radioIsChecked}
                  onValueChange={onCommunitySelect}
                  tintColors={{true: '#9B51E0', false: 'black'}}
                  lineWidth={2}
                  hideBox={true}
                  boxType="circle"
                  onCheckColor="#9B51E0"
                />
              )}
            </View>
          )}
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  card: {
    padding: 16,
    marginBottom: 8,
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: Colors.background,
    flex: 1,
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  cardDescription: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  cardTitle: {},
  cardSubtitle: {
    marginTop: 2,
  },
  memberCount: {
    marginTop: 2,
  },
  joinButonContainer: {
    justifyContent: 'center',
  },
  joinButton: {
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 8,
    width: 80,
    justifyContent: 'center',
    alignItems: 'center',
  },
  joinButtonText: {
    textAlign: 'center',
  },
  checkBoxContainer: {
    position: 'absolute',
    right: 0,
  },
  checkBox: {
    borderRadius: 6,
    width: 20,
    height: 20,
    borderWidth: 1,
    borderColor: Colors.primary,
    borderStyle: 'solid',
    marginRight: 8,
  },
  radioButton: {
    borderRadius: 8,
    width: 18,
    height: 18,
    borderWidth: 1,
    borderColor: Colors.primary,
    borderStyle: 'solid',
  },
});

export default CommunityCard;
