/* eslint-disable */
import React, {useContext, useRef} from 'react';
import {createContext} from 'react';
import {StyleSheet} from 'react-native';
import type {StyleProp, ViewStyle} from 'react-native';
import Animated, {
  Extrapolate,
  SharedValue,
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
} from 'react-native-reanimated';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const COMMUNITY_BANNER_SIZE = 72;

type Context = {
  scrollY: SharedValue<number>;
  position: SharedValue<number>;
  height: SharedValue<number>;
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    tight: 0,
    bottom: 0,
  },
});

class _CommunityLayoutContext {
  private _Context = createContext<Context>({} as any);
  private useLayoutContext = () => useContext(this._Context);
  Provider = ({children}: {children: any}) => {
    const scrollY = useSharedValue(0);
    const position = useSharedValue(32 + 8 + COMMUNITY_BANNER_SIZE);
    const height = useSharedValue(32);

    return (
      <this._Context.Provider value={{scrollY, position, height}}>
        {children}
      </this._Context.Provider>
    );
  };
  /**
   * Wrap your join group componet  with current component
   * so that it get the heith and position of the children component
   */
  LayoutContainer = ({
    children,
    style,
  }: {
    children: any;
    style?: StyleProp<ViewStyle>;
  }) => {
    const viewRef = useRef<Animated.View>(null);

    return (
      <Animated.View ref={viewRef} style={style}>
        {children}
      </Animated.View>
    );
  };
  /**
   * use this in the both of the tabs and provide with the scroll position
   * which can got by useCurrentPageScroll of collapsible tabview
   */
  useOnScoll = (position: Animated.SharedValue<number>) => {
    const {scrollY} = this.useLayoutContext();
    useDerivedValue(() => {
      scrollY.value = position.value;
    });
  };
  BottomPadding = () => {
    const {bottom, top} = useSafeAreaInsets();
    const viewHeight = useSharedValue(0);
    const {scrollY, position, height} = this.useLayoutContext();

    const animatedStyle = useAnimatedStyle(() => {
      return {
        paddingBottom: bottom || 0,

        transform: [
          {
            translateY: interpolate(
              scrollY.value,
              [
                0,
                position.value - (top + 46),
                position.value + height.value - (top + 46),
              ],
              [0, 0, viewHeight.value],
              Extrapolate.CLAMP,
            ),
          },
        ],
      };
    }, [bottom, top, scrollY, position, viewHeight, height, viewHeight]);
    return <Animated.View style={animatedStyle} />;
  };
  /**
   * Wrap the bottom view wuth this component so that it can animated
   * with scroll
   */
  BottomContainer = ({
    children,
    style,
  }: {
    children: any;
    style?: StyleProp<ViewStyle>;
  }) => {
    const {bottom, top} = useSafeAreaInsets();
    const viewHeight = 44 + (bottom || 10); // useSharedValue(44 + (bottom || 10));
    const {scrollY, position, height} = this.useLayoutContext();

    // dont delete this code used in future
    // const onLayout = useCallback((event: LayoutChangeEvent) => {
    //   'worklet';
    //   viewHeight.value = event.nativeEvent.layout.height;
    // }, []);

    const animatedStyle = useAnimatedStyle(() => {
      return {
        paddingBottom: bottom || 0,

        transform: [
          {
            translateY: interpolate(
              scrollY.value,
              [
                0,
                position.value - (top + 46),
                position.value + height.value - (top + 46),
              ],
              [viewHeight, viewHeight, 0],
              Extrapolate.CLAMP,
            ),
          },
        ],
      };
    }, [bottom, top, scrollY, position, viewHeight, height]);
    return (
      <Animated.View
        // dont delete this code used in future
        // onLayout={onLayout}
        style={[styles.container, animatedStyle, style]}>
        {children}
      </Animated.View>
    );
  };
}

const CommunityLayoutContext = new _CommunityLayoutContext();

export default CommunityLayoutContext;
