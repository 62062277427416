import queryString from 'query-string';

import ConstantsEnum from '@constants/constantsEnum';
import type {CommentList} from '@models/Comment/CommentList';
import type {CreateTextCommentPayload} from '@models/Comment/CreateTextCommentPayload';
import type {CreateCommentEventPayload} from '@models/Comment/CreateCommentEventPayload';
import type {FetchPostCommentsPayload} from '@models/Comment/FetchPostCommentsPayload';
import axiosInstance from './axios';
import AuthenticationService from './authenticationService';

const fetchCommentsPerPost = async (payload: FetchPostCommentsPayload) => {
  let accessToken = await AuthenticationService.getAccessToken();
  if (!accessToken && !!payload.pass) {
    accessToken = 'khuljasimsim';
  }
  return axiosInstance.get<CommentList>('/nexum/get/list', {
    params: {
      thing_type: ConstantsEnum.ThingTypeEnum.COMMENT,
      filter: JSON.stringify({
        parent_thing_id: payload.postId,
      }),
      page: payload.page,
    },
    headers: {
      Authorization:
        accessToken == 'khuljasimsim' ? accessToken : `Bearer ${accessToken}`,
    },
  });
};

const createTextComment = async (payload: CreateTextCommentPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.post<CommentList>(
    '/nexum/create/post/comment/',
    queryString.stringify({
      thing_type: ConstantsEnum.ThingTypeEnum.COMMENT,
      kwargs: JSON.stringify(payload.kwargs),
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

const createCommentEvent = async (payload: CreateCommentEventPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.get<any>('/nexum/event', {
    params: payload,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const CommentService = {
  fetchCommentsPerPost,
  createTextComment,
  createCommentEvent,
};

export default CommentService;
