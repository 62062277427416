import {StyleSheet, FlatList, ActivityIndicator} from 'react-native';
import React, {useEffect, useCallback} from 'react';
import NotificationCard from '@components/Card/NotificationCard';
import Header from '@components/Header/Header';
import {useSelector, dispatch} from '@redux/store';
import {
  notificationSelector,
  notificationPagesAndTotalNumbersSelector,
  notificationEndReachedSelector,
} from '@redux/selectors/notificationSelector';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {fetchUserNotificationsList} from '@redux/actions/notifications';
import Colors from '@constants/Colors';
import {shallowEqual} from 'react-redux';
import {
  setNotificationPage,
  resetNotificationList,
} from '@redux/slices/notificationSlice';
import Screen from '@components/Screen';
import BigScreenTabBar from '@components/BigScreenTabBar';
import {withTokenRefresh} from '@utils/webhooks';
import BigScreenRightBar from '@components/BigScreenRightBar';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

const Notifications = ({}: {navigation: any}) => {
  const notifications = useSelector(notificationSelector);
  const userThingId = useSelector(userAccountThingIdSelector);
  const [page, totalNumberOfPagesinNotification] = useSelector(
    notificationPagesAndTotalNumbersSelector,
    shallowEqual,
  );
  const notificationEndReached = useSelector(notificationEndReachedSelector);

  useEffect(() => {
    if (userThingId) {
      dispatch(fetchUserNotificationsList({page: page, userId: userThingId}));
    }
  }, [page, userThingId]);

  const renderNotification = useCallback(
    ({item}: {item: {notificationId: string}}) => (
      <NotificationCard
        key={item.notificationId}
        notificationId={item.notificationId}
      />
    ),
    [],
  );

  const handleLoadMore = useCallback(
    ({distanceFromEnd}: {distanceFromEnd: number}) => {
      if (distanceFromEnd <= 0) return;
      if (notificationEndReached) return;
      dispatch(setNotificationPage(page + 1));
    },
    [page, notificationEndReached],
  );

  const refreshFeed = useCallback(() => {
    dispatch(resetNotificationList());
    dispatch(fetchUserNotificationsList({page: 0, userId: userThingId!}));
  }, [userThingId]);

  const listFooter = useCallback(
    () =>
      totalNumberOfPagesinNotification == page + 1 ? null : (
        <ActivityIndicator size="small" color={Colors.primary} />
      ),
    [page, totalNumberOfPagesinNotification],
  );

  return (
    <Screen
      RightComponent={BigScreenRightBar}
      LeftComponent={BigScreenTabBar}
      style={styles.container}>
      <Header title="Notifications" />
      <FlatList
        disableIntervalMomentum
        removeClippedSubviews
        data={Object.keys(notifications).map(notificationId => ({
          notificationId,
        }))}
        keyExtractor={item => item.notificationId}
        renderItem={renderNotification}
        initialNumToRender={20}
        onEndReached={handleLoadMore}
        onEndReachedThreshold={0.5}
        refreshing={false}
        onRefresh={refreshFeed}
        ListFooterComponent={listFooter}
      />
    </Screen>
  );
};

export default withTokenRefresh(Notifications);
