/*eslint-disable */
import {
  View,
  Dimensions,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  SafeAreaView,
  useWindowDimensions,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import Spinner from 'react-native-loading-spinner-overlay';
import {Button as Btn} from 'native-base';
import CommunityCard from '@components/Card/CommunityCard';
import ConstantsEnum, {
  CommunityCategoryEnum,
  JoinEventEnum,
} from '@constants/constantsEnum';
import {communitiesSelector} from '@redux/selectors/metaSelector';
import {CommunityJoinEventPayload} from '@models/Community/CommunityJoinEventPayload';
import {joinCommunityEventHandler} from '@redux/actions/community';
import {updateJoinedCommunitiesByUser} from '@redux/slices/communitySlice';
import {createCommunitiesJoinedByUserSelector} from '@redux/selectors/communitySelector';
import {dispatch, useSelector} from '@redux/store';
import {userAccountDataSelector} from '@redux/selectors/userAccountSelectors';
import {updateCurrentUserJoinedCommunities} from '@redux/slices/profileSlice';
import NavigationService from '@services/navigationService';
import Analytics, {IEventData} from '@wyse/analytics';
import {
  EventTypeEnum,
  ProfileCreationAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import {MetaDataCommunity} from '@models/MetaData/metaDataCommunity';
import {FontFamily, FontSize} from '@utils/sizes';
import {CustomText} from '@components/Texts';

const {height} = Dimensions.get('window');

const SelectSpacesSignup = ({navigation}: {navigation: any}) => {
  // TODO - useSelector to be applied as per refactored code
  const userAccountData = useSelector(userAccountDataSelector);

  const joinedCommunityIds: string[] = useSelector(
    createCommunitiesJoinedByUserSelector,
  );

  let metaCommunities = useSelector(communitiesSelector);

  const [nonMetaJoinedCommunities, setNonMetaJoinedCommunities] = useState<
    MetaDataCommunity[]
  >([]);

  const {width} = useWindowDimensions();
  const [metaJoinedCommunities, setMetaJoinedCommunities] = useState<
    MetaDataCommunity[]
  >([]);

  const [nonJoinedCommunities, setNonJoinedCommunities] = useState<
    MetaDataCommunity[]
  >([]);

  useEffect(() => {
    populateJoinedCommunitiesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinedCommunityIds]);

  const populateJoinedCommunitiesData = () => {
    if (userAccountData) {
      let metaJoinedCommunityList: MetaDataCommunity[] = [];
      let nonMetaJoinedCommunityList: MetaDataCommunity[] = [];
      let nonJoinedCommunityList: MetaDataCommunity[] = [];
      metaCommunities.map((community: MetaDataCommunity) => {
        if (joinedCommunityIds.includes(community.thing_id)) {
          if (community.category == CommunityCategoryEnum.META) {
            metaJoinedCommunityList.push(community);
          } else {
            nonMetaJoinedCommunityList.push(community);
          }
        } else {
          nonJoinedCommunityList.push(community);
        }
      });
      setNonMetaJoinedCommunities(nonMetaJoinedCommunityList);
      setMetaJoinedCommunities(metaJoinedCommunityList);
      setNonJoinedCommunities(nonJoinedCommunityList);
    }
  };

  const [loadingNextScreen, setLoadingNextScreen] = useState<boolean>(false);

  useEffect(() => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: ProfileCreationAnalEventValue.SPACES_LIST_ONBOARDING_IMPR,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
  }, []);

  const handleClick = () => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: ProfileCreationAnalEventValue.SPACES_LIST_NEXT_CLICK,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
    setLoadingNextScreen(true);
    setTimeout(() => {
      setLoadingNextScreen(false);
      NavigationService.getInstance().replaceToApp();
    }, 1500);
  };

  const handleJoinEvent = async (
    communityThingId: string,
    action: JoinEventEnum,
  ) => {
    const payload: CommunityJoinEventPayload = {
      resource_id: communityThingId,
      thing_type: ConstantsEnum.ThingTypeEnum.COMMUNITY,
      action: action,
      user_id: userAccountData!.thing_id!,
    };

    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val:
        action == JoinEventEnum.SUBSCRIBE
          ? ProfileCreationAnalEventValue.ONB_JOIN_COMMUNITY
          : ProfileCreationAnalEventValue.ONB_UNJOIN_COMMUNITY,
      thing_type: ThingTypeEnum.COMMUNITY,
      thing_id: communityThingId,
    };
    Analytics.trackEvent(analyticsEventPayload);
    /** to update state */
    dispatch(updateJoinedCommunitiesByUser(payload));
    dispatch(updateCurrentUserJoinedCommunities(payload));
    /** for api calls, state management and db updates are independent */
    const response = await dispatch(joinCommunityEventHandler(payload));
    if (joinCommunityEventHandler.fulfilled.match(response)) {
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val:
          action == JoinEventEnum.SUBSCRIBE
            ? ProfileCreationAnalEventValue.ONB_JOIN_COMMUNITY_SUCCESS
            : ProfileCreationAnalEventValue.ONB_UNJOIN_COMMUNITY_SUCCESS,
        thing_type: ThingTypeEnum.COMMUNITY,
        thing_id: communityThingId,
      };
      Analytics.trackEvent(analyticsEventPayload);
    } else if (joinCommunityEventHandler.rejected.match(response)) {
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val:
          action == JoinEventEnum.SUBSCRIBE
            ? ProfileCreationAnalEventValue.ONB_JOIN_COMMUNITY_FAILED
            : ProfileCreationAnalEventValue.ONB_UNJOIN_COMMUNITY_FAILED,
        thing_type: ThingTypeEnum.COMMUNITY,
        thing_id: communityThingId,
      };
      Analytics.trackEvent(analyticsEventPayload);
    }
  };

  return (
    <SafeAreaView style={{flex: 1}}>
      <Spinner
        visible={loadingNextScreen}
        textContent={'Updating Account Data...'}
        textStyle={styles.spinnerTextStyle}
      />
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: width * 0.044,
          paddingVertical: height * 0.014,
          marginBottom: height * 0.01,
          backgroundColor: '#FFFFFF',
        }}>
        <View style={{display: 'flex', flexDirection: 'row'}}>
          <View style={{marginRight: width * 0.02}}></View>
          <CustomText size={FontSize.heading}>Recommended Spaces</CustomText>
        </View>
        <TouchableOpacity>
          <View style={{width: width * 0.2}}>
            <Btn
              size="sm"
              style={{
                backgroundColor: '#9B51E0',
                borderRadius: 8,
                opacity: nonMetaJoinedCommunities.length < 3 ? 0.2 : 1.0,
              }}
              disabled={nonMetaJoinedCommunities.length < 3}
              onPress={handleClick}>
              <CustomText>Recommended Spaces</CustomText>
            </Btn>
          </View>
        </TouchableOpacity>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginVertical: 5,
          marginBottom: 10,
          marginHorizontal: 10,
        }}>
        <View>
          <CustomText
            size={FontSize.title}
            fontFamily={FontFamily.FigtreeMedium}>
            Join atleast 3 spaces to continue
          </CustomText>
        </View>
      </View>
      <ScrollView style={{height: '85%'}}>
        {nonMetaJoinedCommunities.map(
          (item: MetaDataCommunity, index: number) => (
            <CommunityCard
              key={index}
              communityThingId={item.thing_id}
              communityAvatar={item.community_avatar!}
              communityTitle={item.title}
              communityHeadline={item.community_headline}
              communitySubCount={item.sub_count}
              joinOption={
                item.category === CommunityCategoryEnum.META ? false : true
              }
              isJoinedByUser={joinedCommunityIds.includes(item.thing_id)}
              checkBoxOption={false}
              radioButtonOption={false}
              navigation={navigation}
              communityCardIndex={index}
              handleJoinEvent={handleJoinEvent}
              redirectToCommunityPage={false}
            />
          ),
        )}
        {nonJoinedCommunities.map((item: any, index: number) => (
          <CommunityCard
            key={index}
            communityThingId={item.thing_id}
            communityAvatar={item.community_avatar}
            communityTitle={item.title}
            communityHeadline={item.community_headline}
            communitySubCount={item.sub_count}
            joinOption={true}
            isJoinedByUser={joinedCommunityIds.includes(item.thing_id)}
            checkBoxOption={false}
            radioButtonOption={false}
            navigation={navigation}
            communityCardIndex={index}
            handleJoinEvent={handleJoinEvent}
            redirectToCommunityPage={false}
          />
        ))}
        {metaJoinedCommunities.map((item: MetaDataCommunity, index: number) => (
          <CommunityCard
            key={index}
            communityThingId={item.thing_id}
            communityAvatar={item.community_avatar!}
            communityTitle={item.title}
            communityHeadline={item.community_headline}
            communitySubCount={item.sub_count}
            joinOption={
              item.category === CommunityCategoryEnum.META ? false : true
            }
            isJoinedByUser={joinedCommunityIds.includes(item.thing_id)}
            checkBoxOption={false}
            radioButtonOption={false}
            navigation={navigation}
            communityCardIndex={index}
            handleJoinEvent={handleJoinEvent}
            redirectToCommunityPage={false}
          />
        ))}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  spinnerTextStyle: {
    color: '#FFF',
  },
});

export default SelectSpacesSignup;
