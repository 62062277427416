import {createAsyncThunk} from '@reduxjs/toolkit';
import CommentService from '@services/commentService';
import type {FetchPostCommentsPayload} from '@models/Comment/FetchPostCommentsPayload';
import type {CreateTextCommentPayload} from '@models/Comment/CreateTextCommentPayload';
import type {CommentData} from '@models/Comment/CommentData';
import {CreateCommentEventPayload} from '@models/Comment/CreateCommentEventPayload';

/**
 *
 *
 * Thunk actions
 */
export const getComments = createAsyncThunk(
  'feed/details/comments/get',
  async (payload: FetchPostCommentsPayload, {rejectWithValue}) => {
    try {
      if (!!payload.data) return {results: payload.data, num_pages: 0};
      const response = await CommentService.fetchCommentsPerPost(payload);
      return response.data.response;
    } catch (error) {
      return rejectWithValue({error: error.message});
    }
  },
);

export const createTextComment = createAsyncThunk(
  'feed/details/comments/add',
  async (
    payload: {
      createTextCommentPayload: CreateTextCommentPayload;
      isCommentReply: boolean;
      feedDataId: string;
      commentParentScreen: string;
    },
    {rejectWithValue},
  ) => {
    try {
      const response = await CommentService.createTextComment(
        payload.createTextCommentPayload,
      );
      return response.data.response.results[0] as CommentData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const createCommentEvent = createAsyncThunk(
  'feed/details/comments/event',
  async (payload: CreateCommentEventPayload, {rejectWithValue}) => {
    try {
      const response = await CommentService.createCommentEvent(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
