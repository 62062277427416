import queryString from 'query-string';
import type {AccountDataLinkedIn} from '@models/AccountData/AccountDataLinkedIn';
import type {AccountDataList} from '@models/AccountData/AccountDataList';
import type {UserProfilePicUploadResponse} from '@models/AccountData/UserProfilePicUploadResponse';
import type {UpdateUserProfilePayload} from '@models/UpdateUserProfilePayload';
import type {EditAccountDataPayload} from '@models/AccountData/EditAccountDataPayload';
import type {EditAccountDataResponse} from '@models/AccountData/EditAccountDataResponse';
import axiosInstance from './axios';
import AuthenticationService from './authenticationService';
import ConstantsEnum from '../constants/constantsEnum';
import {BlockAccountPayload} from '@models/AccountData/BlockAccountPayload';
import {Platform} from 'react-native';

const getUserDataFromLinkedInImport = async (
  linkedinProfileUrl: string,
  accountId: string,
) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.post<AccountDataLinkedIn>(
    '/nexum/import/profile',
    {
      social_platform: 'linkedin',
      social_url: linkedinProfileUrl,
      thing_id: accountId,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

const getUserAccountDataFromPhoneNumber = async (phoneNumber: string) => {
  const accessToken = await AuthenticationService.getAccessToken();
  console.log('accessToken', accessToken);
  return axiosInstance.get<AccountDataList>('/nexum/get/list', {
    params: {
      thing_type: 'account',
      filter: JSON.stringify({
        mobile: phoneNumber,
      }),
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const createUserAccount = async (phoneNumber: string) => {
  const accessToken = await AuthenticationService.getAccessToken();
  const currentUuid = AuthenticationService.getUserUUid();
  return axiosInstance.post<AccountDataList>(
    '/nexum/create/user',
    queryString.stringify({
      thing_type: 'account',
      kwargs: JSON.stringify({
        mobile: phoneNumber,
        user_uid: currentUuid,
      }),
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

const dataURItoBlob = (dataURI: string) => {
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], {type: mimeString});
};

const uploadUserProfileImage = async (payload: UpdateUserProfilePayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  let formData = new FormData();
  if (Platform.OS === 'web') {
    formData.append('file', dataURItoBlob(payload.imageUri));
  } else {
    const imageFile: any = {
      uri: payload.imageUri,
      name: `${payload.thingId}_${payload.userName}_image.jpeg`,
      type: 'image/jpeg',
    };
    formData.append('file', imageFile);
    console.log('File', imageFile);
  }
  formData.append('thing_id', payload.thingId!);
  formData.append('thing_type', ConstantsEnum.ThingTypeEnum.ACCOUNT);
  formData.append('thing_field', 'avatar');
  formData.append('media_type', 'img');

  return axiosInstance.post<UserProfilePicUploadResponse>(
    '/nexum/media/upload',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

const editUserAccountData = async (payload: EditAccountDataPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  const formData = new FormData();
  formData.append('thing_id', payload.thing_id);
  formData.append('thing_type', payload.thing_type);
  formData.append('attrs', JSON.stringify(payload.attrs));
  return axiosInstance.post<EditAccountDataResponse>(
    '/nexum/edit/thing',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

const getUserAccountDataFromThingId = async (thing_id: string) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.get<AccountDataList>('/nexum/get/list', {
    params: {
      thing_type: 'account',
      filter: JSON.stringify({
        thing_id: thing_id,
      }),
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const onboardUser = async (payload: EditAccountDataPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  const formData = new FormData();
  formData.append('thing_id', payload.thing_id);
  formData.append('thing_type', payload.thing_type);
  formData.append('attrs', JSON.stringify(payload.attrs));
  return axiosInstance.post<EditAccountDataResponse>(
    '/nexum/import/interests',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

const handleBlockEvent = async (payload: BlockAccountPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.get<any>('/nexum/block', {
    params: {
      action: payload.action,
      user_id: payload.user_id,
      resource_id: payload.resource_id,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const requestOtp = async (mobile: string) => {
  // const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.post<any>(
    '/nexum/auth/request-otp',
    queryString.stringify({
      mobile_number: mobile,
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

const confirmOtp = async (mobile: string, otp: string) => {
  // const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.post<any>(
    '/nexum/auth/verify-otp',
    queryString.stringify({
      mobile_number: mobile,
      otp: otp,
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

const UserAccountDataService = {
  getUserDataFromLinkedInImport,
  getUserAccountDataFromPhoneNumber,
  createUserAccount,
  uploadUserProfileImage,
  editUserAccountData,
  getUserAccountDataFromThingId,
  onboardUser,
  handleBlockEvent,
  requestOtp,
  confirmOtp,
};

export default UserAccountDataService;
