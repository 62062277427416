import {ApiStatusEnum} from '@constants/constantsEnum';
import type {RootState} from '@redux/store';
import {MessageAccountData} from '@services/firestoreService';
/**
 * selector which gives back feedId array for particular Profile
 * @param {RootState} state root state of redux
 * @returns {string[]} feed data id array
 */
export const createProfileFeedIdsSelector =
  (profileId: string) => (state: RootState) => {
    return state.profile.profileFeedIds[profileId] || [];
  };

export const createProfileFeedLoadingSelector =
  (profileId: string) => (state: RootState) => {
    return !!state.profile.profileFeeds[profileId]
      ? state.profile.profileFeeds[profileId].fetchStatus ===
          ApiStatusEnum.LOADING
      : false;
  };

export const createProfileFeedLoadMoreLoadingSelector =
  (profileId: string) => (state: RootState) => {
    return !!state.profile.profileFeeds[profileId]
      ? state.profile.profileFeeds[profileId].feedLoadMoreStatus ===
          ApiStatusEnum.LOADING
      : false;
  };

export const createProfileFeedEndReachedSelector =
  (profileId: string) => (state: RootState) => {
    if (!!state.profile.profileFeeds[profileId]) {
      const ids = state.profile.profileFeedIds[profileId];
      if (!ids) return true;
      if (ids.length < 15) return true;
      return (
        state.profile.profileFeeds[profileId].feedPage ==
        state.profile.profileFeeds[profileId].totalPagesInFeed
      );
    } else {
      return true;
    }
  };

export const createProfileFeedPageAndTotalPageSelector =
  (profileId: string) => (state: RootState) => {
    if (!!state.profile.profileFeeds[profileId]) {
      return [
        state.profile.profileFeeds[profileId].feedPage,
        state.profile.profileFeeds[profileId].totalPagesInFeed,
      ];
    }
    return [0, undefined];
  };

export const createProfileDetailSelector =
  (profileId: string) => (state: RootState) =>
    state.profile.profileDetails[profileId];

export const createUserCurrentOrgSelector =
  (profileId: string) => (state: RootState) => {
    if (!!state.profile.profileDetails[profileId]) {
      return state.profile.profileDetails[profileId].profileAccountData
        ?.user_current_org;
    } else {
      return undefined;
    }
  };

export const createUserCurrentRoleSelector =
  (profileId: string) => (state: RootState) => {
    if (!!state.profile.profileDetails[profileId]) {
      return state.profile.profileDetails[profileId].profileAccountData
        ?.user_current_role;
    } else {
      return undefined;
    }
  };

export const createUserAvatarSelector =
  (profileId: string) => (state: RootState) => {
    if (!!state.profile.profileDetails[profileId]) {
      return state.profile.profileDetails[profileId].profileAccountData?.avatar;
    } else {
      return undefined;
    }
  };

export const createUserNameSelector =
  (profileId: string) => (state: RootState) => {
    if (!!state.profile.profileDetails[profileId]) {
      return state.profile.profileDetails[profileId].profileAccountData
        ?.user_fullname;
    } else {
      return undefined;
    }
  };
export const createUserAccountDataSelector =
  (profileId: string) => (state: RootState) => {
    if (!!state.profile.profileDetails[profileId]) {
      return state.profile.profileDetails[profileId].profileAccountData;
    } else {
      return undefined;
    }
  };

export const createProfileDetailLoadingSelector =
  (profileId: string) => (state: RootState) => {
    return !!state.profile.profileDetails[profileId]
      ? state.profile.profileDetails[profileId].fetchStatus ==
          ApiStatusEnum.LOADING
      : false;
  };

export const createUserNameIDAvatarSelector =
  (id?: string) => (state: RootState) =>
    !id
      ? undefined
      : !state.profile.profileDetails[id]?.profileAccountData
      ? undefined
      : ({
          thing_id:
            state.profile.profileDetails[id]?.profileAccountData?.thing_id!,
          user_fullname:
            state.profile.profileDetails[id]?.profileAccountData
              ?.user_fullname!,
          avatar: state.profile.profileDetails[id]?.profileAccountData?.avatar!,
        } as MessageAccountData);

export const userDocumentIdSelector = (state: RootState) =>
  state.userAccountData.accountData?.thing_id
    ? state.profile.documentIds[state.userAccountData.accountData.thing_id]
    : undefined;
export const createGetDocumentIdSelector = (id: string) => (state: RootState) =>
  state.profile.documentIds[id];
