import {Message} from '@models/Conversation/Message';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {
  createGetDocumentIdSelector,
  userDocumentIdSelector,
} from '@redux/selectors/profileSelector';

class _DatabaseService {
  useQuery = () => ({});
  useRealm = () => ({});
  useObject = () => ({});
  useMessages = (
    id: string,
    reciverId: string,
    onUpdateMsgRecived: (
      firstUserDocId: string,
      secondUserDocId: string,
      conversation_id: string,
      messageDocId: string,
    ) => Promise<void>,
  ) => {
    const userDocId = useSelector(userDocumentIdSelector);
    const reciverDocId = useSelector(createGetDocumentIdSelector(reciverId));
    const allMessages = [];

    useEffect(() => {
      if (!id || !reciverDocId || !userDocId) return;
    }, [userDocId, reciverDocId, id, onUpdateMsgRecived]);

    if (!id) return [] as any;

    return allMessages;
  };
  Provider = ({children}) => {
    return children;
  };
  //eslint-disable-next-line  @typescript-eslint/no-unused-vars
  addMessage(realm: any, id: string, message: Message): Promise<any> {
    return new Promise(() => {
      try {
      } catch (error) {
        console.log('error', message, error);
      }
    });
  }
  updateMessage(realm: any, updateFunction: () => void) {
    try {
      realm.write(updateFunction);
    } catch (error) {
      console.log('error', error);
    }
  }
}

const DatabaseService = new _DatabaseService();

export default DatabaseService;
