/*eslint-disable */
import React, {useCallback, useEffect} from 'react';
import type {FC} from 'react';
import {StyleSheet, Image} from 'react-native';
import LinearGradient from '@libs/LinearGradient';
import LocalStorage from '@services/storage';
import ConstantsEnum from '@constants/constantsEnum';
import {
  editUserAccountData,
  fetchUserAccountDataByPhoneNumber,
} from '@redux/actions/userAccount';
import {dispatch} from '@redux/store';
import {fetchMetaData} from '@redux/actions/meta';
import PushNotificationService from '@services/pushNotificationService';
import type {AccountData} from '@models/AccountData/AccountData';
import type {EditAccountDataPayload} from '@models/AccountData/EditAccountDataPayload';
import {AuthScreens} from '@navigations/ScreenNames';
import NavigationService from '@services/navigationService';
import Analytics, {IEventData} from '@wyse/analytics';
import {
  EventTypeEnum,
  LoginAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import WebContainer from '@components/WebContainer';
import {SplashImage} from '@assets';
import {fetchUserNotificationsList} from '@redux/actions/notifications';
import appsFlyer from '@libs/AppsFlyer';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {width: '100%', height: '100%'},
});

const AppOpenScreen: FC = () => {
  console.log('AppOpenScreenx');

  const updateFCMToken = useCallback(async (data: AccountData) => {
    const latestFCMToken = await PushNotificationService.getFCMToken();
    if (
      !!!data.fcm_token ||
      !!!data.apps_flyer_uid ||
      data.fcm_token != latestFCMToken
    ) {
      appsFlyer.getAppsFlyerUID((error: Error, uid: string) => {
        if (error) {
          console.log('Appsflyer uid error: ', error);
        } else {
          let editAccountDataPayload: EditAccountDataPayload = {
            thing_id: data!.thing_id!,
            thing_type: ConstantsEnum.ThingTypeEnum.ACCOUNT,
            attrs: [
              ['fcm_token', latestFCMToken, 'replace'],
              ['apps_flyer_uid', uid, 'replace'],
            ],
          };
          dispatch(editUserAccountData(editAccountDataPayload));
        }
      });
    }
  }, []);

  const trackAppOpenedAnalEvent = useCallback((isUserPresent: boolean) => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: LoginAnalEventValue.APP_OPENED,
      thing_type: isUserPresent ? ThingTypeEnum.ACCOUNT : ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
  }, []);

  const getData = useCallback(async () => {
    try {
      /**
       * initializing the analytics library
       */
      /**
       * TODO(Changes to be made),
       * in Init fucntion, get sessionStorage API is called,
       * thus creates dependency on Analytics service for App to move forward from Splash Screen
       * Decoupling(async) of analytics service call is required
       */
      /**
       * TODO(Changes to be made),
       * fetching metaData needs to be done asynchronously
       */
      // await dispatch(fetchMetaData());
      console.log('fetchmetadata');
      const data = await LocalStorage.getData<AccountData>(
        ConstantsEnum.LocalStorageKeysEnum.USER_ACCOUNT_DATA,
      );
      console.log('welcome', !data);
      if (!data) {
        NavigationService.getInstance().replaceToApp();
        trackAppOpenedAnalEvent(false);
        return;
      }
      if (!!data.mobile) {
        Analytics.setPhoneNumber(data.mobile);
      }
      if (!!data.thing_id) {
        Analytics.setUserId(data.thing_id);
      }
      /**
       * Whenever the events like upvotes/downotes/communities join/unjoin, subs -> only redux state is updated not the local storage
       * Data of user is fetched to sync updates in above field in local storage.
       */
      /**
       * TODO(Changes to be made)
       * fetching accountData needs to be done Async
       */
      await dispatch(
        fetchUserAccountDataByPhoneNumber({
          phoneNumber: data.mobile,
        } as any),
      );
      if (!data.user_fullname || data.user_fullname?.length === 0) {
        NavigationService.getInstance().replaceToAuth({
          page: AuthScreens.LinkedinImportScreen,
        });
        trackAppOpenedAnalEvent(true);
        return;
      }
      // if (!data.avatar || data.avatar.length === 0) {
      //   NavigationService.getInstance().replaceToAuth({
      //     page: AuthScreens.ProfilePicUploadScreen,
      //   });
      //   trackAppOpenedAnalEvent(true);
      //   return;
      // }
      if (data.communities?.length == 0) {
        NavigationService.getInstance().replaceToAuth({
          page: AuthScreens.SelectSpacesSignupScreen,
        });
        trackAppOpenedAnalEvent(true);
        return;
      }
      updateFCMToken(data);
      trackAppOpenedAnalEvent(true);
      NavigationService.getInstance().replaceToApp();
      const userThingId = data?.thing_id;
      dispatch(fetchUserNotificationsList({page: 0, userId: userThingId}));
    } catch (error) {
      console.log('error', error);
    }
  }, []);
  useEffect(() => {
    console.log('useEffect');
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LinearGradient
      colors={['#4B04E0', '#8E2EE2']}
      start={{y: 0, x: 1}}
      end={{y: 1, x: 0}}
      style={styles.container}>
      <WebContainer style={styles.container}>
        <Image source={SplashImage} resizeMode="cover" style={styles.image} />
      </WebContainer>
    </LinearGradient>
  );
};

export default AppOpenScreen;
