import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';
import {Button} from 'native-base';
import TextCarousel from '../../components/TextCarousel';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons/faArrowRight';
import Analytics, {IEventData} from '@wyse/analytics';
import {
  EventTypeEnum,
  LoginAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import WebContainer from '@components/WebContainer';
import {CustomText} from '@components/Texts';
import {FontFamily, FontSize} from '@utils/sizes';

export const WelcomeScreen = ({navigation}: {navigation: any}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const colorBasedOnIndex = ['#9B51E0', '#FF7000', '#00BECE'];
  const bgColorBasedOnIndex = ['#F7EFFF', '#fff1e5', '#e1fdff'];

  const handlePress = () => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: LoginAnalEventValue.ONBOARD_NEXT_CLICK,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
    navigation.navigate('OtpScreen');
  };
  return (
    <WebContainer
      style={{
        flex: 1,
        backgroundColor: bgColorBasedOnIndex[activeIndex],
        paddingTop: 32,
      }}>
      <View style={[styles.carouselContainer]}>
        <TextCarousel
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      </View>
      <View style={[styles.buttonContainer]}>
        <Button
          style={{
            backgroundColor: colorBasedOnIndex[activeIndex],
            borderRadius: 8,
          }}
          onPress={handlePress}>
          <View style={styles.buttonContentContainer}>
            <CustomText
              color="white"
              size={FontSize.title}
              fontFamily={FontFamily.FigtreeMedium}
              style={styles.buttonText}>
              Get started
            </CustomText>
            <FontAwesomeIcon icon={faArrowRight} color="#FFFFFF" size={12} />
          </View>
        </Button>
      </View>
    </WebContainer>
  );
};

const styles = StyleSheet.create({
  carouselContainer: {
    alignSelf: 'center',
    flex: 1,
  },
  buttonContainer: {
    alignSelf: 'stretch',
    paddingHorizontal: 96,
    paddingVertical: 32,
  },
  buttonContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    marginRight: 16,
  },
});

export default WelcomeScreen;
