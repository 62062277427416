import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useState,
} from 'react';
import type {FC} from 'react';
import {FlatList, ListRenderItem, StyleSheet, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import type {LinkedInParsedDataPositionGroup} from '@models/AccountData/LinkedInModels/LinkedInParsedDataPositionGroup';
import type {LinkedInParsedDataEducation} from '@models/AccountData/LinkedInModels/LinkedInParsedDataEducation';
import Colors from '@constants/Colors';
import {
  userAccountThingIdSelector,
  userEducationkDataSelector,
  userWorkDataSelector,
} from '@redux/selectors/userAccountSelectors';
import {dispatch, useSelector} from '@redux/store';
import {ExperienceDetailsCard} from '@components/Card/ExperienceDetailsCard';
import {EducationDetailsCard} from '@components/Card/EducationDetailsCard';
import {
  openAddEditEducation,
  openAddEditWork,
} from '@redux/slices/userAccountDataSlice';
import Header, {
  HeaderBackButton,
  AddIconButton,
} from '@components/Header/Header';

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: '#FFFFFF',
  },
  headerContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
    paddingVertical: 12,

    backgroundColor: '#FFFFFF',
  },
  stretch: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  title: {
    fontWeight: '700',
    fontSize: 18,
    lineHeight: 24,
    color: '#181818',
  },
  listContainer: {
    flex: 1,
    paddingTop: 2,
    backgroundColor: Colors.lightBackground,
  },
  listContent: {
    padding: 16,
  },
});

type State = {
  type?: 'work' | 'education';
  visible: boolean;
  userId?: string;
};

/**
 * ShowAllContext
 * will be used to opena dn close view all education or work details
 */
const ShowAllContext = createContext<
  [State, React.Dispatch<React.SetStateAction<State>>]
>([0, 0] as any);

/**
 * useShowAllModal
 * use this hook to open the view all page
 *
 * eg:
 * ```
 * const [_, setState] = useShowAllModal();
 *
 * setState(st => ({...st, visile: true, type: 'work' or 'education'}))
 * ```
 */
export const useShowAllModal = () => useContext(ShowAllContext);

type Props = {
  userId?: string;
};
/**
 *
 * Work Details Page
 */
const Work: FC<Props> = ({userId}) => {
  const workDertails = useSelector(userWorkDataSelector);

  const {bottom} = useSafeAreaInsets();
  const keyExtractor = useCallback(
    (item: LinkedInParsedDataPositionGroup, index: number) =>
      `work-${item.date}-${index}`,
    [],
  );

  const onPressAddWork = useCallback(
    (data?: LinkedInParsedDataPositionGroup, index?: number) => {
      dispatch(
        openAddEditWork(
          data && index !== undefined
            ? {data: data.profile_positions![0], index}
            : undefined,
        ),
      );
    },
    [],
  );

  const renderItem = useCallback<
    ListRenderItem<LinkedInParsedDataPositionGroup>
  >(
    ({item, index}) => (
      <ExperienceDetailsCard
        experienceDetail={item}
        index={index}
        onPressEdit={() => {
          onPressAddWork(item, index);
        }}
        userId={userId!}
      />
    ),
    [onPressAddWork, userId],
  );

  if (!workDertails) return null;

  return (
    <View style={styles.listContainer}>
      <FlatList
        contentContainerStyle={[
          styles.listContent,
          {paddingBottom: bottom || 16},
        ]}
        {...{keyExtractor, renderItem}}
        data={workDertails}
      />
    </View>
  );
};

/**
 * Education Details Page
 */
const Education: FC<Props> = ({userId}) => {
  const educationDetails = useSelector(userEducationkDataSelector);
  const {bottom} = useSafeAreaInsets();
  const keyExtractor = useCallback(
    (item: LinkedInParsedDataEducation, index: number) =>
      `education-${item.date}-${index}`,
    [],
  );

  const onPressAddEducation = useCallback(
    (data?: LinkedInParsedDataEducation, index?: number) => {
      dispatch(
        openAddEditEducation(
          data && index !== undefined ? {data, index} : undefined,
        ),
      );
    },
    [],
  );

  const renderItem = useCallback<ListRenderItem<LinkedInParsedDataEducation>>(
    ({item, index}) => (
      <EducationDetailsCard
        educationDetail={item}
        index={index}
        userId={userId!}
        onPressEdit={() => {
          onPressAddEducation(item, index);
        }}
      />
    ),
    [onPressAddEducation, userId],
  );

  if (!educationDetails) return null;

  return (
    <View style={styles.listContainer}>
      <FlatList
        contentContainerStyle={[
          styles.listContent,
          {paddingBottom: bottom || 16},
        ]}
        {...{keyExtractor, renderItem}}
        data={educationDetails}
      />
    </View>
  );
};

const ShowAllModal = memo(() => {
  const [state, setState] = useShowAllModal();
  const userThingId = useSelector(userAccountThingIdSelector);
  const onPressAdd = useCallback(() => {
    dispatch(
      state.type === 'work' ? openAddEditWork() : openAddEditEducation(),
    );
  }, [state.type]);

  if (!state.visible) return null;

  return (
    <View style={styles.container}>
      <Header
        title={state.type === 'work' ? 'Work Experience' : 'Education'}
        headerRight={
          userThingId === state.userId
            ? () => <AddIconButton onPress={onPressAdd} />
            : undefined
        }
        headerLeft={() => (
          <HeaderBackButton
            onPress={() => {
              setState(st => ({...st, visible: false}));
            }}
          />
        )}
      />
      {state.type === 'work' ? (
        <Work userId={state.userId} />
      ) : (
        <Education userId={state.userId} />
      )}
    </View>
  );
});

/**
 * ShowAllContextProvider
 *
 * Add this mdoal in the root afeter redux provide or at the tab navigator
 */
const ShowAllContextProvider: FC<{children: any}> = ({children}) => {
  return (
    <ShowAllContext.Provider value={useState<State>({visible: false})}>
      {children}
      <ShowAllModal />
    </ShowAllContext.Provider>
  );
};

export default ShowAllContextProvider;
