export enum MainScreens {
  AppOpenScreen = 'AppOpenScreen',

  Auth = 'Auth',
  App = 'App',
}

export enum AuthScreens {
  WelcomeScreen = 'WelcomeScreen',
  OtpScreen = 'OtpScreen',
  LinkedinImportScreen = 'LinkedinImportScreen',
  ManualImportScreen = 'ManualImportScreen',
  ProfilePicUploadScreen = 'ProfilePicUploadScreen',
  ReviewScreen = 'ReviewScreen',
  // SelectInterestScreen = 'SelectInterestScreen',
  SelectSpacesSignupScreen = 'SelectSpacesSignupScreen',
  WelcomeOnboard = 'WelcomeOnboard',
  WyseGreetingScreen = 'WyseGreetingScreen',
}

export enum TabScreens {
  /**
   * Feed Screen
   */
  Home = 'Home',
  Spaces = 'Spaces', // prev Discover
  Post = 'Post',
  Profile = 'Profile',
  Notification = 'Notification',
}

export enum AppScreens {
  Tabs = 'Tabs',
  FeedDetail = 'FeedDetail',
  CreatePost = 'CreatePost',
  ImageView = 'ImageView',
  CommunityDetail = 'CommunityDetail',
  CommunitySearch = 'CommunitySearch',
  CreateCommunity = 'CreateCommunity',
  SelectSpaces = 'SelectSpaces',
  LinkedinImport = 'LinkedinImport',
  Conversations = 'Conversations',
  Chat = 'Chat',
  Profile = 'Profile',
  InitiateChat = 'InitiateChat',
  Notifications = 'Notifications',
}

export enum CommunityTabs {
  Feed = 'Posts',
  Members = 'Members',
}
