import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {dispatch} from '@redux/store';
import {userAccountDataSelector} from '@redux/selectors/userAccountSelectors';
import {fetchUserAccountDataByPhoneNumber} from '@redux/actions/userAccount';
import {LocalStorageKeysEnum} from '@constants/constantsEnum';
import NavigationService from '@services/navigationService';
import LocalStorage from '@services/storage';
import type {AccountData} from '@models/AccountData/AccountData';
import {fetchMetaData} from '@redux/actions/meta';

export const withTokenRefresh = (
  OriginalComponent: React.FunctionComponent,
  pass?: true,
) => {
  const Component = () => {
    const userData = useSelector(userAccountDataSelector);
    useEffect(() => {
      console.log('useEffect token refresh');
      (async () => {
        console.log('userData', userData);
        if (!!userData) return;
        const data = await LocalStorage.getData<AccountData>(
          LocalStorageKeysEnum.USER_ACCOUNT_DATA,
        );
        console.log('data', data);
        if (!data) {
          if (!pass) {
            NavigationService.getInstance().replaceToAuth();
          } else {
            await dispatch(fetchMetaData());
          }
          return;
        }
        setTimeout(async () => {
          await dispatch(fetchMetaData());
          await dispatch(
            fetchUserAccountDataByPhoneNumber({
              phoneNumber: data.mobile,
            } as any),
          );
        }, 800);
      })();
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log('userDatauserDatauserData', userData);
    if (!userData) {
      if (!pass) {
        return null;
      }
    }

    return <OriginalComponent />;
  };

  return Component;
};
