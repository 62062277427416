import React from 'react';
import {
  Linking,
  Pressable,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native';

import Colors from '@constants/Colors';
import {FontFamily, FontSize, SCREEN_SIZE, TAB_BAR_SIZE} from '@utils/sizes';
import {CustomText} from '@components/Texts';
import AppStore from '../../assets/appstore.svg';
import Playstore from '../../assets/playstore.svg';
// import QrCode from '../../assets/qr_code.svg';
import QRCode from 'react-qr-code';

const styles = StyleSheet.create({
  containerWeb: {
    width: TAB_BAR_SIZE,
    height: '100%',

    borderLeftWidth: 2,
    borderLeftColor: Colors.background,
  } as any,
  barHeight: {
    marginTop: 10,
    alignItems: 'center',
    alignSelf: 'stretch',
    marginBottom: 10,
  },
  codeContainer: {
    flex: 1,
    aspectRatio: 1 / 1,
  },
  icon: {
    marginRight: 4,
  },
  appContainer: {
    alignSelf: 'stretch',
    padding: 16,
    backgroundColor: Colors.white,
  },
  button: {flex: 1, flexDirection: 'row', alignItems: 'center'},
  buttonBar: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },
});

const DownloadApp = () => {
  return (
    <View style={styles.appContainer}>
      <CustomText
        size={FontSize.medium}
        fontFamily={FontFamily.FigtreeSemiBold}
        color="primary">
        Get The Wyse app!
      </CustomText>
      <CustomText style={{marginTop: 4}} color="lightText">
        Scan this QR code to download the app
      </CustomText>
      <View style={styles.barHeight}>
        <Pressable
          onPress={() => {
            Linking.openURL('https://bewyse.in/install.html');
          }}>
          <QRCode
            fgColor={Colors.text}
            // L,M,H,Q
            // level="Q"
            size={120}
            viewBox="0 0 120 120"
            value="https://bewyse.in/install.html"
          />
        </Pressable>
      </View>
      <View
        style={{
          alignSelf: 'stretch',
          borderBottomWidth: 2,
          marginBottom: 10,
          borderBottomColor: Colors.background,
        }}
      />
      <CustomText color="lightText">or follow the link at</CustomText>
      <View style={styles.buttonBar}>
        <Pressable
          onPress={() => {
            Linking.openURL(
              'https://apps.apple.com/in/app/wyse-network-discuss-jobs/id6446937173',
            );
          }}
          style={styles.button}>
          <View style={styles.icon}>
            <AppStore />
          </View>
          <CustomText color="primary">Appstore</CustomText>
        </Pressable>
        <Pressable
          onPress={() => {
            Linking.openURL(
              'https://play.google.com/store/apps/details?id=com.wyse.app',
            );
          }}
          style={styles.button}>
          <View style={styles.icon}>
            <Playstore />
          </View>
          <CustomText color="primary">Playstore</CustomText>
        </Pressable>
      </View>
    </View>
  );
};

const BigScreenRightBar = () => {
  const {width} = useWindowDimensions();
  const show = width >= 2 * TAB_BAR_SIZE + SCREEN_SIZE;
  if (!show) return null;
  return (
    <View style={[styles.containerWeb]}>
      <DownloadApp />
    </View>
  );
};

export default BigScreenRightBar;
