/* eslint-disable */
import React, {FC, memo, useCallback, useEffect, useRef, useState} from 'react';
import {StyleSheet, FlatList, View, Platform, Pressable} from 'react-native';
import {shallowEqual} from 'react-redux';
import {Spinner} from 'native-base';
import {useNavigation} from '@react-navigation/native';
import FeedCard from '@components/Card/FeedCard/FeedCard';
import FilterModal from '@components/Modals/FilterModal';
import Header, {ChatIcon} from '@components/Header/Header';
import AppUpdateModal from '@components/Modals/AppUpdateModal';
import FeedListLoader from '@components/FeedListLoader';
import type {FeedData} from '@models/Feed/FeedData';
import type {AnalyticsEventPayload} from '@models/Analytics/AnalyticsEventPayload';
import type {FilterFeedByFlairPayload} from '@models/Feed/FilterFeedByFlairPayload';
import ConstantsEnum, {
  DynamicLinkThingTypeEnum,
  EventTypeEnum,
  FeedEnagagementAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import {dispatch, useSelector} from '@redux/store';
import {createAnalyticsEvent} from '@redux/slices/analyticsSlice';
import {
  resetFeedList,
  resetFeedListWithFlair,
  setFeedFilterModalVisiblity,
  setFeedPage,
} from '@redux/slices/feedSlice';
import {fetchFeedsListByLabel, fetchFeedsList} from '@redux/actions/feed';
import {
  feedDataIdSelector,
  feedEndReachedSelector,
  feedFilterModalVisible,
  homeFeedsPagesAndTotalNumbersSelector,
  selectedFliarSelector,
} from '@redux/selectors/feedSelector';
import {
  userAccountDataSelector,
  userAccountNameSelector,
  userAccountThingIdSelector,
} from '@redux/selectors/userAccountSelectors';
import DynamicLinkService from '@services/dynamicLinkService';
import ShareService from '@services/shareService';
import {
  convertToCamelCaseString,
  onNotificationClick,
} from '@utils/utilityFunctions';
import PushNotificationService from '@services/pushNotificationService';
import Analytics, {IEventData} from '@wyse/analytics';
import {withTokenRefresh} from '@utils/webhooks';
import Screen from '@components/Screen';
import BigScreenTabBar from '@components/BigScreenTabBar';
import {FontFamily, FontSize, SCREEN_SIZE, TAB_BAR_SIZE} from '@utils/sizes';
import Colors from '@constants/Colors';
import RemoteConfigService from '@services/RemoteConfigService';
import AppsFlyerService from '@services/appsFlyerService';
import {EditAccountDataPayload} from '@models/AccountData/EditAccountDataPayload';
import {editUserAccountData} from '@redux/actions/userAccount';
import BigScreenRightBar from '@components/BigScreenRightBar';
import {CustomText} from '@components/Texts';
import NavigationService from '@services/navigationService';
import {AuthScreens} from '@navigations/ScreenNames';
var pkg = require('../../../package.json');

const HomeFeedCard: FC<{
  thingId: string;
  index: number;
  userThingId: string;
}> = memo(props => {
  const navigation = useNavigation() as any;
  /**
   * This is not getting used, delete it
   */
  const handleComment = useCallback(
    (feedCardIndex: number) => {
      navigation.navigate('FeedDetail', {
        feedDataIndex: feedCardIndex,
        // userAccountData: userAccountData,
        isSeeMoreClicked: false,
        isProfileCard: false,
      });
      const analyticsEventPayload: AnalyticsEventPayload = {
        ev_typ: ConstantsEnum.EventTypeEnum.ANALYTICS,
        ev_val: ConstantsEnum.EventTypeValueEnum.COMMENT,
        thing_id: props.thingId,
        thing_type: ConstantsEnum.ThingTypeEnum.ANALYTICS,
        uid: props.userThingId,
      };

      dispatch(createAnalyticsEvent(analyticsEventPayload));
    },
    [props.thingId, props.userThingId],
  );

  const handleShare = useCallback(async (data: FeedData) => {
    const link = await DynamicLinkService.createDynamicLink(
      data.thing_id,
      DynamicLinkThingTypeEnum.FEED_DETAIL,
    );
    const options = {
      message: `Check out this post on Wyse App ${data.title}: ${link} \n\nJoin the most meaningful discussions by industry leaders and your peers on Wyse app now.\nInnovation starts from conversation`,
      title: '',
      url: link,
    };
    await ShareService.openShare(options);
  }, []);

  return (
    <FeedCard
      thingId={props.thingId}
      loggedInUserId={props.userThingId}
      feedCardIndex={props.index}
      isFeedDetailCard={false}
      isProfileCard={false}
      seeMore
      navigation={navigation}
      handleComment={handleComment}
      handleShare={handleShare}
    />
  );
});

const LoginCard = () => {
  return (
    <View style={styles.signInContainer}>
      <CustomText
        color="title"
        style={{textAlign: 'center'}}
        fontFamily={FontFamily.FigtreeSemiBold}
        size={FontSize.medium}>
        Sign in to access thousands of posts and comments from top professionals
        on Wyse
      </CustomText>
      <CustomText color="lightText" style={{textAlign: 'center', marginTop: 8}}>
        You are in awesome company - there’s professionals from companies like
        Google, Meta, Amazon, Swiggy, Zomato and more!
      </CustomText>
      <Pressable
        onPress={() => {
          NavigationService.getInstance().replaceToAuth({
            page: AuthScreens.OtpScreen,
          });
        }}
        style={styles.signinButton}>
        <CustomText fontFamily={FontFamily.FigtreeBold} color="white">
          Sign in
        </CustomText>
      </Pressable>
    </View>
  );
};

const FeedList = () => {
  const feedData = useSelector(feedDataIdSelector, shallowEqual as any);
  const userThingId = useSelector(userAccountThingIdSelector)!;
  const loading = useRef(false);
  const selectedFlair = useSelector(selectedFliarSelector);
  const [page, totalNumberOfPagesinFeed] = useSelector(
    homeFeedsPagesAndTotalNumbersSelector,
    shallowEqual,
  );
  const feedEndReached = useSelector(feedEndReachedSelector);

  /**
   * functional effect to call everytime page updates
   */
  useEffect(() => {
    // if (!userThingId) return;
    (async () => {
      if (!!loading.current) return;
      if (selectedFlair) {
        const payload: FilterFeedByFlairPayload = {
          page: page,
          flair: selectedFlair,
          userId: userThingId,
        };
        loading.current = true;
        await dispatch(fetchFeedsListByLabel(payload));
        loading.current = false;
        return;
      }
      loading.current = true;
      await dispatch(
        fetchFeedsList({
          page: page,
          userId: userThingId || 'uUhXMZpdX8',
          pass: true,
        }),
      );
      loading.current = false;
    })();
  }, [page, selectedFlair, userThingId, loading]);

  /**
   * renderItem of Flatlist
   */
  const renderItem = useCallback(
    ({item, index}: {item: string; index: number}) => {
      return <HomeFeedCard {...{userThingId}} thingId={item} index={index} />;
    },
    [userThingId],
  );

  /**
   * Reset Feed List
   */
  const resetData = useCallback(() => {
    dispatch(resetFeedList());
  }, []);

  /**
   * Handle Load more
   */
  const handleLoadMore = useCallback(
    ({distanceFromEnd}: {distanceFromEnd: number}) => {
      if (!userThingId) {
        return;
      }
      if (!!loading.current) return;
      if (distanceFromEnd <= 0) return;
      if (feedEndReached) return;
      dispatch(setFeedPage(page + 1));
    },
    [page, feedEndReached, userThingId, loading],
  );

  /**
   * REfresh feed function
   */
  const refreshFeed = useCallback(() => {
    if (!userThingId) {
      return;
    }
    resetData();
    if (selectedFlair) {
      const payload: FilterFeedByFlairPayload = {
        page: 0,
        flair: selectedFlair,
        userId: userThingId,
      };
      dispatch(fetchFeedsListByLabel(payload));
    } else {
      dispatch(fetchFeedsList({page: 0, userId: userThingId}));
    }
  }, [selectedFlair, resetData, userThingId]);

  /**
   * List Footer Component
   */
  const listFooter = useCallback(
    () =>
      !userThingId ? (
        <LoginCard />
      ) : totalNumberOfPagesinFeed == page + 1 ? null : (
        <Spinner size="sm" color="#9B51E0" />
      ),
    [page, !userThingId, totalNumberOfPagesinFeed],
  );

  /**
   * key extractor important for flatlist to render cells for batch update
   */
  const keyExtractor = useCallback(
    (item: string, index: number) => `feed-post-${item}-index-${index}`,
    [],
  );

  const seperator = useCallback(() => <View style={styles.seperator} />, []);
  /**
   * conditional render for null checks of userThingId, feedData
   */
  // if (userThingId == null || (feedData.length <= 0 && !!loading)) {
  //   return <FeedListLoader />;
  // }

  return (
    <FlatList
      disableIntervalMomentum
      removeClippedSubviews
      keyExtractor={keyExtractor}
      data={feedData}
      contentContainerStyle={styles.contentContainerStyle}
      initialNumToRender={10}
      renderItem={renderItem}
      onEndReached={handleLoadMore}
      onEndReachedThreshold={0.5}
      scrollIndicatorInsets={{right: 0}}
      style={styles.list}
      refreshing={false}
      ItemSeparatorComponent={seperator}
      onRefresh={refreshFeed}
      ListFooterComponent={listFooter}
    />
  );
};

const FeedHeader = () => {
  const useName = useSelector(userAccountNameSelector);
  const selectedFlair = useSelector(selectedFliarSelector);

  const renderRight = useCallback(
    () => (
      <View style={{height: '100%', flexDirection: 'row'}}>
        <ChatIcon />
        <View style={{width: 8}} />
        {/* <FilterBy
          isFilterApplied={!!selectedFlair}
          onPress={() => {
            dispatch(setFeedFilterModalVisiblity(true));
          }}
        /> */}
      </View>
    ),
    [!!selectedFlair],
  );
  return (
    <Header
      title={`Welcome, ${
        useName ? convertToCamelCaseString(useName.split(' ')[0]) : ''
      }`}
      headerRight={renderRight}
    />
  );
};

const FeedFilterModal = () => {
  const visible = useSelector(feedFilterModalVisible);

  const applyFilter = useCallback((flair: string | null) => {
    dispatch(resetFeedListWithFlair(flair));
  }, []);

  return (
    <FilterModal
      isFilterModalOpen={visible}
      setIsFilterModalOpen={() => {
        dispatch(setFeedFilterModalVisiblity(false));
      }}
      applyFilter={applyFilter}
    />
  );
};

export const Feed = () => {
  console.log('Feed');
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [showCancelOption, setShowCancelOption] = useState<boolean>(false);
  const userThingId = useSelector(userAccountThingIdSelector)!;
  const userAccountData = useSelector(userAccountDataSelector);
  const handleUpdateModal = (
    installedVersion: number,
    minVersion: number,
    currReleaseVersion: number,
    updTriggerAppVersion: number,
  ) => {
    setShowUpdateModal(false);
    setShowCancelOption(false);
    if (installedVersion <= minVersion) {
      setShowUpdateModal(true);
    } else if (installedVersion <= updTriggerAppVersion) {
      setShowUpdateModal(true);
      setShowCancelOption(true);
    }
  };

  useEffect(() => {
    DynamicLinkService.backgroundDynamicLinkHandler();
    if (Platform.OS === 'ios') {
      const minVersionIOS = RemoteConfigService.fetchAppVersion(
        ConstantsEnum.RemoteConfigAppVersionEnum.MIN_VERSION_IOS,
      );
      const curReleasedAppVersionIOS = RemoteConfigService.fetchAppVersion(
        ConstantsEnum.RemoteConfigAppVersionEnum.CUR_VERSION_IOS,
      );
      const updTriggerAppVersionIOS = RemoteConfigService.fetchAppVersion(
        ConstantsEnum.RemoteConfigAppVersionEnum.UPD_TRIGGER_VERSION_IOS,
      );
      const installedVersionIOS = pkg.version_ios;
      handleUpdateModal(
        parseInt(installedVersionIOS.split('.')[1]),
        parseInt(minVersionIOS.split('.')[1]),
        parseInt(curReleasedAppVersionIOS.split('.')[1]),
        parseInt(updTriggerAppVersionIOS.split('.')[1]),
      );
    } else {
      const minVersionAND = RemoteConfigService.fetchAppVersion(
        ConstantsEnum.RemoteConfigAppVersionEnum.MIN_VERSION_AND,
      );
      const curReleasedAppVersionAND = RemoteConfigService.fetchAppVersion(
        ConstantsEnum.RemoteConfigAppVersionEnum.CUR_VERSION_AND,
      );
      const updTriggerAppVersionAND = RemoteConfigService.fetchAppVersion(
        ConstantsEnum.RemoteConfigAppVersionEnum.UPD_TRIGGER_VERSION_AND,
      );
      const installedVersionAND = pkg.version_android;

      handleUpdateModal(
        parseInt(installedVersionAND),
        parseInt(minVersionAND),
        parseInt(curReleasedAppVersionAND),
        parseInt(updTriggerAppVersionAND),
      );
    }

    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: FeedEnagagementAnalEventValue.FEED_PAGE_IMPR,
      thing_type: ThingTypeEnum.ANALYTICS,
    };
    Analytics.trackEvent(analyticsEventPayload);
    if (!userAccountData?.apps_flyer_feedEvent_triggered) {
      AppsFlyerService.registerInAppEvents(
        FeedEnagagementAnalEventValue.FEED_PAGE_IMPR,
        userThingId,
      );
      let editAccountDataPayload: EditAccountDataPayload = {
        thing_id: userThingId,
        thing_type: ConstantsEnum.ThingTypeEnum.ACCOUNT,
        attrs: [['apps_flyer_feedEvent_triggered', true, 'replace']],
      };
      dispatch(editUserAccountData(editAccountDataPayload));
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const notificationData =
          await PushNotificationService.handleInitialNotification();

        if (notificationData && !!notificationData.data.post_id) {
          onNotificationClick(notificationData);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    })();
  }, []);

  return (
    <Screen
      RightComponent={BigScreenRightBar}
      LeftComponent={BigScreenTabBar}
      maxWidth={TAB_BAR_SIZE + SCREEN_SIZE}
      style={{flex: 1, backgroundColor: Colors.white}}>
      <View style={{flex: 1, backgroundColor: Colors.background}}>
        <FeedHeader />
        <FeedList />
        <FeedFilterModal />
        <AppUpdateModal
          isOpen={showUpdateModal}
          setIsOpen={setShowUpdateModal}
          showCancelOption={showCancelOption}
        />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  list: {flex: 1, backgroundColor: Colors.background},
  contentContainerStyle: {
    paddingTop: 0,
  },
  seperator: {
    alignSelf: 'stretch',
    height: 1,
    backgroundColor: Colors.background,
  },
  signInContainer: {
    alignSelf: 'stretch',
    paddingVertical: 20,
    paddingHorizontal: 30,
    backgroundColor: Colors.white,
    borderTopWidth: 1,
    borderTopColor: Colors.background,
    alignItems: 'center',
  },
  signinButton: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 20,
    marginTop: 20,
    backgroundColor: Colors.primary,
  },
});

export default withTokenRefresh(Feed, true);
