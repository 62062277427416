import React from 'react';
import type {FC} from 'react';
import {View} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';
import {useRoute} from '@react-navigation/native';
import AddEducationBottomSheet from '@components/Modals/AddEducationBottomSheet';
import AddWorkBottomSheet from '@components/Modals/AddWorkBottomSheet';
import WelcomeScreen from '@screens/SignUp/WelcomeScreen';
import OtpScreen from '@screens/SignUp/OtpScreen';
import LinkedinImportScreen from '@screens/SignUp/LinkedinImportScreen';
import ManualImportScreen from '@screens/SignUp/ManualImport';
import ProfilePicUpload from '@screens/SignUp/ProfilePicUpload';
// import ReviewScreen from '@screens/SignUp/ReviewScreen';
// import SelectInterest from '@screens/SignUp/SelectInterest';
import SelectSpacesSignup from '@screens/SignUp/SelectSpacesSignup';
import WelcomeOnboard from '@screens/SignUp/WelcomeOnboard';
import WyseGreetingScreen from '@screens/SignUp/WyseGreetingScreen';
import {AuthScreens} from './ScreenNames';
import {
  authNavigatorOptions,
  linkedInImportScreen,
  manulaImportScreen,
  otpScreenOptions,
  profilePicUploadScreen,
  // reviewScreen,
  // selectInterestScreen,
  selectSpacesSignupScreen,
  welcomeOnboardScreen,
  welcomeScreenOptions,
  wyseGreetingScreen,
} from './options';

const AuthStack = createStackNavigator();

const AuthNavigatorContainer: FC<{children: any}> = ({children}) => {
  const params = useRoute().params as {page?: AuthScreens};

  return (
    <View style={{flex: 1}}>
      <AuthStack.Navigator
        screenOptions={authNavigatorOptions}
        initialRouteName={params?.page}>
        {children}
      </AuthStack.Navigator>
      <AddEducationBottomSheet />
      <AddWorkBottomSheet />
    </View>
  );
};

const AuthNavigator = ({}) => {
  return (
    <AuthNavigatorContainer>
      <AuthStack.Screen
        name={AuthScreens.WelcomeScreen}
        component={WelcomeScreen}
        options={welcomeScreenOptions}
      />
      <AuthStack.Screen
        name={AuthScreens.OtpScreen}
        component={OtpScreen}
        options={otpScreenOptions}
      />
      <AuthStack.Screen
        name={AuthScreens.WyseGreetingScreen}
        component={WyseGreetingScreen}
        options={wyseGreetingScreen}
      />
      <AuthStack.Screen
        name={AuthScreens.LinkedinImportScreen}
        component={LinkedinImportScreen}
        options={linkedInImportScreen}
      />
      <AuthStack.Screen
        name={AuthScreens.ManualImportScreen}
        component={ManualImportScreen}
        options={manulaImportScreen}
      />
      <AuthStack.Screen
        name={AuthScreens.ProfilePicUploadScreen}
        component={ProfilePicUpload}
        options={profilePicUploadScreen}
      />
      {/* <AuthStack.Screen
        name={AuthScreens.ReviewScreen}
        component={ReviewScreen}
        options={reviewScreen}
      /> */}
      {/* <AuthStack.Screen
        name={AuthScreens.SelectInterestScreen}
        component={SelectInterest}
        options={selectInterestScreen}
      /> */}
      <AuthStack.Screen
        name={AuthScreens.SelectSpacesSignupScreen}
        component={SelectSpacesSignup}
        options={selectSpacesSignupScreen}
      />
      <AuthStack.Screen
        name={AuthScreens.WelcomeOnboard}
        component={WelcomeOnboard}
        options={welcomeOnboardScreen}
      />
    </AuthNavigatorContainer>
  );
};

export default AuthNavigator;
