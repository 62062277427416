import React from 'react';
import FullImageViewer from '../../components/FullImageViewer';

const ImageView = ({route}: {navigation: any; route: any}) => {
  const {
    imageUrl,
    postTitle,
  }: {
    imageUrl: string;
    postTitle: string;
  } = route.params;

  return <FullImageViewer imgSource={imageUrl} postTitle={postTitle} />;
};

export default ImageView;
