import React, {FC, RefObject, useContext, useEffect} from 'react';
import {createContext, useRef} from 'react';
import FirestoreService from './firestoreService';
import auth from '@libs/Firebase/auth';

type Values = RefObject<Map<string, () => void>>;
type Numbers = RefObject<Map<string, number>>;

class _AMAService {
  private Context = createContext<{values: Values; numbers: Numbers}>(
    {} as any,
  );
  private hasListener = (eventId: string, values: Values) => {
    return !!values.current!.get(eventId);
  };
  removeListener = (eventId: string, values: Values, numbers: Numbers) => {
    if (numbers.current!.get(eventId) == 1) {
      numbers.current!.delete(eventId);
      const listner = values.current!.get(eventId);
      values.current!.delete(eventId);
      if (!listner) return () => {};
      return listner;
    }
    return () => {};
  };
  Provider = ({children}: {children: any}) => {
    const numbers: Numbers = useRef(new Map());
    const values: Values = useRef(new Map());
    return (
      <this.Context.Provider value={{numbers, values}}>
        {children}
      </this.Context.Provider>
    );
  };
  useAddListener = (eventId: string) => {
    const {values, numbers} = useContext(this.Context);
    useEffect(() => {
      if (!this.hasListener(eventId, values)) {
        (async () => {
          if (!auth().currentUser) {
            await auth().signInAnonymously();
          }
          const listener = FirestoreService.onAmaComments(eventId);
          values.current!.set(eventId, listener);
          numbers.current!.set(eventId, 1);
        })();
      } else {
        numbers.current!.set(eventId, (numbers.current!.get(eventId) || 0) + 1);
      }
      return () => this.removeListener(eventId, values, numbers)();
    }, [eventId, numbers, values]);
  };

  Listener: FC<{id: string}> = ({id}) => {
    this.useAddListener(id);
    return null;
  };
}

const AMAService = new _AMAService();

export default AMAService;
