import React, {useEffect, useRef, useState} from 'react';
import type {FC} from 'react';
import type {ImageStyle, StyleProp} from 'react-native';
import {Image, Pressable, useWindowDimensions, View} from 'react-native';
import {imageContainerSize} from '@utils/utilityFunctions';

const FeedImage: FC<{
  uri: string;
  style?: StyleProp<ImageStyle>;
  onPress?: () => void;
}> = props => {
  const {uri, style, onPress} = props;
  const {width} = useWindowDimensions();
  const [imageSize, setImageSize] = useState({width: 0, height: 0});
  const imageContainerRef = useRef<View>(null);

  useEffect(() => {
    if (!uri) return;
    Image.getSize(
      uri,
      (imageWidth: number, imageHeight: number) => {
        const uploadedImageConatinerSize = imageContainerSize(
          imageHeight,
          imageWidth,
          width - 50,
        );

        setImageSize({
          height:
            uploadedImageConatinerSize.containerHeight > 400
              ? 400
              : uploadedImageConatinerSize.containerHeight,
          width: uploadedImageConatinerSize.containerWidth,
        });
      },
      error => {
        console.log('error', error);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, imageContainerRef]);

  if (!imageSize.width && !imageSize.height) return null;

  console.log('imageSize', imageSize);
  return (
    <Pressable
      style={{
        ...imageSize,
      }}
      onPress={onPress}
      ref={imageContainerRef}>
      <Image
        source={{
          uri,
        }}
        style={style}
      />
    </Pressable>
  );
};

export default FeedImage;
