import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CommentData} from '@models/Comment/CommentData';
import ConstantsEnum from '@constants/constantsEnum';
import {CommentListResponse} from '@models/Comment/CommentListResponse';

export interface Comment {
  commentsHomeScreen: CommentData[] | null;
  commentsProfileScreen: CommentData[] | null;
  commentsDiscoverScreen: CommentData[] | null;
  commentsHomeFetchStatus: string;
  commentsProfileFetchStatus: string;
  commentsDiscoverFetchStatus: string;
  createCommentStatus: string;
  error: any;
  postId: string | null;
  page: number;
  numPagesCommentsHome: number;
  numPagesCommentsProfile: number;
  numPagesCommentsDiscover: number;
  activeFeedDetailIdsHome: string[];
  activeFeedDetailIdsDiscover: string[];
  activeFeedDetailIdsProfile: string[];
}

const initialState: Comment = {
  commentsHomeScreen: null,
  commentsProfileScreen: null,
  commentsDiscoverScreen: null,
  commentsHomeFetchStatus: ConstantsEnum.ApiStatusEnum.IDLE,
  commentsProfileFetchStatus: ConstantsEnum.ApiStatusEnum.IDLE,
  commentsDiscoverFetchStatus: ConstantsEnum.ApiStatusEnum.IDLE,
  createCommentStatus: ConstantsEnum.ApiStatusEnum.IDLE,
  error: null,
  page: 0,
  numPagesCommentsHome: 0,
  numPagesCommentsProfile: 0,
  numPagesCommentsDiscover: 0,
  postId: null,
  activeFeedDetailIdsHome: [],
  activeFeedDetailIdsDiscover: [],
  activeFeedDetailIdsProfile: [],
};

export const commentSlice = createSlice({
  name: 'comment',
  initialState: initialState,
  reducers: {
    updateActiveFeedDetailIds: (
      state,
      action: PayloadAction<{
        feedId: string;
        bottomTabLocation: string;
        navigationAction: string;
      }>,
    ) => {
      if (action.payload.bottomTabLocation == ConstantsEnum.TabNameEnum.HOME) {
        state.commentsHomeScreen = null;
        if (
          action.payload.navigationAction === ConstantsEnum.NavigationAction.POP
        ) {
          state.activeFeedDetailIdsHome = state.activeFeedDetailIdsHome.slice(
            0,
            -1,
          );
        } else {
          state.activeFeedDetailIdsHome = [
            ...state.activeFeedDetailIdsHome,
            action.payload.feedId,
          ];
        }
      } else if (
        action.payload.bottomTabLocation == ConstantsEnum.TabNameEnum.DISCOVER
      ) {
        state.commentsDiscoverScreen = null;
        if (
          action.payload.navigationAction === ConstantsEnum.NavigationAction.POP
        ) {
          state.activeFeedDetailIdsDiscover =
            state.activeFeedDetailIdsDiscover.slice(0, -1);
        } else {
          state.activeFeedDetailIdsDiscover = [
            ...state.activeFeedDetailIdsDiscover,
            action.payload.feedId,
          ];
        }
      } else if (
        action.payload.bottomTabLocation == ConstantsEnum.TabNameEnum.PROFILE
      ) {
        state.commentsProfileScreen = null;
        if (
          action.payload.navigationAction === ConstantsEnum.NavigationAction.POP
        ) {
          state.activeFeedDetailIdsProfile =
            state.activeFeedDetailIdsProfile.slice(0, -1);
        } else {
          state.activeFeedDetailIdsProfile = [
            ...state.activeFeedDetailIdsProfile,
            action.payload.feedId,
          ];
        }
      }
    },
    setCommentData: (
      state,
      action: PayloadAction<{
        commentData: CommentData[] | null;
        commentParentScreen: string;
      }>,
    ) => {
      if (
        action.payload.commentParentScreen ===
        ConstantsEnum.CommentParentScreenEnum.HOME
      ) {
        state.commentsHomeScreen = action.payload.commentData;
      } else if (
        action.payload.commentParentScreen ===
        ConstantsEnum.CommentParentScreenEnum.PROFILE
      ) {
        state.commentsProfileScreen = action.payload.commentData;
      } else if (
        action.payload.commentParentScreen ===
        ConstantsEnum.CommentParentScreenEnum.DISCOVER
      ) {
        state.commentsDiscoverScreen = action.payload.commentData;
      }
    },
    updateCommentListStatus: (
      state,
      action: PayloadAction<{status: string; commentParentScreen: string}>,
    ) => {
      if (
        action.payload.commentParentScreen ===
        ConstantsEnum.CommentParentScreenEnum.HOME
      ) {
        state.commentsHomeFetchStatus = action.payload.status;
      } else if (
        action.payload.commentParentScreen ===
        ConstantsEnum.CommentParentScreenEnum.PROFILE
      ) {
        state.commentsProfileFetchStatus = action.payload.status;
      } else if (
        action.payload.commentParentScreen ===
        ConstantsEnum.CommentParentScreenEnum.DISCOVER
      ) {
        state.commentsDiscoverFetchStatus = action.payload.status;
      }
    },
    updateCreateCommentStatus: (state, action) => {
      state.createCommentStatus = action.payload;
    },
    //CUSTOM WRITTEN EXTRA EXTRA REDUCERS
    fetchCommentsFulfilled: (
      state,
      action: PayloadAction<{
        commentListReponse: CommentListResponse;
        commentParentScreen: string;
      }>,
    ) => {
      if (
        action.payload.commentParentScreen ==
        ConstantsEnum.CommentParentScreenEnum.HOME
      ) {
        state.commentsHomeFetchStatus = ConstantsEnum.ApiStatusEnum.SUCCEEDED;
        if (
          state.numPagesCommentsHome !=
          action.payload.commentListReponse.num_pages
        ) {
          state.numPagesCommentsHome =
            action.payload.commentListReponse.num_pages;
        }
        if (state.commentsHomeScreen === null) {
          state.commentsHomeScreen = action.payload.commentListReponse.results;
        } else {
          state.commentsHomeScreen = [
            ...state.commentsHomeScreen!,
            ...action.payload.commentListReponse.results,
          ];
        }
      } else if (
        action.payload.commentParentScreen ==
        ConstantsEnum.CommentParentScreenEnum.PROFILE
      ) {
        state.commentsProfileFetchStatus =
          ConstantsEnum.ApiStatusEnum.SUCCEEDED;
        if (
          state.numPagesCommentsProfile !=
          action.payload.commentListReponse.num_pages
        ) {
          state.numPagesCommentsProfile =
            action.payload.commentListReponse.num_pages;
        }
        if (state.commentsProfileScreen === null) {
          state.commentsProfileScreen =
            action.payload.commentListReponse.results;
        } else {
          state.commentsProfileScreen = [
            ...state.commentsProfileScreen!,
            ...action.payload.commentListReponse.results,
          ];
        }
      } else if (
        action.payload.commentParentScreen ==
        ConstantsEnum.CommentParentScreenEnum.DISCOVER
      ) {
        state.commentsDiscoverFetchStatus =
          ConstantsEnum.ApiStatusEnum.SUCCEEDED;
        if (
          state.numPagesCommentsDiscover !=
          action.payload.commentListReponse.num_pages
        ) {
          state.numPagesCommentsDiscover =
            action.payload.commentListReponse.num_pages;
        }
        if (state.commentsDiscoverScreen === null) {
          state.commentsDiscoverScreen =
            action.payload.commentListReponse.results;
        } else {
          state.commentsDiscoverScreen = [
            ...state.commentsDiscoverScreen!,
            ...action.payload.commentListReponse.results,
          ];
        }
      }
    },
    fetchCommentsPending: (
      state,
      action: PayloadAction<{commentParentScreen: string}>,
    ) => {
      if (
        action.payload.commentParentScreen ===
        ConstantsEnum.CommentParentScreenEnum.HOME
      ) {
        state.commentsHomeFetchStatus = ConstantsEnum.ApiStatusEnum.LOADING;
        state.commentsHomeScreen = null;
      } else if (
        action.payload.commentParentScreen ===
        ConstantsEnum.CommentParentScreenEnum.PROFILE
      ) {
        state.commentsProfileFetchStatus = ConstantsEnum.ApiStatusEnum.LOADING;
        state.commentsProfileScreen = null;
      } else if (
        action.payload.commentParentScreen ===
        ConstantsEnum.CommentParentScreenEnum.DISCOVER
      ) {
        state.commentsDiscoverFetchStatus = ConstantsEnum.ApiStatusEnum.LOADING;
        state.commentsDiscoverScreen = null;
      }
    },
    fetchCommentsRejected: (
      state,
      action: PayloadAction<{commentParentScreen: string; error: any}>,
    ) => {
      if (
        action.payload.commentParentScreen ===
        ConstantsEnum.CommentParentScreenEnum.HOME
      ) {
        state.commentsHomeFetchStatus = ConstantsEnum.ApiStatusEnum.FAILED;
        state.error = action.payload;
        state.commentsHomeScreen = null;
      } else if (
        action.payload.commentParentScreen ===
        ConstantsEnum.CommentParentScreenEnum.PROFILE
      ) {
        state.commentsProfileFetchStatus = ConstantsEnum.ApiStatusEnum.FAILED;
        state.error = action.payload;
        state.commentsProfileScreen = null;
      } else if (
        action.payload.commentParentScreen ===
        ConstantsEnum.CommentParentScreenEnum.DISCOVER
      ) {
        state.commentsDiscoverFetchStatus = ConstantsEnum.ApiStatusEnum.FAILED;
        state.error = action.payload;
        state.commentsDiscoverScreen = null;
      }
    },
    createTextCommentFulFilled: (
      state,
      action: PayloadAction<{
        commentData: CommentData;
        isCommentReply: boolean;
        commentParentScreen: string;
      }>,
    ) => {
      state.createCommentStatus = ConstantsEnum.ApiStatusEnum.SUCCEEDED;
      if (
        action.payload.commentParentScreen ===
        ConstantsEnum.CommentParentScreenEnum.HOME
      ) {
        if (action.payload.isCommentReply) {
          let updatedCommentData: CommentData[] = JSON.parse(
            JSON.stringify(state.commentsHomeScreen),
          );
          updatedCommentData?.map(data => {
            if (data.thing_id == action.payload.commentData.parent_thing_id) {
              data.replies = [action.payload.commentData, ...data.replies];
            }
          });
          state.commentsHomeScreen = updatedCommentData;
        } else {
          state.commentsHomeScreen = [
            action.payload.commentData,
            ...state.commentsHomeScreen!,
          ];
        }
      } else if (
        action.payload.commentParentScreen ===
        ConstantsEnum.CommentParentScreenEnum.PROFILE
      ) {
        if (action.payload.isCommentReply) {
          let updatedCommentData: CommentData[] = JSON.parse(
            JSON.stringify(state.commentsProfileScreen),
          );
          updatedCommentData?.map(data => {
            if (data.thing_id == action.payload.commentData.parent_thing_id) {
              data.replies = [action.payload.commentData, ...data.replies];
            }
          });
          state.commentsProfileScreen = updatedCommentData;
        } else {
          state.commentsProfileScreen = [
            action.payload.commentData,
            ...state.commentsProfileScreen!,
          ];
        }
      } else if (
        action.payload.commentParentScreen ===
        ConstantsEnum.CommentParentScreenEnum.DISCOVER
      ) {
        if (action.payload.isCommentReply) {
          let updatedCommentData: CommentData[] = JSON.parse(
            JSON.stringify(state.commentsDiscoverScreen),
          );
          updatedCommentData?.map(data => {
            if (data.thing_id == action.payload.commentData.parent_thing_id) {
              data.replies = [action.payload.commentData, ...data.replies];
            }
          });
          state.commentsDiscoverScreen = updatedCommentData;
        } else {
          state.commentsDiscoverScreen = [
            action.payload.commentData,
            ...state.commentsDiscoverScreen!,
          ];
        }
      }
    },
    createTextCommentPending: state => {
      state.createCommentStatus = ConstantsEnum.ApiStatusEnum.LOADING;
    },
    createTextCommentRejected: (state, action) => {
      state.createCommentStatus = ConstantsEnum.ApiStatusEnum.FAILED;
      state.error = action.payload;
    },
    createCommentEventFulFilled: () => {},
    createCommentEventPending: () => {},
    createCommentEventRejected: () => {},
  },
});

export const {
  updateActiveFeedDetailIds,
  setCommentData,
  fetchCommentsFulfilled,
  fetchCommentsPending,
  fetchCommentsRejected,
  createTextCommentFulFilled,
  createTextCommentPending,
  createTextCommentRejected,
  createCommentEventFulFilled,
  createCommentEventPending,
  createCommentEventRejected,
  updateCommentListStatus,
  updateCreateCommentStatus,
} = commentSlice.actions;

export default commentSlice.reducer;
