export enum FontSize {
  /**
   * 24
   */
  largeHeading = 24,

  /**
   * 18
   */
  heading = 18,
  /**
   * 16
   */
  medium = 16,
  /**
   * 14
   */
  title = 14,
  /**
   * 12
   */
  regular = 12,
  /**
   * 11
   */
  small = 10,
  /**
   * 8
   */
  verySmall = 8,
}

export const LineHeight = {
  /**
   * 30
   */
  largeHeading: 30,
  /**
   * 24
   */
  heading: 24,
  /**
   * 20
   */
  medium: 20,
  /**
   * 20
   */
  title: 20,
  /**
   * 18
   */
  regular: 18,
  /**
   * 16
   */
  small: 16,
  /**
   * 12
   */
  verySmall: 12,
};

export const FontWeight = {
  /**
   * 700
   */
  bold: '700' as any,
  /**
   * 500
   */
  medium: '500' as any,
  /**
   * 400
   */
  regular: '400' as any,

  'Figtree-Regular': '400' as any,
  'Figtree-Medium': '500' as any,
  'Figtree-SemiBold': '600' as any,
  'Figtree-Bold': '700' as any,
};

export enum FontFamily {
  roboto = 'Roboto',
  lato_bold = 'Lato-Bold',
  lato_regular = 'Lato-Regular',
  lato_light = 'Lato-Light',
  lato_thin = 'Lato-Thin',
  FigtreeRegular = 'Figtree-Regular',
  FigtreeMedium = 'Figtree-Medium',
  FigtreeSemiBold = 'Figtree-SemiBold',
  FigtreeBold = 'Figtree-Bold',
}

export const TAB_BAR_SIZE = 275;
export const SCREEN_SIZE = 600;
