import AuthenticationService from '../authenticationService';
import axiosInstance from '../axios';

const requestNotificationPermission = async () => {};

const registerBackgroundNotificationHandler = () => {};

const handleForegroundNotification = () => {};

const registerForegroundNotificationHandler = () => {};

const registerNotificationOpenHandler = () => {};

const getFCMToken = async () => {};

const onRefreshToken = async () => {};

const updateFCMToken = async () => {
  const accessToken = await AuthenticationService.getAccessToken();
  let formData = new FormData();

  return axiosInstance.post<any>('/nexum/update/fcm', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const handleInitialNotification = () => {};

const PushNotificationService = {
  registerBackgroundNotificationHandler,
  registerForegroundNotificationHandler,
  registerNotificationOpenHandler,
  updateFCMToken,
  getFCMToken,
  requestNotificationPermission,
  onRefreshToken,
  handleForegroundNotification,
  handleInitialNotification,
};

export default PushNotificationService;
