import React, {memo, useCallback, useEffect, useMemo, useRef} from 'react';
import type {FC} from 'react';
import {Spinner, Button} from 'native-base';
import {FlatList, View, StyleSheet, Text} from 'react-native';
import {shallowEqual, useSelector} from 'react-redux';
import {useNavigation} from '@react-navigation/native';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {createAnalyticsEvent} from '@redux/slices/analyticsSlice';
import {dispatch} from '@redux/store';
import FeedCard from '@components/Card/FeedCard/FeedCard';
import FeedListLoader from '@components/FeedListLoader';
import ShareService from '@services/shareService';
import NavigationService from '@services/navigationService';
import DynamicLinkService from '@services/dynamicLinkService';
import type {FeedData} from '@models/Feed/FeedData';
import type {AnalyticsEventPayload} from '@models/Analytics/AnalyticsEventPayload';
import {
  DynamicLinkThingTypeEnum,
  EventTypeEnum,
  EventTypeValueEnum,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import {
  createProfileFeedEndReachedSelector,
  createProfileFeedIdsSelector,
  createProfileFeedLoadingSelector,
  createProfileFeedPageAndTotalPageSelector,
} from '@redux/selectors/profileSelector';
import {FeedPostByUserPayload} from '@models/Feed/FeedPostByUserPayload';
import {fetchProfileFeedData} from '@redux/actions/profile';
import {
  resetProfileFeedList,
  setProfileFeedPage,
} from '@redux/slices/profileSlice';
import {FontFamily} from '@utils/sizes';
import Colors from '@constants/Colors';

const styles = StyleSheet.create({
  list: {height: '100%', backgroundColor: Colors.background},
  contentContainerStyle: {
    paddingTop: 0,
  },
  emptyContainer: {
    alignItems: 'center',
    alignSelf: 'stretch',
    marginTop: 150,
  },
  button: {
    backgroundColor: '#9B51E0',
    borderRadius: 8,
  },
  seperator: {
    alignSelf: 'stretch',
    height: 1,
    backgroundColor: Colors.background,
  },
});

const UserFeedCard: FC<{
  thingId: string;
  index: number;
  currentProfileUserId: string;
  loggedInUserId: string;
}> = memo(props => {
  const navigation = useNavigation() as any;

  const handleComment = useCallback(
    (feedCardIndex: number) => {
      navigation.navigate('FeedDetail', {
        feedDataIndex: feedCardIndex,
        isSeeMoreClicked: false,
        isProfileCard: false,
      });
      const analyticsEventPayload: AnalyticsEventPayload = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: EventTypeValueEnum.COMMENT,
        thing_id: props.thingId,
        thing_type: ThingTypeEnum.ANALYTICS,
        uid: props.loggedInUserId,
      };

      dispatch(createAnalyticsEvent(analyticsEventPayload));
    },
    [props.thingId, props.loggedInUserId, navigation],
  );

  const handleShare = useCallback(async (data: FeedData) => {
    const link = await DynamicLinkService.createDynamicLink(
      data.thing_id,
      DynamicLinkThingTypeEnum.FEED_DETAIL,
    );
    const options = {
      message: `Check out this post on Wyse App ${data.title}: ${link} \n\nJoin the most meaningful discussions by industry leaders and your peers on Wyse app now.\nInnovation starts from conversation`,
      title: '',
    };
    await ShareService.openShare(options);
  }, []);
  return (
    <FeedCard
      thingId={props.thingId}
      loggedInUserId={props.loggedInUserId}
      currentProfileUserId={props.currentProfileUserId}
      feedCardIndex={props.index}
      isFeedDetailCard={false}
      isProfileCard={false}
      seeMore
      navigation={navigation}
      handleComment={handleComment}
      handleShare={handleShare}
    />
  );
});

const UserPosts: FC<{userId: string}> = ({userId}) => {
  const feedData = useSelector(
    createProfileFeedIdsSelector(userId),
    shallowEqual as any,
  );
  const isLoading = useSelector(createProfileFeedLoadingSelector(userId));

  /**
   * referance does not rerender the component sice previously the referance was user used
   * even after the loading was false it did not update the ui
   *
   * we had conditio  if (data.length ===0 && !loading)
   * abice if the data was not 0 then condition was becaomming false
   * but when data was 0 and ref updated ui did not change since the value for loading didnt got updated
   */
  const loading = useRef(false);
  const loggedInUserId = useSelector(userAccountThingIdSelector)!;
  const [page, totalNumberOfPagesinFeed] = useSelector(
    createProfileFeedPageAndTotalPageSelector(userId),
    shallowEqual,
  );
  const handleMore = useRef(false);
  const feedEndReached = useSelector(
    createProfileFeedEndReachedSelector(userId),
  );

  /**
   * functional effect to call everytime page updates
   */
  useEffect(() => {
    if (!userId) return;
    (async () => {
      if (!!loading.current) return;
      loading.current = true;
      if (
        totalNumberOfPagesinFeed == undefined ||
        (totalNumberOfPagesinFeed !== undefined &&
          page <= totalNumberOfPagesinFeed)
      ) {
        const payload: FeedPostByUserPayload = {
          authorId: userId,
          page: page == undefined ? 0 : page,
        };
        await dispatch(fetchProfileFeedData(payload));
        if (handleMore.current) handleMore.current = false;
      }
      loading.current = false;
    })();
  }, [page, userId, loading, totalNumberOfPagesinFeed, handleMore]);

  /**
   * renderItem of Flatlist
   */
  const renderItem = useCallback(
    ({item, index}: {item: string; index: number}) => {
      return (
        <UserFeedCard
          {...{loggedInUserId}}
          thingId={item}
          index={index}
          currentProfileUserId={userId}
        />
      );
    },
    [loggedInUserId, userId],
  );

  /**
   * Reset Feed List
   */
  const resetData = useCallback(() => {
    dispatch(resetProfileFeedList(userId));
  }, [userId]);

  /**
   * Handle Load more
   */
  const handleLoadMore = useCallback(
    ({distanceFromEnd}: {distanceFromEnd: number}) => {
      if (!!loading.current) return;
      if (distanceFromEnd <= 0) return;
      if (feedEndReached) return;
      if (!!handleMore.current) return;
      handleMore.current = true;
      dispatch(setProfileFeedPage({profileId: userId, page: page + 1}));
    },
    [page, userId, feedEndReached, loading, handleMore],
  );

  /**
   * REfresh feed function
   */
  const refreshFeed = useCallback(() => {
    // Do not refresh feed
    console.log('resetData', resetData, userId);
    return;
    // if (!userId) return;
    // resetData();
    // const payload: FeedPostByUserPayload = {
    //   authorId: userId,
    //   page: 0,
    // };
    // dispatch(fetchProfileFeedData(payload));
  }, [userId, resetData]);

  /**
   * List Footer Component
   */
  const listFooter = useCallback(
    () =>
      feedData.length === 0 ? null : totalNumberOfPagesinFeed ==
        page + 1 ? null : (
        <Spinner size="sm" color="#9B51E0" />
      ),
    [page, totalNumberOfPagesinFeed, feedData.length],
  );

  /**
   * key extractor important for flatlist to render cells for batch update
   */
  const keyExtractor = useCallback((item: string) => `feed-post-${item}`, []);

  const empty = useMemo(
    () => (
      <View style={styles.emptyContainer}>
        <Text style={{marginBottom: 20, fontFamily: FontFamily.lato_regular}}>
          {`${
            userId === loggedInUserId
              ? `You haven't created any post till now.`
              : 'User did not create any post till now'
          }`}
        </Text>
        {userId === loggedInUserId ? (
          <Button
            style={styles.button}
            onPress={() => NavigationService.getInstance().openCreatePost()}>
            <Text
              style={{
                color: '#F4F4F4',
                fontFamily: FontFamily.lato_regular,
              }}>
              Create Post
            </Text>
          </Button>
        ) : null}
      </View>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId === loggedInUserId],
  );

  const seperator = useCallback(() => <View style={styles.seperator} />, []);

  /**
   * conditional render for null checks of userThingId, feedData
   */
  if (userId == null || isLoading) {
    return <FeedListLoader />;
  }

  return (
    <FlatList
      disableIntervalMomentum
      removeClippedSubviews
      keyExtractor={keyExtractor}
      data={feedData}
      contentContainerStyle={styles.contentContainerStyle}
      initialNumToRender={10}
      renderItem={renderItem}
      onEndReached={handleLoadMore}
      onEndReachedThreshold={0.5}
      style={styles.list}
      refreshing={false}
      ListEmptyComponent={empty}
      onRefresh={refreshFeed}
      ItemSeparatorComponent={seperator}
      ListFooterComponent={listFooter}
    />
  );
};

export default UserPosts;
