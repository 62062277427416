/* eslint-disable */
import React, {useCallback, useEffect, useState} from 'react';
import type {FC} from 'react';
import {
  Pressable,
  StyleSheet,
  View,
  ActivityIndicator,
  TextInput,
  KeyboardAvoidingView,
  Platform,
  BackHandler,
  Alert,
} from 'react-native';
import uuid from 'react-native-uuid';
import {useSelector} from 'react-redux';
import {useRoute} from '@react-navigation/native';
import Header, {HeaderBackButton} from '@components/Header/Header';
import {
  MediumText,
  RegularText,
  SmallText,
  VerySmallThinText,
  textStyles,
} from '@components/Texts';
import CustomAvatar from '@components/Avatar';
import Colors from '@constants/Colors';
import {
  createGetDocumentIdSelector,
  createProfileDetailLoadingSelector,
  createUserAccountDataSelector,
  createUserNameIDAvatarSelector,
  userDocumentIdSelector,
} from '@redux/selectors/profileSelector';
import {dispatch} from '@redux/store';
import {
  userAccountNameIdAvatarSelector,
  userAccountThingIdSelector,
} from '@redux/selectors/userAccountSelectors';
import {fetchProfileDetails} from '@redux/actions/profile';
import {setDocumentId} from '@redux/slices/profileSlice';
import type {AccountData} from '@models/AccountData/AccountData';
import {getMessage, useInputDebounce} from '@utils/utilityFunctions';
import NavigationService from '@services/navigationService';
import FirestoreService from '@services/firestoreService';
import DatabaseService from '@services/DatabaseService';
import ToasterService from '@services/toasterService';
import {CHAT_REQUEST} from '@constants/ChatContstanats';
import {FontFamily} from '@utils/sizes';
import {textInputStyles} from '@components/Inputs';

type PageParams = {
  id: string;
  name?: string;
  avatar?: string;
  role?: string;
  org?: string;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  gap: {
    alignSelf: 'stretch',
    height: 8,
    backgroundColor: Colors.lightBackground,
  },
  toContainer: {
    alignSelf: 'stretch',
    padding: 16,
  },
  toInnerCotainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    height: 32,
    alignItems: 'center',
    marginTop: 8,
  },
  infoContainer: {
    flex: 1,
    paddingHorizontal: 8,
  },
  hButtonContainer: {
    height: '100%',
    justifyContent: 'center',
  },
  disabledSendbutton: {
    height: '80%',
    paddingHorizontal: 16,
    borderRadius: 8,
    backgroundColor: Colors.primary + 60,
    justifyContent: 'center',
  },
  sendButton: {
    height: '80%',
    paddingHorizontal: 16,
    borderRadius: 8,
    backgroundColor: Colors.primary,
    justifyContent: 'center',
  },
  loadingContainer: {alignSelf: 'center', marginTop: 16},
  subjectInput: {padding: 16, alignSelf: 'stretch', color: Colors.text},
  messageInput: {
    padding: 16,
    flex: 1,
    color: Colors.text,
    textAlignVertical: 'top',
  },
});

const Loading = () => {
  return (
    <View style={styles.loadingContainer}>
      <ActivityIndicator color={Colors.text} />
    </View>
  );
};

const Gap = () => {
  return <View style={styles.gap} />;
};

const ToContainer: FC<{params?: PageParams}> = ({params}) => {
  return (
    <View style={styles.toContainer}>
      <MediumText color="text" fontFamily={FontFamily.lato_regular}>
        To
      </MediumText>
      <View style={styles.toInnerCotainer}>
        <CustomAvatar
          width={28}
          height={28}
          borderRadius={14}
          source={params?.avatar || ''}
          backgroundColor={Colors.lightBackground}
        />
        <View style={styles.infoContainer}>
          <RegularText color="text" fontFamily={FontFamily.lato_regular}>
            {params?.name}
          </RegularText>
          <VerySmallThinText
            color="lightText"
            fontFamily={FontFamily.lato_regular}>
            {params?.role ? params?.role + ' | ' + (params?.org || '') : ''}
          </VerySmallThinText>
        </View>
      </View>
    </View>
  );
};

const InitiateChat: FC = () => {
  const params = useRoute().params as PageParams;
  const realm = DatabaseService.useRealm();
  const userThingId = useSelector(userAccountThingIdSelector);
  const [isInitiating, setIsInitiating] = useState(false);
  const userAccountData = useSelector(userAccountNameIdAvatarSelector);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const userDocId = useSelector(userDocumentIdSelector);
  const reciverDocId = useSelector(createGetDocumentIdSelector(params?.id));
  const onSubjectChange = useInputDebounce(setSubject, 250);
  const onMessageChange = useInputDebounce(setMessage, 250);

  useEffect(() => {
    if (!params || (params && !params?.id)) {
      NavigationService.getInstance().goBack(2);
      return;
    }
    (async () => {
      if (!userDocId) {
        const uId = await FirestoreService.getUserDocId(userThingId!);
        dispatch(setDocumentId({id: userThingId!, docId: uId}));
      }
      if (!reciverDocId) {
        const rId = await FirestoreService.getUserDocId(params?.id!);
        dispatch(setDocumentId({id: userThingId!, docId: rId}));
      }
    })();
  }, []);

  useEffect(() => {
    if (Platform.OS == 'web') return;
    const handler = BackHandler.addEventListener('hardwareBackPress', () => {
      NavigationService.getInstance().goBack(2);
      return true;
    });
    return handler.remove;
  }, []);

  const headerLeft = useCallback(
    () => (
      <HeaderBackButton
        onPress={() => {
          NavigationService.getInstance().goBack(2);
        }}
      />
    ),
    [],
  );

  const headerRight = useCallback(() => {
    const disabled = subject.trim().length === 0 || message.trim().length === 0;
    const isDisabled =
      isInitiating || disabled || !userDocId || !reciverDocId || !params.name;
    return (
      <View style={styles.hButtonContainer}>
        <Pressable
          onPress={async () => {
            setIsInitiating(true);
            const conversationId = uuid.v4().toString();

            const msg = getMessage(
              userAccountData.thing_id!,
              undefined,
              undefined,
              subject,
              message,
            );
            DatabaseService.addMessage(realm, conversationId, msg);
            await FirestoreService.createNewMessage(
              conversationId,
              msg._id,
              msg,
            );
            await FirestoreService.initializeChatRoom({
              conversationId,
              firstUserDocId: userDocId!,
              secondUserDocId: reciverDocId!,
              firstUserData: userAccountData,
              secondUserData: {
                avatar: params?.avatar!,
                user_fullname: params?.name!,
                thing_id: params?.id,
              },
              subject,
              message,
              msg,
            });
            ToasterService.showToast('Message request sent');
            NavigationService.getInstance().goBack();
          }}
          disabled={isDisabled}
          style={isDisabled ? styles.disabledSendbutton : styles.sendButton}>
          <SmallText
            fontFamily={FontFamily.lato_regular}
            color={isInitiating || disabled ? 'lightText' : 'white'}>
            Send
          </SmallText>
        </Pressable>
      </View>
    );
  }, [
    subject,
    message,
    userDocId,
    reciverDocId,
    params?.id,
    params?.name,
    params?.avatar,
    !!userAccountData,
    isInitiating,
  ]);

  if (!params) return null;

  return (
    <View style={styles.container}>
      <Header
        title="New Message"
        headerLeft={headerLeft}
        headerRight={headerRight}
      />

      <KeyboardAvoidingView
        keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 10}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.container}>
        <Gap />
        <ToContainer {...{params}} />
        <Gap />
        <TextInput
          maxLength={CHAT_REQUEST.MAX_LENGTH_SUBJECT}
          editable={!isInitiating}
          onChangeText={onSubjectChange}
          placeholderTextColor={Colors.veryLightText}
          placeholder="Subject"
          style={[
            textStyles.titleText,
            styles.subjectInput,
            textInputStyles.webInput,
          ]}
        />
        <Gap />
        <TextInput
          maxLength={CHAT_REQUEST.MAX_LENGTH_MESSAGE}
          editable={!isInitiating}
          onChangeText={onMessageChange}
          multiline
          placeholderTextColor={Colors.veryLightText}
          placeholder="Write Message"
          style={[
            textStyles.titleThinText,
            styles.messageInput,
            textInputStyles.webInput,
          ]}
        />
      </KeyboardAvoidingView>
    </View>
  );
};

export default InitiateChat;
