import type {AnalyticsEventPayload} from '@models/Analytics/AnalyticsEventPayload';
import AuthenticationService from './authenticationService';
import axiosInstance from './axios';

const createAnalyticsEvent = async (payload: AnalyticsEventPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.get<any>('/nexum/event', {
    params: payload,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const AnalyticsService = {
  createAnalyticsEvent,
};

export default AnalyticsService;
