import firebase from 'firebase/compat/app';
import {FireBaseApp} from '../app/index.web';

import 'firebase/compat/auth';

FireBaseApp.initialize();

const auth = firebase.auth;
console.log(
  'auth().currentUser?.getIdTokenResult();',
  auth().currentUser?.getIdToken(),
);
export default auth;
