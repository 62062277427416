import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import type {FC} from 'react';
import {
  View,
  TextInput,
  Switch,
  Pressable,
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  ActivityIndicator,
  Keyboard,
} from 'react-native';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
  cancelAnimation,
  SharedValue,
} from 'react-native-reanimated';
import {useSelector} from '@redux/store';
import Icon from 'react-native-vector-icons/Ionicons';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {
  userAccountNameSelector,
  userAvatarSelector,
} from '@redux/selectors/userAccountSelectors';
import {CustomIcon} from '@components/icon';
import Colors from '@constants/Colors';
import {FontFamily, FontSize, FontWeight} from '@utils/sizes';
import CustomAvatar from '../Avatar';
import {resolveImageAssetSource} from '@utils/utilityFunctions';
import {textInputStyles} from '@components/Inputs';
import {CustomText} from '@components/Texts';

const anonImage = require('@assets/anon-incognito.png');
const randomAvatarImage = require('@assets/profile-unvailable-avatar.png');
const anonymousURI = resolveImageAssetSource(anonImage).uri;
const randomAvatarURI = resolveImageAssetSource(randomAvatarImage).uri;

const styles = StyleSheet.create({
  commentContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,

    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: 'hidden',
  },
  comentsContainerWeb: {
    left: 0,
    right: 0,
    bottom: 0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: 'hidden',
  } as any,
  title: {
    paddingLeft: 8,
  },
  switchContainer: {
    alignSelf: 'stretch',
    paddingVertical: 14,
    paddingHorizontal: 10,
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputContainer: {
    paddingHorizontal: 10,
    paddingBottom: 10,
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textInput: {
    flex: 1,
    borderRadius: 8,
    minHeight: 40,
    maxHeight: 60,
    fontWeight: FontWeight.regular,
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 2,
    fontFamily: Platform.OS == 'web' ? 'Figtree' : FontFamily.FigtreeRegular,
    fontSize: FontSize.title,
    color: Colors.lightText,
    borderWidth: 0.5,
    marginRight: 10,
    borderColor: '#F2F2F2',
    height: 'auto',
    alignContent: 'flex-start',
    padding: 0,
  },
  sendContainer: {
    justifyContent: 'center',
    alignContent: 'center',
  },
  pressedSecodContainer: {
    opacity: 0.5,
    justifyContent: 'center',
    alignContent: 'center',
  },
});

export const CommentInputHeightContext = createContext<SharedValue<number>>(
  0 as any,
);

export const useCommentInputHeight = () =>
  useContext(CommentInputHeightContext);

const UserAvatar: FC<{isAnnonComment: boolean}> = ({isAnnonComment}) => {
  const avatar = useSelector(userAvatarSelector);
  return (
    <CustomAvatar
      width={36}
      height={36}
      borderRadius={56}
      source={
        !isAnnonComment ? (!avatar ? randomAvatarURI : avatar) : anonymousURI
      }
    />
  );
};

const {First, Second} = Platform.select({
  ios: {
    First: KeyboardAvoidingView,
    Second: View,
  },
  default: {
    First: View,
    Second: KeyboardAvoidingView,
  },
});

const CommentModal = ({
  visible,
  onClose,
  createComment,
  isCommentReply,
  onFocus,
  loading,
}: {
  visible: boolean;
  setLoadig?: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  onFocus?: () => void;
  loading: boolean;
  createComment: (
    text: string,
    isAnnonComment: boolean,
  ) => Promise<'fulfilled' | 'rejected'>;
  isCommentReply: boolean;
}) => {
  const heightValue = useCommentInputHeight();

  const {bottom} = useSafeAreaInsets();
  const [isAnnonComment, setIsAnnonComment] = useState(false);
  const [commentText, setCommentText] = useState<string>('');
  const inputRef = useRef<TextInput>(null);
  const userName = useSelector(userAccountNameSelector);

  const value = useSharedValue(1);

  const animatedStyle = useAnimatedStyle(
    () => ({
      paddingBottom: interpolate(value.value, [0, 1], [0, bottom]),
    }),
    [bottom, value],
  );

  useEffect(() => {
    if (visible) {
      value.value = withTiming(0, {duration: 300});
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);

      return () => {
        cancelAnimation(value);
      };
    } else {
      value.value = withTiming(1, {duration: 300});
      inputRef.current?.blur();
      Keyboard.dismiss();
      return () => {
        cancelAnimation(value);
      };
    }
  }, [visible, value, inputRef]);

  return (
    <First
      style={[
        Platform.OS == 'web'
          ? styles.comentsContainerWeb
          : styles.commentContainer,
        {backgroundColor: isAnnonComment ? '#181818' : Colors.white},
      ]}
      onLayout={
        Platform.OS == 'android'
          ? ({
              nativeEvent: {
                layout: {height},
              },
            }) => {
              console.log('height', height);
              heightValue.value = height;
            }
          : undefined
      }
      keyboardVerticalOffset={Platform.OS === 'android' ? 20 : 0}
      behavior={'padding'}>
      <Second
        onLayout={
          Platform.OS !== 'android'
            ? ({
                nativeEvent: {
                  layout: {height},
                },
              }) => {
                heightValue.value = height;
              }
            : undefined
        }
        style={{flex: 1}}
        keyboardVerticalOffset={Platform.OS === 'android' ? 20 : 0}
        behavior={'height'}>
        <View
          style={[
            styles.switchContainer,
            {
              // backgroundColor: isAnnonComment ? '#222222' : Colors.white,
            },
          ]}>
          <Switch
            disabled={loading}
            trackColor={{false: '#f4f4f4', true: '#9B51E0'}}
            thumbColor={isAnnonComment ? Colors.white : '#d9d9d9'}
            onValueChange={setIsAnnonComment}
            value={isAnnonComment}
          />
          <View style={{flex: 1}}>
            <CustomText
              size={FontSize.title}
              style={[
                styles.title,
                {
                  color: isAnnonComment ? Colors.white : '#222222',
                },
              ]}>
              {isCommentReply
                ? !isAnnonComment
                  ? `Replying as ${userName}`
                  : 'Replying Anonymously'
                : !isAnnonComment
                ? `Comment as ${userName}`
                : 'Comment Anonymously'}
            </CustomText>
          </View>
          {visible && (
            <Pressable
              disabled={loading}
              style={{paddingRight: 8}}
              onPress={() => {
                onClose();
              }}>
              {
                <CustomIcon
                  name="remove"
                  color={isAnnonComment ? Colors.white : Colors.lightText}
                />
              }
            </Pressable>
          )}
        </View>
        <View
          style={[
            styles.inputContainer,
            {
              backgroundColor: isAnnonComment ? '#222222' : '#FFFFFF',
            },
          ]}>
          <View
            style={{
              marginRight: 10,
            }}>
            <UserAvatar {...{isAnnonComment}} />
          </View>

          <TextInput
            editable={!loading}
            ref={inputRef}
            onFocus={() => {
              if (!visible) {
                onFocus?.();
              }
            }}
            onBlur={() => {
              if (visible) {
                onClose();
              }
            }}
            value={commentText}
            style={[
              styles.textInput,
              {
                backgroundColor: isAnnonComment ? '#f4f4f4' : '#f4f4f4',
              },
              textInputStyles.webInput,
            ]}
            multiline
            textAlignVertical="top"
            cursorColor="#222222"
            onChangeText={setCommentText}
          />
          <Pressable
            onPress={async () => {
              const response = await createComment(commentText, isAnnonComment);
              if (response === 'fulfilled') {
                setCommentText('');
                return;
              }
            }}
            disabled={loading || commentText.trim() === ''}
            style={({pressed}) =>
              pressed ? styles.pressedSecodContainer : styles.sendContainer
            }>
            {!loading ? (
              <Icon
                color={isAnnonComment ? '#FFFFFF' : '#222222'}
                name="send-outline"
                size={20}
              />
            ) : (
              <ActivityIndicator />
            )}
          </Pressable>
        </View>
        <Animated.View style={animatedStyle} />
      </Second>
    </First>
  );
};

export default CommentModal;
