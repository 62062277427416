/* eslint-disable */
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import type {FC} from 'react';
import {Spinner} from 'native-base';
import {View, StyleSheet, Text} from 'react-native';
import {shallowEqual, useSelector} from 'react-redux';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {dispatch} from '@redux/store';
import {
  fetchCommunityFeed,
  fetchCommunityMembers,
} from '@redux/actions/community';
import {
  createCommunityMembersLoadMoreLoadingSelector,
  createCommunityMemberIdsSelector,
  createCommunityMembersEndReachedSelector,
  createCommunityMembersPageAndTotalPageSelector,
} from '@redux/selectors/communitySelector';
import {
  resetCommunityMembersList,
  setCommunityMembersPage,
} from '@redux/slices/communitySlice';
import {
  FeedListLoaderItem,
  ListLoaderProvider,
  LOADING_DATA_LARGE,
} from '@components/FeedListLoader';
import MemberCard from '@components/Card/MemberCard';
import type {CommunityUtilPayload} from '@models/Community/CommunityUtilPayload';
import CommunityLayoutContext from './CommunitJoin';
import {FlatList} from './FlatList';
import {FontFamily} from '@utils/sizes';
import {withTokenRefresh} from '@utils/webhooks';
import {useRoute} from '@react-navigation/native';

const styles = StyleSheet.create({
  list: {height: '100%'},
  contentContainerStyle: {
    backgroundColor: '#FFFFFF',
  },
  emptyContainer: {
    alignItems: 'center',
    alignSelf: 'stretch',
    marginTop: 150,
  },
  button: {
    backgroundColor: '#9B51E0',
    borderRadius: 8,
    // height: 30,
  },
  cellRenderer: {
    // paddingTop: 10,
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
});

const CommunityMembers: FC<{communityThingId: string}> = ({
  communityThingId,
}) => {
  const loadingRef = useRef(null);
  const listRef = useRef(null);
  const {bottom} = useSafeAreaInsets();
  const memberIds = useSelector(
    createCommunityMemberIdsSelector(communityThingId),
    shallowEqual as any,
  );
  const loading = useRef(false);
  const handleMore = useRef(false);
  const userThingId = useSelector(userAccountThingIdSelector)!;
  const moreLoading = useSelector(
    createCommunityMembersLoadMoreLoadingSelector(communityThingId),
  );
  const [page, totalNumberOfPagesinFeed] = useSelector(
    createCommunityMembersPageAndTotalPageSelector(communityThingId),
    shallowEqual,
  );

  const memebersPageEndReached = useSelector(
    createCommunityMembersEndReachedSelector(communityThingId),
  );
  /**
   * functional effect to call everytime page updates
   */
  useEffect(() => {
    if (!communityThingId) return;
    (async () => {
      if (!!loading.current) return;
      loading.current = true;
      if (
        (page == undefined && totalNumberOfPagesinFeed == undefined) ||
        handleMore.current
      ) {
        const communityUtilPayload: CommunityUtilPayload = {
          communityId: communityThingId,
          page: page == undefined ? 0 : page,
        };
        await dispatch(fetchCommunityMembers(communityUtilPayload));
        if (handleMore.current) handleMore.current = false;
      }
      loading.current = false;
    })();
  }, [page, communityThingId, loading]);

  const loadingData =
    communityThingId == null || (memberIds.length <= 0 && !!loading);

  /**
   * renderItem of Flatlist
   */
  const renderItem = useCallback(
    ({item}: {item: number | string}) => {
      if (typeof item === 'number' && loadingData) {
        return <FeedListLoaderItem item={item} />;
      }
      if (typeof item === 'string') {
        return (
          <MemberCard
            userThingId={userThingId}
            memberThingId={item}
            communityThingId={communityThingId}
          />
        );
      }
      return null;
    },
    [userThingId, userThingId, loadingData],
  );

  /**
   * Reset Feed List
   */
  const resetData = useCallback(() => {
    dispatch(resetCommunityMembersList(communityThingId));
  }, [communityThingId]);

  /**
   * Handle Load more
   */
  const handleLoadMore = useCallback(
    ({distanceFromEnd}: {distanceFromEnd: number}) => {
      if (loadingData) return;
      if (!!loading.current) return;
      if (distanceFromEnd <= 0) return;
      if (memebersPageEndReached) return;
      if (!!handleMore.current) return;
      handleMore.current = true;
      dispatch(
        setCommunityMembersPage({
          communityId: communityThingId,
          page: page + 1,
        }),
      );
    },
    [page, communityThingId, memebersPageEndReached, loading, loadingData],
  );

  /**
   * Refresh feed function
   */
  const refreshFeed = useCallback(() => {
    return;
    if (loadingData) return;
    if (!communityThingId) return;
    resetData();
    const communityUtilPayload: CommunityUtilPayload = {
      communityId: communityThingId,
      page: 0,
    };
    dispatch(fetchCommunityFeed(communityUtilPayload));
  }, [communityThingId, resetData, loadingData]);

  /**
   * List Footer Component
   */
  const listFooter = useCallback(
    () => (
      <View style={{alignSelf: 'stretch', alignItems: 'center', marginTop: 10}}>
        {loadingData ? null : memberIds.length ===
          0 ? null : !moreLoading ? null : totalNumberOfPagesinFeed ==
          page + 1 ? null : (
          <Spinner size="sm" color="#9B51E0" />
        )}
        <CommunityLayoutContext.BottomPadding />
      </View>
    ),
    [
      page,
      totalNumberOfPagesinFeed,
      !moreLoading,
      memberIds.length,
      loadingData,
    ],
  );

  /**
   * key extractor important for flatlist to render cells for batch update
   */
  const keyExtractor = useCallback(
    (item: number | string, index: number) =>
      loadingData ? `loading-ite-${item}` : `comunity-member-${item}-${index}`,
    [loadingData],
  );

  const empty = useMemo(
    () =>
      loading ? null : (
        <View style={styles.emptyContainer}>
          <Text style={{marginBottom: 20, fontFamily: FontFamily.lato_regular}}>
            This community has no members
          </Text>
        </View>
      ),
    [loading],
  );

  const CellRendererComponent = useCallback(
    props =>
      loadingData ? (
        <View style={{alignSelf: 'stretch'}}>{props.children}</View>
      ) : (
        <View style={styles.cellRenderer}>{props.children}</View>
      ),
    [loadingData],
  );

  return (
    <View style={{flex: 1}}>
      <ListLoaderProvider loading={loadingData}>
        {useMemo(
          () => (
            <FlatList
              showsVerticalScrollIndicator={false}
              ref={loadingData ? loadingRef : listRef}
              keyExtractor={keyExtractor}
              data={loadingData ? LOADING_DATA_LARGE : memberIds}
              contentContainerStyle={[
                styles.contentContainerStyle,
                {paddingBottom: bottom || 18},
              ]}
              CellRendererComponent={CellRendererComponent}
              renderItem={renderItem}
              onEndReached={handleLoadMore}
              onEndReachedThreshold={0.5}
              style={styles.list}
              numColumns={1}
              refreshing={false}
              ListEmptyComponent={empty}
              onRefresh={refreshFeed}
              ListFooterComponent={listFooter}
            />
          ),
          [
            bottom,
            renderItem,
            CellRendererComponent,
            handleLoadMore,
            empty,
            refreshFeed,
            listFooter,
            loadingData,
            memberIds.length,
          ],
        )}
      </ListLoaderProvider>
    </View>
  );
};

export default withTokenRefresh(CommunityMembers);
