import React, {useRef, useState} from 'react';
import {StyleSheet, View, Animated} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import {TapGestureHandler, State} from 'react-native-gesture-handler';
import {CustomText} from './Texts';
import {FontSize} from '@utils/sizes';
import NavigationService from '@services/navigationService';
import ImageZoom from './ImageZoom/ImageZoom';

interface FullImageViewerProps {
  imgSource: string;
  postTitle: string;
}

const FullImageViewer: React.FC<FullImageViewerProps> = ({
  imgSource,
  postTitle,
}) => {
  const scale = useRef(new Animated.Value(1)).current;
  const [lastScale, setLastScale] = useState(1);

  const onDoubleTap = (event: any) => {
    if (event.nativeEvent.state === State.ACTIVE) {
      const newScale = lastScale === 1 ? 2 : 1;
      Animated.spring(scale, {
        toValue: newScale,
        useNativeDriver: true,
      }).start();
      setLastScale(newScale);
    }
  };

  return (
    <View style={{flex: 1, backgroundColor: 'black'}}>
      <View style={styles.contaninerTop}>
        <Icon
          name="close-outline"
          size={30}
          onPress={() => {
            NavigationService.getInstance().goBack();
          }}
          color="#fff"
        />
      </View>
      <TapGestureHandler onHandlerStateChange={onDoubleTap} numberOfTaps={2}>
        <Animated.View
          style={[styles.imageConatiner, {transform: [{scale: scale}]}]}>
          <ImageZoom uri={imgSource} />
        </Animated.View>
      </TapGestureHandler>
      <View style={styles.containerBottom}>
        <CustomText
          color="white"
          size={FontSize.title}
          style={styles.bottomText}>
          {postTitle}
        </CustomText>
      </View>
    </View>
  );
};

export default FullImageViewer;

const styles = StyleSheet.create({
  contaninerTop: {
    marginHorizontal: 15,
    marginTop: 40,
    position: 'absolute',
    zIndex: 1,
  },
  containerBottom: {
    position: 'absolute',
    bottom: 40,
  },
  imageConatiner: {
    width: '100%',
    height: '100%',
    borderColor: 'white',
    zIndex: -1,
  },
  bottomText: {
    paddingLeft: 20,
  },
});
