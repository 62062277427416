import axios from 'axios';

var baseUrl = 'https://api.bewyse.in';

if (__DEV__) {
  baseUrl = 'https://api-dev.bewyse.in';
}

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: '*',
  },
});

export default axiosInstance;
