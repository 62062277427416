/*eslint-disable */
import React, {useCallback, useState} from 'react';
import type {FC} from 'react';
import {
  ScrollView,
  View,
  Text,
  StyleSheet,
  Pressable,
  Platform,
  Dimensions,
  TextInput,
} from 'react-native';
import {Button} from 'native-base';
import type {ViewStyle, StyleProp} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import {EducationDetailsCard} from '@components/Card/EducationDetailsCard';
import {useShowAllModal} from '@components/Modals/ProfileWorkEducationmodals';
import {ExperienceDetailsCard} from '@components/Card/ExperienceDetailsCard';
import {
  createLinkedinImportLoadingSelector,
  userAccountThingIdSelector,
} from '@redux/selectors/userAccountSelectors';
import {FontFamily, FontSize, FontWeight, LineHeight} from '@utils/sizes';
import Colors from '@constants/Colors';
import {dispatch, useSelector} from '@redux/store';
import type {LinkedInParsedDataEducation} from '@models/AccountData/LinkedInModels/LinkedInParsedDataEducation';
import {
  openAddEditEducation,
  openAddEditWork,
} from '@redux/slices/userAccountDataSlice';
import {LinkedInParsedDataPositionGroup} from '@models/AccountData/LinkedInModels/LinkedInParsedDataPositionGroup';
import {createUserAccountDataSelector} from '@redux/selectors/profileSelector';
import ConstantsEnum, {
  EventTypeEnum,
  ThingTypeEnum,
  UserProfileAnalEventValue,
} from '@constants/constantsEnum';
import {AccountInfoRequestPayload} from '@models/AccountData/AccountInfoRequestPayload';
import {AnalyticsEventPayload} from '@models/Analytics/AnalyticsEventPayload';
import {importUserAccountDataFromLinkedIn} from '@redux/actions/userAccount';
import {createAnalyticsEvent} from '@redux/slices/analyticsSlice';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import {LinkedinInstruction} from '@screens/SignUp/LinkedinImportScreen';
import Analytics, {IExtraData, IEventData} from '@wyse/analytics';
import {textInputStyles} from '@components/Inputs';
import {CustomText} from '@components/Texts';

const {width, height} = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  titleText: {},
  addContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addButtonText: {
    marginRight: 5,
  },
  content: {
    padding: 16,
  },
  viewAllTextContainer: {
    alignSelf: 'center',
  },
  viewAllText: {
    fontWeight: FontWeight.medium,
    fontSize: FontSize.small,
    lineHeight: LineHeight.small,
    color: Colors.primary,
    fontFamily: FontFamily.lato_regular,
  },
  LinkedinImportContainer: {
    width: width * 0.75,
    alignSelf: 'center',
    marginTop: 20,
  },
  LinkedinImportTitleText: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 20,
    textAlign: 'center',
    color: '#4F4F4F',
    fontFamily: FontFamily.lato_regular,
  },
  LinkedinInputContainer: {
    width: width * 0.82,
    alignSelf: 'center',
    marginTop: 25,
    display: 'flex',
    alignItems: 'flex-start',
  },
  LinkedinInputText: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 18,
    textAlign: 'center',
    color: '#4F4F4F',
    fontFamily: FontFamily.lato_regular,
  },
  LinkedinInput: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 20,
    color: '#4F4F4F',
    height: height * 0.05,
  },
  LinkedinHelpText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 18,
    textAlign: 'left',
    color: '#4F4F4F',
    fontFamily: FontFamily.lato_regular,
  },
  spinnerTextStyle: {
    color: '#FFF',
  },
});

const TitleBox: FC<{
  userId: string;
  title: string;
  onPressAdd?: () => void;
  style?: StyleProp<ViewStyle>;
}> = ({userId, title, onPressAdd, style}) => {
  const loggedInUserId = useSelector(userAccountThingIdSelector);

  console.log('userId', loggedInUserId, userId);
  return (
    <View style={[styles.titleContainer, style]}>
      <CustomText
        size={FontSize.medium}
        fontFamily={FontFamily.FigtreeMedium}
        style={styles.titleText}>
        {title}
      </CustomText>
      {userId == loggedInUserId ? (
        <Pressable
          onPress={onPressAdd ? () => onPressAdd() : undefined}
          style={styles.addContainer}>
          <CustomText
            size={FontSize.small}
            color="primary"
            fontFamily={FontFamily.FigtreeMedium}
            style={styles.addButtonText}>
            Add
          </CustomText>

          <Icon name="add-circle-outline" size={16} color="#9B51E0" />
        </Pressable>
      ) : null}
    </View>
  );
};

const LinkedInImport: FC<{userId: string}> = ({userId}) => {
  const [linkedinURL, setLinkedinURL] = useState<string>('');
  const loggedInUserThingId = useSelector(userAccountThingIdSelector);
  const linkedImportLoading = useSelector(createLinkedinImportLoadingSelector);
  const linkedinInstructions = [
    {part1: 'Tap your', part2: 'profile photo', part3: ''},
    {part1: 'Tap', part2: 'view profile', part3: ''},
    {
      part1: 'Tap on the ion text to',
      part2: 'Add section',
      part3: 'button',
    },
    {part1: 'Tap', part2: 'share', part3: ''},
    {
      part1: 'Click on Copy and paste this link on Wyse App',
      part2: '',
      part3: '',
    },
  ];
  const fetchUserDataFromLinkedInImport = async () => {
    const userAccountInfo: AccountInfoRequestPayload = {
      linkedinProfileUrl: linkedinURL,
      thingId: loggedInUserThingId!,
      phoneNumber: null,
      uuid: loggedInUserThingId!,
    };
    const extraData: IExtraData = {
      linkedinUrl: linkedinURL,
    };
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: UserProfileAnalEventValue.POFILE_IMPORT_LINKEDIN_CLICKED,
      thing_type: ThingTypeEnum.NA,
      extra_data: JSON.stringify(extraData),
    };
    Analytics.trackEvent(analyticsEventPayload);
    dispatch(importUserAccountDataFromLinkedIn(userAccountInfo));
  };
  return (
    <View>
      <Spinner
        visible={linkedImportLoading}
        textContent={'Importing Account Info....'}
        textStyle={styles.spinnerTextStyle}
      />
      <ScrollView
        contentContainerStyle={{
          flexGrow: Platform.OS == 'android' ? 1 : 0,
        }}
        showsVerticalScrollIndicator={true}
        automaticallyAdjustKeyboardInsets={Platform.OS === 'ios' ? true : false}
        keyboardShouldPersistTaps="handled">
        <View style={styles.LinkedinImportContainer}>
          {loggedInUserThingId == userId ? (
            <CustomText style={styles.LinkedinImportTitleText}>
              Setup your Wyse profile by importing your Linkedin details
            </CustomText>
          ) : (
            <CustomText style={styles.LinkedinImportTitleText}>
              User has not completed his Wyse profile
            </CustomText>
          )}
        </View>
        {userId == loggedInUserThingId ? (
          <View>
            <View style={styles.LinkedinInputContainer}>
              <View>
                <CustomText style={styles.LinkedinInputText}>
                  Your Linkedin Profile URL
                </CustomText>
              </View>

              <View
                style={{
                  width: '100%',
                  height: height * 0.05,
                  justifyContent: 'center',
                }}>
                <TextInput
                  style={[styles.LinkedinInput, textInputStyles.webInput]}
                  // textContentType="URL"
                  placeholder="https://www.linkedin.com/in/..."
                  onChangeText={text => setLinkedinURL(text)}
                />
              </View>
              <View
                style={{
                  width: width * 0.82,
                  height: 0.75,
                  borderRadius: 1,
                  backgroundColor:
                    linkedinURL.length == 0 ? '#4F4F4F' : '#9B51E0',
                }}></View>
            </View>
            <View
              style={{
                // position: 'absolute',
                width: 'auto',
                height: 44,
                alignSelf: 'center',
                marginTop: 35,
              }}>
              <Button
                style={{
                  backgroundColor:
                    linkedinURL.length != 0 ? '#9B51E0' : '#E0E0E0',
                  borderRadius: 8,
                }}
                onPress={fetchUserDataFromLinkedInImport}
                // onPress={onClick}
                disabled={linkedinURL.length != 0 ? false : true}>
                <CustomText
                  style={{
                    color: linkedinURL.length != 0 ? '#F4F4F4' : '#BDBDBD',
                    fontFamily: FontFamily.lato_regular,
                  }}>
                  Import From Linkedin
                </CustomText>
              </Button>
            </View>
            <View
              style={{
                marginTop: 24,
                display: 'flex',
                marginLeft: 40,
              }}>
              {linkedinInstructions.map((item, index) => (
                <LinkedinInstruction
                  key={index}
                  index={index + 1}
                  part1={item.part1}
                  part2={item.part2}
                  part3={item.part3}
                />
              ))}
            </View>
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
};

const ViewAll: FC<{count: number; onPress: () => void}> = ({
  count,
  onPress,
}) => {
  return (
    <Pressable onPress={onPress} style={styles.viewAllTextContainer}>
      <Text style={styles.viewAllText}>{`View All (${count} more)`}</Text>
    </Pressable>
  );
};

const UserDetails: FC<{userId: string}> = ({userId}) => {
  const accountData = useSelector(createUserAccountDataSelector(userId));
  const loggedInUserId = useSelector(userAccountThingIdSelector);
  const [_, setState] = useShowAllModal();

  const onPressAddEducation = useCallback(
    (data?: LinkedInParsedDataEducation, index?: number) => {
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: UserProfileAnalEventValue.USER_EDUCATION_ADD_CLICK,
        thing_type: ThingTypeEnum.NA,
      };
      Analytics.trackEvent(analyticsEventPayload);
      dispatch(
        openAddEditEducation(
          data && index !== undefined ? {data, index} : undefined,
        ),
      );
    },
    [],
  );

  const onPressAddWork = useCallback(
    (data?: LinkedInParsedDataPositionGroup, index?: number) => {
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: UserProfileAnalEventValue.USER_EXPERIENCE_ADD_CLICK,
        thing_type: ThingTypeEnum.NA,
      };
      Analytics.trackEvent(analyticsEventPayload);
      dispatch(
        openAddEditWork(
          data && index !== undefined
            ? {data: data.profile_positions![0], index}
            : undefined,
        ),
      );
    },
    [],
  );
  if (!accountData) return null;

  const workData = accountData.linkedin_parsed!.position_groups?.slice(0, 2);
  const showWorkMoreButton =
    (accountData.linkedin_parsed!.position_groups?.length || 0) > 2;

  const educationData = accountData!.linkedin_parsed!.education?.slice(0, 2);

  const showEducationMoreButton =
    (accountData.linkedin_parsed!.education?.length || 0) > 2;

  return (
    <View style={styles.container}>
      {!!accountData?.linkedin_parsed &&
      Object.keys(accountData?.linkedin_parsed).length > 0 ? (
        <ScrollView contentContainerStyle={styles.content}>
          <TitleBox
            title="Work Experience"
            onPressAdd={onPressAddWork}
            userId={userId}
          />
          {workData?.map((experience, index) => (
            <View key={index}>
              <ExperienceDetailsCard
                userId={userId}
                experienceDetail={experience}
                index={index}
                onPressEdit={() => {
                  onPressAddWork(experience, index);
                }}
              />
            </View>
          ))}
          {showWorkMoreButton ? (
            <ViewAll
              count={
                accountData?.linkedin_parsed?.position_groups
                  ? accountData?.linkedin_parsed?.position_groups?.length - 2
                  : 0
              }
              onPress={() => {
                const extraData: IExtraData = {
                  selfProfile: loggedInUserId == userId,
                };
                const analyticsEventPayload: IEventData = {
                  ev_typ: EventTypeEnum.ANALYTICS,
                  ev_val: UserProfileAnalEventValue.USER_EXPERIENCE_VIEW_ALL,
                  thing_type: ThingTypeEnum.PROFILE,
                  thing_id: userId,
                  extra_data: JSON.stringify(extraData),
                };
                Analytics.trackEvent(analyticsEventPayload);
                setState(st => ({
                  ...st,
                  userId: userId,
                  visible: true,
                  type: 'work',
                }));
              }}
            />
          ) : null}
          <TitleBox
            userId={userId}
            style={{marginTop: 10}}
            title="Education"
            onPressAdd={onPressAddEducation}
          />
          {educationData?.map((education, index) => (
            <EducationDetailsCard
              userId={userId}
              key={index}
              educationDetail={education}
              index={index}
              onPressEdit={() => {
                onPressAddEducation(education, index);
                // setState(st => ({...st, visible: true, type: 'education'}));
              }}
            />
          ))}
          {showEducationMoreButton ? (
            <ViewAll
              count={
                accountData?.linkedin_parsed?.education
                  ? accountData.linkedin_parsed.education.length - 2
                  : 0
              }
              onPress={() => {
                const extraData: IExtraData = {
                  selfProfile: loggedInUserId == userId,
                };
                const analyticsEventPayload: IEventData = {
                  ev_typ: EventTypeEnum.ANALYTICS,
                  ev_val: UserProfileAnalEventValue.USER_EDUCATION_VIEW_ALL,
                  thing_type: ThingTypeEnum.PROFILE,
                  thing_id: userId,
                  extra_data: JSON.stringify(extraData),
                };
                Analytics.trackEvent(analyticsEventPayload);
                setState(st => ({...st, visible: true, type: 'education'}));
              }}
            />
          ) : null}
        </ScrollView>
      ) : (
        <View
          style={{
            marginTop: 24,
            display: 'flex',
          }}>
          <LinkedInImport userId={userId} />
        </View>
      )}
    </View>
  );
};

export default UserDetails;
