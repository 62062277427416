import {View, Dimensions, TextInput, StyleSheet} from 'react-native';
import {Menu} from 'react-native-paper';
import React, {useState} from 'react';
import Fuse from 'fuse.js';
import Icon from 'react-native-vector-icons/Ionicons';
import {textInputStyles} from './Inputs';

const {width} = Dimensions.get('window');
const AutoComplete = ({
  value: origValue,
  data,
  onChange,
  onSubmitEditing,
  target,
  placeHolderText,
}) => {
  const [value, setValue] = useState(origValue);
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<string[]>([]);
  const fuse = new Fuse(data, {});

  const filterData = (text: string) => {
    return fuse.search(text).map(obj => obj.item) as string[];
  };
  return (
    <View>
      <TextInput
        style={[styles.formTextInput, textInputStyles.webInput]}
        placeholder={placeHolderText}
        onChangeText={text => {
          onChange(target, text);
          if (text && text !== '') {
            const fd = filterData(text);

            setMenuVisible(true);
            setFilteredData(fd);
          } else {
            setFilteredData(data);
            setMenuVisible(false);
          }
          setValue(text);
        }}
        value={value}
        onSubmitEditing={onSubmitEditing}
        returnKeyType="done"
        maxLength={200}
        selectionColor={'#9B51E0'}
      />
      {!!data &&
        data.length > 0 &&
        !!filteredData &&
        filteredData.length > 0 &&
        menuVisible && (
          <View style={styles.suggestionContainer}>
            <Icon
              name="close-outline"
              size={20}
              onPress={() => {
                setMenuVisible(false);
              }}
              color="#9B51E0"
              style={{
                left: width * 0.85,
              }}
            />
            {filteredData.map((text, i) => (
              <Menu.Item
                key={i}
                style={styles.suggestionItem}
                onPress={() => {
                  onChange(target, text);
                  setValue(text);
                  setMenuVisible(false);
                }}
                contentStyle={{maxWidth: width * 0.91}}
                title={text}
                titleStyle={styles.sugestionText}
              />
            ))}
          </View>
        )}
    </View>
  );
};

const styles = StyleSheet.create({
  formTextInput: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 16,
    color: '#4F4F4F',
    padding: 16,
    alignItems: 'flex-start',
    width: '100%',
    borderWidth: 1,
    borderColor: '#9B51E0',
    borderRadius: 4,
    marginTop: 2,
  },
  suggestionContainer: {
    flex: 1,
  },
  suggestionItem: {
    backgroundColor: '#FFFFFF',
    marginHorizontal: 2,
    borderRadius: 3,
    maxWidth: width * 0.91,
  },
  sugestionText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 18,
    color: '#222222',
  },
});

export default AutoComplete;
