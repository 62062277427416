/*eslint-disable */
import React from 'react';
import type {FC} from 'react';
import {Platform, StyleProp, StyleSheet, Text, TextStyle} from 'react-native';
import type {TextProps} from 'react-native';
import {FontFamily, FontSize, FontWeight, LineHeight} from '@utils/sizes';
import Colors from '@constants/Colors';

export const textStyles = StyleSheet.create({
  mediumBoldText: {
    fontSize: FontSize.medium,
    lineHeight: LineHeight.medium,
    fontWeight: FontWeight.bold,
    fontFamily: FontFamily.roboto,
  },
  mediumThinText: {
    fontSize: FontSize.medium,
    lineHeight: LineHeight.medium,
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.roboto,
  },
  mediumText: {
    fontSize: FontSize.medium,
    lineHeight: LineHeight.medium,
    fontWeight: FontWeight.medium,
    fontFamily: FontFamily.roboto,
  },
  titleBoldText: {
    fontSize: FontSize.title,
    lineHeight: LineHeight.title,
    fontWeight: FontWeight.bold,
    fontFamily: FontFamily.roboto,
  },
  titleThinText: {
    fontSize: FontSize.title,
    lineHeight: LineHeight.title,
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.roboto,
  },
  titleText: {
    fontSize: FontSize.title,
    lineHeight: LineHeight.title,
    fontWeight: FontWeight.medium,
    fontFamily: FontFamily.roboto,
  },
  regularThinText: {
    fontSize: FontSize.regular,
    lineHeight: LineHeight.regular,
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.roboto,
  },
  regular: {
    fontSize: FontSize.regular,
    fontWeight: '400',
    fontFamily: Platform.OS === 'web' ? 'Figtree' : FontFamily.FigtreeRegular,
  },
  regularText: {
    fontSize: FontSize.regular,
    lineHeight: LineHeight.regular,
    fontWeight: FontWeight.medium,
    fontFamily: FontFamily.roboto,
  },
  smallThinText: {
    fontSize: FontSize.small,
    lineHeight: LineHeight.small,
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.roboto,
  },
  smallText: {
    fontSize: FontSize.small,
    lineHeight: LineHeight.small,
    fontWeight: FontWeight.medium,
    fontFamily: FontFamily.roboto,
  },
  verySmallThinText: {
    fontSize: FontSize.verySmall,
    lineHeight: LineHeight.verySmall,
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.roboto,
  },
  verySmallText: {
    fontSize: FontSize.verySmall,
    lineHeight: LineHeight.verySmall,
    fontWeight: FontWeight.medium,
    fontFamily: FontFamily.roboto,
  },
});

export type CustomTextStyle = Omit<
  StyleProp<TextStyle>,
  'fontSize' | 'lineHeight' | 'fontWeight'
>;

export type TextColors =
  | 'text'
  | 'darkGray'
  | 'gray'
  | 'lightText'
  | 'lightWhite'
  | 'veryLightText'
  | 'primary'
  | 'white'
  | 'dark'
  | 'mediumDarkGrey'
  | 'veryDarkGrey'
  | 'title';
type CustomTextProps = TextProps & {
  style?: CustomTextStyle;
  color?: TextColors;
  fontFamily?: string;
};

export const MediumThinText: FC<CustomTextProps> = props => {
  const {
    style,
    children,
    color = 'text',
    fontFamily = FontFamily.roboto,
    ...rest
  } = props;
  return (
    <Text
      style={[
        textStyles.mediumThinText,
        {color: Colors[color], fontFamily: fontFamily},

        style,
      ]}
      {...rest}>
      {children}
    </Text>
  );
};

export const MediumText: FC<CustomTextProps> = props => {
  const {style, children, color = 'text', fontFamily, ...rest} = props;
  return (
    <Text
      style={[
        textStyles.mediumText,
        {color: Colors[color], fontFamily: fontFamily},
        style,
      ]}
      {...rest}>
      {children}
    </Text>
  );
};

export const MediumBoldText: FC<CustomTextProps> = props => {
  const {style, children, color = 'text', fontFamily, ...rest} = props;
  return (
    <Text
      style={[
        textStyles.mediumBoldText,
        {color: Colors[color], fontFamily: fontFamily},
        style,
      ]}
      {...rest}>
      {children}
    </Text>
  );
};

export const TitleThinText: FC<CustomTextProps> = props => {
  const {style, children, color = 'text', fontFamily, ...rest} = props;
  return (
    <Text
      style={[
        textStyles.titleThinText,
        {color: Colors[color], fontFamily: fontFamily},
        style,
      ]}
      {...rest}>
      {children}
    </Text>
  );
};

export const TitleText: FC<CustomTextProps> = props => {
  const {style, children, color = 'text', fontFamily, ...rest} = props;
  return (
    <Text
      style={[
        textStyles.titleText,
        {color: Colors[color], fontFamily: fontFamily},
        style,
      ]}
      {...rest}>
      {children}
    </Text>
  );
};

export const TitleBoldText: FC<CustomTextProps> = props => {
  const {style, children, color = 'text', fontFamily, ...rest} = props;
  return (
    <Text
      style={[
        textStyles.titleBoldText,
        {color: Colors[color], fontFamily: fontFamily},
        style,
      ]}
      {...rest}>
      {children}
    </Text>
  );
};

export const RegularThinText: FC<CustomTextProps> = props => {
  const {style, children, color = 'text', fontFamily, ...rest} = props;
  return (
    <Text
      style={[
        textStyles.regularThinText,
        {color: Colors[color], fontFamily: fontFamily},
        style,
      ]}
      {...rest}>
      {children}
    </Text>
  );
};

export const RegularText: FC<CustomTextProps> = props => {
  const {style, children, color = 'text', fontFamily, ...rest} = props;
  return (
    <Text
      style={[
        textStyles.regularText,
        {color: Colors[color], fontFamily: fontFamily},
        style,
      ]}
      {...rest}>
      {children}
    </Text>
  );
};

export const SmallThinText: FC<CustomTextProps> = props => {
  const {style, children, color = 'text', fontFamily, ...rest} = props;
  return (
    <Text
      style={[
        textStyles.smallThinText,
        {color: Colors[color], fontFamily: fontFamily},
        style,
      ]}
      {...rest}>
      {children}
    </Text>
  );
};

export const SmallText: FC<CustomTextProps> = props => {
  const {style, children, color = 'text', fontFamily, ...rest} = props;
  return (
    <Text
      style={[
        textStyles.smallText,
        {color: Colors[color], fontFamily: fontFamily},
        style,
      ]}
      {...rest}>
      {children}
    </Text>
  );
};

export const VerySmallThinText: FC<CustomTextProps> = props => {
  const {style, children, color = 'text', fontFamily, ...rest} = props;
  return (
    <Text
      style={[
        textStyles.verySmallThinText,
        {color: Colors[color], fontFamily: fontFamily},
        style,
      ]}
      {...rest}>
      {children}
    </Text>
  );
};

export const VerySmallText: FC<CustomTextProps> = props => {
  const {style, children, color = 'text', fontFamily, ...rest} = props;
  return (
    <Text
      style={[
        textStyles.verySmallText,
        {color: Colors[color], fontFamily: fontFamily},
        style,
      ]}
      {...rest}>
      {children}
    </Text>
  );
};

type CustomFigTreeTextProps = CustomTextProps & {
  size?: FontSize;
};

export const CustomText: FC<CustomFigTreeTextProps> = ({
  style,
  size = FontSize.regular,
  color = 'text',
  fontFamily = FontFamily.FigtreeRegular,
  children,
  ...rest
}) => {
  console.log(
    'FontWeight[fontFamily]',
    FontWeight[fontFamily],
    Platform.OS == 'web' ? fontFamily.split('-')[0] : fontFamily,
  );
  return (
    <Text
      style={[
        {
          color: Colors[color],
          fontFamily:
            Platform.OS == 'web' ? fontFamily.split('-')[0] : fontFamily,
          fontSize: size,
        },
        Platform.OS === 'web' && {
          fontWeight: FontWeight[fontFamily],
        },
        style,
      ]}
      {...rest}>
      {children}
    </Text>
  );
};
