import React, {memo, useCallback} from 'react';
import type {FC} from 'react';
import {shallowEqual} from 'react-redux';
import {View, Dimensions, Pressable, StyleSheet} from 'react-native';
import {useSelector} from '@redux/store';
import {
  createCommunitiesJoinedByUserSelector,
  createCommunityMemberDataSelector,
} from '@redux/selectors/communitySelector';
import ToasterService from '@services/toasterService';
import Colors from '@constants/Colors';
import {FontFamily, FontSize, FontWeight, LineHeight} from '@utils/sizes';
import CustomAvatar from '../Avatar';
import NavigationService from '@services/navigationService';
import Icon from 'react-native-vector-icons/Ionicons';
import {RegularText, SmallThinText} from '@components/Texts';
import {resolveImageAssetSource} from '@utils/utilityFunctions';
import {RandomAvatar} from '@assets';
const randomAvatarURI = resolveImageAssetSource(RandomAvatar).uri;

const {width} = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    width: width,
    justifyContent: 'center',
    paddingHorizontal: 16,
    marginVertical: 5,
    paddingTop: 10,
  },
  innerContainer: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
  },
  profileContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  avatarContainer: {
    borderRadius: 56,
    overflow: 'hidden',
    marginRight: 8,
  },
  profileBottomContainer: {
    flex: 1,
  },
  nameText: {
    fontFamily: 'Roboto-Regular',
    fontWeight: FontWeight.regular,
    fontSize: FontSize.regular,
    lineHeight: LineHeight.regular,
    color: '#222222',
  },
  roleText: {
    fontFamily: 'Roboto-Regular',
    fontWeight: FontWeight.regular,
    fontSize: 10,
    lineHeight: 14,
    color: '#666666',
  },
  separator: {
    alignSelf: 'stretch',
    height: 2,
    backgroundColor: Colors.tabBackground,
  },
  messageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageText: {
    fontFamily: 'Noto Sans',
    fontWeight: FontWeight.medium,
    fontSize: FontSize.regular,
    lineHeight: LineHeight.regular,
    color: Colors.primary,
    marginLeft: 4,
  },
});

const MemberCard: FC<{
  memberThingId: string;
  userThingId?: string;
  communityThingId: string;
}> = ({memberThingId, communityThingId, userThingId}) => {
  const communitiesJoinedByUser = useSelector(
    createCommunitiesJoinedByUserSelector,
  );
  const memberData = useSelector(
    createCommunityMemberDataSelector(memberThingId),
    shallowEqual,
  );
  const isJoined = communitiesJoinedByUser.includes(communityThingId);
  const redirectToProfileScreen = useCallback(() => {
    if (isJoined) {
      //TODO add navigation for Profile
      NavigationService.getInstance().openProfileDetails(memberThingId);
    } else {
      ToasterService.showToast(
        'Join Space to View Profile of Members',
        '#EED202',
        1500,
      );
    }
  }, [isJoined, memberThingId]);

  if (!memberData.user_fullname || memberData.user_fullname?.length === 0) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <Pressable
          style={styles.profileContainer}
          onPress={redirectToProfileScreen}>
          <View style={styles.avatarContainer}>
            <CustomAvatar
              height={40}
              width={40}
              style={{flex: 1}}
              source={memberData?.avatar || randomAvatarURI}
            />
          </View>
          <View style={styles.profileBottomContainer}>
            <RegularText
              color={'veryDarkGrey'}
              fontFamily={FontFamily.lato_regular}
              numberOfLines={1}>
              {memberData?.user_fullname}
            </RegularText>
            <SmallThinText
              color={'veryDarkGrey'}
              fontFamily={FontFamily.lato_regular}
              numberOfLines={1}>
              {memberData?.user_current_role +
                ' | ' +
                memberData?.user_current_org}
            </SmallThinText>
          </View>
        </Pressable>
        {isJoined && (
          <Pressable
            disabled={userThingId == memberData.thing_id}
            onPress={() => {
              NavigationService.getInstance().openChat(
                memberData.thing_id,
                {
                  user_fullname: memberData.user_fullname,
                  user_avatar: memberData.avatar,
                  user_thing_id: memberData.thing_id,
                } as any,
                memberData.user_current_role,
                memberData.user_current_org,
              );
            }}
            style={styles.messageContainer}>
            <Icon
              name="md-chatbox-ellipses-outline"
              size={20}
              color="#4F4F4F"
            />
          </Pressable>
        )}
      </View>
    </View>
  );
};

export default memo(MemberCard);
