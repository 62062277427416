import React from 'react';
import {
  View,
  TouchableOpacity,
  StyleSheet,
  useWindowDimensions,
  Platform,
} from 'react-native';
import {ScrollView} from 'native-base';
import {FontFamily, FontSize} from '@utils/sizes';
import FastImage from '@libs/FastImage';
import {CarouselImage1, CarouselImage2, CarouselImage3} from '@assets';
import {CustomText} from './Texts';

const TextCarousel = ({
  activeIndex,
  setActiveIndex,
}: {
  activeIndex: any;
  setActiveIndex: any;
}) => {
  const {width} = useWindowDimensions();

  const colorBasedOnIndex = ['#9B51E0', '#FF7000', '#00BECE'];
  const bgColorBasedOnIndex = ['#F7EFFF', '#fff1e5', '#e1fdff'];

  const items = [
    {
      part1: 'Connect with',
      part2: 'like minded',
      part3: 'people around the globe',
      image: CarouselImage1,
    },
    {
      part1: 'Expand your',
      part2: 'professional network',
      part3: 'beyond your boundaries',
      image: CarouselImage2,
    },
    {
      part1: '',
      part2: 'Find Jobs and Gigs',
      part3: 'easily with one click.',
      image: CarouselImage3,
    },
  ];

  return (
    <View
      style={[styles.carouselContainer, {width: width > 420 ? 420 : width}]}>
      <ScrollView
        horizontal
        pagingEnabled
        snapToInterval={width > 420 ? 420 : width}
        showsHorizontalScrollIndicator={false}
        onScroll={event => {
          const itemWidth = width > 420 ? 420 : width;
          const x = event.nativeEvent.contentOffset.x;
          const index = Math.floor(x / (itemWidth - 60));
          console.log('index', index);
          if (index !== activeIndex) {
            setActiveIndex(index);
          }
        }}
        scrollEventThrottle={16}>
        {items.map((item, index) => (
          <View
            key={index}
            style={[styles.itemContainer, {width: width > 420 ? 420 : width}]}>
            <View
              style={{
                paddingHorizontal: 16,
              }}>
              <CustomText style={styles.title}>
                <CustomText
                  size={FontSize.largeHeading}
                  fontFamily={FontFamily.FigtreeBold}>
                  {item.part1}
                </CustomText>{' '}
                <CustomText
                  size={FontSize.largeHeading}
                  fontFamily={FontFamily.FigtreeBold}
                  style={{color: colorBasedOnIndex[index]}}>
                  {item.part2}
                </CustomText>{' '}
                <CustomText
                  size={FontSize.largeHeading}
                  fontFamily={FontFamily.FigtreeBold}>
                  {item.part3}
                </CustomText>
              </CustomText>
            </View>
            <View
              style={[
                styles.imageContainer,
                {
                  backgroundColor: bgColorBasedOnIndex[index],
                },
              ]}>
              <FastImage
                style={{
                  flex: 1,
                }}
                source={item.image}
                resizeMode="contain"
              />
            </View>
          </View>
        ))}
      </ScrollView>
      <View style={styles.dotContainer}>
        {items.map((_, index) => (
          <TouchableOpacity
            key={index}
            onPress={() =>
              Platform.OS === 'web' ? setActiveIndex(index) : null
            }>
            <View
              style={[
                styles.dot,
                {
                  backgroundColor:
                    index === activeIndex
                      ? colorBasedOnIndex[activeIndex]
                      : '#E0E0E0',
                },
              ]}
            />
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
};

export default TextCarousel;

const styles = StyleSheet.create({
  carouselContainer: {
    flex: 1,
  },
  itemContainer: {
    paddingHorizontal: 16,
    height: '100%',
  },
  title: {
    fontSize: FontSize.largeHeading,
    lineHeight: 28.8,
    textAlign: 'center',
    color: '#222222',
    fontFamily: 'DMSans-Bold',
    fontWeight: '700',
  },
  dotContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: 16,
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 10,
    marginRight: 10,
  },
  imageContainer: {
    flex: 1,
  },
});
