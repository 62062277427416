import {AccountDataListResponse} from '@models/AccountData/AccountDataListResponse';
import {FeedPostByUserPayload} from '@models/Feed/FeedPostByUserPayload';
import {addFeedsFromProfile} from '@redux/slices/feedSlice';
import {createAsyncThunk} from '@reduxjs/toolkit';
import ProfileService from '@services/profileService';

export const fetchProfileDetails = createAsyncThunk(
  'userAccountData/fetchOtherUserAccountDataByThingId',
  async (profileId: string, {rejectWithValue}) => {
    try {
      const response = await ProfileService.getProfileDetailsFromThingId(
        profileId,
      );
      const accountDataListResponse: AccountDataListResponse =
        response.data.response;
      return accountDataListResponse.results.length === 0
        ? null
        : accountDataListResponse.results[0];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchProfileFeedData = createAsyncThunk(
  'community/fetchProfileFeed',
  async (payload: FeedPostByUserPayload, {dispatch, rejectWithValue}) => {
    try {
      const response = await ProfileService.fetchProfileFeedData(payload);
      dispatch(addFeedsFromProfile(response.data.response.results));
      return response.data.response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
