import React from 'react';
import type {FC} from 'react';
import {
  KeyboardType,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  View,
  ViewStyle,
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import {BottomSheetTextInput} from '@gorhom/bottom-sheet';
import Colors from '@constants/Colors';
import {FontSize, FontWeight, LineHeight} from '@utils/sizes';
import CheckBox from '@libs/Checkbox';

const styles = StyleSheet.create({
  inputContainer: {
    alignSelf: 'stretch',
    marginBottom: 20,
  },
  checkBoxInputContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  inputTitle: {
    fontSize: FontSize.regular,
    fontWeight: FontWeight.regular,
    lineHeight: LineHeight.regular,
    color: Colors.lightText,
  },
  inputSubContainer: {
    alignSelf: 'stretch',
    height: 40,
    marginTop: 4,
  },
  inputDateSubContainer: {
    alignSelf: 'stretch',
    height: 40,
    marginTop: 4,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightText,
    alignItems: 'center',
  },
  input: {
    flex: 1,
    fontSize: FontSize.medium,
    fontWeight: FontWeight.regular,
    color: Colors.lightText,
    lineHeight: LineHeight.medium,
    borderBottomWidth: 1,
    paddingVertical: 0,
    borderBottomColor: Colors.lightText,
  },
  dateText: {
    fontSize: FontSize.medium,
    fontWeight: FontWeight.regular,
    color: Colors.lightText,
  },
  flexCenter: {
    flex: 1,
    justifyContent: 'center',
  },
  checkbox: {
    borderRadius: 8,
    width: 20,
    height: 20,
    borderWidth: 1,
    borderColor: '#9B51E0',
    borderStyle: 'solid',
    marginRight: 8,
  },
  webInput:
    Platform.OS === 'web' &&
    ({
      outlineColor: 'transparent',
      outlineWidth: 0,
    } as any),
});

const textInputStyles = styles;
export {textInputStyles};
type InputProps = {
  editable?: boolean;
  title: string;
  value: string;
  onChangeText: (value: string) => void;
  style?: StyleProp<ViewStyle>;
  placeholder?: string;
  keyboardType?: KeyboardType;
};

export const SheetInput: FC<InputProps> = props => {
  const {
    editable,
    title,
    value,
    onChangeText,
    style,
    placeholder,
    keyboardType,
  } = props;

  return (
    <View style={[styles.inputContainer, style]}>
      <Text style={styles.inputTitle}>{title}</Text>
      <View style={styles.inputSubContainer}>
        <BottomSheetTextInput
          editable={editable}
          value={value}
          onChangeText={onChangeText}
          style={[styles.input, styles.webInput]}
          keyboardType={keyboardType}
          placeholder={placeholder}
          placeholderTextColor={Colors.lightText}
        />
      </View>
    </View>
  );
};

export const Input: FC<InputProps> = props => {
  const {
    editable,
    title,
    value,
    onChangeText,
    style,
    placeholder,
    keyboardType,
  } = props;

  return (
    <View style={[styles.inputContainer, style]}>
      <Text style={styles.inputTitle}>{title}</Text>
      <View style={styles.inputSubContainer}>
        <TextInput
          editable={editable}
          value={value}
          numberOfLines={1}
          onChangeText={onChangeText}
          style={[styles.input, styles.webInput]}
          keyboardType={keyboardType}
          placeholder={placeholder}
          placeholderTextColor={Colors.lightText}
        />
      </View>
    </View>
  );
};

type SheetDateInputProps = {
  title: string;
  style?: StyleProp<ViewStyle>;
  value: string;
  disabled?: boolean;
  onPress: () => void;
};

export const SheetDateInput: FC<SheetDateInputProps> = props => {
  const {title, style, value, onPress, disabled} = props;

  return (
    <View style={[styles.inputContainer, style]}>
      <Text style={styles.inputTitle}>{title}</Text>
      <Pressable
        disabled={disabled}
        onPress={() => onPress()}
        style={styles.inputDateSubContainer}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <View style={styles.flexCenter}>
            <Text style={styles.dateText}>{value}</Text>
          </View>
          <Icon name="calendar-outline" size={23} />
        </View>
      </Pressable>
      {/* {visible ? (
        Platform.OS === 'android' ? (
          <DateTimePicker
            mode="date"
            value={new Date(value)}
            onChange={(_, date) => onChangeDate(date)}
          />
        ) : null
      ) : null}
      {Platform.OS === 'ios' ? (
        <DateTimePickerModal
          isVisible={visible}
          mode="date"
          onConfirm={onChangeDate}
          onCancel={() => setVisible(false)}
        />
      ) : null} */}
    </View>
  );
};

export const DateInput: FC<SheetDateInputProps> = props => {
  const {title, style, value, disabled, onPress} = props;

  return (
    <View style={[styles.inputContainer, style]}>
      <Text style={styles.inputTitle}>{title}</Text>
      <Pressable
        disabled={disabled}
        onPress={onPress}
        style={styles.inputDateSubContainer}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <View style={styles.flexCenter}>
            <Text style={styles.dateText}>{value}</Text>
          </View>
          <Icon name="calendar-outline" size={23} />
        </View>
      </Pressable>
    </View>
  );
};

type CheckBoxInputProps = {
  value: boolean;
  onValueChange: (data: boolean) => void;
  style?: StyleProp<ViewStyle>;
  title: string;
};

export const SheetCheckBoxInput: FC<CheckBoxInputProps> = props => {
  const {value, onValueChange, style, title} = props;
  return (
    <View style={[styles.checkBoxInputContainer, style]}>
      <CheckBox
        style={styles.checkbox}
        value={value}
        onValueChange={onValueChange}
        tintColors={{true: '#9B51E0', false: 'black'}}
        lineWidth={2}
        hideBox={true}
        boxType="square"
        onCheckColor="#9B51E0"
      />
      <Text style={styles.inputTitle}>{title}</Text>
    </View>
  );
};

export const CheckBoxInput: FC<CheckBoxInputProps> = props => {
  const {value, onValueChange, style, title} = props;
  return (
    <View style={[styles.checkBoxInputContainer, style]}>
      <CheckBox
        style={styles.checkbox}
        value={value}
        onValueChange={onValueChange}
        tintColors={{true: '#9B51E0', false: 'black'}}
        lineWidth={2}
        hideBox={true}
        boxType="square"
        onCheckColor="#9B51E0"
      />
      <Text style={styles.inputTitle}>{title}</Text>
    </View>
  );
};
