import firebase from 'firebase/compat/app';
import {FireBaseApp} from '../app/index.web';

import 'firebase/compat/firestore';

FireBaseApp.initialize();

const firestore = firebase.firestore;

export default firestore;
