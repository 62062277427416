import {Image, PixelRatio, Platform} from 'react-native';

export type PackagerAsset = {
  __packager_asset: boolean;
  fileSystemLocation: string;
  httpServerLocation: string;
  width?: number;
  height?: number;
  scales: Array<number>;
  hash: string;
  name: string;
  type: string;
};

const assets: Array<PackagerAsset> = [];
const svgDataUriPattern = /^(data:image\/svg\+xml;utf8,)(.*)/;

export function registerAsset(asset: PackagerAsset): number {
  // `push` returns new array length, so the first asset will
  // get id 1 (not 0) to make the value truthy
  return assets.push(asset);
}

export function getAssetByID(assetId: number): PackagerAsset {
  return assets[assetId - 1];
}
function resolveAssetUri(source): string | null {
  let uri: string | null = null;
  if (typeof source === 'number') {
    // get the URI from the packager
    const asset = getAssetByID(source);
    let scale = asset.scales[0];
    if (asset.scales.length > 1) {
      const preferredScale = PixelRatio.get();
      // Get the scale which is closest to the preferred scale
      scale = asset.scales.reduce((prev, curr) =>
        Math.abs(curr - preferredScale) < Math.abs(prev - preferredScale)
          ? curr
          : prev,
      );
    }
    const scaleSuffix = scale !== 1 ? `@${scale}x` : '';
    uri = asset
      ? `${asset.httpServerLocation}/${asset.name}${scaleSuffix}.${asset.type}`
      : '';
  } else if (typeof source === 'string') {
    uri = source;
  } else if (source && typeof source.uri === 'string') {
    uri = source.uri;
  }

  if (uri) {
    const match = uri.match(svgDataUriPattern);
    // inline SVG markup may contain characters (e.g., #, ") that need to be escaped
    if (match) {
      const [, prefix, svg] = match;
      const encodedSvg = encodeURIComponent(svg);
      return `${prefix}${encodedSvg}`;
    }
  }

  return uri;
}

function resolveAssetDimensions(source) {
  if (typeof source === 'number') {
    const {height, width} = getAssetByID(source);
    return {height, width};
  } else if (
    source != null &&
    !Array.isArray(source) &&
    typeof source === 'object'
  ) {
    const {height, width} = source;
    return {height, width};
  }
}

export const resolveAssetSource = (
  source: string,
): {
  uri: string;
  width?: number;
  height?: number;
} => {
  if (Platform.OS != 'web') {
    return Image.resolveAssetSource(source as any);
  }
  const uri = resolveAssetUri(source) || '';
  const dimensions = resolveAssetDimensions(source);
  let width, height;
  if (!dimensions)
    return {
      uri,
      width: undefined,
      height: undefined,
    };
  if (typeof dimensions.width === 'number') {
    width = dimensions.width;
  }
  if (typeof dimensions.height === 'number') {
    height = dimensions.height;
  }
  return {uri, width, height};
};
