import {useEffect} from 'react';
import {getLastUpdatedTime} from '@utils/utilityFunctions';
import type {
  FirebaseConversation,
  Conversation,
  Request,
} from '@models/Conversation';
import type {
  Message,
  ImageMessage,
  VideoMessage,
  DocumentMessage,
} from '@models/Conversation/Message';
import type {AccountData} from '@models/AccountData/AccountData';
import type {CommentData} from '@models/Comment/CommentData';
import {dispatch} from '@redux/store';
import {getComments} from '@redux/actions/comments';
import firestore from '@libs/Firebase/firestore';
import DatabaseService from './DatabaseService';

function getCollection(collectionName: string) {
  if (__DEV__) {
    return collectionName;
  } else {
    return collectionName;
  }
}

const userCollection = getCollection('users');
const conversationCollection = getCollection('conversations');
const postCollection = getCollection('posts');

const getUserDocId = async (user_thing_id: string) => {
  return new Promise<string>((resolve, reject) => {
    try {
      firestore()
        .collection(userCollection)
        .where('thing_id', '==', user_thing_id)
        .get()
        .then(querySnapshot => {
          console.log('querySnapshot', querySnapshot);
          if (querySnapshot.size && querySnapshot.docs[0].id) {
            resolve(querySnapshot.docs[0].id);
          } else {
            reject(undefined);
          }
        });
    } catch (error) {
      console.log('error', error);
      reject(undefined);
    }
  });
};

const getUserData = async (user_DocId: string) => {
  return new Promise((resolve, reject) => {
    try {
      firestore()
        .collection(userCollection)
        .doc(`${user_DocId}`)
        .onSnapshot(docSnapShot => {
          resolve(docSnapShot.data());
        });
    } catch (error) {
      reject('FetchFireStoreUser: Error ' + error);
    }
  });
};

const getConversation = async (
  first_user_thing_id: string,
  second_user_thing_id: string,
  openSheet: (conv: Conversation) => void,
) => {
  return new Promise<{data: Conversation | undefined; accepted: boolean}>(
    async resolve => {
      try {
        const firstUserConversation = await firestore()
          .collection(conversationCollection)
          .where('first_user_thing_id', '==', first_user_thing_id)
          .where('second_user_thing_id', '==', second_user_thing_id)
          .get();

        console.log('firstUserConversation', firstUserConversation.size);
        if (firstUserConversation.size === 1) {
          let data: Conversation | undefined;
          firstUserConversation.forEach(docSnapshot => {
            if (docSnapshot.exists) {
              data = {
                ...docSnapshot.data(),
                conversation_id: docSnapshot.id,
              } as Conversation;
            }
          });
          if (!!data) {
            const docId = await getUserDocId(second_user_thing_id);
            const otherConversation = await firestore()
              .collection(
                `${userCollection}/${docId}/user_conversation_requests`,
              )
              .doc(data.conversation_id)
              .get({source: 'server'});
            if (otherConversation.exists) {
              resolve({
                data,
                accepted: otherConversation.exists
                  ? (otherConversation.data() as Conversation).accepted
                  : false,
              });
            } else {
              const otherConversation2 = await firestore()
                .collection(`${userCollection}/${docId}/user_conversations`)
                .doc(data.conversation_id)
                .get({source: 'server'});
              if (otherConversation2.exists) {
                resolve({
                  data,
                  accepted: otherConversation2.exists
                    ? (otherConversation2.data() as Conversation).accepted
                    : false,
                });
              } else {
                resolve({data: undefined, accepted: false});
              }
            }
          } else {
            resolve({data: undefined, accepted: false});
          }
        } else {
          const secondUserConversation = await firestore()
            .collection(conversationCollection)
            .where('first_user_thing_id', '==', second_user_thing_id)
            .where('second_user_thing_id', '==', first_user_thing_id)
            .get();
          if (secondUserConversation.size === 1) {
            let data: Conversation;
            secondUserConversation.forEach(docSnapshot => {
              if (docSnapshot.exists) {
                data = {
                  ...(docSnapshot.data() as any),
                  conversation_id: docSnapshot.id,
                };
              }
            });

            const docId = await getUserDocId(first_user_thing_id);
            const otherConversation = await firestore()
              .collection(
                `${userCollection}/${docId}/user_conversation_requests`,
              )
              .doc(data!.conversation_id)
              .get();
            const conv = otherConversation.data() as Conversation;
            if (!!conv) {
              if (!conv.accepted) {
                openSheet(conv);
              }
              resolve({
                data: data!,
                accepted: conv.accepted,
              });
            } else {
              resolve({
                data: data!,
                accepted: true,
              });
            }
          } else {
            resolve({data: undefined, accepted: false});
          }
        }
      } catch (error) {
        console.log(
          'Error fetching conversation id between user1, user2 : ',
          first_user_thing_id,
          second_user_thing_id,
        );
        resolve({data: undefined, accepted: false});
      }
    },
  );
};

const doesConversationExists = async conversationId => {
  try {
    const response = await firestore()
      .collection(conversationCollection)
      .doc(conversationId)
      .get();
    return response.exists;
  } catch (error) {
    return false;
  }
};
const _createConversation = async (
  conversationId: string,
  first_user_fullname: string | null,
  first_user_thing_id: string,
  second_user_fullname: string | null,
  second_user_thing_id: string,
) => {
  return new Promise<string | undefined>(async resolve => {
    try {
      await firestore()
        .collection(conversationCollection)
        .doc(conversationId)
        .set({
          _id: conversationId,
          first_user_fullname: first_user_fullname,
          first_user_thing_id: first_user_thing_id,
          second_user_fullname: second_user_fullname,
          second_user_thing_id: second_user_thing_id,
        });
      resolve(conversationId);
    } catch (error) {
      console.log(
        'Error creating conversation between user1, user2 : ',
        error,
        first_user_fullname,
        second_user_fullname,
      );
      resolve(undefined);
    }
  });
};

export type MessageAccountData = Pick<
  AccountData,
  'avatar' | 'user_fullname' | 'thing_id'
>;

type ConversationData = {
  docId: string;
  conversationId: string;
  userData: MessageAccountData;
  subject: string;
  message: string;
  msg: Message;
};

const _createUserConversation = async (data: ConversationData) => {
  const {docId, conversationId, userData, msg, subject, message} = data;
  return new Promise<boolean>(async resolve => {
    try {
      await firestore()
        .doc(`${userCollection}/${docId}/user_conversations/${conversationId}`)
        .set({
          accepted: true,
          conversation_id: conversationId,
          deleted: false,
          read: false,
          rejected: false,
          user_avatar: userData.avatar,
          user_fullname: userData.user_fullname,
          user_thing_id: userData.thing_id,
          subject,
          message,
          last_message: msg,
          updated_at: firestore.FieldValue.serverTimestamp(),
        });

      resolve(true);
    } catch (error) {
      console.log(
        'Error creating user conversation ref : firstUserDocId, conversationId',
        docId,
        conversationId,
      );
      resolve(false);
    }
  });
};

const _createUserConversationReq = async (data: ConversationData) => {
  const {docId, conversationId, userData, msg, subject, message} = data;
  return new Promise<boolean>(async resolve => {
    try {
      await firestore()
        .doc(
          `${userCollection}/${docId}/user_conversation_requests/${conversationId}`,
        )
        .set({
          accepted: false,
          conversation_id: conversationId,
          deleted: false,
          read: false,
          rejected: false,
          user_avatar: userData.avatar,
          user_fullname: userData.user_fullname,
          user_thing_id: userData.thing_id,
          subject,
          message,
          last_message: msg,
          updated_at: firestore.FieldValue.serverTimestamp(),
        });
      resolve(true);
    } catch (error) {
      console.log(
        'Error creating user conversation request ref : UserDocId, conversationId',
        docId,
        conversationId,
      );
      resolve(false);
    }
  });
};

export const onAmaComments = (id: string) => {
  return firestore()
    .collection(`${postCollection}/${id}/comments`)
    .orderBy('created_timestamp', 'desc')
    .onSnapshot(querySnapshot => {
      const data: CommentData[] = [];
      querySnapshot.forEach(snapshot => {
        const comment = snapshot.data() as CommentData;
        delete comment.created_timestamp;
        data.push(comment);
      });
      dispatch(getComments({postId: id, page: 0, data}));
    });
};

export type InitializeChatRoomData = {
  conversationId: string;
  firstUserDocId: string;
  secondUserDocId: string;
  firstUserData: MessageAccountData;
  secondUserData: MessageAccountData;
  subject: string;
  message: string;
  msg: Message;
};

const initializeChatRoom = async (data: InitializeChatRoomData) => {
  const {
    conversationId,
    firstUserDocId,
    secondUserDocId,
    firstUserData,
    secondUserData,
    subject,
    msg,
    message,
  } = data;
  return new Promise<string>(async (resolve, reject) => {
    try {
      await _createConversation(
        conversationId,
        firstUserData.user_fullname,
        firstUserData.thing_id,
        secondUserData.user_fullname,
        secondUserData.thing_id,
      );
      if (!!conversationId) {
        const userConvRefCreateRes = await _createUserConversation({
          docId: firstUserDocId,
          conversationId,
          userData: secondUserData,
          subject,
          message,
          msg,
        });
        if (userConvRefCreateRes) {
          const userConvReqRefCreateResponse = await _createUserConversationReq(
            {
              docId: secondUserDocId,
              conversationId,
              userData: firstUserData,
              subject,
              message,
              msg,
            },
          );
          if (userConvReqRefCreateResponse) {
            resolve(conversationId);
            return;
          }
        }
      }
      console.log(
        'Error Initializing Chat Room : firstUserDocId,secondUserDocId, conversationId',
        firstUserDocId,
        secondUserDocId,
        conversationId,
      );
      reject('Error Initializing Chat Room ');
    } catch (error) {
      console.log(
        'Error Initializing Chat Room : firstUserDocId,secondUserDocId',
        firstUserDocId,
        secondUserDocId,
      );
      reject('Error Initializing Chat Room ' + error);
    }
  });
};

const createNewMessageWithAttachment = async (
  conversation_id: string,
  messageDocId: string,
  mediaType: string,
  uri: string,
  name: string,
  rest: ImageMessage | VideoMessage | DocumentMessage,
) => {
  const formData = new FormData();
  const keys = Object.keys(rest);
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] != 'url') {
      formData.append(keys[i], rest[keys[i]]);
    }
  }
  formData.append('url', {
    type: mediaType,
    uri,
    name,
  } as any);
};

const createNewMessage = async (
  conversation_id: string,
  messageDocId: string,
  newMsg: Message,
) => {
  return new Promise<Message>(async (resolve, reject) => {
    try {
      await firestore()
        .doc(
          `${conversationCollection}/${conversation_id}/messages/${messageDocId}`,
        )
        .set({
          ...newMsg,
        });

      await firestore()
        .doc(
          `${conversationCollection}/${conversation_id}/messages/${messageDocId}`,
        )
        .update({
          sent: true,
          received: false,
        });

      newMsg.sent = true;
      resolve(newMsg);
    } catch (error) {
      console.log(
        'Error while creating Msg : conversation_id, messageDocId, msg',
        conversation_id,
        messageDocId,
        newMsg,
      );
      reject('Error while creating Msg ' + error);
    }
  });
};

const updateUserConvRefLastMessage = async (
  user_DocId: string,
  conversation_id: string,
  newMsg: any,
) => {
  try {
    const userConversationsDoc = await firestore()
      .doc(
        `${userCollection}/${user_DocId}/user_conversations/${conversation_id}`,
      )
      .get();
    if (userConversationsDoc.exists) {
      firestore()
        .doc(
          `${userCollection}/${user_DocId}/user_conversations/${conversation_id}`,
        )
        .update({
          last_message: newMsg,
          updated_at: firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          console.log(
            'user Conversation Last Message Updated ',
            user_DocId,
            conversation_id,
            newMsg,
          );
        });
    }
  } catch (error) {
    console.log('user_conversations: last_message update error : ', error);
  }
};

const updateUserConvReqLastMessage = async (
  user_DocId: string,
  conversation_id: string,
  newMsg: any,
) => {
  try {
    const userConversationsReqDoc = await firestore()
      .doc(
        `${userCollection}/${user_DocId}/user_conversation_requests/${conversation_id}`,
      )
      .get();
    if (userConversationsReqDoc.exists) {
      firestore()
        .doc(
          `${userCollection}/${user_DocId}/user_conversation_requests/${conversation_id}`,
        )
        .update({
          last_message: newMsg,
        })
        .then(() => {
          console.log(
            'user Conversation Request Last Message Updated ',
            user_DocId,
            conversation_id,
            newMsg,
          );
        });
    }
  } catch (error) {
    console.log('user_conversations: last_message update error : ', error);
  }
};

const isMsgRequestAccepted = async (
  userId: string,
  conversation_id: string,
) => {
  return new Promise(async resolve => {
    try {
      console.log(
        'userConversationsDoc',
        `${userCollection}/${userId}/user_conversations/${conversation_id}`,
      );
      const userDocId = await getUserDocId(userId);

      const userConversationsDoc = await firestore()
        .doc(
          `${userCollection}/${userDocId}/user_conversations/${conversation_id}`,
        )
        .get();
      console.log('userConversationsDoc', userConversationsDoc.data());
      if (!userConversationsDoc.exists) {
        resolve(false);
      }
      if (
        userConversationsDoc?.data()?.accepted &&
        !userConversationsDoc?.data()?.rejected
      ) {
        resolve(true);
      } else {
        resolve(false);
      }
    } catch (error) {
      resolve(false);
      console.log(
        'Error Resolving isMsgRequestAccepted userDocId, conversationId',
        userId,
        conversation_id,
      );
    }
  });
};

const acceptConversationRequest = async (
  first_user_docId: string,
  conversation_id: string,
) => {
  return new Promise<Boolean>(async (resolve, reject) => {
    try {
      const conversationRequestRef = firestore().doc<FirebaseConversation>(
        `${userCollection}/${first_user_docId}/user_conversation_requests/${conversation_id}`,
      );
      const conversationReq = await conversationRequestRef.get();
      if (!conversationReq.exists) {
        return reject(
          `Conversation Request Doesnt Exist for user ${first_user_docId} conversationId ${conversation_id}`,
        );
      }
      await conversationRequestRef.delete();
      const conversationData = conversationReq.data();
      conversationData!.accepted = true;
      const conversationRef = firestore().doc<FirebaseConversation>(
        `${userCollection}/${first_user_docId}/user_conversations/${conversation_id}`,
      );

      const newConversationReq = await conversationRef.get();
      if (newConversationReq.exists) {
        return reject(
          `Error Accept Conversation Req user ${first_user_docId} conversationId ${conversation_id} error : user conversation already existing`,
        );
      }
      await conversationRef.set(conversationData!);
      return resolve(true);
    } catch (error) {
      console.log(
        'Error while accepting conversation req ## user, conversation_id ',
        first_user_docId,
        conversation_id,
      );
      reject(
        `Error Accept Conversation Req user ${first_user_docId} conversationId ${conversation_id} error : ${error}`,
      );
    }
  });
};

const rejectConversationRequest = async (
  userDocId: string,
  conversation_id: string,
  secondUserDocId: string,
) => {
  return new Promise<Boolean>(async (resolve, reject) => {
    try {
      const conversationRef = firestore().doc(
        `${userCollection}/${userDocId}/user_conversation_requests/${conversation_id}`,
      );
      const conversationReq = await conversationRef.get();
      if (conversationReq.exists) {
        await conversationRef.update({
          rejected: true,
        });

        console.log(
          'Conversation request updated : userDocId , conversation_id',
          userDocId,
          conversation_id,
        );
        await firestore()
          .doc(
            `${userCollection}/${secondUserDocId}/user_conversations/${conversation_id}`,
          )
          .update({
            rejected: true,
          });
        console.log('Second User Conversation Updated to rejected');
        resolve(true);
      } else {
        reject(
          `Conversation Request Doesnt Exist for user ${userDocId} conversationId ${conversation_id}`,
        );
      }
    } catch (error) {
      console.log(
        'Error while accepting conversation req ## user, conversation_id ',
        userDocId,
        conversation_id,
      );
      reject(
        `Error Accept Conversation Req user ${userDocId} conversationId ${conversation_id} error : ${error}`,
      );
    }
  });
};

// const _updateLastMsgReceivedForUserConversations = async (
//   userDocId: string,
//   conversation_id: string,
//   messageDocId: string,
// ) => {
//   try {
//     firestore()
//       .doc(
//         `${userCollection}/${userDocId}/user_conversations/${conversation_id}`,
//       )
//       .get()
//       .then(q => {
//         //TODO - Null check
//         if (q.exists && q.data()!.last_message._id === messageDocId) {
//           firestore()
//             .doc(
//               `${userCollection}/${userDocId}/user_conversations/${conversation_id}`,
//             )
//             .update({
//               'last_message.received': true,
//             })
//             .then(() => {
//               console.log(
//                 'last_message.received updated ## userDocId, conversation_id, messageDocId ##',
//                 userDocId,
//                 conversation_id,
//                 messageDocId,
//               );
//             });
//         }
//       });
//   } catch (error) {
//     console.log(
//       'Error updating last msg received for user conversation ',
//       error,
//     );
//   }
// };

// const _updateLastMsgReceivedForUserConversationReq = async (
//   userDocId: string,
//   conversation_id: string,
//   messageDocId: string,
// ) => {
//   try {
//     firestore()
//       .doc(
//         `${userCollection}/${userDocId}/user_conversation_requests/${conversation_id}`,
//       )
//       .get()
//       .then(q => {
//         if (q.exists && q.data()!.last_message._id === messageDocId) {
//           firestore()
//             .doc(
//               `${userCollection}/${userDocId}/user_conversation_requests/${conversation_id}`,
//             )
//             .update({
//               'last_message.received': true,
//             })
//             .then(() => {
//               console.log(
//                 'last_message.received updated ## userDocId, conversation_id, messageDocId ##',
//                 userDocId,
//                 conversation_id,
//                 messageDocId,
//               );
//             });
//         }
//       });
//   } catch (error) {
//     console.log(
//       'Error updating last msg received for user conversation ',
//       error,
//     );
//   }
// };

const updateMsgReceived = async (
  conversation_id: string,
  messageDocId: string,
) => {
  try {
    firestore()
      .doc(
        `${conversationCollection}/${conversation_id}/messages/${messageDocId}`,
      )
      .update({
        received: true,
      });
    // .then(async () => {
    //Update last msg received for first user
    // _updateLastMsgReceivedForUserConversationReq(
    //   firstUserDocId,
    //   conversation_id,
    //   messageDocId,
    // );
    // _updateLastMsgReceivedForUserConversations(
    //   firstUserDocId,
    //   conversation_id,
    //   messageDocId,
    // );

    // //Update last msg received for second user
    // _updateLastMsgReceivedForUserConversationReq(
    //   secondUserDocId,
    //   conversation_id,
    //   messageDocId,
    // );
    // _updateLastMsgReceivedForUserConversations(
    //   secondUserDocId,
    //   conversation_id,
    //   messageDocId,
    // );
    // });
  } catch (error) {
    console.log('Error updating msg received', error);
  }
};

const updateData = async () => {
  const usersRef = firestore().collection(userCollection);
  const snapshot = await usersRef.get();

  snapshot.forEach(async data => {
    if (data.exists) {
      const userId = data.id;

      const conversationsRef = usersRef
        .doc(userId)
        .collection('user_conversations');
      const conversations = await conversationsRef.get();
      if (conversations.size > 0) {
        conversations.forEach(convesation => {
          conversationsRef.doc(convesation.id).update({
            updated_at: firestore.FieldValue.serverTimestamp(),
          });
        });
      }
      //     const dataField = data.data();
      //     const documentid = data.id;
      //     if (!dataField.updated_at) {
      //       conversationRef.doc(documentid).update({
      //         updated_at: firestore.FieldValue.serverTimestamp(),
      //       });
      //     }
    }
  });
};
const onConversationChange = (
  id: string,
  listener: (data: Conversation[]) => void,
) => {
  return firestore()
    .collection(userCollection)
    .doc(id)
    .collection('user_conversations')
    .orderBy('updated_at', 'desc')
    .onSnapshot(
      querySnapshot => {
        const data: Conversation[] = [];
        querySnapshot.forEach(docSnapshot => {
          const fData = docSnapshot.data() as FirebaseConversation;

          if (fData.last_message) {
            data.push({
              ...fData,
              lastUpdatedAt: getLastUpdatedTime(fData.last_message.createdAt),
            });
          }
        });
        listener(data);
      },
      error => {
        console.log('eror', error);
        listener([]);
      },
    );
};

/**
 * this hooks gets the list of message from firestore and stores in realm db
 * which can be gotton from the database later
 */
const useFirebaseMessages = (id?: string, userId?: string) => {
  const realm = DatabaseService.useRealm();
  useEffect(() => {
    if (!id) return;
    const listner = firestore()
      .collection(conversationCollection)
      .doc(id)
      .collection('messages')
      .orderBy('createdAt', 'asc')
      .onSnapshot(querySnapshot => {
        querySnapshot.forEach(item => {
          const message = item.data() as Message;
          DatabaseService.addMessage(realm, id, message);
          if (!message.received) {
            console.log(
              'userid, ',
              userId,
              message.user._id !== userId,
              message.received,
            );
          }
          if (!!userId && message.user._id !== userId && !message.received) {
            updateMsgReceived(id, message._id);
          }
        });
      });
    return listner;
  }, [id, realm, userId]);
};

const onConversationRequestChange = (
  id: string,
  listener: (data: Request[]) => void,
) => {
  return firestore()
    .collection(`${userCollection}/${id}/user_conversation_requests`)
    .onSnapshot(
      querySnapshot => {
        const data: Request[] = [];
        const rejected: Conversation[] = [];
        const requests: Conversation[] = [];
        querySnapshot.forEach(docSnapshot => {
          const fData = docSnapshot.data() as FirebaseConversation;

          console.log('onSnapshot');
          if (fData.last_message) {
            if (fData.rejected) {
              rejected.push({
                ...fData,
                lastUpdatedAt: getLastUpdatedTime(fData.last_message.createdAt),
              });
            } else {
              requests.push({
                ...fData,
                lastUpdatedAt: getLastUpdatedTime(fData.last_message.createdAt),
              });
            }
          }
        });
        if (requests.length > 0) {
          data.push({title: 'Pending', data: requests});
        }
        if (rejected.length > 0) {
          data.push({title: 'Rejected', data: rejected});
        }
        listener(data);
      },
      error => {
        console.log('eror', error);
        listener([]);
      },
    );
};

const FirestoreService = {
  getUserDocId,
  getUserData,
  getConversation,
  initializeChatRoom,
  createNewMessage,
  updateUserConvRefLastMessage,
  updateUserConvReqLastMessage,
  isMsgRequestAccepted,
  acceptConversationRequest,
  rejectConversationRequest,
  updateMsgReceived,
  onConversationChange,
  onConversationRequestChange,
  useFirebaseMessages,
  doesConversationExists,
  updateData,
  onAmaComments,
  createNewMessageWithAttachment,
};

export default FirestoreService;
