import React, {useCallback, useMemo, useRef, useState} from 'react';
import type {FC} from 'react';
import {
  type StyleProp,
  StyleSheet,
  Text,
  View,
  type ViewStyle,
  Pressable,
  Modal,
  useWindowDimensions,
} from 'react-native';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import {useSelector} from 'react-redux';
import IconTextButton from '@components/IconTextButton';
import Colors from '@constants/Colors';
import {
  BlockEventEnum,
  DynamicLinkThingTypeEnum,
  EventTypeEnum,
  EventTypeValueEnum,
  ReportTypeEnum,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import type {BlockAccountPayload} from '@models/AccountData/BlockAccountPayload';
import {dispatch} from '@redux/store';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {blockAccountEventHandler} from '@redux/actions/userAccount';
import {updateBlockedUsers} from '@redux/slices/userAccountDataSlice';
import {AnalyticsEventPayload} from '@models/Analytics/AnalyticsEventPayload';
import {createAnalyticsEvent} from '@redux/slices/analyticsSlice';
import ToasterService from '@services/toasterService';
import NavigationService from '@services/navigationService';
import DynamicLinkService from '@services/dynamicLinkService';
import ShareService from '@services/shareService';
import {SmallText, textStyles} from '@components/Texts';
import {AuthScreens} from '@navigations/ScreenNames';
import LocalStorage from '@services/storage';
import {useRoute} from '@react-navigation/native';

export type ReportType = 'user' | 'post';

export type PostSheetOpenProps = {
  /**
   * thingId
   * @required true
   * @type {string}
   * post id
   */
  thingId: string;
  /**
   * authorId
   * @required false
   * @type {string}
   * author id
   */
  authorId?: string;
  /**
   * communityId
   * required false
   * @type {string}
   * community id if the post
   */
  communityId?: string;
  /**
   * title
   * @required true
   * @type {string}
   * title of the post used in share feature
   */
  title: string;
  avatar: string;
  user_fullname: string;
  role: string;
  org: string;
  anon_post: boolean;
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
  },
  flex: {width: '100%', height: '100%'},
  primaryBorderedButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.background,
    borderColor: Colors.primary,
    borderRadius: 8,
    width: 130,
    height: 35,
  },
  primaryButton: {
    backgroundColor: Colors.primary,
    borderRadius: 8,
    height: 35,
    alignItems: 'center',
    justifyContent: 'center',
    width: 130,
  },
  line: {alignSelf: 'stretch', height: 1, backgroundColor: Colors.background},
  blockModalContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#00000040',
  },
  modalInfoContainer: {
    position: 'absolute',
    maxWidth: 700,
    backgroundColor: Colors.white,
    padding: 20,
  },
  modalButtonContainer: {
    paddingHorizontal: 30,
    marginTop: 10,
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
});

class _PostOptionSheet {
  public PostOptionsheetButton: FC<
    {
      children: any;
      style?: StyleProp<ViewStyle>;
      loggedInUser?: string;
    } & PostSheetOpenProps
  > = ({children, style, loggedInUser, ...options}) => {
    return (
      <SheetComponent loggedInUser={loggedInUser} style={style} data={options}>
        {children}
      </SheetComponent>
    );
  };
}

/**
 * PostOptionSheet
 *
 * if in the app you want to open the PostOptionSheet sheet the import the
 * class instance and call openSheet Method with appropriate values
 *
 * eg:
 * ```
 * import PostOptionSheet from '@components/modals/PostOptionSheet';
 * ```
 */
const PostOptionSheet = new _PostOptionSheet();

const SheetComponent: FC<{
  data: PostSheetOpenProps;
  style: StyleProp<ViewStyle>;
  loggedInUser?: string;
  children: any;
}> = ({data, children, loggedInUser, style}) => {
  const menuRef = useRef<Menu>(null);
  const route = useRoute();
  const userThingId = useSelector(userAccountThingIdSelector);
  const [isSharing, setSharing] = useState(false);
  const [isBlocked, setBlocked] = useState<boolean>(false);
  const [isReported, setReported] = useState<boolean>(false);
  const closeSheet = useCallback(() => {
    // PostOptionSheet.sheetRef.current?.close();
  }, []);

  const onViewCommunityPress = () => {
    NavigationService.getInstance().openCommunityDetails(data.communityId!);
  };

  const onViewAuthorPress = () => {
    closeSheet();
    NavigationService.getInstance().openProfileDetails(data.authorId!);
  };

  const onMessageAuthorPress = () => {
    closeSheet();
    NavigationService.getInstance().openChat(
      data.authorId!,
      {
        user_avatar: data.avatar,
        user_thing_id: data.authorId,
        user_fullname: data.user_fullname,
      } as any,
      data.role,
      data.org,
    );
  };

  const onSharePress = async () => {
    setSharing(true);

    try {
      const link = await DynamicLinkService.createDynamicLink(
        data.thingId,
        DynamicLinkThingTypeEnum.FEED_DETAIL,
      );
      setSharing(false);
      closeSheet();
      const options = {
        message: `Check out ${data.title} on Wyse app. Connect with professional communities of same interest. \nPost link - \n ${link}`,
        title: 'Share dynamic link testing',
      };
      setTimeout(() => {
        ShareService.openShare(options);
      }, 200);
    } catch (error) {}
  };

  const onReportPress = (type: ReportType) => {
    setTimeout(() => {
      setReported(true);
      setBlocked(false);
      const analyticsEventPayload: AnalyticsEventPayload = {
        ev_typ: EventTypeEnum.REPORT,
        ev_val:
          type == ReportTypeEnum.POST
            ? EventTypeValueEnum.REPORT_POST
            : EventTypeValueEnum.REPORT_USER,
        thing_id: data.authorId!,
        thing_type: ThingTypeEnum.REPORT,
        //TODO - nullCheck
        uid: userThingId!,
      };
      dispatch(createAnalyticsEvent(analyticsEventPayload));
      // closeSheet();
    }, 200);
  };

  const onBlockPress = () => {
    setTimeout(() => {
      setBlocked(true);
      setReported(false);
    }, 200);
  };

  const reportOrBlockConfirmation = () => {
    setBlocked(false);
    setReported(false);
    if (isBlocked) {
      const payload: BlockAccountPayload = {
        action: BlockEventEnum.BLOCK,
        user_id: userThingId!,
        resource_id: data.authorId!,
      };
      dispatch(blockAccountEventHandler(payload));
      dispatch(updateBlockedUsers(payload));
      setTimeout(() => {
        ToasterService.showToast('User Blocked Successfully!!', '#27AE60');
      }, 250);
    } else {
      setTimeout(() => {
        ToasterService.showToast(
          'Report request submitted !! Wyse Team will take action within 24hrs',
          '#27AE60',
          2000,
        );
      }, 1500);
    }
    closeSheet();
  };

  const cancelButtonClick = () => {
    setBlocked(false);
    setReported(false);
    closeSheet();
  };

  const {width} = useWindowDimensions();

  return (
    <View>
      <Pressable
        onPress={async () => {
          console.log('loggedInUser', loggedInUser);
          if (!loggedInUser) {
            await LocalStorage.setData('redirect', route);

            NavigationService.getInstance().replaceToAuth({
              page: AuthScreens.OtpScreen,
            });
            return;
          }
          menuRef.current?.open();
        }}
        style={style}>
        {children}
      </Pressable>
      <Menu
        ref={menuRef}
        style={style}
        renderer={width <= 500 ? renderers.SlideInMenu : renderers.Popover}>
        <MenuTrigger></MenuTrigger>
        <MenuOptions>
          {!isBlocked && !isReported && (
            <>
              {data.communityId ? (
                <MenuOption
                  disabled={isSharing}
                  onSelect={onViewCommunityPress}>
                  <IconTextButton
                    disabled
                    text="Go to space"
                    Icon="space-deselect"
                  />
                </MenuOption>
              ) : null}
              {!data.anon_post ? (
                <MenuOption disabled={isSharing} onSelect={onViewAuthorPress}>
                  <IconTextButton
                    disabled
                    text="View Author"
                    Icon="profile-deselect"
                  />
                </MenuOption>
              ) : null}
              {!data.anon_post && data.authorId !== userThingId ? (
                <MenuOption
                  disabled={isSharing}
                  onSelect={onMessageAuthorPress}>
                  <IconTextButton
                    disabled
                    text="Message Author"
                    Icon="message"
                  />
                </MenuOption>
              ) : null}
              <MenuOption disabled={isSharing} onSelect={onSharePress}>
                <IconTextButton
                  disabled
                  text="Share"
                  Icon="share"
                  isLoading={isSharing}
                />
              </MenuOption>

              {data.authorId !== userThingId ? (
                <MenuOption
                  disabled={isSharing}
                  onSelect={() => onReportPress(ReportTypeEnum.POST)}>
                  <IconTextButton disabled text="Report Post" Icon="info" />
                </MenuOption>
              ) : null}
              {data.authorId !== userThingId ? (
                <MenuOption
                  disabled={isSharing}
                  onSelect={() => onReportPress(ReportTypeEnum.USER)}>
                  <IconTextButton disabled text="Report User" Icon="info" />
                </MenuOption>
              ) : null}
              {data.authorId !== userThingId ? (
                <MenuOption disabled={isSharing} onSelect={onBlockPress}>
                  <IconTextButton
                    disabled
                    text="Block User"
                    Icon="profile-deselect"
                  />
                </MenuOption>
              ) : null}
              {/* no {data.authorId !== userThingId ? (
            <IconTextButton
              disabled={isSharing}
              onPress={onContactPress}
              text="Contact Us"
              Icon="profile-deselect"
            />
          ) : null} */}
            </>
          )}
        </MenuOptions>
      </Menu>
      {(isBlocked || isReported) && (
        <Modal
          visible
          onRequestClose={() => {
            if (isBlocked) {
              setBlocked(false);
            }

            if (isReported) {
              setReported(false);
            }
          }}
          transparent>
          <View style={styles.blockModalContainer}>
            <Pressable
              onPress={() => {
                if (isBlocked) {
                  setBlocked(false);
                }

                if (isReported) {
                  setReported(false);
                }
              }}
              style={styles.flex}
            />
            <View style={styles.modalInfoContainer}>
              <Text
                style={[textStyles.mediumThinText, {paddingHorizontal: 18}]}>
                Thakyou for keeping Wyse safe. We will have this Post/User
                reviewed by a moderator
              </Text>
              <View style={styles.modalButtonContainer}>
                <Pressable
                  style={styles.primaryBorderedButton}
                  onPress={() => {
                    cancelButtonClick();
                  }}>
                  <SmallText color="lightText">Cancel</SmallText>
                </Pressable>
                <Pressable
                  style={styles.primaryButton}
                  onPress={() => {
                    reportOrBlockConfirmation();
                  }}>
                  <SmallText color="white">
                    {isBlocked ? 'Block' : 'Report'}
                  </SmallText>
                </Pressable>
              </View>
            </View>
          </View>
        </Modal>
      )}
    </View>
  );
};
export const PostOptionSheetComponent: FC = () => {
  return useMemo(() => <View />, []);
};

export default PostOptionSheet;
