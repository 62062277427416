/* eslint-disable */
import React, {useCallback, useEffect, useRef} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import FirestoreService from '@services/firestoreService';
import NavigationService from '@services/navigationService';
import {useSelector} from 'react-redux';
import {
  ChatInput,
  LayoutContext,
  ChatList,
  ChatScreenParams,
} from '@components/Chat';
import Header, {HeaderBackButton} from '@components/Header/Header';
import CustomAvatar from '@components/Avatar';
import Colors from '@constants/Colors';
import {CustomText} from '@components/Texts';
import {dispatch} from '@redux/store';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {setDocumentId} from '@redux/slices/profileSlice';
import {
  createGetDocumentIdSelector,
  userDocumentIdSelector,
} from '@redux/selectors/profileSelector';
import {
  resolveImageAssetSource,
  usePressDebounce,
} from '@utils/utilityFunctions';
import ConversationRequestSheet from '@components/Modals/ConversationRequestSheet';
import {FontSize, SCREEN_SIZE, TAB_BAR_SIZE} from '@utils/sizes';
import {withTokenRefresh} from '@utils/webhooks';
import BigScreenRightBar from '@components/BigScreenRightBar';
import BigScreenTabBar from '@components/BigScreenTabBar';
import Screen from '@components/Screen';
import {AppScreens} from '@navigations/ScreenNames';

const anonImage = require('@assets/profile-unvailable-avatar.png');
const anonymousURI = resolveImageAssetSource(anonImage).uri;

const styles = StyleSheet.create({
  container: {flex: 1, backgroundColor: Colors.white},
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 8,
  },
  avatar: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: Colors.background,
    overflow: 'hidden',
  },
  nameContainer: {flex: 1, paddingLeft: 8},
});

const ChatScreen = () => {
  const params = useRoute().params as ChatScreenParams;
  const userthingId = useSelector(userAccountThingIdSelector);
  const senderDocId = useSelector(userDocumentIdSelector);
  const reciverDocId = useSelector(
    createGetDocumentIdSelector(params.reciverId),
  );

  const shouldReload = useRef(true);
  const navigation = useNavigation();
  const onPressProfile = usePressDebounce(() => {
    NavigationService.getInstance().openProfileDetails(params.reciverId);
  }, 400);
  const isDone = useRef(false);

  FirestoreService.useFirebaseMessages(params?.conversationId, userthingId);

  useFocusEffect(
    useCallback(() => {
      console.log('params.conversation', params);
      if (!params) return;
      if (!isDone.current && !!userthingId && !!params.reciverId) {
        if (!shouldReload.current) return;
        if (!params.conversationId) {
          shouldReload.current = false;
          isDone.current = true;
          (async () => {
            console.log('getting conversations');
            const {data: conversation, accepted} =
              await FirestoreService.getConversation(
                userthingId,
                params.reciverId,
                async conv => {
                  const response = await ConversationRequestSheet.openSheet(
                    conv,
                  );
                  if (response == 'reject') {
                    NavigationService.getInstance().goBack();
                    return;
                  }
                  if (response == 'accept') {
                    isDone.current = false;
                    shouldReload.current = true;
                    navigation.setParams({...params} as any);
                    return;
                  }
                },
              );
            console.log('conv', conversation, accepted);
            if (!!conversation) {
              navigation.setParams({
                ...params,
                accepted,
                conversation: {
                  ...(params.conversation || {}),
                  ...conversation,
                } as any,
              } as any);
              return;
            }
            shouldReload.current = true;
            isDone.current = false;
            NavigationService.getInstance().openInitiateChat(
              params.reciverId,
              params.conversation?.user_fullname!,
              params.conversation?.user_avatar!,
              params.role,
              params.org,
            );
          })();
        } else {
          (async () => {
            shouldReload.current = false;
            isDone.current = true;
            const accepted = await FirestoreService.isMsgRequestAccepted(
              params?.reciverId,
              params.conversation?.conversation_id!,
            );
            console.log('accepted', accepted);
            navigation.setParams({...params, accepted} as any);
          })();
        }
      }
    }, [
      isDone,
      userthingId,
      params?.reciverId,
      params?.conversation?.user_avatar,
      params?.conversation?.user_fullname,
      !!params?.conversation,
    ]),
  );

  useEffect(() => {
    if (reciverDocId && senderDocId) return;
    if (!params?.reciverId) return;
    (async () => {
      if (!reciverDocId) {
        const docId = await FirestoreService.getUserDocId(params?.reciverId);
        dispatch(setDocumentId({id: params?.reciverId, docId}));
      }
      if (!senderDocId) {
        const docId = await FirestoreService.getUserDocId(userthingId!);
        dispatch(setDocumentId({id: userthingId!, docId}));
      }
    })();
  }, [reciverDocId, senderDocId, params?.reciverId]);

  return (
    <Screen
      RightComponent={BigScreenRightBar}
      LeftComponent={BigScreenTabBar}
      maxWidth={TAB_BAR_SIZE + SCREEN_SIZE}
      screen={AppScreens.Chat}
      style={{flex: 1, backgroundColor: Colors.white}}>
      <LayoutContext.LayoutProvider>
        <View style={styles.container}>
          <Header
            headerLeft={() => (
              <HeaderBackButton
                onPress={() => {
                  NavigationService.getInstance().goBack();
                }}
              />
            )}
            title={() => {
              return (
                <Pressable
                  onPress={onPressProfile}
                  style={styles.titleContainer}>
                  <View style={styles.avatar}>
                    <CustomAvatar
                      source={params.conversation?.user_avatar || anonymousURI}
                      style={{flex: 1}}
                    />
                  </View>
                  <View style={styles.nameContainer}>
                    <CustomText color="text" size={FontSize.title}>
                      {params?.conversation?.user_fullname}
                    </CustomText>
                  </View>
                </Pressable>
              );
            }}
          />
          <ChatList userId={userthingId!} />
          <ChatInput {...{senderDocId, reciverDocId}} />
        </View>
      </LayoutContext.LayoutProvider>
    </Screen>
  );
};

export default withTokenRefresh(ChatScreen);
