import Share, {type ShareOptions} from '@libs/Share';

const openShare = async (options: ShareOptions) => {
  try {
    console.log('Share', Share);
    const shareResponse = await Share.open(options);
    return shareResponse;
  } catch (error: any) {
    console.log('Error =>', error);
  }
};

const ShareService = {
  openShare,
};

export default ShareService;
