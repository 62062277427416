import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/auth';

export default async (number: string) => {
  console.log(
    '(window as any).recaptchaVerifier',
    (window as any).recaptchaVerifier,
  );
  if (!(window as any).recaptchaVerifier) {
    (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {},
    );
  } else {
    (window as any).recaptchaVerifier.render().then(function () {});
  }
  return firebase
    .auth()
    .signInWithPhoneNumber(number, (window as any).recaptchaVerifier);
};

export const ReCaptchaContainer = () => {
  return <div id="recaptcha-container"></div>;
};
