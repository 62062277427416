import {configureStore} from '@reduxjs/toolkit';
import type {
  ThunkAction,
  Action,
  ThunkDispatch,
  AnyAction,
} from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';
import communityReducer from './slices/communitySlice';
import feedReducer from './slices/feedSlice';
import userAccountDataReducer from './slices/userAccountDataSlice';
import commentReducer from './slices/commentSlice';
import metaDataReducer from './slices/metaDataSlice';
import profileReducer from './slices/profileSlice';
import feedDetails from './slices/feedDetailsSlice';
import notificationReducer from './slices/notificationSlice';

const middlewares: any = [
  /* other middlewares */
];

if (__DEV__) {
  const createDebugger = require('redux-flipper').default;
  middlewares.push(createDebugger());
}

const store = configureStore({
  reducer: {
    feed: feedReducer,
    community: communityReducer,
    userAccountData: userAccountDataReducer,
    comment: commentReducer,
    metaData: metaDataReducer,
    profile: profileReducer,
    feedDetails,
    notifications: notificationReducer,
  },
  devTools: true,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware().concat(middlewares);
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

/**
 * Exporting the dipatch function so that we can use it globally without using useDispatch hook
 */
export const dispatch = store.dispatch as ThunkDispatch<
  RootState,
  unknown,
  AnyAction
>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
