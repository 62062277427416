/*eslint-disable */
import {View, Text, Dimensions, Linking, Platform} from 'react-native';
import React from 'react';
import {Button as Btn, Modal} from 'native-base';
import {FontFamily} from '@utils/sizes';
const {width, height} = Dimensions.get('window');

const AppUpdateModal = ({
  isOpen,
  setIsOpen,
  showCancelOption,
}: {
  isOpen: boolean;
  setIsOpen: any;
  showCancelOption?: boolean;
}) => {
  const redirectLink =
    Platform.OS === 'ios'
      ? 'https://apps.apple.com/in/app/wyse-network-discuss-jobs/id6446937173'
      : 'https://play.google.com/store/apps/details?id=com.wyse.app';
  return (
    <Modal
      isOpen={isOpen}
      safeAreaTop={true}
      avoidKeyboard={true}
      closeOnOverlayClick={false}>
      <Modal.Content width="100%">
        <Modal.Header>
          <Text
            style={{
              fontSize: 18,
              fontWeight: '600',
              lineHeight: 24,
              color: '#333333',
              fontFamily: FontFamily.lato_regular,
            }}>
            Update the App
          </Text>
        </Modal.Header>

        <Modal.Body>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 20,
            }}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: '400',
                lineHeight: 20,
                color: '#4F4F4F',
                fontFamily: FontFamily.lato_regular,
              }}>
              Please update app for better experience.
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}>
            <View
              style={{
                width: width * 0.3,
                alignSelf: 'center',
                display: showCancelOption ? 'flex' : 'none',
              }}>
              <Btn
                variant="outline"
                size="sm"
                style={{
                  borderColor: '#9B51E0',
                  borderRadius: 8,
                }}
                onPress={() => setIsOpen(false)}>
                <Text
                  style={{
                    color: '#9B51E0',
                  }}>
                  Cancel
                </Text>
              </Btn>
            </View>
            <View style={{width: width * 0.3, alignSelf: 'center'}}>
              <Btn
                variant="outline"
                size="sm"
                style={{
                  backgroundColor: '#9B51E0',
                  borderRadius: 8,
                }}
                onPress={() => Linking.openURL(redirectLink)}>
                <Text
                  style={{
                    color: '#F4F4F4',
                  }}>
                  Update
                </Text>
              </Btn>
            </View>
          </View>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default AppUpdateModal;
