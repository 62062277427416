import {createAsyncThunk} from '@reduxjs/toolkit';
import FeedService from '@services/feedService';
import type {FilterFeedByFlairPayload} from '@models/Feed/FilterFeedByFlairPayload';
import type {CreatePostPayload} from '@models/Feed/CreatePostPayload';
import type {CreatePostEventPayload} from '@models/Feed/CreatePostEventPayload';
import {UpVotePollPayload} from '@models/Feed/UpVotePollPayload';
import {DeletePostPayload} from '@models/Feed/DeletePostPayload';
import {dispatch} from '@redux/store';
import {addCreatedPostIdInLoggedInUserFeed} from '@redux/slices/profileSlice';
import Analytics, {IEventData} from '@wyse/analytics';
import {
  EventTypeEnum,
  CreatePostAnalEvent,
  ThingTypeEnum,
  FeedEnagagementAnalEventValue,
} from '@constants/constantsEnum';

export const fetchFeedDataByThingId = createAsyncThunk(
  'feed/fetchByThingId',
  async (thingId: string, {rejectWithValue}) => {
    try {
      const response = await FeedService.fetchFeedDataByThingId(thingId, true);
      return response.data.response.results[0];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchFeedsList = createAsyncThunk(
  'userFeed/get',
  async (
    payload: {page: number; userId: string; pass?: boolean},
    {rejectWithValue},
  ) => {
    try {
      const response = await FeedService.fetchUserFeedsList(payload);
      return response.data.response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// export const fetchFeedsListPostedByUser = createAsyncThunk(
//   'feed/byUser/get',
//   async (payload: FeedPostByUserPayload, {rejectWithValue}) => {
//     try {
//       const response = await FeedService.fetchFeedsListPostedByUser(payload);
//       return response.data.response;
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   },
// );

export const fetchFeedsListByLabel = createAsyncThunk(
  'feed/get/byLabel',
  async (payload: FilterFeedByFlairPayload, {rejectWithValue}) => {
    try {
      const response = await FeedService.fetchFeedsListByLabel(payload);
      return response.data.response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const createPost = createAsyncThunk(
  'feed/createPost',
  async (payload: CreatePostPayload, {rejectWithValue}) => {
    try {
      const response = await FeedService.createTextPost(
        payload.createTextPostPayload,
      );
      const feed = response.data.response.results[0];

      if (payload.uploadImageInPostPayload) {
        try {
          payload.uploadImageInPostPayload.thingId = feed.thing_id;
          const {data} = await FeedService.updateImageInPost(
            payload.uploadImageInPostPayload,
          );
          feed.data.push(data.instance);
          const analyticsEventPayload: IEventData = {
            ev_typ: EventTypeEnum.ANALYTICS,
            ev_val: CreatePostAnalEvent.CREATE_POST_ATTACH_SUCCESS,
            thing_id: feed.thing_id,
            thing_type: ThingTypeEnum.POST,
          };
          Analytics.trackEvent(analyticsEventPayload);
        } catch (error) {
          const analyticsEventPayload: IEventData = {
            ev_typ: EventTypeEnum.ANALYTICS,
            ev_val: CreatePostAnalEvent.CREATE_POST_SUBMIT_FAILED,
            thing_type: ThingTypeEnum.POST,
          };
          Analytics.trackEvent(analyticsEventPayload);
        }
      }
      if (!!feed) {
        dispatch(
          addCreatedPostIdInLoggedInUserFeed({
            loggedInUserId: feed.author_mini.thing_id,
            feedId: feed.thing_id,
          }),
        );
      }
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: CreatePostAnalEvent.CREATE_POST_SUBMIT_SUCCESS,
        thing_type: ThingTypeEnum.POST,
        thing_id: feed.thing_id,
      };
      Analytics.trackEvent(analyticsEventPayload);
      return feed;
    } catch (error) {
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: CreatePostAnalEvent.CREATE_POST_SUBMIT_FAILED,
        thing_type: ThingTypeEnum.NA,
      };
      Analytics.trackEvent(analyticsEventPayload);
      return rejectWithValue(error.message);
    }
  },
);

export const createPostEvent = createAsyncThunk(
  'feed/event/create',
  async (payload: CreatePostEventPayload, {rejectWithValue}) => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val:
        payload.ev_typ == EventTypeEnum.UPVOTE
          ? FeedEnagagementAnalEventValue.POST_UPVOTE
          : FeedEnagagementAnalEventValue.POST_DOWNVOTE,
      thing_type: ThingTypeEnum.POST,
      thing_id: payload.thing_id,
    };
    Analytics.trackEvent(analyticsEventPayload);
    try {
      const response = await FeedService.createPostEvent(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const votePollOption = createAsyncThunk(
  'feed/votePollOption',
  async (payload: UpVotePollPayload) => {
    FeedService.votePollOption(payload)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log('Vote Poll Option Error', error);
      });
  },
);

export const deletePostByThingId = createAsyncThunk(
  'feed/deletePostByThingId',
  async (payload: DeletePostPayload, {rejectWithValue}) => {
    try {
      const response = await FeedService.deletePost(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
