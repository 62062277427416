/*eslint-disable */
import React, {useState} from 'react';
import {View, Text, useWindowDimensions} from 'react-native';
import {Button as Btn, Modal, Radio} from 'native-base';
import {useSelector} from 'react-redux';
import {createPostLabelSelector} from '@redux/selectors/metaSelector';
import {FontFamily, SCREEN_SIZE} from '@utils/sizes';

const FilterModal = ({
  isFilterModalOpen,
  setIsFilterModalOpen,
  applyFilter,
}: {
  isFilterModalOpen: any;
  setIsFilterModalOpen: any;
  applyFilter: any;
}) => {
  const {width} = useWindowDimensions();
  const [filter, setFilter] = useState<string | null>(null);
  const [filteredFlairThingId, setFilteredFlairThingId] = useState<
    string | null
  >(null);
  const postLabels = useSelector(createPostLabelSelector);
  const postLabelsTitles = postLabels.map(flair => flair.title);

  return (
    <Modal
      isOpen={isFilterModalOpen}
      onClose={() => setIsFilterModalOpen(false)}
      safeAreaTop={true}>
      <Modal.Content
        width="100%"
        maxWidth={width > SCREEN_SIZE ? SCREEN_SIZE : width}
        {...(width > SCREEN_SIZE ? {} : {marginBottom: -2, marginTop: 'auto'})}>
        <Modal.CloseButton />
        <Modal.Header>
          <Text
            style={{
              fontSize: 18,
              fontWeight: '600',
              lineHeight: 24,
              color: '#333333',
              fontFamily: FontFamily.lato_regular,
            }}>
            Filter Posts By
          </Text>
        </Modal.Header>

        <Modal.Body>
          <Radio.Group
            name="myRadioGroup"
            colorScheme="purple"
            value={!!filter ? filter : undefined}
            onChange={value => {
              const selectedFlairThingId = postLabels.filter(
                flair => flair.title == value,
              )[0].thing_id;
              setFilter(value);
              setFilteredFlairThingId(selectedFlairThingId!);
            }}>
            {postLabelsTitles.map((item, index) => (
              <Radio key={index} value={item} my={1} borderColor="#4F4F4F">
                <Text
                  style={{
                    fontSize: 17,
                    fontWeight: '400',
                    lineHeight: 21,
                    color: '#4F4F4F',
                    fontFamily: FontFamily.lato_regular,
                  }}>
                  {item}
                </Text>
              </Radio>
            ))}
          </Radio.Group>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            flexDirection: 'row',
            padding: 12,
          }}>
          <View style={{width: 158}}>
            <Btn
              variant="outline"
              size="sm"
              style={{
                backgroundColor: filter === '' ? '#E0E0E0' : '#FFFFFF',
                borderColor: filter !== '' ? '#4F4F4F' : '#E0E0E0',
                borderRadius: 8,
              }}
              onPress={() => {
                setFilter(null);
                applyFilter(null);
                setIsFilterModalOpen(false);
              }}
              disabled={filter === '' ? true : false}>
              <Text
                style={{
                  color: filter !== '' ? '#4F4F4F' : '#BDBDBD',
                  fontFamily: FontFamily.lato_regular,
                }}>
                Remove Filter
              </Text>
            </Btn>
          </View>
          <View style={{width: 158}}>
            <Btn
              size="sm"
              style={{
                backgroundColor: filter === '' ? '#E0E0E0' : '#9B51E0',
                borderRadius: 8,
              }}
              onPress={() => {
                applyFilter(filteredFlairThingId);
                setIsFilterModalOpen(false);
              }}
              disabled={filter === '' ? true : false}>
              <Text
                style={{
                  color: filter === '' ? '#BDBDBD' : '#FFFFFF',
                  fontFamily: FontFamily.lato_regular,
                }}>
                Apply
              </Text>
            </Btn>
          </View>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default FilterModal;
