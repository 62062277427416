import React, {type FC} from 'react';
import {
  View,
  type ViewProps,
  useWindowDimensions,
  Platform,
} from 'react-native';

const WebContainer: FC<
  ViewProps & {maxHeight?: number | string; maxWidth?: number}
> = ({
  children,
  style,
  maxHeight = Platform.OS == 'web' ? 860 : '100%',
  maxWidth = 500,
  ...rest
}) => {
  const {width, height} = useWindowDimensions();

  console.log('width', width, width > maxWidth ? maxWidth : width);
  return (
    <View
      style={[style, {alignItems: 'center', justifyContent: 'center'}]}
      {...rest}>
      <View
        style={{
          height: maxHeight
            ? height
            : typeof maxHeight == 'string'
            ? height
            : height > maxHeight
            ? 860
            : height,
          width: width > maxWidth ? maxWidth : width,
          overflow: 'hidden',
        }}>
        {children}
      </View>
    </View>
  );
};

export default WebContainer;
