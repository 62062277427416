import firebase from 'firebase/compat/app';
import type {FirebaseOptions} from 'firebase/app';

export const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyD_Rh-h1JTj1mKeyAA1YyFnEGYtMlmYyIw',
  authDomain: 'wyse-331021.firebaseapp.com',
  databaseURL: 'https://wyse-331021-default-rtdb.firebaseio.com',
  projectId: 'wyse-331021',
  storageBucket: 'wyse-331021.appspot.com',
  messagingSenderId: '726871258669',
  appId: '1:726871258669:web:e7e3dd022c286746096aa4',
  measurementId: 'G-WF3ZYDBKT8',
};

const initializeApp = (): void => {
  firebase.initializeApp(firebaseConfig);
  firebase.auth().setPersistence('local');
};

class _App {
  s;
  isInitialized = false;
  initialize = () => {
    if (!this.isInitialized) {
      this.isInitialized = true;
      return initializeApp();
    }
    return firebase.app();
  };
}

export const FireBaseApp = new _App();

export default firebase;
