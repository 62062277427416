/*eslint-disable */
import React from 'react';
import {View, Pressable, StyleSheet} from 'react-native';
import type {LinkedInParsedDataPositionGroup} from '@models/AccountData/LinkedInModels/LinkedInParsedDataPositionGroup';
import {FontSize} from '@utils/sizes';
import Colors from '@constants/Colors';
import {CustomIcon} from '../icon';
import CustomAvatar from '../Avatar';
import {useSelector} from 'react-redux';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {CustomText} from '@components/Texts';
import {resolveImageAssetSource} from '@utils/utilityFunctions';
import {Orgonization} from '@assets';

const anonymousURI = resolveImageAssetSource(Orgonization).uri;

const monthMap = new Map([
  [1, 'Jan'],
  [2, 'Feb'],
  [3, 'Mar'],
  [4, 'Apr'],
  [5, 'May'],
  [6, 'Jun'],
  [7, 'Jul'],
  [8, 'Aug'],
  [9, 'Sep'],
  [10, 'Oct'],
  [11, 'Nov'],
  [12, 'Dec'],
]);

const styles = StyleSheet.create({
  container: {
    padding: 16,
    borderRadius: 10,
    backgroundColor: Colors.white,
    marginBottom: 10,
  },
  topContainer: {marginRight: 8, alignSelf: 'stretch', flexDirection: 'row'},
  detailsContainer: {
    flex: 1,
    paddingHorizontal: 8,
  },
  flex: {
    flex: 1,
  },
  title: {
    letterSpacing: 0.3,
  },
  companyText: {
    letterSpacing: 0.3,
    marginTop: 4,
  },
  posistionDescriptionContainer: {
    marginTop: 12,
    alignSelf: 'stretch',
  },
  descriptionText: {
    letterSpacing: 0.3,
  },
  yearText: {
    letterSpacing: 0.3,
    marginTop: 2,
  },
});

export const ExperienceDetailsCard = ({
  userId,
  experienceDetail,
  index,
  onPressEdit,
}: {
  userId: string;
  experienceDetail: LinkedInParsedDataPositionGroup;
  index: number;
  onPressEdit?: () => any;
}) => {
  const loggedInUserId = useSelector(userAccountThingIdSelector);
  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <CustomAvatar
          height={36}
          backgroundColor={Colors.lightBackground}
          width={36}
          resizeMode="contain"
          borderRadius={18}
          source={
            experienceDetail.company?.logo
              ? experienceDetail.company?.logo!
              : anonymousURI
          }
        />

        <View style={styles.detailsContainer}>
          <View style={styles.flex}>
            <CustomText
              size={FontSize.title}
              color={'text'}
              style={styles.title}>
              {experienceDetail.profile_positions![0].title!}
            </CustomText>

            <CustomText color={'lightText'} style={styles.companyText}>
              {experienceDetail.profile_positions![0].company!}
            </CustomText>
            <CustomText color={'veryLightText'} style={styles.yearText}>
              {experienceDetail.profile_positions![0].date?.start!.month &&
              experienceDetail.profile_positions![0].date?.start!.year
                ? monthMap.get(
                    experienceDetail.profile_positions![0].date?.start?.month!,
                  ) +
                  ' ' +
                  experienceDetail.profile_positions![0].date?.start!.year!
                : experienceDetail.profile_positions![0].date?.start!.year!
                ? experienceDetail.profile_positions![0].date?.start!.year!
                : ' '}{' '}
              -{' '}
              {experienceDetail.profile_positions![0].date?.end!.month &&
              experienceDetail.profile_positions![0].date?.end!.year
                ? monthMap.get(
                    experienceDetail.profile_positions![0].date?.end?.month!,
                  ) +
                  ' ' +
                  experienceDetail.profile_positions![0].date?.end!.year!
                : experienceDetail.profile_positions![0].date?.end!.year!
                ? experienceDetail.profile_positions![0].date?.end!.year!
                : index == 0
                ? 'Present'
                : ' '}
            </CustomText>
          </View>
        </View>
        {loggedInUserId == userId && (
          <Pressable onPress={onPressEdit}>
            <CustomIcon size={15} name="edit" color={Colors.lightText} />
          </Pressable>
        )}
      </View>
      {experienceDetail.profile_positions?.[0]?.description ? (
        <View style={styles.posistionDescriptionContainer}>
          <CustomText color={'text'} style={styles.descriptionText}>
            {experienceDetail.profile_positions![0].description}
          </CustomText>
        </View>
      ) : null}
    </View>
  );
};
