import type {RootState} from '@redux/store';

/**
 * selector which gives back postLabels array
 */
export const createPostLabelSelector = (state: RootState) =>
  !!state.metaData.data ? state.metaData.data.flairs : [];
/**
 * selector which gives back item with respect to thing_id passed oin the function
 */
export const communityAvatarColorSelector =
  (communityId: string) => (state: RootState) => {
    return state.metaData.communityAvatarColorMap?.[communityId];
  };

export const communityAvatarUrlSelector =
  (communityId: string) => (state: RootState) => {
    return state.metaData.communityAvatarMap?.[communityId];
  };

export const communitiesSelector = (state: RootState) => {
  return !!state.metaData.data ? state.metaData.data?.community : [];
};

export const createCommunitySelector =
  (communityId: string) => (state: RootState) => {
    return state.metaData.communities[communityId];
  };

export const postLabelSelector = (state: RootState) =>
  state.metaData.data?.flairs || [];

export const organisationDataSelector = (state: RootState) =>
  state.metaData.organisation;
