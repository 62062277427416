import {createIconSetFromIcoMoon} from 'react-native-vector-icons';
import icoMoonConfig from '../selection.json';

// We use the IcoMoon app (https://icomoon.io) to generate a custom font made up
// of SVG icons. The actual font file is loaded up-front in src/index.js.
export const CustomIcon = createIconSetFromIcoMoon(icoMoonConfig);

export enum Icons {
  Comments = 'chat',
  CheckMark = 'check',
  Download = 'download',
  Forward = 'forward',
  Home = 'home',
  Chats = 'message-chat-circle',
  MoreHoriz = 'more-horiz',
  Notifications = 'notifications',
  Profile = 'profile',
  ShareNew = 'share-new',
  Spaces = 'spaces',
  VoteDown = 'vote-down-1',
  VoteDownFilled = 'vote-down-filled',
  VoteUp = 'vote-down',
  VoteUpFilled = 'vote-up-filled',
  PinBig = 'pin-big',
  Info = 'info',
  Message = 'message',
  More = 'more',
  PinSmall = 'pin-small',
  Calender = 'calender',
  Bell = 'bell',
  Edit = 'edit',
  Camera = 'recycle-camera',
  Remove = 'remove',
  ProfileSelect = 'profile-select',
  Reply = 'reply',
  Jobs = 'jobs-deselect',
  Pencil = 'edit-icon',
}
