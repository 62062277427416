import React from 'react';
import {View, StyleSheet, Platform} from 'react-native';
import {
  type StackNavigationOptions,
  TransitionPresets,
} from '@react-navigation/stack';
import {
  type LinkingOptions,
  type PathConfigMap,
} from '@react-navigation/native';
import {type BottomTabNavigationOptions} from '@react-navigation/bottom-tabs';
import {CustomIcon, Icons} from '@components/icon';
import Icon from 'react-native-vector-icons/Ionicons';
import {VerySmallText} from '@components/Texts';
import {unreadNotificationCountSelector} from '@redux/selectors/notificationSelector';
import {useSelector} from '@redux/store';
import {FontFamily} from '@utils/sizes';

import {
  AppScreens,
  AuthScreens,
  CommunityTabs,
  MainScreens,
  TabScreens,
} from './ScreenNames';
import Colors from '@constants/Colors';

const styles = StyleSheet.create({
  unreadCountCircle: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: '#9B51E0',
    top: -5,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 9,
    position: 'absolute',
    left: 10,
  },
});

// type StackOptions =
//   | StackNavigationOptions
//   | ((props: {
//       route: RouteProp<ParamListBase, MainScreens.WelcomeScreen>;
//       navigation: any;
//     }) => StackNavigationOptions)
//   | undefined;

export const mainNavigatorOptions: StackNavigationOptions = {
  headerShown: false,
  ...TransitionPresets.SlideFromRightIOS,
  animationEnabled: Platform.OS !== 'web',
};

export const authNavigatorOptions: StackNavigationOptions = {
  headerShown: false,
  title: 'Wyse',
  ...TransitionPresets.SlideFromRightIOS,
  animationEnabled: Platform.OS !== 'web',
};

export const appNavigatorOptions: StackNavigationOptions = {
  headerShown: false,
  title: 'Wyse',
  ...TransitionPresets.SlideFromRightIOS,
  animationEnabled: Platform.OS !== 'web',
  cardStyle: {
    width: '100%',
    flex: 1,
  },
};

export const mainScreenOptions: StackNavigationOptions = {
  headerShown: false,
  cardStyle: {backgroundColor: '#F9F9F9'},

  title: 'Wyse',
};

export const welcomeScreenOptions: StackNavigationOptions = {
  cardStyle: {backgroundColor: '#F9F9F9'},

  title: 'Wyse',
};

export const otpScreenOptions: StackNavigationOptions = {
  cardStyle: {backgroundColor: '#F9F9F9'},

  title: 'Wyse',
};

export const linkedInImportScreen: StackNavigationOptions = {
  cardStyle: {backgroundColor: '#F9F9F9'},

  title: 'Wyse',
};

export const manulaImportScreen: StackNavigationOptions = {
  title: 'Wyse',
};

export const wyseGreetingScreen: StackNavigationOptions = {
  title: 'Wyse',
};

export const profilePicUploadScreen: StackNavigationOptions = {
  cardStyle: {backgroundColor: '#F9F9F9'},

  title: 'Wyse',
};

export const reviewScreen: StackNavigationOptions = {
  cardStyle: {backgroundColor: '#F9F9F9'},

  title: 'Wyse',
};

// export const selectInterestScreen: StackNavigationOptions = {
//   cardStyle: {backgroundColor: '#F9F9F9'},
// };

export const selectSpacesSignupScreen: StackNavigationOptions = {
  cardStyle: {backgroundColor: '#F9F9F9'},

  title: 'Wyse',
};

export const welcomeOnboardScreen: StackNavigationOptions = {
  cardStyle: {backgroundColor: '#F9F9F9'},

  title: 'Wyse',
};

export const tabNavigatorOptions: BottomTabNavigationOptions = {
  headerShown: false,
  lazy: true,
  freezeOnBlur: true,

  tabBarStyle: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    shadowColor: '#000',
    shadowOffset: {width: 1, height: 4},
    shadowOpacity: 0.8,
    shadowRadius: 0,
    elevation: 5,
  },
  tabBarLabelStyle: {
    fontFamily: Platform.OS == 'web' ? 'Figtree' : FontFamily.FigtreeSemiBold,
    fontWeight: Platform.OS == 'web' ? '600' : undefined,
  },
  tabBarInactiveTintColor: Colors.gray,
  tabBarActiveTintColor: Colors.primary,
};

export const homeTabOptions: BottomTabNavigationOptions = {
  tabBarLabel: 'Home',
  title: 'Wyse',
  tabBarIcon: ({focused}) => {
    return (
      <CustomIcon
        size={20}
        name={Icons.Home}
        color={focused ? Colors.primary : Colors.gray}
      />
    );
  },
};

export const spacesTabOptions: BottomTabNavigationOptions = {
  tabBarLabel: 'Spaces',
  title: 'Wyse',
  tabBarIcon: ({focused}) => {
    return (
      <CustomIcon
        size={20}
        name={Icons.Spaces}
        color={focused ? Colors.primary : Colors.gray}
      />
    );
  },
};

export const createPostTabOptions: BottomTabNavigationOptions = {
  tabBarLabel: 'Post',
  title: 'Wyse',
  tabBarIcon: ({focused}) => {
    return (
      <CustomIcon
        size={20}
        name={focused ? 'create-select' : 'post-deselect'}
        color={focused ? '#9B51E0' : '#4F4F4F'}
        style={{
          marginBottom: -5,
        }}
      />
    );
  },
  tabBarHideOnKeyboard: true,
};

export const profileTabOptions: BottomTabNavigationOptions = {
  tabBarLabel: 'Profile',
  title: 'Wyse',
  tabBarIcon: ({focused}) => {
    return (
      <CustomIcon
        size={20}
        name={Icons.Profile}
        color={focused ? Colors.primary : Colors.gray}
        style={{
          marginBottom: -5,
        }}
      />
    );
  },
  tabBarHideOnKeyboard: true,
};

const NotificationTabIcon = ({focused}: {focused: boolean}) => {
  const unreadCount = useSelector(unreadNotificationCountSelector);
  return (
    <View>
      <View
        style={[
          styles.unreadCountCircle,
          {display: unreadCount <= 0 ? 'none' : 'flex'},
        ]}>
        <VerySmallText fontFamily={FontFamily.lato_regular} color="white">
          {unreadCount}
        </VerySmallText>
      </View>
      <Icon
        size={20}
        name={Icons.Notifications}
        color={focused ? Colors.primary : Colors.gray}
      />
    </View>
  );
};

export const notificationTabOptions: BottomTabNavigationOptions = {
  tabBarLabel: 'Notification',
  title: 'Wyse',
  tabBarIcon: ({focused}) => {
    return <NotificationTabIcon focused={focused} />;
  },
};
const config: {
  initialRouteName?: undefined;
  screens: PathConfigMap<ReactNavigation.RootParamList>;
} = {
  screens: {
    [MainScreens.AppOpenScreen]: {
      path: '/',
    },
    [MainScreens.Auth]: {
      screens: {
        [AuthScreens.WelcomeScreen]: {
          path: '/welcome',
        },
        [AuthScreens.OtpScreen]: {
          path: '/signup',
        },
        [AuthScreens.WyseGreetingScreen]: {
          path: '/greeting',
        },
        [AuthScreens.LinkedinImportScreen]: {
          path: '/import/linkedin',
        },
        [AuthScreens.ManualImportScreen]: {
          path: '/import/manual',
        },
        [AuthScreens.ProfilePicUploadScreen]: {
          path: '/profile/pic/upload',
        },
        [AuthScreens.SelectSpacesSignupScreen]: {
          path: '/spaces/select',
        },
        [AuthScreens.WelcomeOnboard]: {
          path: '/onboard',
        },
      },
    },
    [MainScreens.App]: {
      screens: {
        [AppScreens.Tabs]: {
          screens: {
            [TabScreens.Home]: {
              path: 'posts',
            },
            [TabScreens.Spaces]: {
              path: 'spaces',
            },
            [TabScreens.Post]: {
              path: 'posts/create',
            },
            [TabScreens.Notification]: {
              path: 'notifications',
            },
            [TabScreens.Profile]: {
              path: 'users/:profileThingId/:tab',
            },
          },
        },
        [AppScreens.FeedDetail]: {
          path: 'posts/:feedThingId',
        },
        [AppScreens.Chat]: {
          path: 'users/:reciverId/chat/:conversationId',
          stringify: {
            conversation: data => JSON.stringify(data),
          },
          parse: {
            conversation: data => JSON.parse(data),
          },
        },
        [AppScreens.CommunityDetail]: {
          screens: {
            [CommunityTabs.Feed]: {
              path: 'spaces/:communityThingId/posts',
            },
            [CommunityTabs.Members]: {
              path: 'spaces/:communityThingId/members',
            },
          },
        },
        [AppScreens.Conversations]: {
          path: 'chat',
        },
        [AppScreens.Profile]: {
          path: 'profile/:profileThingId',
        },
      },
    },
  },
};

export const linking: LinkingOptions<ReactNavigation.RootParamList> = {
  prefixes: [
    'http://localhost:8080/',
    'http://127.0.0.1:5501/',
    'https://wyse.page.link/',
    'com.wyse.app://',
    /* your linking prefixes */
  ],
  // async getInitialURL(): Promise<string> {
  //   // Check if the app was opened by a deep link
  //   const url = await Linking.getInitialURL();
  //   const dynamicLinkUrl = await dynamicLinks().getInitialLink();
  //   console.log('dynamicLinkUrl', dynamicLinkUrl);

  //   if (dynamicLinkUrl) {
  //     const newUrl =
  //       Platform.OS == 'web'
  //         ? dynamicLinkUrl.url
  //         : dynamicLinkUrl.url.replace('https://bewyse.in/', 'com.wyse.app://');
  //     console.log('newUrl', newUrl);
  //     return newUrl;
  //   }
  //   if (url) {
  //     return url;
  //   }
  //   // If it was not opened by a deep link, go to the home screen
  //   return 'com.wyse.app://';
  // },
  config,
};
