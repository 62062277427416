/*eslint-disable */
import {View, StyleSheet, Pressable} from 'react-native';
import React from 'react';
import CustomAvatar from '@components/Avatar';
import {useSelector, dispatch} from '@redux/store';
import {
  notificationbyIdSelector,
  notificationReadStatusByIdSelector,
} from '@redux/selectors/notificationSelector';
import {markAsRead} from '@redux/slices/notificationSlice';
import {NotificationType} from '@constants/constantsEnum';
import {TitleThinText, SmallThinText, CustomText} from '@components/Texts';
import {timeago} from '@utils/utilityFunctions';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {resolveAssetSource} from '@utils/image-web';
import Colors from '@constants/Colors';
import {onNotificationClick} from '@utils/utilityFunctions';
import {setRefreshFeedDetailsOnNotification} from '@redux/slices/notificationSlice';
import {FontFamily, FontSize} from '@utils/sizes';
import {AnonIncognito, ProfileUunvailableAvatar} from '@assets';
const randomUserURI = resolveAssetSource(ProfileUunvailableAvatar).uri;
const anonymousURI = resolveAssetSource(AnonIncognito).uri;

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingVertical: 12,
    marginVertical: 1,
    alignSelf: 'stretch',
  },
  innerContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  avatarContainer: {
    borderRadius: 56,
    overflow: 'hidden',
    marginRight: 8,
  },
  notificationBodyContainer: {
    flex: 1,
  },
});

const NotificationCard = ({notificationId}: {notificationId: string}) => {
  const notification = useSelector(notificationbyIdSelector(notificationId));
  const isRead = useSelector(
    notificationReadStatusByIdSelector(notificationId),
  );

  let notificationBody = '';

  switch (notification.type) {
    case NotificationType.CommPostNew:
      notificationBody = `${notification.data.author_name} has posted in ${notification.data.community_name}: \n${notification.data.post_heading}`;
      break;
    case NotificationType.PostUpvote:
      notificationBody = `Your post recieved 3 upvotes: \n${notification.data.post_heading}`;
      break;
    case NotificationType.PostCommentNew:
      notificationBody = `${notification.data.author_name} has commented on your post: \n${notification.data.post_heading}`;
      break;
    case NotificationType.PostCommentReply:
      notificationBody = `${notification.data.author_name} has replied to your comment on the post: \n${notification.data.post_heading}`;
      break;
    case NotificationType.CommentUpvote:
      notificationBody = `Your comment has recieved 2 upvotes: \n${notification.data.comment_text}`;
      break;
    case NotificationType.ReplyUpvote:
      notificationBody = `Your reply has recieved 2 upvotes: \n${notification.data.reply_text}`;
      break;
    case NotificationType.WelcomePost:
      notificationBody = `Nishant Posted in Wyse Updates.\n Welcome to a democratised professional networking & discussion platform`;
      break;
    default:
      notificationBody = '';
      break;
  }

  const handlePress = () => {
    dispatch(markAsRead(notification.notification_id));
    onNotificationClick(notification);
    dispatch(setRefreshFeedDetailsOnNotification(true));
  };

  return (
    <Pressable
      style={[
        styles.container,
        {backgroundColor: isRead ? '#FFFFFF' : '#f2e8fb'},
      ]}
      onPress={handlePress}>
      <View style={styles.innerContainer}>
        <View
          style={{
            justifyContent: 'center',
            marginRight: 4,
            width: 11,
          }}>
          <FontAwesomeIcon
            icon={faCircle}
            color={Colors.primary}
            size={7}
            style={{display: isRead ? 'none' : 'flex'}}
          />
        </View>
        <View style={styles.avatarContainer}>
          <CustomAvatar
            height={36}
            width={36}
            style={{flex: 1}}
            source={
              notification.data.author_name === 'Someone'
                ? anonymousURI
                : notification.avatar || randomUserURI
            }
          />
        </View>
        <View style={styles.notificationBodyContainer}>
          <CustomText
            color="text"
            size={FontSize.title}
            numberOfLines={2}
            ellipsizeMode="tail">
            {notificationBody}
          </CustomText>
        </View>
        <View style={{marginLeft: 4}}>
          <CustomText size={FontSize.small} color="lightText">
            {timeago(notification.created_at)}
          </CustomText>
        </View>
      </View>
    </Pressable>
  );
};

export default NotificationCard;
