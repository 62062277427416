/* eslint-disable */
import React, {useEffect, useState} from 'react';
import type {FC} from 'react';
import type {Conversation, Request} from '@models/Conversation';
import {createContext, useContextSelector} from 'use-context-selector';
import FirestoreService from '@services/firestoreService';
import {useSelector} from 'react-redux';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {BackHandler, Platform} from 'react-native';

type ConversationState = {
  isLoading: boolean;
  id?: string;
  isLoadingPedingConversation: boolean;
  isLoadingConversations: boolean;
  pendingConversations: Request[];
  conversations: Conversation[];
  pendingConversationsError?: string;
  conversationsError?: string;
  requestPageVisible: boolean;
  str: string;
};

const ConversationContext = createContext<
  [ConversationState, React.Dispatch<React.SetStateAction<ConversationState>>]
>(null as any);

export const ConversationStateProvider: FC<{
  children?: any;
}> = ({children}) => {
  const userThingId = useSelector(userAccountThingIdSelector);
  const [state, setState] = useState<ConversationState>({
    isLoading: true,
    requestPageVisible: false,
    isLoadingPedingConversation: true,
    isLoadingConversations: true,
    pendingConversations: [],
    conversations: [],
    str: '',
  });

  useEffect(() => {
    if (state.requestPageVisible && Platform.OS !== 'web') {
      const lisneter = BackHandler.addEventListener('hardwareBackPress', () => {
        setState(st => ({...st, requestPageVisible: false}));
        return true;
      });
      return lisneter.remove;
    }
  }, [state.requestPageVisible]);

  useEffect(() => {
    if (!userThingId) return;
    (async () => {
      try {
        const id = await FirestoreService.getUserDocId(userThingId);
        console.log('useEffectid', id);
        setState(st => ({...st, isLoading: false, id}));
      } catch (error) {
        console.log('useEffect error', error);
      }
    })();
  }, [userThingId]);

  useEffect(() => {
    if (!!state.id) {
      const unsubscribe = FirestoreService.onConversationChange(
        state.id,
        conversations => {
          setState(st => ({
            ...st,
            isLoadingConversations: false,
            conversations,
          }));
        },
      );
      return unsubscribe;
    }
  }, [!!state.id]);

  useEffect(() => {
    if (!!state.id) {
      const unsubscribe = FirestoreService.onConversationRequestChange(
        state.id,
        pendingConversations => {
          setState(st => ({
            ...st,
            isLoadingPedingConversation: false,
            pendingConversations,
          }));
        },
      );
      return unsubscribe;
    }
  }, [!!state.id]);

  return (
    <ConversationContext.Provider value={[state, setState]}>
      {children}
    </ConversationContext.Provider>
  );
};

export const useLoading = () =>
  useContextSelector(ConversationContext, v => v[0].isLoading);

/**
 * Request Conversations
 */
export const usePendingConversationsLoading = () =>
  useContextSelector(
    ConversationContext,
    v => v[0].isLoadingPedingConversation,
  );

export const usePenginConversations = () =>
  useContextSelector(ConversationContext, v => v[0].pendingConversations);

export const usePendingConversationsCount = () =>
  useContextSelector(
    ConversationContext,
    v => v[0].pendingConversations.length,
  );

export const useRequestPageVisiblity = () =>
  useContextSelector(ConversationContext, v => v[0].requestPageVisible);

/**
 * Conversations
 */
export const useConversations = () =>
  useContextSelector(ConversationContext, v => {
    const search = v[0].str.toLowerCase();
    const data = v[0].conversations;
    if (search.length === 0) return data;
    return data.filter(item =>
      item.user_fullname.toLowerCase().includes(search),
    );
  });

export const useConversationsLoading = () =>
  useContextSelector(ConversationContext, v => v[0].isLoadingConversations);

export const useSetState = () =>
  useContextSelector(ConversationContext, v => v[1]);
