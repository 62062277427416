/*eslint-disable */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import ConstantsEnum, {
  ApiStatusEnum,
  FeedCardBodyTypeEnum,
} from '@constants/constantsEnum';
import type {FeedData} from '@models/Feed/FeedData';
import type {CommentData} from '@models/Comment/CommentData';
import {createTextComment, getComments} from '@redux/actions/comments';
import {
  createPost,
  createPostEvent,
  fetchFeedDataByThingId,
  fetchFeedsList,
  fetchFeedsListByLabel,
  // fetchFeedsListPostedByUser,
} from '@redux/actions/feed';
import {UpVotePollPayload} from '@models/Feed/UpVotePollPayload';
import {FeedPollVotes} from '@models/Poll/FeedPollDict';
import {UserPollVotes} from '@models/AccountData/AccountData';
import {deletePostByThingId} from '@redux/actions/feed';
import {DeletePostPayload} from '@models/Feed/DeletePostPayload';
import {createTextCommentPending} from './commentSlice';

export type FeedDetailsState = {
  postId: string;
  commentPage: number;
  status: ApiStatusEnum;
  error?: any;
  commentStatus: ApiStatusEnum;
  totalCommentPages?: number;
};

export type VoteType = 'up' | 'down' | undefined;

export interface Feed {
  feedDataIds: string[];
  upVotes: Record<string, number>;
  downVotes: Record<string, number>;
  /** mapping btw feedId and options votes */
  pollVotes: Record<string, FeedPollVotes>;
  commentsCounts: Record<string, number>;
  replyCounts: Record<string, number>;
  // userFeedIds: string[];
  feeds: Record<string, FeedData>;
  commentsIds: Record<string, string[]>;
  feedDetails: Record<string, FeedDetailsState>;
  userUpVotesIds: Record<string, boolean>;
  userDownVotesIds: Record<string, boolean>;
  voteTypes: Record<string, VoteType>;
  userPollVotes: UserPollVotes;

  comments: Record<string, CommentData>;
  fetchedFeedDataByThingId?: FeedData | null;
  selectedFlair?: string | null;
  feedFilterModalVisible: boolean;
  status: ApiStatusEnum;
  feedPostedByUser?: FeedData[] | null;
  // feedPostedByUserStatus: ApiStatusEnum;
  fetchedFeedDataByThingIdStatus: string;
  fetchedFeedDataByThingIdError: any;
  fetchFeedDataError: any;
  page: number;
  // userPage: number;
  createPostStatus: ApiStatusEnum;
  createTextPostStatus: ApiStatusEnum;
  updateImageInPostStatus: ApiStatusEnum;
  latestCreatedPost?: FeedData | null;
  createPostError: any;
  createPostEventStatus: ApiStatusEnum;
  totalNumberOfPagesInFeed: number;
  deletePostByThingIdStatus: ApiStatusEnum;
  // numPagesInFeedPostedByUser: number;
}

const createPageState = (thindId: string): FeedDetailsState => ({
  postId: thindId,
  commentPage: 0,
  status: ApiStatusEnum.IDLE,
  commentStatus: ApiStatusEnum.IDLE,
});

const initialState: Feed = {
  feedDataIds: [],
  upVotes: {},
  downVotes: {},
  pollVotes: {},
  commentsCounts: {},
  replyCounts: {},
  // userFeedIds: [],
  commentsIds: {},
  feedDetails: {},
  feeds: {},
  userUpVotesIds: {},
  userDownVotesIds: {},
  voteTypes: {},
  userPollVotes: {},
  comments: {},

  selectedFlair: null,
  feedFilterModalVisible: false,
  status: ApiStatusEnum.IDLE,
  feedPostedByUser: null,
  // feedPostedByUserStatus: ApiStatusEnum.IDLE,
  fetchedFeedDataByThingIdStatus: ApiStatusEnum.IDLE,
  fetchFeedDataError: null,
  fetchedFeedDataByThingIdError: null,
  page: 0,
  // userPage: 0,
  createPostStatus: ApiStatusEnum.IDLE,
  createTextPostStatus: ApiStatusEnum.IDLE,
  updateImageInPostStatus: ApiStatusEnum.IDLE,
  latestCreatedPost: null,
  createPostError: null,
  createPostEventStatus: ApiStatusEnum.IDLE,
  totalNumberOfPagesInFeed: 0,
  deletePostByThingIdStatus: ApiStatusEnum.IDLE,
  // numPagesInFeedPostedByUser: 0,
};

export const feedSlice = createSlice({
  name: 'feed',
  initialState: initialState,
  reducers: {
    initFeedDetailsPage: (state, action: PayloadAction<string>) => {
      const thingId = action.payload;
      // TODO: create page state for thing id if it does not exists
      if (!state.feedDetails[thingId]) {
        state.feedDetails[thingId] = createPageState(thingId);
      }
    },
    removeFeedDetailsPage: (state, action: PayloadAction<string>) => {
      // TODO: remove page state fot thing id
      delete state.feedDetails[action.payload];
    },
    setCommentsPage: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const pageState = state.feedDetails[id];
      if (pageState.commentPage + 1 < (pageState.totalCommentPages || 0)) {
        pageState.commentPage = pageState.commentPage + 1;
      }
    },

    updateCommentCountById: (state, action: PayloadAction<string>) => {
      // TODO: update the comment count by 1 for the comment
      state.commentsCounts[action.payload] =
        state.commentsCounts[action.payload] + 1;
    },
    addUpVote: (state, action: PayloadAction<string>) => {
      const {upVotes, voteTypes, userUpVotesIds, userDownVotesIds} = state;
      const id = action.payload;

      userUpVotesIds[id] = true;
      voteTypes[id] = 'up';
      if (userDownVotesIds[id]) {
        delete userDownVotesIds[id];
        upVotes[id] = upVotes[id] + 2;
      } else {
        upVotes[id] = upVotes[id] + 1;
      }
    },
    addDownVote: (state, action: PayloadAction<string>) => {
      const {downVotes, voteTypes, userUpVotesIds, userDownVotesIds} = state;
      const id = action.payload;

      voteTypes[id] = 'down';
      userDownVotesIds[id] = true;
      if (userUpVotesIds[id]) {
        delete userUpVotesIds[id];
        downVotes[id] = downVotes[id] - 2;
      } else {
        downVotes[id] = downVotes[id] - 1;
      }
    },
    addPollVotes: (state, action: PayloadAction<UpVotePollPayload>) => {
      const {pollVotes, userPollVotes} = state;
      const votePollPayload = action.payload;
      pollVotes[votePollPayload.thing_id][votePollPayload.option_id] =
        pollVotes[votePollPayload.thing_id][votePollPayload.option_id] + 1;
      userPollVotes[votePollPayload.thing_id] = votePollPayload.option_id;
    },
    setFeedPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    // setUserFeedPage: (state, action: PayloadAction<number>) => {
    //   state.userPage = action.payload;
    // },
    resetFeedList: state => {
      state.page = 0;
      state.feedDataIds = [];
      state.totalNumberOfPagesInFeed = 0;
    },
    // resetUserFeedList: state => {
    //   state.userPage = 0;
    //   state.userFeedIds = [];
    //   state.numPagesInFeedPostedByUser = 0;
    // },
    resetFeedListWithFlair: (state, action: PayloadAction<string | null>) => {
      state.page = 0;
      state.feedDataIds = [];
      state.totalNumberOfPagesInFeed = 0;
      state.selectedFlair = action.payload;
    },
    setSelectedFlair: (state, action: PayloadAction<string>) => {
      state.selectedFlair = action.payload;
    },
    setFeedFilterModalVisiblity: (state, action: PayloadAction<boolean>) => {
      state.feedFilterModalVisible = action.payload;
    },
    updateUserVotes: (
      state,
      action: PayloadAction<{t_ups: string[] | null; t_downs: string[] | null}>,
    ) => {
      // TODO: add/update user upvotes/downvotes ids
      action.payload?.t_ups?.forEach(up => {
        state.userUpVotesIds[up] = true;
      });
      action.payload?.t_downs?.forEach(down => {
        state.userDownVotesIds[down] = true;
      });
    },
    //add/update user poll votes
    updateUserPollVotes: (state, action: PayloadAction<UserPollVotes>) => {
      state.userPollVotes = action.payload;
    },
    /**
     * FeedData for all Screens in the app is centralized in feeds
     * feed states like commentsCount, upvotes, downvotes etc.
     */
    addFeedsFromProfile: (state, action: PayloadAction<FeedData[]>) => {
      const {feeds, upVotes, downVotes, commentsCounts, pollVotes} = state;

      for (let i = 0; i < action.payload.length; i++) {
        const post = action.payload[i];
        const {thing_id, ups, downs, num_comments, data} = post;

        // TODO: add feed details to feeds
        feeds[thing_id] = post;

        // TODO: add/update up votes count
        upVotes[thing_id] = ups;

        // TODO: add/update down votes count
        downVotes[thing_id] = downs;

        // TODO: add/update vote type accordingly
        state.voteTypes[thing_id] = state.userUpVotesIds[thing_id]
          ? 'up'
          : state.userDownVotesIds[thing_id]
          ? 'down'
          : undefined;

        // TODO: add/update comments count
        commentsCounts[thing_id] = num_comments;
        /**
         * Update/Add Poll Votes in Feed containing Polls
         */
        data.forEach(d => {
          if (d.key == FeedCardBodyTypeEnum.POLL) {
            /** For poll type feed, dict will be present*/
            pollVotes[thing_id] = d.dict!.votes;
          }
        });
      }
    },
    /**
     * FeedData for all Screens in the app is centralized in feeds
     * feed states like commentsCount, upvotes, downvotes etc.
     */
    addFeedsFromCommunity: (state, action: PayloadAction<FeedData[]>) => {
      const {feeds, upVotes, downVotes, commentsCounts, pollVotes} = state;

      for (let i = 0; i < action.payload.length; i++) {
        const post = action.payload[i];
        const {thing_id, ups, downs, num_comments, data} = post;

        // TODO: add feed details to feeds
        feeds[thing_id] = post;

        // TODO: add/update up votes count
        upVotes[thing_id] = ups;

        // TODO: add/update down votes count
        downVotes[thing_id] = downs;

        // TODO: add/update vote type accordingly
        state.voteTypes[thing_id] = state.userUpVotesIds[thing_id]
          ? 'up'
          : state.userDownVotesIds[thing_id]
          ? 'down'
          : undefined;

        // TODO: add/update comments count
        commentsCounts[thing_id] = num_comments;
        /**
         * Update/Add Poll Votes in Feed containing Polls
         */
        data.forEach(d => {
          if (d.key == FeedCardBodyTypeEnum.POLL) {
            //for poll type feed, dict will be present
            pollVotes[thing_id] = d.dict!.votes;
          }
        });
      }
    },
    deletePostFromFeed: (state, action: PayloadAction<DeletePostPayload>) => {
      const {thing_id} = action.payload;
      delete state.feeds[thing_id];
      state.feedDataIds = state.feedDataIds.filter(id => id !== thing_id);
    },

    setDeletePostStatusIdle: state => {
      state.deletePostByThingIdStatus = ApiStatusEnum.IDLE;
    },
    /**
     * TODO - ANURAG - Action to be written in extra reducers as per new statemangement implementation
     */
    // deletePostFulfullied: (state, action: PayloadAction<DeletePostPayload>) => {
    //   let updatedFeedData: FeedData[] = JSON.parse(
    //     JSON.stringify(state.feedData),
    //   );
    //   state.feedData = updatedFeedData.filter(
    //     feed => feed.thing_id !== action.payload.thing_id,
    //   );
    // },
  },
  /**
   * mapping thunck actions to reducer so that they can manage and update the state
   * each thunk actions gives us 3 states
   * 1. action.pending -> this indicates that the action is fired and its in loading state
   * 2. action.fulfilled -> this indicates that the action has responseded with data and do the correspoding state manupulations
   *    to make thisbwork we should use try catch block inside the thunk action and always return some values
   * 3. action.rejected -> this indicates the action has ended with some error
   *    to make this work wenever error happents use rejectwithvalues function and return it with appropriate message
   */
  extraReducers: builder => {
    /**
     *
     * fetchFeedDataByThingId
     */
    builder.addCase(fetchFeedDataByThingId.pending, (state, action) => {
      const thingId = action.meta.arg;
      // TODO: if state does not exists create a state
      if (!state.feedDetails[thingId]) {
        state.feedDetails[thingId] = createPageState(thingId);
      }
      const pageState = state.feedDetails[thingId];
      // TODO: update status of the coresponding page
      pageState.status = ApiStatusEnum.LOADING;
    });
    builder.addCase(fetchFeedDataByThingId.fulfilled, (state, action) => {
      const thingId = action.meta.arg;
      const feed = action.payload;
      const pageState = state.feedDetails[thingId];
      const feeds = state.feeds;
      // TODO: store the feed in feeds Record with proper id
      feeds[thingId] = feed;
      // TODO: store up and down vote ids
      state.upVotes[thingId] = feed.ups;
      state.downVotes[thingId] = feed.downs;
      // TODO: store vote type accordingly
      state.voteTypes[thingId] = state.userUpVotesIds[thingId]
        ? 'up'
        : state.userDownVotesIds[thingId]
        ? 'down'
        : undefined;
      // TODO: store comments count
      state.commentsCounts[thingId] = feed.num_comments;
      /**
       * Update/Add Poll Votes in Feed containing Polls
       */
      feed.data.forEach(d => {
        if (d.key == FeedCardBodyTypeEnum.POLL) {
          //for poll type feed, dict will be present
          state.pollVotes[thingId] = d.dict!.votes;
        }
      });
      // TODO: update the status of coresponding state
      pageState.status = ApiStatusEnum.SUCCEEDED;
    });
    builder.addCase(fetchFeedDataByThingId.rejected, (state, action) => {
      const thingId = action.meta.arg;
      const pageState = state.feedDetails[thingId];
      // TODO: update the status of coresponding state
      pageState.status = ApiStatusEnum.FAILED;
      pageState.error = action.payload as string;
    });

    /**
     *
     * fetchFeedsList
     *  */
    builder.addCase(fetchFeedsList.pending, state => {
      // TODO: update status
      state.status = ApiStatusEnum.LOADING;
    });
    builder.addCase(fetchFeedsList.fulfilled, (state, action) => {
      // TODO: update status
      state.status = ApiStatusEnum.SUCCEEDED;

      const {
        payload: {results},
        meta: {
          arg: {page},
        },
      } = action;

      const {feeds, upVotes, downVotes, commentsCounts, pollVotes} = state;

      if (page == 0) {
        state.feedDataIds = [];
      }

      for (let i = 0; i < results.length; i++) {
        const post = results[i];
        const {thing_id, ups, downs, num_comments, data} = post;

        // TODO: add feed details to feeds
        feeds[thing_id] = post;

        // TODO: add/update up votes count
        upVotes[thing_id] = ups;

        // TODO: add/update down votes count
        downVotes[thing_id] = downs;

        // TODO: add/update vote type accordingly
        state.voteTypes[thing_id] = state.userUpVotesIds[thing_id]
          ? 'up'
          : state.userDownVotesIds[thing_id]
          ? 'down'
          : undefined;

        // TODO: add/update comments count
        commentsCounts[thing_id] = num_comments;
        /**
         * Update/Add Poll Votes in Feed containing Polls
         */
        data.forEach(d => {
          if (d.key == FeedCardBodyTypeEnum.POLL) {
            //for poll type feed, dict will be present
            pollVotes[thing_id] = d.dict!.votes;
          }
        });
        // TODO: update feed ids ids array
        state.feedDataIds.push(thing_id);
      }
      state.totalNumberOfPagesInFeed = action.payload.num_pages;
    });
    builder.addCase(fetchFeedsList.rejected, state => {
      // TODO: update status
      state.status = ApiStatusEnum.FAILED;
    });

    /**
     * fetchFeedsListPostedByUser
     */
    // builder.addCase(fetchFeedsListPostedByUser.pending, state => {
    //   // TODO: update status
    //   state.feedPostedByUserStatus = ApiStatusEnum.LOADING;
    // });
    // builder.addCase(fetchFeedsListPostedByUser.fulfilled, (state, action) => {
    //   // TODO: update status
    //   state.feedPostedByUserStatus = ApiStatusEnum.SUCCEEDED;

    //   const {
    //     payload: {results},
    //     meta: {
    //       arg: {page, authorId},
    //     },
    //   } = action;

    //   const {feeds, upVotes, downVotes, commentsCounts, pollVotes} = state;
    //   if (page === 0) {
    //     state.userFeedIds = [];
    //   }
    //   for (let i = 0; i < results.length; i++) {
    //     const post = results[i];
    //     const {thing_id, ups, downs, num_comments, data} = post;

    //     // TODO: add feed details to feeds
    //     feeds[thing_id] = post;

    //     // TODO: add/update up votes count
    //     upVotes[thing_id] = ups;

    //     // TODO: add/update down votes count
    //     downVotes[thing_id] = downs;

    //     // TODO: add/update vote type accordingly
    //     state.voteTypes[thing_id] = state.userUpVotesIds[thing_id]
    //       ? 'up'
    //       : state.userDownVotesIds[thing_id]
    //       ? 'down'
    //       : undefined;

    //     // TODO: add/update comments count
    //     commentsCounts[thing_id] = num_comments;

    //     // TODO: update feed ids ids array
    //     state.userFeedIds.push(thing_id);
    //     /**
    //      * Update/Add Poll Votes in Feed containing Polls
    //      */
    //     data.forEach(d => {
    //       if (d.key == FeedCardBodyTypeEnum.POLL) {
    //         //for poll type feed, dict will be present
    //         pollVotes[thing_id] = d.dict!.votes;
    //       }
    //     });
    //     // TODO: update feed ids ids array
    //     state.feedDataIds.push(thing_id);
    //   }
    //   state.numPagesInFeedPostedByUser = action.payload.num_pages;
    // });
    // builder.addCase(fetchFeedsListPostedByUser.rejected, state => {
    //   // TODO: update status
    //   state.feedPostedByUserStatus = ApiStatusEnum.FAILED;
    // });

    /**
     *
     * fetchFeedsListByLabel
     */
    builder.addCase(fetchFeedsListByLabel.pending, state => {
      // TODO: update status
      state.status = ApiStatusEnum.LOADING;
    });
    builder.addCase(fetchFeedsListByLabel.fulfilled, (state, action) => {
      // TODO: update status
      state.status = ApiStatusEnum.SUCCEEDED;

      const {
        payload: {results},
        meta: {
          arg: {page},
        },
      } = action;

      const {feeds, upVotes, downVotes, commentsCounts, pollVotes} = state;
      if (page === 0) {
        state.feedDataIds = [];
      }
      for (let i = 0; i < results.length; i++) {
        const post = results[i];
        const {thing_id, ups, downs, num_comments, data} = post;

        // TODO: add feed details to feeds
        feeds[thing_id] = post;

        // TODO: add/update up votes count
        upVotes[thing_id] = ups;

        // TODO: add/update down votes count
        downVotes[thing_id] = downs;

        // TODO: add/update vote type accordingly
        state.voteTypes[thing_id] = state.userUpVotesIds[thing_id]
          ? 'up'
          : state.userDownVotesIds[thing_id]
          ? 'down'
          : undefined;

        // TODO: add/update comments count
        commentsCounts[thing_id] = num_comments;
        /**
         * Update/Add Poll Votes in Feed containing Polls
         */
        data.forEach(d => {
          if (d.key == FeedCardBodyTypeEnum.POLL) {
            //for poll type feed, dict will be present
            pollVotes[thing_id] = d.dict!.votes;
          }
        });

        // TODO: update feed ids ids array
        state.feedDataIds.push(thing_id);
      }
    });
    builder.addCase(fetchFeedsListByLabel.rejected, state => {
      // TODO: update status
      state.status = ApiStatusEnum.FAILED;
    });

    /**
     *
     * createPost
     */
    builder.addCase(createPost.pending, state => {
      state.createPostStatus = ApiStatusEnum.LOADING;
    });
    builder.addCase(createPost.fulfilled, (state, action) => {
      const {feeds, upVotes, downVotes, commentsCounts, pollVotes} = state;
      const {thing_id, ups, downs, num_comments, data} = action.payload;
      // const authorThingId = action.payload.author_mini.thing_id;

      state.latestCreatedPost = action.payload;
      // TODO: update the feedDataIds array
      state.feedDataIds.unshift(thing_id);

      // TODO: add feed details to feeds
      feeds[thing_id] = action.payload;

      // TODO: add/update up votes count
      upVotes[thing_id] = ups;

      // TODO: add/update down votes count
      downVotes[thing_id] = downs;

      // TODO: add/update vote type accordingly
      state.voteTypes[thing_id] = state.userUpVotesIds[thing_id]
        ? 'up'
        : state.userDownVotesIds[thing_id]
        ? 'down'
        : undefined;

      // TODO: add/update comments count
      commentsCounts[thing_id] = num_comments;
      /**
       * Update/Add Poll Votes in Feed containing Polls
       */
      data.forEach(d => {
        if (d.key == FeedCardBodyTypeEnum.POLL) {
          //for poll type feed, dict will be present
          pollVotes[thing_id] = d.dict!.votes;
        }
      });

      // TODO: update the status
      state.createPostStatus = ApiStatusEnum.SUCCEEDED;
      // state.userFeedIds.push(thing_id);
    });
    builder.addCase(createPost.rejected, state => {
      state.createPostStatus = ApiStatusEnum.FAILED;
    });

    /**
     *
     * createPostEvent
     */
    builder.addCase(createPostEvent.pending, state => {
      // TODO: update status
      state.createPostEventStatus = ApiStatusEnum.LOADING;
    });
    builder.addCase(createPostEvent.fulfilled, state => {
      // TODO: update status
      state.createPostEventStatus = ApiStatusEnum.SUCCEEDED;
    });
    builder.addCase(createPostEvent.rejected, state => {
      // TODO: update status
      state.createPostEventStatus = ApiStatusEnum.FAILED;
    });

    /**
     *
     * getComments
     */
    builder.addCase(getComments.pending, (state, action) => {
      const id = action.meta.arg.postId;
      // TODO: update status
      const feedState = state.feedDetails[id];
      if (feedState) {
        feedState.commentStatus = ApiStatusEnum.LOADING;
      }
    });
    builder.addCase(getComments.fulfilled, (state, action) => {
      const {postId: id, page, data} = action.meta.arg;
      const {num_pages, results} = action.payload;
      const feedState = state.feedDetails[id];

      // TODO: clear the comment ids record if page is 0
      if (!!!state.commentsIds[id] || page == 0) {
        state.commentsIds[id] = [];
      }

      let ids: string[] = [];
      let totalCounts = 0;
      for (let i = 0; i < results.length; i++) {
        totalCounts += 1;
        const comment = results[i];
        const feed_thing_id = comment.parent_thing_id!;
        delete comment.parent_thing_id;
        // TODO: add/update comments record
        state.comments[comment.thing_id] = {...comment, feed_thing_id};

        // TODO: add/update vote counts
        state.upVotes[comment.thing_id] = comment.ups;
        state.downVotes[comment.thing_id] = comment.downs;

        // TODO: add/update vote type
        state.voteTypes[comment.thing_id] = state.userUpVotesIds[
          comment.thing_id
        ]
          ? 'up'
          : state.userDownVotesIds[comment.thing_id]
          ? 'down'
          : undefined;

        // TODO: add/update commentsIds record with id
        ids.push(comment.thing_id);
        // TODO: add/update reply count for each comment
        state.replyCounts[comment.thing_id] = comment.replies.length;
        for (let j = 0; j < comment.replies.length; j++) {
          totalCounts += 1;
          const reply = comment.replies[j];
          // TODO: add/update comments record
          state.comments[reply.thing_id] = {...reply, feed_thing_id};

          // TODO: add/update vote counts
          state.upVotes[reply.thing_id] = reply.ups;
          state.downVotes[reply.thing_id] = reply.downs;

          // TODO: add/update vote type
          state.voteTypes[reply.thing_id] = state.userUpVotesIds[reply.thing_id]
            ? 'up'
            : state.userDownVotesIds[reply.thing_id]
            ? 'down'
            : undefined;
          // TODO: add/update commentsIds record with id
          ids.push(reply.thing_id);
        }
      }
      if (data) {
        state.commentsCounts[id] = totalCounts;
      }
      if (feedState) {
        // TODO: update the total number of pages
        feedState.totalCommentPages = num_pages;
      }
      state.commentsIds[id] = state.commentsIds[id].concat(ids);
      // TODO: update status
      if (feedState) {
        feedState.commentStatus = ApiStatusEnum.SUCCEEDED;
      }
    });
    builder.addCase(getComments.rejected, (state, action) => {
      const feedState = state.feedDetails[action.meta.arg.postId];
      // TODO: update status
      if (feedState) {
        feedState.commentStatus = ApiStatusEnum.FAILED;
      }
    });

    // createTextComment
    builder.addCase(createTextComment.pending, (state, action) => {
      console.log('pending create text comment');
    });

    builder.addCase(createTextComment.fulfilled, (state, action) => {
      const {
        meta: {
          arg: {feedDataId, isCommentReply, createTextCommentPayload},
        },
      } = action;
      const {commentsIds, commentsCounts, feeds} = state;
      const feedData = feeds[feedDataId];
      if (__DEV__) {
        if (feedData.flairs[0] == ConstantsEnum.prodAMAFlairId) {
          return;
        }
      } else {
        if (feedData.flairs[0] == ConstantsEnum.prodAMAFlairId) {
          return;
        }
      }
      const comment = action.payload;
      const commentId = createTextCommentPayload.kwargs.parent_thing_id;
      delete comment.parent_thing_id;

      if (!commentsIds[commentId]) {
        commentsIds[commentId] = [];
      }
      // TODO: add/update comments record
      state.comments[comment.thing_id] = {
        ...comment,
        feed_thing_id: feedDataId,
      };

      if (isCommentReply) {
        state.comments[comment.thing_id].parent_thing_id = commentId;
        // TODO: update reply count for the comment id
        state.replyCounts[commentId] = state.replyCounts[commentId] + 1;
        state.commentsCounts[feedDataId] = state.commentsCounts[feedDataId] + 1;
      }

      // TODO: add/update vote counts
      state.upVotes[comment.thing_id] = comment.ups;
      state.downVotes[comment.thing_id] = comment.downs;

      // TODO: add/update vote type
      state.voteTypes[comment.thing_id] = state.userUpVotesIds[comment.thing_id]
        ? 'up'
        : state.userDownVotesIds[comment.thing_id]
        ? 'down'
        : undefined;

      // TODO: increase commentcout
      commentsCounts[commentId] = commentsCounts[commentId] + 1;
      // TODO: add/update commentsIds record with id
      if (isCommentReply) {
        let ids: string[] = [];
        const parent = commentsIds[feedDataId];
        for (let i = 0; i < parent.length; i++) {
          if (parent[i] === commentId) {
            ids.push(parent[i]);
            ids.push(comment.thing_id);
          } else {
            ids.push(parent[i]);
          }
        }
        commentsIds[feedDataId] = ids;
      } else {
        commentsIds[commentId].unshift(comment.thing_id);
      }
    });

    builder.addCase(createTextComment.rejected, (state, action) => {
      console.log('rejected create text comment');
    });

    /**
     *
     * deletePostByThingId
     */
    builder.addCase(deletePostByThingId.pending, state => {
      state.deletePostByThingIdStatus = ApiStatusEnum.LOADING;
    });
    builder.addCase(deletePostByThingId.fulfilled, state => {
      state.deletePostByThingIdStatus = ApiStatusEnum.SUCCEEDED;
    });
    builder.addCase(deletePostByThingId.rejected, state => {
      state.deletePostByThingIdStatus = ApiStatusEnum.FAILED;
    });
  },
});
export const {
  updateCommentCountById,
  initFeedDetailsPage,
  setFeedPage,
  resetFeedList,
  setSelectedFlair,
  setFeedFilterModalVisiblity,
  resetFeedListWithFlair,
  addUpVote,
  addDownVote,
  addPollVotes,
  updateUserPollVotes,
  addFeedsFromProfile,
  addFeedsFromCommunity,
  removeFeedDetailsPage,
  setCommentsPage,
  // setUserFeedPage,
  updateUserVotes,
  deletePostFromFeed,
  setDeletePostStatusIdle,
  // resetUserFeedList,
} = feedSlice.actions;

export default feedSlice.reducer;
