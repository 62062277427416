export enum BasicDetailsKey {
  USER_FULLNAME = 'user_fullname',
  USER_CURRENT_ORG = 'user_current_org',
  USER_CURRENT_ROLE = 'user_current_role',
}

export type BottomSheetModal = {
  present: () => void;
  close: () => void;
};
