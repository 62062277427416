import {MessageType} from '@models/Conversation/Message';
import {Platform} from 'react-native';
import {openPicker} from '@libs/ImagePicker';

export type OpenPickerResponse = {
  uri: string;
  name: string;
  type: string;
  width: number;
  height: number;
};

class _DeviceService {
  openPicker = (
    type: MessageType.Image | MessageType.Video | MessageType.Document,
    limit: number = 1,
  ) =>
    new Promise<OpenPickerResponse | undefined>(async resolve => {
      if (type == MessageType.Image || type == MessageType.Video) {
        const response = await openPicker({
          mediaType: type == MessageType.Image ? 'photo' : 'video',
          quality: 0.5,
          maxWidth: 1080,
          maxHeight: 720,
          presentationStyle: 'fullScreen',
          videoQuality: Platform.OS === 'ios' ? 'medium' : 'low',
          selectionLimit: limit,
        });
        if (!response.sourceURL || !response.filename) {
          resolve(undefined);
          return;
        }
        resolve({
          uri: response.sourceURL,
          name: response.filename,
          type: response.mime,
          width: response.width,
          height: response.height,
        });
      }
    });
}

const DeviceService = new _DeviceService();

export default DeviceService;
