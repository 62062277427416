import firebase from 'firebase/compat/app';
import {FireBaseApp} from '../app/index.web';

import 'firebase/compat/remote-config';

FireBaseApp.initialize();

const remoteConfig = firebase.remoteConfig;

export default remoteConfig;
