/*eslint-disable */
import React, {useEffect} from 'react';
import {View} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';
import FeedDetail from '@screens/Home/FeedDetail';
import ImageView from '@screens/Home/ImageView';
import CommunityDetail from '@screens/Discover/CommunityDetail';
import CommunitySearch from '@screens/Discover/CommunitySearch';
import CreateCommunity from '@screens/Discover/CreateCommuntiy';
import ChatScreen from '@screens/Conversation/ChatScreen';
import SelectSpaces from '@screens/Post/SelectSpaces';
import LinkedinImportScreen from '@screens/SignUp/LinkedinImportScreen';
import ConversationsList from '@screens/Conversation/ConversationsList';
import Notifications from '@screens/Notificaions/Notifications';
import InitiateChat from '@screens/Conversation/InitiateChat';
import Profile from '@screens/Profile/Profile';
import Post from '@screens/Post/Post';
import {EditDetailsSheetProvider} from '@components/Modals/EditDetailsSheet';
import AddEducationBottomSheet from '@components/Modals/AddEducationBottomSheet';
import ShowAllContextProvider from '@components/Modals/ProfileWorkEducationmodals';
import {ProfileOptionSheetComponent} from '@components/Modals/ProfileOptionSheet';
import {PostOptionSheetComponent} from '@components/Modals/PostOptionSheet';
import AddWorkBottomSheet from '@components/Modals/AddWorkBottomSheet';
import {ConversationRequestSheetcomponent} from '@components/Modals/ConversationRequestSheet';
import {NotificationConfirmSheetProvider} from '@components/Modals/NotificationTurnOffConfirmationBottomSheet';
import {MessageOptionSheetComponent} from '@components/Modals/MessageOptionSheet';
import AMAService from '@services/amaService';
import {AppScreens} from './ScreenNames';
import TabNavigator from './TabNavigator';
import {appNavigatorOptions} from './options';
import RealmFlipper from '@libs/RealmFlipper';
import {useRoute} from '@react-navigation/native';
import LocalStorage from '@services/storage';

const AppStack = createStackNavigator();

const AppNavigator = () => {
  const params = useRoute().params as {page: AppScreens; params?: any};

  useEffect(() => {
    if (!!params) {
      LocalStorage.removeData('redirect');
    }
  }, []);
  console.log('data');
  return (
    <View style={{flex: 1}}>
      <AMAService.Provider>
        <NotificationConfirmSheetProvider>
          <EditDetailsSheetProvider>
            <ShowAllContextProvider>
              <RealmFlipper />
              <AppStack.Navigator
                initialRouteName={params?.page}
                screenOptions={appNavigatorOptions}>
                <AppStack.Screen
                  name={AppScreens.Tabs}
                  component={TabNavigator}
                />
                <AppStack.Screen
                  options={{title: 'Wyse'}}
                  name={AppScreens.FeedDetail}
                  component={FeedDetail}
                  initialParams={!!params?.params ? params.params : undefined}
                />
                <AppStack.Screen
                  options={{title: 'Wyse'}}
                  name={AppScreens.ImageView}
                  component={ImageView}
                />
                <AppStack.Screen
                  options={{title: 'Wyse'}}
                  name={AppScreens.CommunityDetail}
                  component={CommunityDetail}
                />
                <AppStack.Screen
                  options={{title: 'Wyse'}}
                  name={AppScreens.CommunitySearch}
                  component={CommunitySearch}
                />
                <AppStack.Screen
                  options={{title: 'Wyse'}}
                  name={AppScreens.CreateCommunity}
                  component={CreateCommunity}
                />
                <AppStack.Screen
                  options={{title: 'Wyse'}}
                  name={AppScreens.SelectSpaces}
                  component={SelectSpaces}
                />

                <AppStack.Screen
                  options={{title: 'Wyse'}}
                  name={AppScreens.LinkedinImport}
                  component={LinkedinImportScreen}
                />
                <AppStack.Screen
                  options={{title: 'Wyse'}}
                  name={AppScreens.Conversations}
                  component={ConversationsList}
                />
                <AppStack.Screen
                  options={{title: 'Wyse'}}
                  name={AppScreens.Chat}
                  component={ChatScreen}
                />
                <AppStack.Screen
                  options={{title: 'Wyse'}}
                  name={AppScreens.InitiateChat}
                  component={InitiateChat}
                />
                <AppStack.Screen
                  options={{title: 'Wyse'}}
                  name={AppScreens.CreatePost}
                  component={Post}
                  initialParams={{isStack: true}}
                />
                <AppStack.Screen
                  options={{title: 'Wyse'}}
                  name={AppScreens.Profile}
                  component={Profile}
                />
                <AppStack.Screen
                  options={{title: 'Wyse'}}
                  name={AppScreens.Notifications}
                  component={Notifications}
                />
              </AppStack.Navigator>
              <AddEducationBottomSheet />
              <AddWorkBottomSheet />
            </ShowAllContextProvider>
          </EditDetailsSheetProvider>
        </NotificationConfirmSheetProvider>
        <ProfileOptionSheetComponent />
        <PostOptionSheetComponent />
        <ConversationRequestSheetcomponent />
        <MessageOptionSheetComponent />
      </AMAService.Provider>
    </View>
  );
};

export default AppNavigator;
