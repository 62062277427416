/* eslint-disable */
import {View, StyleSheet} from 'react-native';
import React, {useEffect, useMemo, useState, useCallback} from 'react';
import Header from '@components/Header/Header';
import {ScrollView} from 'react-native-gesture-handler';
import OptionSwitchTab from '@components/OptionSwitchTab';
import {dispatch, useSelector} from '@redux/store';
import ConstantsEnum, {
  CommunityCategoryEnum,
  EventTypeEnum,
  EventTypeValueEnum,
  JoinEventEnum,
  SpacesAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import {AccountData} from '@models/AccountData/AccountData';
import {MetaDataCommunity} from '@models/MetaData/metaDataCommunity';
import CommunityCard from '@components/Card/CommunityCard';
import {CommunityJoinEventPayload} from '@models/Community/CommunityJoinEventPayload';
import {joinCommunityEventHandler} from '@redux/actions/community';
import {communitiesSelector} from '@redux/selectors/metaSelector';
import {updateJoinedCommunitiesByUser} from '@redux/slices/communitySlice';
import type {FC} from 'react';
import {CreateCommunityBtn} from '@components/Header/Header';
import ToasterService from '@services/toasterService';
import {userAccountDataSelector} from '@redux/selectors/userAccountSelectors';
import {createCommunitiesJoinedByUserSelector} from '@redux/selectors/communitySelector';
import {IEventData} from 'src/analytics/utils';
import Analytics from '@wyse/analytics';
import BigScreenTabBar from '@components/BigScreenTabBar';
import {withTokenRefresh} from '@utils/webhooks';
import Screen from '@components/Screen';
import {SCREEN_SIZE, TAB_BAR_SIZE} from '@utils/sizes';
import BigScreenRightBar from '@components/BigScreenRightBar';

const CommunityHeader: FC<{}> = () => {
  const headerRight = useCallback(() => {
    return (
      <CreateCommunityBtn
        onPress={() => {
          const analyticsEventPayload: IEventData = {
            ev_typ: EventTypeEnum.ANALYTICS,
            ev_val: EventTypeValueEnum.CREATE_COMMUNITY_EXP,
            thing_type: ThingTypeEnum.NA,
          };
          Analytics.trackEvent(analyticsEventPayload);
          ToasterService.showToast(
            'Thankyou for showing interest in creating community, Wyse team will contact in 48hrs',
            '#27AE60',
            3000,
          );
        }}
      />
    );
  }, []);
  return <Header title="Spaces" hideBottomBar headerRight={headerRight} />;
};

const Communities = ({navigation}: {navigation: any}) => {
  const userAccountData: AccountData | null | undefined = useSelector(
    userAccountDataSelector,
  );
  const metaCommunities = useSelector(communitiesSelector);

  const joinedCommunityIds: string[] = useSelector(
    createCommunitiesJoinedByUserSelector,
  );

  const [joinedCommunities, setJoinedCommunities] = useState<
    MetaDataCommunity[]
  >([]);

  const [nonJoinedCommunities, setNonJoinedCommunities] = useState<
    MetaDataCommunity[]
  >([]);

  const [activeTab, setActiveTab] = useState<string>(
    ConstantsEnum.SpacesScreenTabOptions.JOINED_OPTION,
  );

  useEffect(() => {
    populateJoinedCommunitiesData();
  }, [joinedCommunityIds]);

  const populateJoinedCommunitiesData = () => {
    if (userAccountData) {
      let joinedCommunityList: MetaDataCommunity[] = [];
      let nonJoinedCommunityList: MetaDataCommunity[] = [];
      metaCommunities.map((community: MetaDataCommunity) => {
        if (joinedCommunityIds.includes(community.thing_id)) {
          joinedCommunityList.push(community);
        } else {
          nonJoinedCommunityList.push(community);
        }
      });
      setJoinedCommunities(joinedCommunityList);
      setNonJoinedCommunities(nonJoinedCommunityList);
    }
  };

  const handleJoinEvent = (communityThingId: string, action: JoinEventEnum) => {
    const payload: CommunityJoinEventPayload = {
      resource_id: communityThingId,
      thing_type: ConstantsEnum.ThingTypeEnum.COMMUNITY,
      action: action,
      user_id: userAccountData!.thing_id!,
    };
    /** to update state */
    dispatch(updateJoinedCommunitiesByUser(payload));
    /** for api calls, state management and db updates are independent */
    dispatch(joinCommunityEventHandler(payload));
  };

  const renderJoinedCommunities = useMemo(
    () => (
      <ScrollView
        style={{
          height: '100%',
          display:
            activeTab === ConstantsEnum.SpacesScreenTabOptions.JOINED_OPTION
              ? 'flex'
              : 'none',
        }}>
        {joinedCommunities &&
          joinedCommunities.length > 0 &&
          joinedCommunities.map((item: MetaDataCommunity, index: number) => (
            <CommunityCard
              key={index}
              communityThingId={item.thing_id}
              communityAvatar={item.community_avatar!}
              communityTitle={item.title}
              communityHeadline={item.community_headline}
              communitySubCount={item.sub_count}
              joinOption={
                item.category === CommunityCategoryEnum.META ? false : true
              }
              isJoinedByUser={true}
              checkBoxOption={false}
              radioButtonOption={false}
              navigation={navigation}
              communityCardIndex={index}
              handleJoinEvent={handleJoinEvent}
              redirectToCommunityPage={true}
            />
          ))}
      </ScrollView>
    ),
    [joinedCommunities, activeTab],
  );

  const renderNonJoinedCommunities = useMemo(
    () => (
      <ScrollView
        style={{
          display:
            activeTab === ConstantsEnum.SpacesScreenTabOptions.EXPLORE_OPTION
              ? 'flex'
              : 'none',
          height: '100%',
        }}>
        {nonJoinedCommunities &&
          nonJoinedCommunities.length > 0 &&
          nonJoinedCommunities.map((item: MetaDataCommunity, index: number) => (
            <CommunityCard
              key={index}
              communityThingId={item.thing_id}
              communityAvatar={item.community_avatar!}
              communityTitle={item.title}
              communityHeadline={item.community_headline}
              communitySubCount={item.sub_count}
              joinOption={true}
              isJoinedByUser={false}
              checkBoxOption={false}
              radioButtonOption={false}
              navigation={navigation}
              communityCardIndex={index}
              handleJoinEvent={handleJoinEvent}
              redirectToCommunityPage={true}
            />
          ))}
      </ScrollView>
    ),
    [nonJoinedCommunities, activeTab],
  );

  useEffect(() => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: SpacesAnalEventValue.SPACES_JOINED_IMP,
      thing_type: ThingTypeEnum.NA,
    };
    if (activeTab === ConstantsEnum.SpacesScreenTabOptions.EXPLORE_OPTION) {
      analyticsEventPayload.ev_val = SpacesAnalEventValue.SPACES_EXPLORED_IMP;
    }

    Analytics.trackEvent(analyticsEventPayload);
  }, [activeTab]);

  return (
    <Screen
      RightComponent={BigScreenRightBar}
      LeftComponent={BigScreenTabBar}
      maxWidth={SCREEN_SIZE + TAB_BAR_SIZE}
      style={{flex: 1}}>
      <CommunityHeader />
      <View style={styles.separator} />
      <OptionSwitchTab
        option1={ConstantsEnum.SpacesScreenTabOptions.JOINED_OPTION}
        option2={ConstantsEnum.SpacesScreenTabOptions.EXPLORE_OPTION}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      />
      <View style={[styles.separator, {height: 10}]}></View>
      {renderNonJoinedCommunities}
      {renderJoinedCommunities}
    </Screen>
  );
};

const styles = StyleSheet.create({
  separator: {
    width: '100%',
    height: 2,
  },
});

export default withTokenRefresh(Communities);
