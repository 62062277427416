import React, {memo} from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {NavigationContainer} from '@react-navigation/native';
import AppOpenScreen from '@screens/SignUp/AppOpenScreen';
import {MainScreens} from './ScreenNames';
import AuthNavigator from './AuthNavigator';
import AppNavigator from './AppNavigation';
import {linking, mainNavigatorOptions} from './options';
import {navigationRef} from '@services/navigationService';

const MainStack = createStackNavigator();

const MainNavigator = () => {
  return (
    <NavigationContainer linking={linking} ref={navigationRef}>
      <MainStack.Navigator screenOptions={mainNavigatorOptions}>
        <MainStack.Screen
          name={MainScreens.AppOpenScreen}
          component={AppOpenScreen}
        />
        <MainStack.Screen name={MainScreens.Auth} component={AuthNavigator} />
        <MainStack.Screen name={MainScreens.App} component={AppNavigator} />
      </MainStack.Navigator>
    </NavigationContainer>
  );
};

export default memo(MainNavigator);
