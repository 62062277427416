import {ApiStatusEnum} from '@constants/constantsEnum';
import type {RootState} from '@redux/store';
import {MessageAccountData} from '@services/firestoreService';

export const userAccountDataSelector = (state: RootState) =>
  state.userAccountData.accountData;

export const hasUserAccountSelector = (state: RootState) =>
  !!state.userAccountData.accountData;

export const userAccountNameSelector = (state: RootState) =>
  state.userAccountData.accountData?.user_fullname || undefined;

export const userAccountNameIdAvatarSelector = (state: RootState) =>
  ({
    thing_id: state.userAccountData.accountData?.thing_id!,
    user_fullname: state.userAccountData.accountData?.user_fullname!,
    avatar: state.userAccountData.accountData?.avatar!,
  } as MessageAccountData);
export const userAccountThingIdSelector = (state: RootState) =>
  state.userAccountData.accountData?.thing_id;

export const userUpVotedIdsSelector = (state: RootState) =>
  !!state.userAccountData?.accountData?.t_ups
    ? state.userAccountData.accountData.t_ups
    : [];

export const userDownVotedIdsSelector = (state: RootState) =>
  !!state.userAccountData?.accountData?.t_downs
    ? state.userAccountData.accountData.t_downs
    : [];

export const userAvatarSelector = (state: RootState) => {
  if (!state.userAccountData.accountData) return undefined;
  if (state.userAccountData.accountData.avatar)
    return state.userAccountData.accountData.avatar;
  if (
    state.userAccountData.accountData.linkedin_parsed &&
    Object.keys(state.userAccountData.accountData.linkedin_parsed || {})
      .length > 0
  )
    return state.userAccountData.accountData.linkedin_parsed.profile_picture;
  return undefined;
};

export const linkedInImportStastusSelector = (state: RootState) =>
  state.userAccountData.linkedinImportStatus;

export const profileImageUploadStatusSelector = (state: RootState) =>
  state.userAccountData.profileImageUploadStatus;

export const editAccountDataStatusSelector = (state: RootState) =>
  state.userAccountData.editUserAccountStatus;

export const userWorkDataSelector = (state: RootState) =>
  state.userAccountData.accountData?.linkedin_parsed?.position_groups;

export const userEducationkDataSelector = (state: RootState) =>
  state.userAccountData.accountData?.linkedin_parsed?.education;

export const addEditEducationVisibleSelector = (state: RootState) =>
  state.userAccountData.addEditEducationVisible;

export const addEditWorkVisibleSelector = (state: RootState) =>
  state.userAccountData.addEditWorkVisible;

export const selectedEducationSelector = (state: RootState) =>
  state.userAccountData.selectedEducation;

export const selectedEducationIndexSelector = (state: RootState) =>
  state.userAccountData.seelectedEducationIndex;

export const selectedWorkSelector = (state: RootState) =>
  state.userAccountData.selectedWork;

export const selectedWorkIndexSelector = (state: RootState) =>
  state.userAccountData.selectedWorkIndex;

export const createLinkedinImportLoadingSelector = (state: RootState) =>
  state.userAccountData.linkedinImportStatus == ApiStatusEnum.LOADING;

/**
 *
 * @returns [role, org]
 */
export const userRoleAndOrganizationSelector = (state: RootState) =>
  [
    state.userAccountData.accountData?.user_current_role,
    state.userAccountData.accountData?.user_current_org,
  ] as [string, string];

export const createBlockedUserSelector = (state: RootState) =>
  !!state.userAccountData.accountData &&
  !!state.userAccountData.accountData.blocked_users
    ? state.userAccountData.accountData.blocked_users
    : [];
