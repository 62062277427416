import React, {memo, useCallback, useMemo} from 'react';
import type {FC} from 'react';
import moment from 'moment';
import {StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import type {LocalMessage} from '@services/DatabaseService';
import {CustomText} from '@components/Texts';
import CustomAvatar from '@components/Avatar';
// import FastImage from '@libs/FastImage';
import Colors from '@constants/Colors';
import {isSameDay, isSameUser} from '@utils/utilityFunctions';
import Day from './Day';
import {MessageType} from '@models/Conversation/Message';
import {FontFamily, FontSize} from '@utils/sizes';

type MessageProps = {
  diffMessage?: LocalMessage;
  item: LocalMessage;
  userId: string;
  avatar: string;
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
  },
  sentMessage: {
    alignItems: 'flex-end',
  },
  stretch: {alignSelf: 'stretch'},
  recivedMessage: {
    justifyContent: 'flex-start',
  },

  recivedMessageParent: {
    maxWidth: '80%',
    flexDirection: 'row',
  },
  sentParent: {maxWidth: '80%', alignItems: 'flex-end'},
  sentMessageWithStatusContainer: {
    alignItems: 'flex-end',
  },
  sentMessageContainer: {
    backgroundColor: Colors.primary,
    borderRadius: 20,
    minWidth: 60,
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  recievedMessageWithTimeContainer: {
    marginLeft: 8,
    alignItems: 'flex-end',
  },
  recievedMessageContainer: {
    alignSelf: 'stretch',
    minWidth: 60,
    backgroundColor: Colors.light,
    borderRadius: 20,
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  avatar: {
    marginTop: 8,
  },
  emptyAvatar: {
    width: 24,
  },
  timeStatusContainer: {
    flexDirection: 'row',

    alignItems: 'center',
    marginTop: 4,
  },
  imageContainer: {
    width: '40%',
    aspectRatio: 1 / 1,
    borderRadius: 20,
  },
});

const TextMessage: FC<{text: string; isSent?: boolean}> = ({
  text,
  isSent = false,
}) => {
  return (
    <View style={styles.stretch}>
      <CustomText size={FontSize.title} color={isSent ? 'white' : 'text'}>
        {text}
      </CustomText>
    </View>
  );
};

type MessageInnerProps = {item: LocalMessage};

const TimeStatus: FC<{
  date: Date;
  recieved: boolean;
  sent: boolean;
  hideStatus?: boolean;
}> = ({date, recieved, sent, hideStatus}) => {
  return (
    <View style={styles.timeStatusContainer}>
      <CustomText
        style={{fontSize: 8}}
        size={FontSize.verySmall}
        color="lightText">
        {moment(date).format('hh:mm A')}
      </CustomText>
      {hideStatus ? null : sent ? (
        <Icon
          size={10}
          color={Colors.veryLightText}
          name={recieved ? 'checkmark-done' : 'checkmark'}
        />
      ) : (
        <CustomText
          size={FontSize.verySmall}
          style={{fontSize: 8}}
          color="white">
          🕓
        </CustomText>
      )}
    </View>
  );
};

const SentMessageContainer: FC<
  Pick<LocalMessage, 'sent' | 'received' | 'createdAt'> & {
    children: any;
    hideStatus?: boolean;
  }
> = props => {
  const {children, sent, received, createdAt, hideStatus = false} = props;
  return (
    <View style={styles.sentParent}>
      <View style={styles.sentMessageWithStatusContainer}>
        <View style={styles.sentMessageContainer}>{children}</View>
        <TimeStatus
          hideStatus={hideStatus}
          sent={sent}
          recieved={received}
          date={createdAt}
        />
      </View>
    </View>
  );
};

const RecivedMessageContainer: FC<
  Pick<LocalMessage, 'sent' | 'received' | 'createdAt'> & {
    children: any;
    hideStatus?: boolean;
  }
> = props => {
  const {children, sent, received, createdAt, hideStatus = false} = props;
  return (
    <View style={styles.recievedMessageWithTimeContainer}>
      <View style={styles.recievedMessageContainer}>{children}</View>
      <TimeStatus
        hideStatus={hideStatus}
        sent={sent}
        recieved={received}
        date={createdAt}
      />
    </View>
  );
};

const RequestMessage: FC<
  Pick<LocalMessage, 'subject' | 'message'> & {isSent?: boolean}
> = ({subject, message, isSent}) => {
  return (
    <View style={styles.stretch}>
      <View style={styles.stretch}>
        <CustomText
          size={FontSize.title}
          fontFamily={FontFamily.FigtreeMedium}
          color={isSent ? 'white' : 'text'}>
          {subject}
        </CustomText>
        <CustomText
          size={FontSize.title}
          color={isSent ? 'lightWhite' : 'lightText'}>
          {message}
        </CustomText>
      </View>
    </View>
  );
};

const SentMessage: FC<MessageInnerProps> = props => {
  const {type, subject, sent, received, message, createdAt} = props.item;

  return (
    <SentMessageContainer
      hideStatus={type === MessageType.Request}
      {...{sent, received, createdAt}}>
      {type === MessageType.Request ? (
        <RequestMessage isSent message={message} subject={subject} />
      ) : (
        <TextMessage text={props.item.text} isSent />
      )}
    </SentMessageContainer>
  );
};

// const ChatImage: FC = () => {
//   return (
//     <Pressable style={styles.imageContainer}>
//       <FastImage style={{flex: 1}} resizeMode="cover" source={{}} />
//     </Pressable>
//   );
// };

const RecivedMessage: FC<
  MessageInnerProps & {
    avatar: string;
    diffUser?: {_id: string};
    isSameDay: boolean;
  }
> = props => {
  const {type, message, subject, sent, received, createdAt} = props.item;
  const renderAvatar = useCallback(() => {
    const isSame = isSameUser(props.item.user, props.diffUser);

    if (isSame && props.isSameDay) return <View style={styles.emptyAvatar} />;
    return (
      <CustomAvatar
        width={24}
        height={24}
        borderRadius={12}
        style={styles.avatar}
        backgroundColor={Colors.background}
        source={props.avatar}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item.user._id, props.diffUser?._id, props.avatar, props.isSameDay]);

  return (
    <View style={styles.recivedMessageParent}>
      {renderAvatar()}
      <RecivedMessageContainer
        hideStatus={type === MessageType.Request}
        sent={sent}
        {...{received, createdAt}}>
        {type === MessageType.Request ? (
          <RequestMessage message={message} subject={subject} />
        ) : (
          <TextMessage text={props.item.text} />
        )}
      </RecivedMessageContainer>
    </View>
  );
};

const Message: FC<MessageProps> = props => {
  const {diffMessage, avatar, item} = props;
  const {user} = item;

  const isSent = user._id === props.userId;

  const {rednerDay, isSame} = useMemo(() => {
    const isSame = isSameDay(item.createdAt, diffMessage?.createdAt);
    const renderInner = () => {
      if (isSame) return null;
      return <Day createdAt={item.createdAt} />;
    };
    return {
      rednerDay: renderInner(),
      isSame,
    };
  }, [diffMessage?.createdAt, item.createdAt]);

  return (
    <View
      style={[
        styles.container,
        isSent ? styles.sentMessage : styles.recivedMessage,
      ]}>
      {rednerDay}
      {isSent ? (
        <SentMessage item={item} />
      ) : (
        <RecivedMessage
          diffUser={diffMessage?.user}
          avatar={avatar}
          item={item}
          isSameDay={isSame}
        />
      )}
    </View>
  );
};

export default memo(Message);
