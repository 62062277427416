/*eslint-disable */
import React, {useMemo, type FC} from 'react';
import {Pressable, StyleSheet, useWindowDimensions} from 'react-native';
import {View} from 'react-native';
import IconTextButton from './IconTextButton';
import {useRoute} from '@react-navigation/native';
import {AppScreens, AuthScreens, TabScreens} from '@navigations/ScreenNames';
import Colors from '@constants/Colors';
import NavigationService from '@services/navigationService';
import {FontFamily, FontSize, SCREEN_SIZE, TAB_BAR_SIZE} from '@utils/sizes';
import {useSelector} from 'react-redux';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import useColors, {isWeb} from '@utils/useColors';
import LocalStorage from '@services/storage';
import {Icons} from './icon';
import {CustomText} from './Texts';

const styles = StyleSheet.create({
  container: {
    width: TAB_BAR_SIZE,
    height: '100%',
    backgroundColor: Colors.white,
    borderRightWidth: 2,
    borderRightColor: Colors.background,
  },
  containerWeb: {
    width: TAB_BAR_SIZE,
    height: '100%',
    borderRightWidth: 2,
    borderRightColor: Colors.background,
  } as any,
  signinButton: {
    height: 40,
    borderRadius: 22,
    paddingHorizontal: 50,
    alignSelf: 'flex-start',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primary,
    marginTop: 16,
  },
  line: {
    alignSelf: 'stretch',
    height: 1,
    backgroundColor: Colors.background,
  },
});
const Tabs = [
  {
    title: 'Home',
    screen: TabScreens.Home,
    icon: Icons.Home,
  },
  {
    title: 'Spaces',
    screen: TabScreens.Spaces,
    icon: Icons.Spaces,
  },
  {
    title: 'Create Post',
    screen: TabScreens.Post,
    icon: 'post-deselect',
  },
  {
    title: 'Notifications',
    screen: TabScreens.Notification,
    icon: Icons.Notifications,
  },
  {
    title: 'Profile',
    screen: TabScreens.Profile,
    icon: Icons.Profile,
    Icon: undefined,
  },
];

const NonSignedInTabs = [
  {
    title: 'Home',
    screen: TabScreens.Home,
    icon: Icons.Home,
  },
  {
    title: 'Profile',
    screen: TabScreens.Profile,
    icon: Icons.Profile,
  },
];

const SignInView = () => {
  return (
    <View
      style={{
        alignSelf: 'stretch',
        paddingVertical: 20,
        paddingHorizontal: 16,
      }}>
      <CustomText>
        Sign in to access thousands of posts and comments from top professionals
        in your industry
      </CustomText>
      <Pressable
        onPress={() => {
          NavigationService.getInstance().replaceToAuth({
            page: AuthScreens.OtpScreen,
          });
        }}
        style={styles.signinButton}>
        <CustomText
          color="white"
          size={FontSize.title}
          fontFamily={FontFamily.FigtreeBold}>
          Sign in
        </CustomText>
      </Pressable>
    </View>
  );
};
const BigScreenTabBar: FC<{screen?: AppScreens | TabScreens}> = ({screen}) => {
  const {width} = useWindowDimensions();
  const colors = useColors();
  const show = width >= TAB_BAR_SIZE + SCREEN_SIZE;
  const thingId = useSelector(userAccountThingIdSelector);
  console.log('width', width, TAB_BAR_SIZE + SCREEN_SIZE);
  const route = useRoute();

  const userId = useSelector(userAccountThingIdSelector);

  // react-hooks/exhaustive-deps
  return useMemo(() => {
    if (!show) return null;
    return (
      <View
        style={
          isWeb
            ? [styles.containerWeb, {backgroundColor: colors.white}]
            : styles.container
        }>
        <View style={{flex: 1}}>
          {(!userId ? NonSignedInTabs : Tabs).map(tab => {
            return (
              <IconTextButton
                color={tab.screen == route.name ? Colors.primary : Colors.gray}
                fontColor={tab.screen == route.name ? 'primary' : 'lightText'}
                disabled={tab.screen == route.name}
                onPress={async () => {
                  if (!thingId) {
                    await LocalStorage.setData('redirect', route);
                    NavigationService.getInstance().replaceToAuth({
                      page: AuthScreens.OtpScreen,
                    });
                  }
                  if (!screen || screen == AppScreens.Tabs) {
                    NavigationService.getInstance().openTab(
                      tab.screen,
                      tab.screen == TabScreens.Profile
                        ? {profileThingId: userId, tab: 'details'}
                        : undefined,
                    );
                    return;
                  }
                  NavigationService.getInstance().openHome(
                    tab.screen,
                    tab.screen == TabScreens.Profile
                      ? {profileThingId: userId, tab: 'details'}
                      : undefined,
                  );
                }}
                fontFamily={
                  tab.screen == route.name
                    ? FontFamily.FigtreeSemiBold
                    : FontFamily.FigtreeRegular
                }
                fontSize={FontSize.title}
                key={`tab-item-${tab.title}`}
                text={tab.title}
                Icon={tab.icon}
              />
            );
          })}
        </View>
        <View style={styles.line} />

        {!userId ? <SignInView /> : null}
      </View>
    );
  }, [route.name, show, userId, colors.white, screen]);
};

export default BigScreenTabBar;
