/*eslint-disable */
import {createAsyncThunk} from '@reduxjs/toolkit';
import UserAccountDataService from '@services/userAccountDataService';
import ToasterService from '@services/toasterService';
import type {
  AccountInfoRequestPayload,
  confirmOTPPayload,
} from '@models/AccountData/AccountInfoRequestPayload';
import type {UpdateUserProfilePayload} from '@models/UpdateUserProfilePayload';
import type {EditAccountDataPayload} from '@models/AccountData/EditAccountDataPayload';
import LocalStorage from '@services/storage';
import ConstantsEnum, {LocalStorageKeysEnum} from '@constants/constantsEnum';
import {updateUserPollVotes, updateUserVotes} from '@redux/slices/feedSlice';
import {addJoinedCommunitiesByUser} from '@redux/slices/communitySlice';
import {dispatch} from '@redux/store';
import {addCurrentUserProfileDetail} from '@redux/slices/profileSlice';
import {BlockAccountPayload} from '@models/AccountData/BlockAccountPayload';
import Analytics, {IEventData, IExtraData} from '@wyse/analytics';
import {
  EventTypeEnum,
  ProfileCreationAnalEventValue,
  LoginAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import {resolveImageAssetSource} from '@utils/utilityFunctions';
import PushNotificationService from '@services/pushNotificationService';
import auth from '@libs/Firebase/auth';
// import appsFlyer from '@libs/AppsFlyer';
import {ProfileUunvailableAvatar} from '@assets';

const randomAvatarURI = resolveImageAssetSource(ProfileUunvailableAvatar).uri;
/**
 * importUserAccountDataFromLinkedIn
 * thunk which gives user data from linked in
 */
export const importUserAccountDataFromLinkedIn = createAsyncThunk(
  'user/account/import/linked-in',
  async (userData: AccountInfoRequestPayload, {rejectWithValue}) => {
    try {
      const response =
        await UserAccountDataService.getUserDataFromLinkedInImport(
          userData.linkedinProfileUrl!,
          userData.uuid!,
        );
      const accountData = response.data.instance;
      LocalStorage.setData(LocalStorageKeysEnum.USER_ACCOUNT_DATA, accountData);
      dispatch(addCurrentUserProfileDetail(response.data.instance));
      return response.data.instance;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchUserAccountDataByPhoneNumber = createAsyncThunk(
  'user/account/get/by-number',
  async (userData: AccountInfoRequestPayload, {dispatch, rejectWithValue}) => {
    try {
      const response =
        await UserAccountDataService.getUserAccountDataFromPhoneNumber(
          userData.phoneNumber!,
        );

      const accountData = response.data.response.results[0];
      console.log('accountData', accountData);
      if (!accountData) {
        return rejectWithValue(response.data);
      }

      const latestFCMToken = await PushNotificationService.getFCMToken();
      // appsFlyer.getAppsFlyerUID((error: Error, uid: string) => {
      //   if (error) {
      //     console.log('Appsflyer uid error: ', error);
      //   } else {
      //     let editAccountDataPayload: EditAccountDataPayload = {
      //       thing_id: accountData!.thing_id!,
      //       thing_type: ConstantsEnum.ThingTypeEnum.ACCOUNT,
      //       attrs: [
      //         ['fcm_token', latestFCMToken, 'replace'],
      //         ['apps_flyer_uid', uid, 'replace'],
      //       ],
      //     };
      //     dispatch(editUserAccountData(editAccountDataPayload));
      //   }
      // });

      LocalStorage.setData(LocalStorageKeysEnum.USER_ACCOUNT_DATA, accountData);
      dispatch(
        updateUserVotes({
          t_ups: accountData.t_ups,
          t_downs: accountData.t_downs,
        }),
      );
      dispatch(updateUserPollVotes(accountData.poll_votes));
      dispatch(
        addJoinedCommunitiesByUser(
          !!accountData.communities ? accountData.communities : [],
        ),
      );
      dispatch(addCurrentUserProfileDetail(accountData));
      Analytics.setUserId(accountData.thing_id);
      Analytics.setPhoneNumber(accountData.mobile!);
      return accountData;
    } catch (error) {
      console.log('error', error.response?.data);
      return rejectWithValue(error.message);
    }
  },
);

export const createUserAccount = createAsyncThunk(
  'user/account/create',
  async (userData: AccountInfoRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await UserAccountDataService.createUserAccount(
        userData.phoneNumber!,
      );
      const accountData = response.data.response.results[0];
      if (!accountData) {
        return rejectWithValue(response.data);
      }
      LocalStorage.setData(LocalStorageKeysEnum.USER_ACCOUNT_DATA, accountData);
      const latestFCMToken = await PushNotificationService.getFCMToken();

      // appsFlyer.getAppsFlyerUID((error: Error, uid: string) => {
      //   if (error) {
      //     console.log('Appsflyer uid error: ', error);
      //   } else {
      //     let editAccountDataPayload: EditAccountDataPayload = {
      //       thing_id: accountData!.thing_id!,
      //       thing_type: ConstantsEnum.ThingTypeEnum.ACCOUNT,
      //       attrs: [
      //         ['fcm_token', latestFCMToken, 'replace'],
      //         ['apps_flyer_uid', uid, 'replace'],
      //       ],
      //     };
      //     dispatch(editUserAccountData(editAccountDataPayload));
      //   }
      // });
      dispatch(
        updateUserVotes({
          t_ups: accountData.t_ups,
          t_downs: accountData.t_downs,
        }),
      );
      dispatch(updateUserPollVotes(accountData.poll_votes));
      dispatch(
        addJoinedCommunitiesByUser(
          !!accountData.communities ? accountData.communities : [],
        ),
      );
      dispatch(addCurrentUserProfileDetail(accountData));
      Analytics.setUserId(accountData.thing_id);
      Analytics.setPhoneNumber(accountData.mobile!);
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: LoginAnalEventValue.SIGNUP_SUCCESS,
        thing_type: ThingTypeEnum.NA,
      };
      Analytics.trackEvent(analyticsEventPayload);
      return accountData;
    } catch (error) {
      const extraData: IExtraData = {
        mobile: userData.phoneNumber!,
      };
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: LoginAnalEventValue.SIGNUP_FAILED,
        thing_type: ThingTypeEnum.NA,
        extra_data: JSON.stringify(extraData),
      };
      Analytics.trackEvent(analyticsEventPayload);
      return rejectWithValue(error.message);
    }
  },
);

export const uploadUserProfileImage = createAsyncThunk(
  'user/account/image/upload',
  async (payload: UpdateUserProfilePayload, {dispatch, rejectWithValue}) => {
    try {
      const response = await UserAccountDataService.uploadUserProfileImage(
        payload,
      );
      const accountData = response.data.instance;
      LocalStorage.setData(LocalStorageKeysEnum.USER_ACCOUNT_DATA, accountData);
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val:
          payload.imageUri === randomAvatarURI
            ? ProfileCreationAnalEventValue.DP_SKIP_SUCCESS
            : ProfileCreationAnalEventValue.DP_UPLOAD_SUCCESS,
        thing_type: ThingTypeEnum.NA,
      };
      Analytics.trackEvent(analyticsEventPayload);
      dispatch(addCurrentUserProfileDetail(accountData));
      return accountData;
    } catch (error) {
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val:
          payload.imageUri === randomAvatarURI
            ? ProfileCreationAnalEventValue.DP_SKIP_ERROR
            : ProfileCreationAnalEventValue.DP_UPLOAD_ERROR,
        thing_type: ThingTypeEnum.NA,
      };
      Analytics.trackEvent(analyticsEventPayload);
      return rejectWithValue(error.message);
    }
  },
);

export const editUserAccountData = createAsyncThunk(
  'user/account/edit',
  async (editAccountPayload: EditAccountDataPayload, {rejectWithValue}) => {
    try {
      const response = await UserAccountDataService.editUserAccountData(
        editAccountPayload,
      );
      const accountData = response.data.instance;
      LocalStorage.setData(LocalStorageKeysEnum.USER_ACCOUNT_DATA, accountData);
      dispatch(addCurrentUserProfileDetail(accountData));
      return accountData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const onboardUser = createAsyncThunk(
  'user/account/onboard',
  async (editAccountPayload: EditAccountDataPayload, {rejectWithValue}) => {
    try {
      const response = await UserAccountDataService.onboardUser(
        editAccountPayload,
      );
      const accountData = response.data.instance;
      LocalStorage.setData(LocalStorageKeysEnum.USER_ACCOUNT_DATA, accountData);
      dispatch(addCurrentUserProfileDetail(accountData));
      return accountData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const blockAccountEventHandler = createAsyncThunk(
  'account/block',
  async (payload: BlockAccountPayload, {dispatch, rejectWithValue}) => {
    try {
      const response = await UserAccountDataService.handleBlockEvent(payload);
      const accountData = response.data;
      dispatch(addCurrentUserProfileDetail(accountData));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

/**
 * thunk to trigger otp from BE
 */
export const requestOtp = createAsyncThunk(
  'user/request/otp',
  async (mobile: string, {rejectWithValue}) => {
    try {
      const response = await UserAccountDataService.requestOtp(mobile);
      console.log('request otp success');
      console.log(response);
      return response.data;
    } catch (error) {
      console.log('request otp failed');
      console.log(error);
      return rejectWithValue(error.message);
    }
  },
);

/**
 * thunk to confirm otp from BE
 */
export const confirmOtp = createAsyncThunk(
  'user/confirm/otp',
  async (payload: confirmOTPPayload, {rejectWithValue}) => {
    try {
      const response = await UserAccountDataService.confirmOtp(
        payload.mobile,
        payload.otp,
      );
      const userCredentials = await auth().signInWithCustomToken(
        response.data.token,
      );
      const userAccountInfo: AccountInfoRequestPayload = {
        linkedinProfileUrl: null,
        thingId: null,
        phoneNumber: payload.mobile,
        uuid: null,
      };
      setTimeout(() => {
        dispatch(fetchUserAccountDataByPhoneNumber(userAccountInfo));
      }, 2000);
      return response.data.token;
    } catch (error) {
      const data: IExtraData = {
        mobile: payload.mobile,
        otp: payload.otp,
      };
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: LoginAnalEventValue.OTP_INCORRECT,
        thing_type: ThingTypeEnum.NA,
        extra_data: JSON.stringify(data),
      };
      Analytics.trackEvent(analyticsEventPayload);

      return rejectWithValue(error.error_code);
    }
  },
);
