/*eslint-disable */
import {toast} from 'react-toastify';

const showToast = async (
  message: string,
  backgroundColor: string = '#27AE60',
  duration: number = 1000,
  position: number = -1,
  shadow: boolean = false,
  animation: boolean = true,
  hideOnPress: boolean = true,
  delay: number = 0,
  textColor: string = '#FFFFFF',
  opacity: number = 1.0,
) => {
  return toast.success(message, {
    hideProgressBar: true,
    style: {width: 500, fontSize: 12},

    position: toast.POSITION.TOP_CENTER,
  });
};

const hideToast = (toast: any) => {};

const ToasterService = {
  showToast,
  hideToast,
};

export default ToasterService;
