import {createSlice} from '@reduxjs/toolkit';
import type {CommentData} from '@models/Comment/CommentData';
import {FeedData} from '@models/Feed/FeedData';

/**
 *
 * Types
 */
export type FeedDetailsState = {
  postId: string;
  data: CommentData[];
  page: number;
  loading: boolean;
  error?: {error: string};
  totalPages?: number;
  feed?: FeedData;
};

export type FeedDetailsSlice = Record<string, FeedDetailsState>;

/**
 *
 *
 * Initial State
 * black since on initial launch there wont be any posts
 */
const initialState: FeedDetailsSlice = {};

/**
 * function which takes the postid and gives back initial comment state
 * only call this function when redux comment state does not contain a record with the current postId
 * @param {string} postId postid of which initial state should be created
 * @returns {CommentState} initial comments state for that post
 */
// const createCommentsInitialState = (
//   postId: string,
//   feed?: FeedData,
// ): FeedDetailsState => ({
//   postId,
//   data: [],
//   loading: false,
//   page: 0,
//   feed,
// });

/**
 *
 *
 * Comment Slice
 */
const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {},
  extraReducers: () => {
    // createTextComment
    // builder.addCase(createTextComment.fulfilled, (state, action) => {
    //   const {feedDataId, isCommentReply, createTextCommentPayload} =
    //     action.meta.arg;
    //   if (!isCommentReply) {
    //     const item = state[feedDataId]!;
    //     item.data.unshift(action.payload);
    //     item.feed!.num_comments = item.feed!.num_comments + 1;
    //   } else {
    //     const item = state[feedDataId]!;
    //     item.data = item.data.map(item =>
    //       item.thing_id === createTextCommentPayload.kwargs.parent_thing_id
    //         ? {...item, replies: [action.payload, ...(item.replies || [])]}
    //         : item,
    //     );
    //     item.feed!.num_comments = item.feed!.num_comments + 1;
    //   }
    // });
  },
});

/**
 * actions
 */
export const {} = commentsSlice.actions;

export default commentsSlice.reducer;
