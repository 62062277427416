import {ApiStatusEnum} from '@constants/constantsEnum';
import type {RootState} from '@redux/store';
/**
 * selector which gives back all communities joined by user
 */
export const createCommunitiesJoinedByUserSelector = (state: RootState) =>
  state.community.joinedCommunitiesByUser;

/**
 * createCommunityFeedIdsSelector
 * selector which gives back feedId array for particular communtiy
 * @param {RootState} state root state of redux
 * @returns {string[]} feed data id array
 */
export const createCommunityFeedIdsSelector =
  (communityId: string) => (state: RootState) => {
    return state.community.communityFeedIds[communityId] || [];
  };

export const createCommunityFeedDetailsSelector =
  (communityId: string) => (state: RootState) => {
    return state.community.communityFeedDetails[communityId];
  };

export const createCommunityFeedLoadingSelector =
  (communityId: string) => (state: RootState) => {
    return !!state.community.communityFeedDetails[communityId]
      ? state.community.communityFeedDetails[communityId].fetchStatus ===
          ApiStatusEnum.LOADING
      : false;
  };

export const createCommunityFeedLoadMoreLoadingSelector =
  (communityId: string) => (state: RootState) => {
    return !!state.community.communityFeedDetails[communityId]
      ? state.community.communityFeedDetails[communityId].feedLoadMoreStatus ===
          ApiStatusEnum.LOADING
      : false;
  };

export const createCommunityFeedEndReachedSelector =
  (communityId: string) => (state: RootState) => {
    if (!!state.community.communityFeedDetails[communityId]) {
      const ids = state.community.communityFeedIds[communityId];
      if (!ids) return true;
      if (ids.length < 15) return true;
      return (
        state.community.communityFeedDetails[communityId].feedPage ==
        state.community.communityFeedDetails[communityId].totalPagesInFeed
      );
    } else {
      return true;
    }
  };

export const createCommunityFeedPageAndTotalPageSelector =
  (communityId: string) => (state: RootState) => {
    if (!!state.community.communityFeedDetails[communityId]) {
      return [
        state.community.communityFeedDetails[communityId].feedPage,
        state.community.communityFeedDetails[communityId].totalPagesInFeed,
      ];
    }
    return [undefined, undefined];
  };

export const createCommunityMemberIdsSelector =
  (communityId: string) => (state: RootState) => {
    return state.community.communityMemberIds[communityId] || [];
  };

export const createCommunityMembersDetailsSelector =
  (communityId: string) => (state: RootState) => {
    return state.community.communityMembersDetails[communityId];
  };

export const createCommunityMemberDataSelector =
  (memberId: string) => (state: RootState) => {
    return state.community.members[memberId];
  };

export const createCommunityMembersLoadingSelector =
  (communityId: string) => (state: RootState) => {
    return !!state.community.communityMembersDetails[communityId]
      ? state.community.communityMembersDetails[communityId].fetchStatus ===
          ApiStatusEnum.LOADING
      : false;
  };

export const createCommunityMembersLoadMoreLoadingSelector =
  (communityId: string) => (state: RootState) => {
    return !!state.community.communityMembersDetails[communityId]
      ? state.community.communityMembersDetails[communityId]
          .membersLoadMoreStatus === ApiStatusEnum.LOADING
      : false;
  };

export const createCommunityMembersEndReachedSelector =
  (communityId: string) => (state: RootState) => {
    if (!!state.community.communityMembersDetails[communityId]) {
      const ids = state.community.communityMemberIds[communityId];
      if (!ids) return true;
      if (ids.length < 15) return true;
      return (
        state.community.communityMembersDetails[communityId].membersPage ==
        state.community.communityMembersDetails[communityId].totalPagesInMembers
      );
    } else {
      return true;
    }
  };

export const createCommunityMembersPageAndTotalPageSelector =
  (communityId: string) => (state: RootState) => {
    if (!!state.community.communityMembersDetails[communityId]) {
      return [
        state.community.communityMembersDetails[communityId].membersPage,
        state.community.communityMembersDetails[communityId]
          .totalPagesInMembers,
      ];
    } else {
      return [undefined, undefined];
    }
  };
