import React, {createContext, useRef, useContext, useMemo} from 'react';
import type {FC} from 'react';
import EditDetailsSheet from './DetailsSheet';
import {BottomSheetModal} from './types';

const EditDetailsContext = createContext<React.RefObject<BottomSheetModal>>(
  0 as any,
);

export const useEditDetailsSheetRef = () => useContext(EditDetailsContext);

export const EditDetailsSheetProvider: FC<{
  children: React.ReactElement;
}> = props => {
  const sheetRef = useRef<BottomSheetModal>(null);
  const {children} = props;
  return (
    <EditDetailsContext.Provider value={sheetRef}>
      {useMemo(() => children, [children])}
      <EditDetailsSheet sheetRef={sheetRef as any} />
    </EditDetailsContext.Provider>
  );
};
