import ToasterService from '@services/toasterService/toasterService';

type ShareOptions = {
  message?: string;
  title?: string;
  url?: string;
  urls?: string[];
  type?: string;
  subject?: string;
  email?: string;
  recipient?: string;
  failOnCancel?: boolean;
  showAppsToView?: boolean;
  filename?: string;
  filenames?: string[];
  saveToFiles?: boolean;
  isNewTask?: boolean;
};

class _Share {
  open(options: ShareOptions) {
    try {
      console.log('options.url', options.url);
      if (!options.url) return false;
      navigator.clipboard.writeText(options.url);
      ToasterService.showToast('Link copied');
      return true;
    } catch (error) {
      console.log('error', error);
    }
  }
}

const Share = new _Share();

export default Share;
