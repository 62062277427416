import React from 'react';
import type {FC} from 'react';
import {StyleSheet, View} from 'react-native';
import moment from 'moment';
import {CustomText} from '@components/Texts';
import Colors from '@constants/Colors';
import {FontSize} from '@utils/sizes';

type DayProps = {
  createdAt: Date;
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 28,
    alignSelf: 'stretch',
    height: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.light,
  },
  text: {
    position: 'absolute',
    paddingHorizontal: 8,
  },
});
const Day: FC<DayProps> = ({createdAt}) => {
  return (
    <View style={styles.container}>
      <CustomText style={styles.text} size={FontSize.small} color="lightText">
        {moment(createdAt).format('DD MMM YYYY')}
      </CustomText>
    </View>
  );
};

export default Day;
