import React, {FC} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import CustomAvatar from '../Avatar';
import type {Conversation} from '@models/Conversation';
import Colors from '@constants/Colors';
import {CustomText} from '@components/Texts';
import NavigationService from '@services/navigationService';
import ConversationRequestSheet from '@components/Modals/ConversationRequestSheet';
import {resolveImageAssetSource} from '@utils/utilityFunctions';
import {RandomAvatar} from '@assets';
import {FontSize} from '@utils/sizes';

const anonymousURI = resolveImageAssetSource(RandomAvatar).uri;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    height: 80,
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatarBackground: {
    backgroundColor: Colors.background,
  },
  messsageBox: {
    flex: 1,
    height: '100%',
    paddingVertical: 6,
    justifyContent: 'space-between',
    paddingLeft: 8,
  },
  row: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  countContainer: {
    height: 16,
    minWidth: 16,
    borderRadius: 8,
    backgroundColor: Colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
  },
  space: {},
  hGap: {width: 18},
});

type ConversactionCardProps = {
  conversation: Conversation;
  isRequestCard?: boolean;
};

const ConversationCard: FC<ConversactionCardProps> = props => {
  const {conversation, isRequestCard} = props;
  return (
    <Pressable
      disabled={!isRequestCard ? false : conversation.rejected}
      onPress={() => {
        if (!isRequestCard) {
          NavigationService.getInstance().openChat(
            conversation.user_thing_id,
            conversation,
          );
          return;
        }
        ConversationRequestSheet.openSheet({...conversation});
      }}
      style={styles.container}>
      <CustomAvatar
        source={
          !!conversation.user_avatar ? conversation.user_avatar : anonymousURI
        }
        width={48}
        style={styles.avatarBackground}
        height={48}
        borderRadius={48}
      />
      <View style={styles.messsageBox}>
        <View style={styles.row}>
          <CustomText numberOfLines={1} color="text">
            {conversation.user_fullname}
          </CustomText>
          <View style={styles.hGap} />
          <CustomText size={FontSize.small} color="lightText">
            {conversation.lastUpdatedAt}
          </CustomText>
        </View>
        <View style={[styles.row, styles.space]}>
          {isRequestCard ? (
            <CustomText color="veryLightText">
              {conversation.last_message.subject}
            </CustomText>
          ) : (
            <CustomText
              numberOfLines={1}
              color={conversation.read ? 'text' : 'veryLightText'}>
              {conversation.last_message.text ||
                conversation.last_message.subject}
            </CustomText>
          )}
          <View style={styles.hGap} />
          {conversation.read ? (
            <View style={styles.countContainer}>
              <CustomText color="white">{''}</CustomText>
            </View>
          ) : null}
        </View>
        {/* {isRequestCard ? (
          <SmallText numberOfLines={1} color="veryLightText">
            {conversation.message}
          </SmallText>
        ) : null} */}
      </View>
    </Pressable>
  );
};

export default ConversationCard;
