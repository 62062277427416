import ConstantsEnum from '@constants/constantsEnum';
import appsFlyer from '@libs/AppsFlyer';

let AppsFlyerUid: string = 'na';

function initAppsFlyer() {
  appsFlyer.initSdk(
    {
      devKey: ConstantsEnum.AppsFlyerDevKey,
      isDebug: true,
      appId: ConstantsEnum.AppStoreId,
      onInstallConversionDataListener: true, //Optional
      onDeepLinkListener: false, //Optional
      timeToWaitForATTUserAuthorization: 10, //for iOS 14.5
    },
    result => {
      console.log(result);
      fetchAppsFlyerUID();
    },
    error => {
      console.error(error);
    },
  );
}

function registerInAppEvents(eventName: string, userId: string) {
  const eventValues = {
    user_id: userId,
  };
  appsFlyer.logEvent(
    eventName,
    eventValues,
    res => {
      console.log(res);
    },
    err => {
      console.error(err);
    },
  );
}

function fetchAppsFlyerUID() {
  appsFlyer.getAppsFlyerUID((error: Error, uid: string) => {
    if (error) {
      console.log(error);
    } else {
      AppsFlyerUid = uid;
    }
  });
}

const AppsFlyerService = {
  initAppsFlyer,
  registerInAppEvents,
  fetchAppsFlyerUID,
  AppsFlyerUid,
};

export default AppsFlyerService;
