import React, {createContext, useContext, useMemo, useRef} from 'react';
import type {FC} from 'react';

/**
 * `FeedDetailsPagesRef`
 * vote ref is a refrence object which holds all the number of counts for the feed details page opened for postId
 * it a Map with keys with postIds and values as instances of the pages
 * let say we have opened post "123" Feed details 2 times the in map for perticular feed id count will be 2
 * usefull when we want to clear the comments slice for a perticular post
 */
export type FeedDetailsPagesRef = React.MutableRefObject<Map<string, number>>;

type FeedDetailsPagesContext = FeedDetailsPagesRef;

/**
 * Base Context which gets created on App launch
 */
const FeedDetailsPagesContext = createContext<FeedDetailsPagesContext>(
  {} as any,
);

/**
 * `useFeedDetailsPagesContext`
 * gives context values for instances of feed detaisl page opened
 * @returns {FeedDetailsPagesRef} map of the feed details instance
 *
 * eg:
 * ```javascript
 *    const feedDetailsPagesRef = useFeedDetailsPagesContext();
 *
 *    feedDetailsPagesRef.current.
 *    downVotedIdRef.current.delete("123")
 *
 * ```
 *
 * available methods
 * ```javascript
 * // adds id
 * feedDetailsPagesRef.current.set("id", 1);
 * // clears all ids
 * feedDetailsPagesRef.current.clear();
 * feedDetailsPagesRef.current.delete();
 * feedDetailsPagesRef.current.forEach(key => {console.log(key)})
 * feedDetailsPagesRef.current.has();
 * feedDetailsPagesRef.current.size();
 * ```
 */

export const useFeedDetailsPagesContext = () => {
  return useContext(FeedDetailsPagesContext) as FeedDetailsPagesRef;
};

const map = new Map<string, number>();

/**
 * `FeedDetailsPagesContextProvider`
 * functional component whcih add the referances in the component tree
 * add this Provider in your root tree after the store initilization
 * eg:
 * ```javascript
 * return (
 *   <Provider store={store}>
 *      <FeedDetailsPagesContextProvider />
 *      <OtherComponents />
 *   </Provider>
 * );
 * ```
 */
export const FeedDetailsPagesContextProvider: FC<{children?: any}> = ({
  children,
}) => {
  const feedDetailsPagesRef: FeedDetailsPagesRef = useRef(map);

  return useMemo(
    () => (
      <FeedDetailsPagesContext.Provider value={feedDetailsPagesRef}>
        {children}
      </FeedDetailsPagesContext.Provider>
    ),
    [feedDetailsPagesRef, children],
  );
};
