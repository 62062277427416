/*eslint-disable */
import React, {useState, useEffect} from 'react';
import {
  View,
  Text,
  ImageBackground,
  TouchableWithoutFeedback,
  Dimensions,
  StyleSheet,
  Pressable,
} from 'react-native';
import {Button, Modal, Progress} from 'native-base';
import FeatherIcon from 'react-native-vector-icons/Feather';
import Svg, {Ellipse} from 'react-native-svg';
import {useSelector} from 'react-redux';
import {AccountData} from '@models/AccountData/AccountData';
import {uploadUserProfileImage} from '@redux/actions/userAccount';
import ToasterService from '@services/toasterService';
import ConstantsEnum from '@constants/constantsEnum';
import {UpdateUserProfilePayload} from '@models/UpdateUserProfilePayload';
import Spinner from 'react-native-loading-spinner-overlay';
import NavigationService from '@services/navigationService';
import {AuthScreens} from '@navigations/ScreenNames';
import Icon from 'react-native-vector-icons/Ionicons';
import {faImage, faXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {dispatch} from '@redux/store';
import {
  userAccountDataSelector,
  profileImageUploadStatusSelector,
} from '@redux/selectors/userAccountSelectors';
import Analytics, {IEventData} from '@wyse/analytics';
import {
  EventTypeEnum,
  ProfileCreationAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import {resolveImageAssetSource} from '@utils/utilityFunctions';

const {width, height} = Dimensions.get('window');
import WhatsappSupport from '@components/WhatsappSupport';
import {openCamera, openPicker} from '@libs/ImagePicker';
import WebContainer from '@components/WebContainer';
import {
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Person,
  RandomAvatar,
} from '@assets';
const randomAvatarURI = resolveImageAssetSource(RandomAvatar).uri;

export const ProfilePicUpload = ({}: {navigation: any}) => {
  // TODO - New impelmentation of selectors to be done
  const userAccountData: AccountData | null | undefined = useSelector(
    userAccountDataSelector,
  );
  const profileImageUploadStatus: string = useSelector(
    profileImageUploadStatusSelector,
  );

  const [openProfilePickerOptionModal, setOpenProfilePickerOptionModal] =
    useState<boolean>(false);
  const [imageUri, setImageUri] = useState<string | null>(null);

  const avatars = [
    {uri: Avatar1},
    {uri: Avatar2},
    {uri: Avatar3},
    {uri: Avatar4},
  ];

  useEffect(() => {
    if (profileImageUploadStatus === ConstantsEnum.ApiStatusEnum.SUCCEEDED) {
      setImageUri(null);
      ToasterService.showToast(
        'Profile Image uploaded successfully!!',
        '#27AE60',
      );
      // dispatch(
      //   // updateProfileImageUploadStatus(ConstantsEnum.ApiStatusEnum.IDLE),
      // );
      NavigationService.getInstance().replaceToAuth({
        page: AuthScreens.SelectSpacesSignupScreen,
      });
    } else if (
      profileImageUploadStatus === ConstantsEnum.ApiStatusEnum.FAILED
    ) {
      setImageUri(null);
      ToasterService.showToast(
        'Error occurred while uploading Image, please try again!!',
        '#EB5757',
      );
    }
  }, [profileImageUploadStatus]);

  const openModal = () => {
    setOpenProfilePickerOptionModal(true);
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: ProfileCreationAnalEventValue.DP_UPLOAD_CLICK,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
  };

  const updateUserProfilePic = () => {
    if (imageUri) {
      const payload: UpdateUserProfilePayload = {
        imageUri: imageUri,
        thingId: userAccountData!.thing_id,
        //TODO - nullCheck
        userName: userAccountData!.user_fullname!,
      };
      dispatch(uploadUserProfileImage(payload));
    } else {
      ToasterService.showToast('Please select image!!', '#EB5757');
    }
  };

  const skipProfilePicSelection = () => {
    const payload: UpdateUserProfilePayload = {
      imageUri: randomAvatarURI,
      thingId: userAccountData!.thing_id,
      userName: userAccountData!.user_fullname!,
    };
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: ProfileCreationAnalEventValue.DP_SKIP_CLICK,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
    setTimeout(() => {
      setImageUri(null);
      NavigationService.getInstance().replaceToAuth({
        page: AuthScreens.SelectSpacesSignupScreen,
      });
    }, 100);
  };

  const onGalleryPress = async () => {
    try {
      const result = await openPicker({
        mediaType: 'photo',
      });
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: ProfileCreationAnalEventValue.DP_UPLOAD_GALLERY_CLICK,
        thing_type: ThingTypeEnum.NA,
      };
      Analytics.trackEvent(analyticsEventPayload);
      if (!!result.sourceURL) {
        //TODO null check on uri
        const uri = result.sourceURL;
        setImageUri(uri);
        setOpenProfilePickerOptionModal(false);
      }
    } catch (error) {}
  };

  const onCameraPress = async () => {
    try {
      const result = await openCamera({
        mediaType: 'photo',
      });
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: ProfileCreationAnalEventValue.DP_UPLOAD_CAMERA_CLICK,
        thing_type: ThingTypeEnum.NA,
      };
      Analytics.trackEvent(analyticsEventPayload);
      if (!!result.sourceURL) {
        //TODO null check on uri
        const uri = result.sourceURL;
        setImageUri(uri);
        setOpenProfilePickerOptionModal(false);
      }
    } catch (error) {}
  };

  return (
    <WebContainer style={{flex: 1}}>
      <Spinner
        visible={
          profileImageUploadStatus === ConstantsEnum.ApiStatusEnum.LOADING
        }
        textContent={
          imageUri ? 'Uploading Image..' : 'Assigning random avatar for you...'
        }
        textStyle={styles.spinnerTextStyle}
      />
      <View style={{paddingHorizontal: 16, paddingVertical: 32}}>
        <View style={styles.topHeaderContainer}>
          <Progress
            value={66}
            width="100%"
            size="sm"
            bg="#E0E0E0"
            _filledTrack={{bg: '#9B51E0'}}
            style={{alignSelf: 'center', borderRadius: 0}}
          />
          <View style={styles.stepIndicatorContainer}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Icon
                name="arrow-back"
                size={14}
                onPress={() => {}}
                color="#333333"
                style={{marginRight: 13}}
              />
              <Text style={styles.stepIndicatorText}>STEP 2/3</Text>
            </View>
            <WhatsappSupport
              mobileNumber={ConstantsEnum.WhatsappSupportNumber}
            />
          </View>

          <View style={styles.titleContainer}>
            <Text style={styles.title}>Choose a profile picture</Text>
          </View>
        </View>

        <View style={styles.imageContainer}>
          <TouchableWithoutFeedback onPress={openModal}>
            <View
              style={{
                alignSelf: 'center',
                marginTop: height * 0.095,
              }}>
              <View style={[styles.ellipseStack]}>
                <Svg viewBox="0 -1 111.76 115.53" style={styles.ellipse}>
                  <Ellipse
                    stroke="rgba(204, 204, 204,1)"
                    strokeWidth={1}
                    fill="rgba(255, 255, 255,1)"
                    cx={57}
                    cy={57}
                    rx={56}
                    ry={57}
                    strokeDasharray={[4, 4]}></Ellipse>
                </Svg>
                <Pressable
                  style={styles.circlePlus}
                  onPress={
                    imageUri !== null
                      ? () => {
                          setImageUri(null);
                        }
                      : openModal
                  }>
                  <FontAwesomeIcon
                    icon={imageUri === null ? faImage : faXmark}
                    color="#FFFFFF"
                    size={14}
                  />
                </Pressable>
                {imageUri && (
                  <ImageBackground
                    source={{uri: imageUri}}
                    resizeMode="cover"
                    style={{
                      flex: 1,
                    }}
                    borderRadius={162}
                  />
                )}
                <ImageBackground
                  source={Person}
                  resizeMode="contain"
                  style={[
                    styles.ellipseImage,
                    {
                      display: imageUri == null ? 'flex' : 'none',
                    },
                  ]}
                />
              </View>
            </View>
          </TouchableWithoutFeedback>
          <View style={styles.customAvatarContainer}>
            <Text style={styles.customAvatarText}>Or choose a wyse avatar</Text>

            <View style={{display: 'flex', flexDirection: 'row'}}>
              {avatars.map((item, index) => (
                <Pressable
                  onPress={() => {
                    setImageUri(resolveImageAssetSource(item.uri).uri);
                  }}
                  key={index}>
                  <ImageBackground
                    source={item.uri}
                    style={{width: 40, height: 40, marginHorizontal: 12}}
                    resizeMode="contain"
                  />
                </Pressable>
              ))}
            </View>
          </View>
        </View>

        <View style={styles.buttonContainer}>
          <Button
            style={{
              backgroundColor: imageUri === null ? '#E0E0E0' : '#9B51E0',
              borderRadius: 8,
            }}
            onPress={updateUserProfilePic}>
            <Text
              style={[
                styles.buttonText,
                {
                  color: imageUri === null ? '#BDBDBD' : '#FFFFFF',
                },
              ]}>
              Continue
            </Text>
          </Button>

          <Button style={styles.skipBtn} onPress={skipProfilePicSelection}>
            <Text
              style={[
                styles.buttonText,
                {
                  color: '#181818',
                },
              ]}>
              Skip
            </Text>
          </Button>
        </View>

        <Modal
          isOpen={openProfilePickerOptionModal}
          onClose={() => setOpenProfilePickerOptionModal(false)}
          safeAreaTop={true}>
          <Modal.Content
            width="100%"
            {...{marginBottom: -2, marginTop: 'auto'}}>
            <Modal.CloseButton />
            <Modal.Header>
              <Text style={styles.modalTitle}>Choose Image from</Text>
            </Modal.Header>

            <Modal.Body style={styles.modalBody}>
              <TouchableWithoutFeedback onPress={onGalleryPress}>
                <View
                  style={[
                    styles.modalIconContainer,
                    {
                      marginRight: width * 0.05,
                    },
                  ]}>
                  <View>
                    <FeatherIcon
                      name="image"
                      style={styles.bottomSheetIcon}></FeatherIcon>
                  </View>
                  <Text style={styles.modalIconText}>Gallery</Text>
                </View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback onPress={onCameraPress}>
                <View style={styles.modalIconContainer}>
                  <View>
                    <FeatherIcon
                      name="camera"
                      style={styles.bottomSheetIcon}></FeatherIcon>
                  </View>
                  <Text style={styles.modalIconText}>Camera</Text>
                </View>
              </TouchableWithoutFeedback>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      </View>
    </WebContainer>
  );
};

const styles = StyleSheet.create({
  spinnerTextStyle: {
    color: '#FFF',
  },
  ellipse: {
    top: 0,
    left: 0,
    width: 176,
    height: 176,
    position: 'absolute',
  },
  circlePlus: {
    top: height * 0.02,
    left: height * 0.174,
    position: 'absolute',
    color: 'rgba(155,81,224,1)',
    fontSize: 40,
    height: 36,
    width: 36,
    borderRadius: 18,
    borderWidth: 2,
    backgroundColor: '#9B51E0',
    borderColor: '#FFFFFF',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  bottomSheetIcon: {
    color: 'rgba(128,128,128,1)',
    fontSize: 30,
  },
  ellipseStack: {
    width: 176,
    height: 176,
    alignSelf: 'center',
  },
  topHeaderContainer: {
    height: height * 0.125,
  },
  stepIndicatorContainer: {
    marginTop: 16,
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stepIndicatorText: {
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 20,
    color: '#181818',
    letterSpacing: 0.09,
  },
  titleContainer: {
    alignSelf: 'stretch',
    marginTop: 16,
    marginBottom: 24,
  },
  title: {
    fontFamily: 'DMSans-Bold',
    fontSize: 24,
    fontWeight: '700',
    lineHeight: 36,
    color: '#181818',
  },
  imageContainer: {
    marginTop: height * 0.03,
    height: height * 0.53,
    alignSelf: 'stretch',
  },
  ellipseImage: {
    width: 120,
    height: 120,
    position: 'absolute',
    left: '18%',
    top: '18%',
  },
  customAvatarContainer: {
    alignSelf: 'stretch',
    marginTop: height * 0.04,
    alignItems: 'center',
  },
  customAvatarText: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 18,
    color: '#666666',
    marginBottom: height * 0.02,
    marginTop: height * 0.04,
  },
  buttonContainer: {
    alignSelf: 'stretch',
    marginTop: height * 0.125,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 20,
  },
  skipBtn: {
    backgroundColor: 'transparent',
    borderRadius: 8,
    marginTop: 12,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 24,
    color: '#333333',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  modalIconContainer: {
    width: width * 0.18,
    height: height * 0.07,
    alignItems: 'center',
  },
  modalIconText: {
    fontSize: 11,
    fontWeight: '400',
    lineHeight: 24,
    color: '#4F4F4F',
  },
});

export default ProfilePicUpload;
