import React, {createRef, useEffect, useMemo, useState} from 'react';
import type {FC} from 'react';
import {
  Animated,
  Pressable,
  StyleSheet,
  View,
  ActivityIndicator,
} from 'react-native';
import {useSelector} from 'react-redux';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Icon from 'react-native-vector-icons/Ionicons';
import {
  BottomSheetModal,
  useBottomSheetDynamicSnapPoints,
} from '@gorhom/bottom-sheet';
import {CustomSimpleBackdrop} from '@components/CustomBackdrop';
import CustomAvatar from '@components/Avatar';
import {ListLoaderProvider} from '@components/FeedListLoader';
import type {Conversation} from '@models/Conversation';
import {
  RegularText,
  RegularThinText,
  SmallText,
  SmallThinText,
} from '@components/Texts';
import Colors from '@constants/Colors';
import NavigationService from '@services/navigationService';
import FirestoreService from '@services/firestoreService';
import ToasterService from '@services/toasterService';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {SheetTitle} from './SheetComponent';
import {
  createGetDocumentIdSelector,
  userDocumentIdSelector,
} from '@redux/selectors/profileSelector';
import {dispatch} from '@redux/store';
import {setDocumentId} from '@redux/slices/profileSlice';
import {FontFamily} from '@utils/sizes';

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    alignSelf: 'stretch',
  },
  line: {alignSelf: 'stretch', height: 1, backgroundColor: Colors.background},
  userDetailsContainer: {
    alignSelf: 'stretch',
    margin: 16,
    borderRadius: 8,
    backgroundColor: Colors.background,
    padding: 16,
  },
  profileInfoContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: Colors.white,
  },
  viewProfileButton: {},
  profileInfoSubContainer: {flex: 1, paddingHorizontal: 10},
  messTitleText: {
    alignSelf: 'stretch',
    marginTop: 10,
  },
  messageText: {alignSelf: 'stretch', marginTop: 4},
  buttonContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    paddingHorizontal: 16,
    height: 36,
    marginVertical: 16,
  },
  button: {
    flex: 1,
    flexDirection: 'row',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonFilled: {
    flex: 1,
    flexDirection: 'row',
    borderRadius: 8,
    backgroundColor: Colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

type SheetOpenProps = Conversation & {};

class _ConversationRequestSheet {
  private resolve?: (value?: 'accept' | 'reject') => void;
  public sheetRef = createRef<BottomSheetModal>();
  public openSheet(data: SheetOpenProps) {
    return new Promise<'accept' | 'reject' | undefined>(resolve => {
      this.resolve = resolve;
      this.sheetRef.current?.present(data);
    });
  }
  public closeSheet(data?: 'accept' | 'reject') {
    this.sheetRef.current?.close();
    this.resolve?.(data);
    this.resolve = undefined;
  }
}

const ConversationRequestSheet = new _ConversationRequestSheet();

const Line = ({style}: {style?: {marginVertical: string}}) => (
  <View style={[styles.line, style as any]} />
);

const SheetComponent: FC<{data: SheetOpenProps; bottom?: number}> = ({
  data,
  bottom,
}) => {
  const userId = useSelector(userAccountThingIdSelector);
  const docId = useSelector(createGetDocumentIdSelector(data.user_thing_id));
  const userDocId = useSelector(userDocumentIdSelector);
  const [declining, setDeclining] = useState(false);
  const [accepting, setAccepting] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (!userId) throw new Error('userId is required');
        const response1 = await FirestoreService.getUserDocId(
          data.user_thing_id,
        );
        const response2 = await FirestoreService.getUserDocId(userId);
        dispatch(setDocumentId({id: data.user_thing_id, docId: response1}));
        dispatch(setDocumentId({id: userId, docId: response2}));
      } catch (error) {
        ToasterService.showToast('Unable to get user info');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ListLoaderProvider loading={!docId}>
      <View style={[styles.container, {paddingBottom: bottom || 20}]}>
        <SheetTitle title="New Message Request" />
        <Line />
        <View style={styles.userDetailsContainer}>
          <View style={styles.profileInfoContainer}>
            <CustomAvatar
              width={32}
              height={32}
              borderRadius={32}
              backgroundColor={Colors.white}
              source={data.user_avatar || ''}
            />
            <View style={styles.profileInfoSubContainer}>
              <RegularThinText
                color="text"
                fontFamily={FontFamily.lato_regular}>
                {data.user_fullname}
              </RegularThinText>
              {/* <SmallThinText color="lightText">
              {data. + ' | ' + authorCurrentOrgName}
            </SmallThinText> */}
            </View>
            <Pressable
              onPress={() => {
                ConversationRequestSheet.closeSheet();
                NavigationService.getInstance().openProfileDetails(
                  data.user_thing_id,
                );
              }}
              style={styles.viewProfileButton}>
              <SmallText color="primary" fontFamily={FontFamily.lato_regular}>
                View Profile
              </SmallText>
            </Pressable>
          </View>
          <RegularText
            style={styles.messTitleText}
            fontFamily={FontFamily.lato_regular}
            color="text">
            {data.subject}
          </RegularText>
          <SmallThinText
            style={styles.messageText}
            fontFamily={FontFamily.lato_regular}
            color="lightText">
            {data.message}
          </SmallThinText>
        </View>
        <Line />
        <View style={styles.buttonContainer}>
          <Pressable
            onPress={async () => {
              if (!(docId && userDocId)) return;
              setDeclining(true);
              try {
                await FirestoreService.rejectConversationRequest(
                  userDocId,
                  data.conversation_id,
                  docId,
                );
                setDeclining(false);
                ConversationRequestSheet.closeSheet('reject');
              } catch (error) {
                console.log('Pressable reject error', error);
              }
            }}
            style={styles.button}>
            {declining ? <ActivityIndicator color={Colors.primary} /> : null}
            {!declining ? (
              <Icon name="close-outline" size={16} color={Colors.primary} />
            ) : null}
            {!declining ? <View style={{width: 8}} /> : null}
            {!declining ? (
              <RegularText color="primary" fontFamily={FontFamily.lato_regular}>
                Reject
              </RegularText>
            ) : null}
          </Pressable>
          <View style={{width: 8}} />
          <Pressable
            onPress={async () => {
              if (!userDocId) return;
              try {
                setAccepting(true);
                await FirestoreService.acceptConversationRequest(
                  userDocId,
                  data.conversation_id,
                );
                setAccepting(false);
                ConversationRequestSheet.closeSheet('accept');
              } catch (error) {
                console.log('error', error);
              }
            }}
            style={styles.buttonFilled}>
            {accepting ? <ActivityIndicator color={Colors.white} /> : null}
            {/* {!accepting ? (
              <Icon name="checkmark" size={16} color={Colors.white} />
            ) : null} */}
            {!accepting ? <View style={{width: 8}} /> : null}
            {!accepting ? (
              <RegularText fontFamily={FontFamily.lato_regular} color="white">
                Accept
              </RegularText>
            ) : null}
          </Pressable>
        </View>
      </View>
    </ListLoaderProvider>
  );
};

export const ConversationRequestSheetcomponent = () => {
  const {bottom} = useSafeAreaInsets();

  const {
    animatedSnapPoints,
    animatedHandleHeight,
    handleContentLayout,
    animatedContentHeight,
  } = useBottomSheetDynamicSnapPoints(['CONTENT_HEIGHT']);

  return useMemo(
    () => (
      <BottomSheetModal
        backdropComponent={props => (
          <CustomSimpleBackdrop
            sheetRef={ConversationRequestSheet.sheetRef}
            {...props}
          />
        )}
        snapPoints={animatedSnapPoints}
        handleHeight={animatedHandleHeight}
        contentHeight={animatedContentHeight}
        ref={ConversationRequestSheet.sheetRef}
        children={({data}) => (
          <Animated.View
            style={{alignSelf: 'stretch'}}
            onLayout={handleContentLayout}>
            <SheetComponent {...{data, bottom}} />
          </Animated.View>
        )}
      />
    ),
    [
      animatedSnapPoints,
      animatedHandleHeight,
      handleContentLayout,
      animatedContentHeight,
      bottom,
    ],
  );
};

export default ConversationRequestSheet;
