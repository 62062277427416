/*eslint-disable */
import {
  View,
  Text,
  TextInput,
  Dimensions,
  KeyboardAvoidingView,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import {Button as Btn, Modal} from 'native-base';
import {FontFamily} from '@utils/sizes';

const {width, height} = Dimensions.get('window');

const CreatePollModal = ({
  isOpen,
  setIsOpen,
  polls,
  setPolls,
  initialPoll,
  onChange,
  addOption,
}: {
  isOpen: any;
  setIsOpen: any;
  polls: any;
  setPolls: any;
  initialPoll: any;
  onChange: any;
  addOption: any;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        setPolls(initialPoll);
      }}
      safeAreaTop={true}
      avoidKeyboard={false}
      closeOnOverlayClick={false}>
      <KeyboardAvoidingView behavior="padding" style={{flex: 1}}>
        <Modal.Content
          width="100%"
          maxHeight={'60%'}
          {...{marginBottom: -2, marginTop: 'auto'}}>
          <Modal.CloseButton />
          <Modal.Header>
            <Text
              style={{
                fontSize: 18,
                fontWeight: '600',
                lineHeight: 24,
                color: '#333333',
                fontFamily: FontFamily.lato_regular,
              }}>
              Create Poll
            </Text>
          </Modal.Header>

          <Modal.Body>
            {/* <View
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                marginBottom: 10,
              }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: '400',
                  lineHeight: 16,
                  color: '#4F4F4F',
                }}>
                Your Question
              </Text>

              <View
                style={{
                  height: 40,
                  width: '100%',
                  borderBottomWidth: 1,
                  borderBottomColor: '#4F4F4F',
                }}>
                <TextInput
                  style={{
                    fontSize: 14,
                    fontWeight: '400',
                    lineHeight: 18,
                    color: '#4F4F4F',
                    paddingVertical: 10,
                  }}
                  placeholder="Add question"
                  placeholderTextColor="#BDBDBD"
                  onChangeText={text => onChange('QUESTION', text)}
                />
              </View>
            </View> */}
            {polls.options.map((item, index) => (
              <View
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginBottom: 10,
                }}
                key={index}>
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: '400',
                    lineHeight: 16,
                    color: '#4F4F4F',
                    fontFamily: FontFamily.lato_regular,
                  }}>
                  Option {index + 1}
                </Text>
                <View
                  style={{
                    height: 40,
                    width: '100%',
                    borderBottomWidth: 1,
                    borderBottomColor: '#4F4F4F',
                  }}>
                  <TextInput
                    style={{
                      fontSize: 14,
                      fontWeight: '400',
                      lineHeight: 18,
                      color: '#4F4F4F',
                      paddingVertical: 10,
                    }}
                    placeholder="Add option"
                    placeholderTextColor="#BDBDBD"
                    onChangeText={text => onChange(text, index)}
                  />
                </View>
              </View>
            ))}
          </Modal.Body>
          <Modal.Footer
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <View style={{width: width * 0.85}}>
              <Btn
                variant="outline"
                size="sm"
                style={{
                  borderColor: '#9B51E0',
                  borderRadius: 8,
                  marginBottom: height * 0.01,
                }}
                onPress={() => addOption()}>
                <Text
                  style={{
                    color: '#9B51E0',
                    fontFamily: FontFamily.lato_regular,
                  }}>
                  Add Option
                </Text>
              </Btn>
            </View>
            <View style={{width: width * 0.85}}>
              <Btn
                variant="outline"
                size="sm"
                style={{
                  borderColor: polls.options.some(ele => ele === null)
                    ? '#BDBDBD'
                    : '#9B51E0',
                  borderRadius: 8,
                  marginBottom: height * 0.01,
                }}
                disabled={
                  polls.options.some(ele => ele === null) ? true : false
                }
                onPress={() => {
                  setIsOpen(false);
                }}>
                <Text
                  style={{
                    color: polls.options.some(ele => ele === null)
                      ? '#BDBDBD'
                      : '#9B51E0',
                    fontFamily: FontFamily.lato_regular,
                  }}>
                  Create
                </Text>
              </Btn>
            </View>
          </Modal.Footer>
        </Modal.Content>
      </KeyboardAvoidingView>
    </Modal>
  );
};

export default CreatePollModal;
