/*eslint-disable */
import React, {useState, useEffect, FC} from 'react';
import {useSelector} from 'react-redux';
import {
  View,
  Text,
  TextInput,
  Dimensions,
  SafeAreaView,
  Platform,
  Pressable,
  Image,
  StyleSheet,
} from 'react-native';
import {Button, ScrollView, Progress} from 'native-base';
import {importUserAccountDataFromLinkedIn} from '@redux/actions/userAccount';
// import {updateLinkedinImportStatus} from '@redux/slices/userAccountDataSlice';
import ToasterService from '@services/toasterService';
import {AccountData} from '@models/AccountData/AccountData';
import {AccountInfoRequestPayload} from '@models/AccountData/AccountInfoRequestPayload';
import ConstantsEnum from '@constants/constantsEnum';
const {width, height} = Dimensions.get('window');
import {faChevronDown, faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {StackActions} from '@react-navigation/native';
import {AuthScreens} from '@navigations/ScreenNames';
import {
  userAccountDataSelector,
  linkedInImportStastusSelector,
} from '@redux/selectors/userAccountSelectors';
import {dispatch} from '@redux/store';
import WhatsappSupport from '@components/WhatsappSupport';
import Analytics, {IEventData, IExtraData} from '@wyse/analytics';
import {
  EventTypeEnum,
  ProfileCreationAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import WebContainer from '@components/WebContainer';
import {ImportLoading} from '@assets';
import {FontFamily, FontSize} from '@utils/sizes';
import {CustomText} from '@components/Texts';
import {isWeb} from '@utils/useColors';

type InstructionProps = {
  index: number;
  part1: string;
  part2: string;
  part3: string;
};

export const LinkedinInstruction: FC<InstructionProps> = ({
  index,
  part1,
  part2,
  part3,
}) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 16,
    }}>
    <View style={styles.instructionNumberCircle}>
      <CustomText
        size={FontSize.title}
        fontFamily={FontFamily.FigtreeSemiBold}
        style={styles.instructionNumberText}>
        {index}
      </CustomText>
    </View>
    <CustomText size={FontSize.title} style={styles.instructionText}>
      {part1}{' '}
      <CustomText size={FontSize.title} fontFamily={FontFamily.FigtreeBold}>
        {part2}{' '}
      </CustomText>
      {part3}
    </CustomText>
  </View>
);

export const LinkedinImportScreen = ({navigation}: {navigation: any}) => {
  const [linkedinURL, setLinkedinURL] = useState('');
  const [showInstruction, setShowInstruction] = useState<boolean>(false);
  const [_, setLnkdImportInProg] = useState<boolean>(false);

  const linkedinInstructions = [
    {part1: 'Tap your', part2: 'profile photo', part3: ''},
    {part1: 'Tap', part2: 'view profile', part3: ''},
    {part1: 'Tap on the icon next to', part2: 'Add section', part3: 'button'},
    {part1: 'Tap', part2: 'share', part3: ''},
    {
      part1: 'Click on Copy and paste this link on Wyse App',
      part2: '',
      part3: '',
    },
  ];

  const linkedinImportStatus: string = useSelector(
    linkedInImportStastusSelector,
  );
  const userAccountData: AccountData | null | undefined = useSelector(
    userAccountDataSelector,
  );

  useEffect(() => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: ProfileCreationAnalEventValue.ONB_LINK_IMPORT_IMPR,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
  }, []);

  const fetchUserDataFromLinkedInImport = async () => {
    let updatedLinkedInURL = linkedinURL;

    if (!updatedLinkedInURL.startsWith('https://')) {
      updatedLinkedInURL = 'https://' + updatedLinkedInURL;
    }
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: ProfileCreationAnalEventValue.ONB_LINK_IMPORT_INITIATED,
      thing_type: ThingTypeEnum.ANALYTICS,
      extra_data: {
        linkedinUrl: updatedLinkedInURL,
      },
    };
    Analytics.trackEvent(analyticsEventPayload);
    const userAccountInfo: AccountInfoRequestPayload = {
      linkedinProfileUrl: updatedLinkedInURL,
      thingId: userAccountData?.thing_id!,
      phoneNumber: null,
      uuid: userAccountData?.thing_id!,
    };
    setLnkdImportInProg(true);
    const response = await dispatch(
      importUserAccountDataFromLinkedIn(userAccountInfo),
    );
    if (importUserAccountDataFromLinkedIn.fulfilled.match(response)) {
      setLnkdImportInProg(false);
      const extraData: IExtraData = {
        linkedinUrl: linkedinURL,
      };
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: ProfileCreationAnalEventValue.ONB_LINK_IMPORT_SUCCESS,
        thing_type: ThingTypeEnum.NA,
        extra_data: JSON.stringify(extraData),
      };
      Analytics.trackEvent(analyticsEventPayload);
      ToasterService.showToast(
        'Linkedin data fetched successfully!!',
        '#27AE60',
      );
      navigation.dispatch(StackActions.replace(AuthScreens.ManualImportScreen));
      return response.meta.requestStatus;
    }
    setLnkdImportInProg(false);
    const extraData: IExtraData = {
      linkedinUrl: linkedinURL,
    };
    const payload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: ProfileCreationAnalEventValue.ONB_LINK_IMPORT_ERROR,
      thing_type: ThingTypeEnum.NA,
      extra_data: JSON.stringify(extraData),
    };
    Analytics.trackEvent(payload);
    ToasterService.showToast(
      'Couldn’t import details from linkedin, please fill details manually',
      '#EB5757',
    );
    navigation.dispatch(StackActions.replace(AuthScreens.ManualImportScreen));
    return 'rejected';
  };

  const handleManualImport = () => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val:
        ProfileCreationAnalEventValue.ONB_LINK_IMPORT_ADD_DETAILS_MANUAL_CLICK,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
    navigation.dispatch(StackActions.replace(AuthScreens.ManualImportScreen));
  };

  return (
    <WebContainer
      style={{
        flex: 1,
      }}>
      <SafeAreaView style={{paddingHorizontal: 16, paddingVertical: 32}}>
        <Progress
          value={33}
          width="100%"
          size="sm"
          bg="#E0E0E0"
          _filledTrack={{bg: '#9B51E0'}}
          style={{alignSelf: 'center', borderRadius: 0}}
        />
        <View style={styles.stepsContainer}>
          <CustomText
            fontFamily={FontFamily.FigtreeSemiBold}
            style={styles.stepText}>
            STEP 1/3
          </CustomText>
          <WhatsappSupport mobileNumber={ConstantsEnum.WhatsappSupportNumber} />
        </View>
        {linkedinImportStatus === ConstantsEnum.ApiStatusEnum.SUCCEEDED && (
          <View style={styles.importSuccessContainer}>
            <View style={styles.importSuccessIconContainer}>
              <FontAwesomeIcon icon={faCheck} color="#FFFFFF" size={24} />
            </View>
            <CustomText
              size={FontSize.medium}
              fontFamily={FontFamily.FigtreeBold}
              style={styles.importSuccessText}>
              Imported
            </CustomText>
          </View>
        )}
        {linkedinImportStatus === ConstantsEnum.ApiStatusEnum.LOADING && (
          <View style={styles.importLoadingContainer}>
            <Image source={ImportLoading} style={{width: 120, height: 160}} />
            <View style={{marginTop: 16}}>
              <CustomText
                size={FontSize.medium}
                fontFamily={FontFamily.FigtreeBold}
                style={styles.importLoadingHeading}>
                Importing details from linkedin
              </CustomText>
              <CustomText style={styles.importLoadingText}>
                Please hold on for a few second.
              </CustomText>
            </View>
            <View style={styles.linkedinInputContainer}>
              <View>
                <CustomText style={styles.linkedinInputContainerText}>
                  Your Linkedin Profile URL
                </CustomText>
              </View>

              <View style={styles.textInputContainer}>
                <TextInput
                  style={styles.linkedinUrlText}
                  placeholder="Your Linkedin profile link"
                  selectionColor={'#9B51E0'}
                  onChangeText={text => setLinkedinURL(text)}
                />
              </View>
            </View>
            <View
              style={{
                width: width * 0.91,
                alignSelf: 'center',
                marginTop: 24,
              }}>
              <Button
                style={[
                  styles.button,
                  {
                    backgroundColor:
                      linkedinURL.length != 0 ? '#9B51E0' : '#E0E0E0',
                  },
                ]}
                onPress={fetchUserDataFromLinkedInImport}
                disabled={linkedinURL.length != 0 ? false : true}>
                <CustomText
                  size={FontSize.title}
                  fontFamily={FontFamily.FigtreeMedium}
                  style={[
                    styles.buttonText,
                    {
                      color: linkedinURL.length != 0 ? '#F4F4F4' : '#BDBDBD',
                    },
                  ]}>
                  Next
                </CustomText>
              </Button>

              <Button
                style={styles.manualImportButton}
                onPress={handleManualImport}>
                <CustomText
                  size={FontSize.title}
                  fontFamily={FontFamily.FigtreeMedium}
                  style={styles.manualImportButtonText}>
                  Add Details Manually
                </CustomText>
              </Button>
            </View>
            <View
              style={[
                styles.instructionContainer,
                {
                  backgroundColor: showInstruction ? '#EEEEEE' : 'transparent',
                },
              ]}>
              <Pressable
                style={[
                  styles.instructionToggle,
                  {
                    marginTop: showInstruction ? 32 : 0,
                  },
                ]}
                onPress={() => setShowInstruction(!showInstruction)}>
                <CustomText style={styles.instructionToggleText}>
                  How to find your linkedin profile URL?
                </CustomText>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  color="#4F4F4F"
                  size={12}
                />
              </Pressable>
              <View
                style={{
                  width: '100%',
                  alignSelf: 'center',
                  marginTop: 18,
                }}>
                <CustomText
                  size={FontSize.largeHeading}
                  fontFamily={FontFamily.FigtreeBold}
                  style={styles.heading}>
                  Create account via Linkedin
                </CustomText>
              </View>
              <View
                style={{
                  width: '100%',
                  alignSelf: 'center',
                  marginTop: 8,
                }}>
                <CustomText
                  fontFamily={FontFamily.FigtreeMedium}
                  size={FontSize.small}
                  style={styles.text}>
                  Setup your Wyse profile by importing your Linkedin details
                </CustomText>
              </View>
              <View style={styles.linkedinInputContainer}>
                <View>
                  <CustomText style={styles.linkedinInputContainerText}>
                    Your Linkedin Profile URL
                  </CustomText>
                </View>

                <View style={styles.textInputContainer}>
                  <TextInput
                    style={styles.linkedinUrlText}
                    placeholder="Paste your Linkedin profile link"
                    selectionColor={'#9B51E0'}
                    onChangeText={text => setLinkedinURL(text)}
                  />
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  alignSelf: 'center',
                  marginTop: 24,
                }}>
                <Button
                  style={[
                    styles.button,
                    {
                      backgroundColor:
                        linkedinURL.length != 0 ? '#9B51E0' : '#E0E0E0',
                    },
                  ]}
                  onPress={fetchUserDataFromLinkedInImport}
                  disabled={linkedinURL.length != 0 ? false : true}>
                  <CustomText
                    style={[
                      styles.buttonText,
                      {
                        color: linkedinURL.length != 0 ? '#F4F4F4' : '#BDBDBD',
                      },
                    ]}>
                    Next
                  </CustomText>
                </Button>

                <Button
                  style={styles.manualImportButton}
                  onPress={handleManualImport}>
                  <Text style={styles.manualImportButtonText}>
                    Add Details Manually
                  </Text>
                </Button>
              </View>
              <View
                style={[
                  styles.instructionContainer,
                  {
                    backgroundColor: showInstruction
                      ? '#EEEEEE'
                      : 'transparent',
                  },
                ]}>
                <Pressable
                  style={[
                    styles.instructionToggle,
                    {
                      marginTop: showInstruction ? 32 : 0,
                    },
                  ]}
                  onPress={() => setShowInstruction(!showInstruction)}>
                  <CustomText style={styles.instructionToggleText}>
                    How to find your linkedin profile URL?
                  </CustomText>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="#4F4F4F"
                    size={12}
                  />
                </Pressable>
                <View
                  style={{
                    marginTop: 24,
                    display: showInstruction ? 'flex' : 'none',
                  }}>
                  {linkedinInstructions.map((item, index) => (
                    <LinkedinInstruction
                      key={index}
                      index={index + 1}
                      part1={item.part1}
                      part2={item.part2}
                      part3={item.part3}
                    />
                  ))}
                </View>
              </View>
            </View>
          </View>
        )}
      </SafeAreaView>
    </WebContainer>
  );
};

const styles = StyleSheet.create({
  spinnerTextStyle: {
    color: '#FFF',
  },
  instructionNumberCircle: {
    width: 22,
    height: 22,
    borderRadius: 11,
    backgroundColor: '#9B51E0',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 9,
  },
  instructionNumberText: {
    color: '#FFFFFF',
  },
  instructionText: {
    color: '#222222',
  },
  stepsContainer: {
    width: '100%',
    marginTop: 16,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stepText: {
    letterSpacing: 0.09,
  },
  importSuccessContainer: {
    alignSelf: 'center',
    marginTop: height * 0.3,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  importSuccessIconContainer: {
    height: 48,
    width: 48,
    borderRadius: 24,
    backgroundColor: '#0BD643',
    justifyContent: 'center',
    alignItems: 'center',
  },
  importSuccessText: {
    lineHeight: 24,
    color: '#222222',
    marginTop: 16,
  },
  importLoadingContainer: {
    alignSelf: 'center',
    marginTop: height * 0.3,
    width: width * 0.91,
    justifyContent: 'center',
    alignItems: 'center',
  },
  importLoadingHeading: {
    color: '#222222',
    marginBottom: 4,
    textAlign: 'center',
  },
  importLoadingText: {
    color: '#666666',
    textAlign: 'center',
  },
  heading: {
    color: '#181818',
  },
  text: {
    color: '#444444',
  },
  linkedinInputContainer: {
    width: '100%',
    alignSelf: 'center',
    marginTop: 24,
    display: 'flex',
    alignItems: 'flex-start',
  },
  linkedinInputContainerText: {
    textAlign: 'center',
    color: '#4F4F4F',
  },
  textInputContainer: {
    width: '100%',
    justifyContent: 'center',
    marginTop: 2,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#9B51E0',
  },
  linkedinUrlText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 16,
    color: '#4F4F4F',
    fontFamily: isWeb ? 'FigTree' : FontFamily.FigtreeRegular,
    padding: 16,
  },
  buttonText: {},
  button: {
    borderRadius: 8,
    padding: 12,
  },
  manualImportButton: {
    backgroundColor: 'transparent',
    padding: 4,
    marginTop: 12,
  },
  manualImportButtonText: {
    color: '#181818',
  },
  instructionContainer: {
    width: '100%',
    alignSelf: 'center',
    marginTop: 60,
    borderRadius: 16,
    paddingHorizontal: 16,
  },
  instructionToggle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  instructionToggleText: {
    color: '#4F4F4F',
  },
});

export default LinkedinImportScreen;
