import {createAsyncThunk} from '@reduxjs/toolkit';
import CommunityService from '@services/communityService';
import type {CommunityJoinEventPayload} from '@models/Community/CommunityJoinEventPayload';
import type {CommunityUtilPayload} from '@models/Community/CommunityUtilPayload';
import {addFeedsFromCommunity} from '@redux/slices/feedSlice';
import {
  EventTypeEnum,
  JoinEventEnum,
  SpacesAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import Analytics, {IEventData} from '@wyse/analytics';

export const fetchAllCommunityList = createAsyncThunk(
  'community/list/get',
  async (_, {rejectWithValue}) => {
    try {
      const response = await CommunityService.fetchAllCommunityList();
      return response.data.response.results;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchCommunityFeed = createAsyncThunk(
  'community/feed/get',
  async (payload: CommunityUtilPayload, {dispatch, rejectWithValue}) => {
    try {
      const response = await CommunityService.fetchCommunityFeedData(payload);
      dispatch(addFeedsFromCommunity(response.data.response.results));
      return response.data.response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchCommunityMembers = createAsyncThunk(
  'community/members/get',
  async (payload: CommunityUtilPayload, {rejectWithValue}) => {
    try {
      const response = await CommunityService.fetchCommunityMembers(payload);
      return response.data.response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const joinCommunityEventHandler = createAsyncThunk(
  'community/join',
  async (payload: CommunityJoinEventPayload, {rejectWithValue}) => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val:
        payload.action == JoinEventEnum.SUBSCRIBE
          ? SpacesAnalEventValue.SPACES_EXPLORED_COMMUNITY_JOIN
          : SpacesAnalEventValue.SPACES_JOINED_COMMUNITY_UNJOIN,
      thing_type: ThingTypeEnum.COMMUNITY,
      thing_id: payload.resource_id,
    };
    Analytics.trackEvent(analyticsEventPayload);
    try {
      await CommunityService.handleJoinEvent(payload);
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val:
          payload.action == JoinEventEnum.SUBSCRIBE
            ? SpacesAnalEventValue.SPACES_EXPLORED_COMMUNITY_JOIN_SUCCESS
            : SpacesAnalEventValue.SPACES_JOINED_COMMUNITY_UNJOIN_SUCCESS,
        thing_type: ThingTypeEnum.COMMUNITY,
        thing_id: payload.resource_id,
      };
      Analytics.trackEvent(analyticsEventPayload);
      return payload;
    } catch (error) {
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val:
          payload.action == JoinEventEnum.SUBSCRIBE
            ? SpacesAnalEventValue.SPACES_EXPLORED_COMMUNITY_JOIN_FAILED
            : SpacesAnalEventValue.SPACES_JOINED_COMMUNITY_UNJOIN_FAILED,
        thing_type: ThingTypeEnum.COMMUNITY,
        thing_id: payload.resource_id,
      };
      Analytics.trackEvent(analyticsEventPayload);
      return rejectWithValue(error.message);
    }
  },
);
