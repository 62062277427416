import {createAsyncThunk} from '@reduxjs/toolkit';
import NotificationService from '@services/notificationService';

export const fetchUserNotificationsList = createAsyncThunk(
  'userNotification/get',
  async (payload: {page: number; userId: string}, {rejectWithValue}) => {
    try {
      const response = await NotificationService.fetchUserNotificationsList(
        payload,
      );
      return response.data.response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateNotificationReadStatus = createAsyncThunk(
  'userNotificationReadStatus/get',
  async (payload: {notificationId: string}, {rejectWithValue}) => {
    try {
      const response = await NotificationService.updateNotificationReadStatus(
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
