import React, {useEffect} from 'react';
import {Provider} from 'react-redux';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {NativeBaseProvider} from 'native-base';
import {MenuProvider} from 'react-native-popup-menu';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@szhsin/react-menu/dist/index.css';
import {BottomSheetModalProvider} from '@gorhom/bottom-sheet';

import store from './redux/store';
import nexumTheme from './constants/theme';
import DynamicLinkService from './services/dynamicLinkService';
import PushNotificationService from './services/pushNotificationService';
import RemoteConfigService from './services/RemoteConfigService';
import {FeedDetailsPagesContextProvider} from './utils/FeedDetailsPagesContext';
import MainNavigator from './navigations/MainNavigator';
import DatabaseService from './services/DatabaseService';
import iconFont from './assets/icomoon.woff';
import IonIconsFont from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import SimpleLineIconsFont from 'react-native-vector-icons/Fonts/SimpleLineIcons.ttf';
import FeatherFont from 'react-native-vector-icons/Fonts/Feather.ttf';
import app from '@libs/Firebase/app/index.web';
import {firebaseConfig} from '@libs/Firebase/app/index.web';

app.initializeApp(firebaseConfig);

const iconFontCss = `
@font-face {
  src: url(${iconFont});
  font-family: icomoon;
}

@font-face {
  src: url(${IonIconsFont});
  font-family: IonIcons;
}

@font-face {
  src: url(${SimpleLineIconsFont});
  font-family: SimpleLineIcons;
}

@font-face {
  src: url(${FeatherFont});
  font-family: Feather;
}
`;

// Create stylesheet
const style = document.createElement('style');

if (style.style.cssText) {
  style.style.cssText = iconFontCss;
  // style.style.cssText = simpleFontStyles;
  // style.style.cssText = featherFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontCss));
  // style.appendChild(document.createTextNode(simpleFontStyles));
  // style.appendChild(document.createTextNode(featherFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);

export default function App() {
  useEffect(() => {
    const foregroundDynamicLinkSubscription =
      DynamicLinkService.foregroundDynamicLinkHandler();
    RemoteConfigService.fetchAndActivateRemoteConfig();
    PushNotificationService.requestNotificationPermission();
    PushNotificationService.registerBackgroundNotificationHandler();
    const foregroundNotificationSubscription =
      PushNotificationService.registerForegroundNotificationHandler();
    PushNotificationService.registerNotificationOpenHandler();

    return () => {
      foregroundDynamicLinkSubscription?.();
      foregroundNotificationSubscription?.();
    };
  }, []);

  return (
    <DatabaseService.Provider>
      <GestureHandlerRootView style={{flex: 1}}>
        <NativeBaseProvider theme={nexumTheme}>
          <Provider store={store}>
            <MenuProvider
              customStyles={{
                backdrop: {backgroundColor: '#00000040', flex: 1},
              }}>
              <BottomSheetModalProvider>
                <FeedDetailsPagesContextProvider>
                  <MainNavigator />
                  <ToastContainer />
                </FeedDetailsPagesContextProvider>
              </BottomSheetModalProvider>
            </MenuProvider>
          </Provider>
        </NativeBaseProvider>
      </GestureHandlerRootView>
    </DatabaseService.Provider>
  );
}
