import React from 'react';
import {View, Text} from 'react-native';
import {Button} from 'native-base';
import Icon from 'react-native-vector-icons/Ionicons';

const Option = ({text}: {text: string}) => {
  return (
    <View style={{marginBottom: 4}}>
      <Button
        variant="outline"
        size="sm"
        style={{
          borderColor: '#9B51E0',
          borderRadius: 4,
        }}>
        <Text
          style={{
            fontSize: 14,
            fontWeight: '700',
            lineHeight: 15,
            color: '#9B51E0',
          }}>
          {text}
        </Text>
      </Button>
      {/* <Progress
        value={65}
        width={'100%'}
        height={35}
        bg="#FFFFFF"
        _filledTrack={{bg: '#efe3fa'}}
        style={{borderColor: '#9B51E0', borderWidth: 2}}>
        <Text
          style={{
            fontSize: 14,
            fontWeight: '700',
            lineHeight: 15,
            color: '#9B51E0',
          }}>
          {text}
        </Text>
      </Progress> */}
    </View>
  );
};
const Polls = ({
  polls,
  setPolls,
  initialPoll,
}: {
  polls: any;
  setPolls: any;
  initialPoll: any;
}) => {
  return (
    <View
      style={{
        borderWidth: 2,
        borderColor: '#d7b9f3',
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        paddingHorizontal: 8,
        paddingVertical: 4,
      }}>
      <View
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 10,
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}>
        <Text
          style={{
            fontSize: 14,
            fontWeight: '700',
            lineHeight: 18,
            color: '#333333',
          }}>
          {polls.question}
        </Text>
        <Icon
          name="close-outline"
          size={23}
          onPress={() => {
            setPolls(initialPoll);
          }}
          color="#d7b9f3"
        />
      </View>
      <View>
        <View>
          {polls.options.map((item, index) => (
            <Option text={item} key={index} />
          ))}
        </View>
      </View>
    </View>
  );
};

export default Polls;
