import type {FirebaseFirestoreTypes} from '@react-native-firebase/firestore';

export enum MessageType {
  Text = 'text',
  Image = 'image',
  Video = 'video',
  Url = 'url',
  Post = 'post',
  Document = 'document',
  Request = 'request',
}

interface BaseMessage {
  _id: string;
  createdAt: FirebaseFirestoreTypes.Timestamp;
  received: boolean;
  sent: boolean;
  user: {
    _id: string;
  };
  text: string;
  local?: {uri: string};
  done: boolean;
}

export interface RequestMessage extends BaseMessage {
  subject: string;
  message: string;
  type: MessageType.Request;
}
export interface TextMessage extends BaseMessage {
  type: MessageType.Text;
}

export interface UrlMessage extends BaseMessage {
  type: MessageType.Url;
}

export interface ImageMessage extends BaseMessage {
  url: string;
  type: MessageType.Image;
}

export interface VideoMessage extends BaseMessage {
  url: string;
  type: MessageType.Video;
}

export interface DocumentMessage extends BaseMessage {
  type: MessageType.Document;
  url: string;
}

export interface PostMessage extends BaseMessage {
  //   post: Omit<FeedData, 'ups' | 'downs' | 'flairs' | 'created_at'>;
  feed_id: string;
  type: MessageType.Post;
}

export interface FireBaseMessage extends FirebaseFirestoreTypes.DocumentData {
  _id: string;
  createdAt: FirebaseFirestoreTypes.Timestamp;
  received: boolean;
  sent: boolean;
  text: string;
  user: {
    _id: string;
  };
}

export type Message =
  | TextMessage
  | UrlMessage
  | ImageMessage
  | VideoMessage
  | DocumentMessage
  | PostMessage
  | RequestMessage;
