/*eslint-disable */
import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  Image,
  TextInput,
  Dimensions,
  Platform,
  Keyboard,
  StyleSheet,
  ScrollView,
  SafeAreaView,
  TouchableWithoutFeedback,
} from 'react-native';
import {throttle} from 'lodash';
import {Button, Pressable, Progress, Modal} from 'native-base';
import {useSelector} from 'react-redux';
import {openCamera, openPicker} from '@libs/ImagePicker';
import ConstantsEnum from '@constants/constantsEnum';
import {ManualImportFormData} from '@models/AccountData/ManualImportFormData';
import {EditAccountDataPayload} from '@models/AccountData/EditAccountDataPayload';
import {AccountData} from '@models/AccountData/AccountData';
import {
  editUserAccountData,
  uploadUserProfileImage,
} from '@redux/actions/userAccount';
import ToasterService from '@services/toasterService';
import {
  convertToCamelCaseString,
  resolveImageAssetSource,
} from '@utils/utilityFunctions';
import {StackActions} from '@react-navigation/native';
import {AuthScreens} from '@navigations/ScreenNames';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {setLinkedinImportStatusIdle} from '@redux/slices/userAccountDataSlice';
import {faImage} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {userAccountDataSelector} from '@redux/selectors/userAccountSelectors';
import {organisationDataSelector} from '@redux/selectors/metaSelector';
import {dispatch} from '@redux/store';
import {fetchOrganistationData} from '@redux/actions/meta';
import AutoComplete from '@components/AutoComplete';
import roles from '@constants/Roles';
import WhatsappSupport from '@components/WhatsappSupport';
import Analytics, {type IEventData} from '@wyse/analytics';
import {
  EventTypeEnum,
  ProfileCreationAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import WebContainer from '@components/WebContainer';
import type {UpdateUserProfilePayload} from '@models/UpdateUserProfilePayload';
import {FontFamily} from '@utils/sizes';
import {RandomAvatar} from '@assets';
import {textInputStyles} from '@components/Inputs';

const profileUnavialableURI = resolveImageAssetSource(RandomAvatar).uri;

const {width, height} = Dimensions.get('window');

const styles = StyleSheet.create({
  circlePlus: {
    top: height * 0.02,
    left: width * 0.24,
    position: 'absolute',
    color: 'rgba(155,81,224,1)',
    fontSize: 40,
    height: 36,
    width: 36,
    borderRadius: 18,
    borderWidth: 2,
    backgroundColor: '#9B51E0',
    borderColor: '#FFFFFF',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  loaderConrainer: {
    alignSelf: 'center',
    width: '100%',
    alignItems: 'center',
    marginTop: height * 0.5,
  },
  loaderText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    color: '#666666',
    fontFamily: FontFamily.lato_regular,
  },
  stepIndicatorContainer: {
    marginTop: 16,
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stepIndicatorText: {
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 20,
    color: '#181818',
    letterSpacing: 0.09,
    fontFamily: FontFamily.lato_regular,
  },
  titleContainer: {
    alignSelf: 'stretch',
    marginTop: 16,
  },
  title: {
    fontFamily: 'DMSans-Bold',
    fontSize: 24,
    fontWeight: '700',
    lineHeight: 36,
    color: '#181818',
  },
  subTitleContainer: {
    alignSelf: 'stretch',
    marginTop: 8,
  },
  subtitle: {
    fontSize: 11,
    fontWeight: '400',
    lineHeight: 16,
    color: '#444444',
    fontFamily: FontFamily.lato_regular,
  },
  avatarContainer: {
    height: 128,
    width: 128,
    borderWidth: 2,
    borderRadius: 172,
  },
  formContainer: {
    alignSelf: 'stretch',
    marginTop: 24,
    alignItems: 'flex-start',
  },
  inputTitle: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 18,
    color: '#4F4F4F',
    fontFamily: FontFamily.lato_regular,
  },
  formTextInput: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 16,
    color: '#4F4F4F',
    padding: 16,
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderWidth: 1,
    borderColor: '#9B51E0',
    borderRadius: 4,
    marginTop: 2,
  },
  buttonContainer: {
    alignSelf: 'stretch',
    marginTop: 24,
    paddingVertical: 2,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 20,
    fontFamily: FontFamily.lato_regular,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 24,
    color: '#333333',
    fontFamily: FontFamily.lato_regular,
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  modalIconContainer: {
    height: height * 0.07,
    alignItems: 'center',
  },
  modalIconText: {
    fontSize: 11,
    fontWeight: '400',
    lineHeight: 24,
    color: '#4F4F4F',
    fontFamily: FontFamily.lato_regular,
  },
  container: {
    width: '100%',
    marginLeft: 16,
    marginTop: 40,
  },
});

export const ManualImportScreen = ({navigation}: {navigation: any}) => {
  const accountData: AccountData | null | undefined = useSelector(
    userAccountDataSelector,
  );

  const organisationData: string[] = useSelector(organisationDataSelector);

  const [formData, setFormData] = useState<ManualImportFormData>({
    user_fullname: accountData?.user_fullname || null,
    user_current_org: accountData?.user_current_org || null,
    user_current_role: accountData?.user_current_role || null,
    avatar: null,
  });
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);
  const [isEditAccountDataPending, setIsEditAccountDataPending] =
    useState<boolean>(false);
  const [progress, setProgress] = useState(0);
  const [openProfilePickerOptionModal, setOpenProfilePickerOptionModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      !!accountData &&
      !!accountData.linkedin_parsed &&
      Object.keys(accountData.linkedin_parsed).length !== 0 &&
      isEditAccountDataPending
    ) {
      const interval = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress < 100) {
            return prevProgress + 20;
          }
          clearInterval(interval);
          return prevProgress;
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditAccountDataPending]);

  useEffect(() => {
    Keyboard.addListener('keyboardDidShow', event => {
      setKeyboardHeight(event.endCoordinates.height);
    });
    Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardHeight(0);
    });
    if (!!accountData) {
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val:
          Object.keys(accountData.linkedin_parsed!).length !== 0
            ? ProfileCreationAnalEventValue.ONB_LINK_REVIEW_IMPR
            : ProfileCreationAnalEventValue.MANUAL_DETAILS_PAGE_IMP,
        thing_type: ThingTypeEnum.NA,
      };
      Analytics.trackEvent(analyticsEventPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const throttledOnChange = throttle((target, text) => {
    setFormData({...formData, [target]: text});
    if (target === ConstantsEnum.ManualImportDataFieldEnum.USER_CURRENT_ORG) {
      dispatch(fetchOrganistationData(text));
    }
  }, 500);

  const onChange = (target: any, text: any) => {
    throttledOnChange(target, text);
  };

  const handlePress = () => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val:
        Object.keys(accountData!.linkedin_parsed!).length !== 0
          ? ProfileCreationAnalEventValue.ONB_LINK_REVIEW_SUBMIT_CLICK
          : ProfileCreationAnalEventValue.MANUAL_DETAILS_SUBMITTED_CLICK,
      thing_type: ThingTypeEnum.ANALYTICS,
    };
    Analytics.trackEvent(analyticsEventPayload);
    updateUserDetails();
  };

  const updateUserDetails = async () => {
    setIsEditAccountDataPending(true);
    let editAccountDataPayload: EditAccountDataPayload = {
      thing_id: accountData!.thing_id!,
      thing_type: ConstantsEnum.ThingTypeEnum.ACCOUNT,
      attrs: [],
      isOnboardingFlow: true,
      isLinkedinReview:
        !!accountData &&
        !!accountData.linkedin_parsed &&
        Object.keys(accountData!.linkedin_parsed!).length > 0
          ? true
          : false,
    };
    if (formData.user_fullname) {
      const editAccountDataPayloadAttr = [
        ConstantsEnum.ManualImportDataFieldEnum.USER_FULLNAME,
        convertToCamelCaseString(formData.user_fullname),
        'replace',
      ];
      editAccountDataPayload.attrs.push(editAccountDataPayloadAttr);
    }
    if (formData.user_current_org) {
      const editAccountDataPayloadAttr = [
        ConstantsEnum.ManualImportDataFieldEnum.USER_CURRENT_ORG,
        convertToCamelCaseString(formData.user_current_org),
        'replace',
      ];
      editAccountDataPayload.attrs.push(editAccountDataPayloadAttr);
    }
    if (formData.user_current_role) {
      const editAccountDataPayloadAttr = [
        ConstantsEnum.ManualImportDataFieldEnum.USER_CURRENT_ROLE,
        convertToCamelCaseString(formData.user_current_role),
        'replace',
      ];
      editAccountDataPayload.attrs.push(editAccountDataPayloadAttr);
    }
    const editResponse = await dispatch(
      editUserAccountData(editAccountDataPayload),
    );
    setIsEditAccountDataPending(false);
    if (editUserAccountData.fulfilled.match(editResponse)) {
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: editAccountDataPayload.isOnboardingFlow
          ? editAccountDataPayload.isLinkedinReview
            ? ProfileCreationAnalEventValue.ONB_LINK_REVIEW_UPDATE_SUCCESS
            : ProfileCreationAnalEventValue.MANUAL_DETAILS_SUBMITTED_SUCCESS
          : '',
        thing_type: ThingTypeEnum.ANALYTICS,
      };
      Analytics.trackEvent(analyticsEventPayload);
      ToasterService.showToast('Account data update success!', '#27AE60');
      setIsEditAccountDataPending(false);
      if (!!accountData && !!accountData.linkedin_parsed) {
        if (Object.keys(accountData!.linkedin_parsed!).length !== 0) {
          navigation.dispatch(
            StackActions.replace(AuthScreens.WyseGreetingScreen),
          );
        } else {
          navigation.dispatch(
            StackActions.replace(AuthScreens.ProfilePicUploadScreen),
          );
        }
      }
    } else if (editUserAccountData.rejected.match(editResponse)) {
      const analyticsEventPayloadFailed: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: editAccountDataPayload.isOnboardingFlow
          ? editAccountDataPayload.isLinkedinReview
            ? ProfileCreationAnalEventValue.ONB_LINK_REVIEW_UPDATE_ERROR
            : ProfileCreationAnalEventValue.MANUAL_DETAILS_SUBMITTED_ERROR
          : '',
        thing_type: ThingTypeEnum.ANALYTICS,
      };
      Analytics.trackEvent(analyticsEventPayloadFailed);
      ToasterService.showToast(
        'Error occurred while updating account data, please try again.',
        '#EB5757',
      );
    }

    if (formData.avatar) {
      const payload: UpdateUserProfilePayload = {
        imageUri: formData.avatar,
        thingId: accountData!.thing_id,
        userName: accountData!.user_fullname!,
      };
      const imageUploadResponse = await dispatch(
        uploadUserProfileImage(payload),
      );
      if (uploadUserProfileImage.fulfilled.match(imageUploadResponse)) {
        const analyticsEventPayload: IEventData = {
          ev_typ: EventTypeEnum.ANALYTICS,
          ev_val: ProfileCreationAnalEventValue.ONB_LINK_REVIEW_UPLOAD_SUCCESS,
          thing_type: ThingTypeEnum.NA,
        };
        Analytics.trackEvent(analyticsEventPayload);
      } else if (uploadUserProfileImage.rejected.match(imageUploadResponse)) {
        const analyticsEventPayload: IEventData = {
          ev_typ: EventTypeEnum.ANALYTICS,
          ev_val: ProfileCreationAnalEventValue.ONB_LINK_REVIEW_UPLOAD_FAILURE,
          thing_type: ThingTypeEnum.NA,
        };
        Analytics.trackEvent(analyticsEventPayload);
      }
    }
  };

  const handleEnterKeyPress = () => {
    Keyboard.dismiss();
  };

  const onGalleryPress = async () => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: ProfileCreationAnalEventValue.ONB_LINK_REVIEW_GALLERY_CLICK,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
    try {
      const result = await openPicker({
        mediaType: 'photo',
      });
      //TODO null check on uri
      const uri = result.sourceURL;
      /**
       * Incorrect implemenation of avatr in data
       */
      onChange(ConstantsEnum.ManualImportDataFieldEnum.AVATAR, uri);
      setOpenProfilePickerOptionModal(false);
    } catch (error) {}
  };

  const onCameraPress = async () => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: ProfileCreationAnalEventValue.ONB_LINK_REVIEW_CAMERA_CLICK,
      thing_type: ThingTypeEnum.ANALYTICS,
    };
    Analytics.trackEvent(analyticsEventPayload);
    try {
      const result = await openCamera({
        mediaType: 'photo',
      });

      //TODO null check on uri
      const uri = result.sourceURL;
      /**
       * Incorrect implemenation of avatr in data
       */
      onChange(ConstantsEnum.ManualImportDataFieldEnum.AVATAR, uri);
      setOpenProfilePickerOptionModal(false);
    } catch (error) {}
  };

  const handleModal = () => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: ProfileCreationAnalEventValue.ONB_LINK_REVIEW_UPLOAD_CLICK,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
    setOpenProfilePickerOptionModal(true);
  };

  return (
    <WebContainer style={{flex: 1}}>
      <SafeAreaView
        style={{flex: 1, paddingHorizontal: 16, paddingVertical: 32}}>
        <View
          style={[
            styles.loaderConrainer,
            {
              display: isEditAccountDataPending ? 'flex' : 'none',
            },
          ]}>
          <Text style={styles.loaderText}>
            {Object.keys(accountData!.linkedin_parsed!).length !== 0
              ? 'Updating Account'
              : 'Creating Account'}
          </Text>
          <Progress
            value={progress}
            width="100%"
            size="sm"
            bg="#E0E0E0"
            _filledTrack={{bg: '#9B51E0'}}
            style={{alignSelf: 'center', borderRadius: 0}}
          />
        </View>
        {!isEditAccountDataPending && (
          <ScrollView
            contentContainerStyle={{flexGrow: Platform.OS == 'android' ? 1 : 0}}
            showsVerticalScrollIndicator={true}
            automaticallyAdjustKeyboardInsets={
              Platform.OS === 'ios' ? true : false
            }>
            <View style={{marginBottom: keyboardHeight / 2}}>
              <Progress
                value={
                  Object.keys(accountData!.linkedin_parsed!).length !== 0
                    ? 66
                    : 33
                }
                width="100%"
                size="sm"
                bg="#E0E0E0"
                _filledTrack={{bg: '#9B51E0'}}
                style={{alignSelf: 'center', borderRadius: 0}}
              />
            </View>
            <View style={styles.titleContainer}>
              <Text style={styles.title}>
                {Object.keys(accountData!.linkedin_parsed!).length !== 0
                  ? 'Review Profile Details'
                  : 'Create your account'}
              </Text>
            </View>
            <View style={styles.subTitleContainer}>
              <Text style={styles.subtitle}>
                {Object.keys(accountData!.linkedin_parsed!).length !== 0
                  ? 'Note: You can always change these details later from My Profile'
                  : 'Setup your Wyse profile by importing your Linkedin details'}
              </Text>
            </View>
            {Object.keys(accountData!.linkedin_parsed!).length !== 0 ? (
              <Pressable onPress={handleModal} style={styles.container}>
                <Image
                  source={{
                    uri: accountData?.avatar || profileUnavialableURI,
                  }}
                  resizeMode="cover"
                  style={styles.avatarContainer}
                />
                <View style={styles.titleContainer}>
                  <Text style={styles.title}>
                    {Object.keys(accountData!.linkedin_parsed!).length !== 0
                      ? 'Review Profile Details'
                      : 'Create your account'}
                  </Text>
                </View>
                <View style={styles.subTitleContainer}>
                  <Text style={styles.subtitle}>
                    {Object.keys(accountData!.linkedin_parsed!).length !== 0
                      ? 'Note: You can always change these details later from My Profile'
                      : 'Setup your Wyse profile by importing your Linkedin details'}
                  </Text>
                </View>
              </Pressable>
            ) : null}
            {Object.keys(accountData!.linkedin_parsed!).length !== 0 && (
              <Pressable onPress={handleModal} style={styles.container}>
                <Image
                  source={{
                    uri:
                      formData.avatar ||
                      'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper.png',
                  }}
                  resizeMode="cover"
                  style={styles.avatarContainer}
                />
                <Pressable
                  style={[
                    styles.circlePlus,
                    {display: Platform.OS === 'web' ? 'none' : 'flex'},
                  ]} // This need to be fixed
                  onPress={() => setOpenProfilePickerOptionModal(true)}>
                  <FontAwesomeIcon icon={faImage} color="#FFFFFF" size={14} />
                </Pressable>
              </Pressable>
            )}
            <View style={styles.formContainer}>
              <View
                style={{
                  marginBottom: 8,
                  alignSelf: 'stretch',
                }}>
                <Text
                  style={[
                    styles.inputTitle,
                    {
                      display:
                        Object.keys(accountData!.linkedin_parsed!).length !== 0
                          ? 'flex'
                          : 'none',
                    },
                  ]}>
                  Name
                </Text>
                <TextInput
                  style={[styles.formTextInput, textInputStyles.webInput]}
                  selectionColor={'#9B51E0'}
                  maxLength={200}
                  placeholder={'Full Name'}
                  onSubmitEditing={handleEnterKeyPress}
                  returnKeyType="done"
                  onChangeText={text => {
                    onChange(
                      ConstantsEnum.ManualImportDataFieldEnum.USER_FULLNAME,
                      text,
                    );
                  }}
                  value={formData.user_fullname ? formData.user_fullname : ''}
                />
              </View>
              <View style={{marginBottom: 8, alignSelf: 'stretch'}}>
                <Text
                  style={[
                    styles.inputTitle,
                    {
                      display:
                        Object.keys(accountData!.linkedin_parsed!).length !== 0
                          ? 'flex'
                          : 'none',
                    },
                  ]}>
                  Job Role
                </Text>
                <AutoComplete
                  value={
                    formData.user_current_role ? formData.user_current_role : ''
                  }
                  data={roles}
                  onSubmitEditing={handleEnterKeyPress}
                  onChange={onChange}
                  target={
                    ConstantsEnum.ManualImportDataFieldEnum.USER_CURRENT_ROLE
                  }
                  placeHolderText={'Job role'}
                />
              </View>
              <View style={{alignSelf: 'stretch'}}>
                <Text
                  style={[
                    styles.inputTitle,
                    {
                      display:
                        Object.keys(accountData!.linkedin_parsed!).length !== 0
                          ? 'flex'
                          : 'none',
                    },
                  ]}>
                  Company Name
                </Text>
                <AutoComplete
                  value={
                    formData.user_current_org ? formData.user_current_org : ''
                  }
                  data={organisationData}
                  onSubmitEditing={handleEnterKeyPress}
                  onChange={onChange}
                  target={
                    ConstantsEnum.ManualImportDataFieldEnum.USER_CURRENT_ORG
                  }
                  placeHolderText={'Company Name'}
                />
              </View>
            </View>

            <View style={styles.buttonContainer}>
              <Button
                style={{
                  backgroundColor:
                    formData.user_fullname &&
                    formData.user_current_role &&
                    formData.user_current_org
                      ? '#9B51E0'
                      : '#E0E0E0',
                  borderRadius: 8,
                }}
                onPress={handlePress}
                disabled={
                  formData.user_fullname &&
                  formData.user_current_role &&
                  formData.user_current_org
                    ? false
                    : true
                }>
                <Text
                  style={[
                    styles.buttonText,
                    {
                      color:
                        formData.user_fullname &&
                        formData.user_current_role &&
                        formData.user_current_org
                          ? '#FFFFFF'
                          : '#BDBDBD',
                    },
                  ]}>
                  Continue
                </Text>
              </Button>
              <Button
                style={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: 8,
                  marginTop: 12,
                  borderWidth: 1,
                  borderColor: '#9B51E0',
                  display:
                    Object.keys(accountData!.linkedin_parsed!).length !== 0
                      ? 'none'
                      : 'flex',
                }}
                onPress={() => {
                  dispatch(setLinkedinImportStatusIdle());
                  navigation.dispatch(
                    StackActions.replace(AuthScreens.LinkedinImportScreen),
                  );
                }}>
                <Text
                  style={[
                    styles.buttonText,
                    {
                      color: '#9B51E0',
                    },
                  ]}>
                  Import from Linkedin
                </Text>
              </Button>
            </View>
          </ScrollView>
        )}
        <Modal
          isOpen={openProfilePickerOptionModal}
          onClose={() => setOpenProfilePickerOptionModal(false)}
          safeAreaTop={true}>
          <Modal.Content
            width="100%"
            maxWidth={'100%'}
            {...{marginBottom: -2, marginTop: 'auto'}}>
            <Modal.CloseButton />
            <Modal.Header>
              <Text style={styles.modalTitle}>Choose Image from</Text>
            </Modal.Header>

            <Modal.Body style={styles.modalBody}>
              <TouchableWithoutFeedback onPress={onGalleryPress}>
                <View
                  style={[
                    styles.modalIconContainer,
                    {
                      marginRight: 18,
                    },
                  ]}>
                  <View>
                    <FeatherIcon
                      name="image"
                      style={{
                        color: 'rgba(128,128,128,1)',
                        fontSize: 30,
                      }}></FeatherIcon>
                  </View>
                  <Text style={styles.modalIconText}>Gallery</Text>
                </View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback onPress={onCameraPress}>
                <View style={styles.modalIconContainer}>
                  <View>
                    <FeatherIcon
                      name="camera"
                      style={{
                        color: 'rgba(128,128,128,1)',
                        fontSize: 30,
                      }}></FeatherIcon>
                  </View>
                  <Text style={styles.modalIconText}>Camera</Text>
                </View>
              </TouchableWithoutFeedback>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      </SafeAreaView>
    </WebContainer>
  );
};

export default ManualImportScreen;
