/*eslint-disable */
import React, {useCallback, useImperativeHandle, useRef, useState} from 'react';
import {ActivityIndicator, Text, TextInput, View} from 'react-native';
import {useSelector} from 'react-redux';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Button} from 'native-base';
import {dispatch} from '@redux/store';
import {
  userAccountDataSelector,
  userAccountThingIdSelector,
} from '@redux/selectors/userAccountSelectors';
import {editUserAccountData} from '@redux/actions/userAccount';

import Analytics, {type IEventData} from '@wyse/analytics';
import {
  EventTypeEnum,
  ManualImportDataFieldEnum,
  ThingTypeEnum,
  UserProfileAnalEventValue,
} from '@constants/constantsEnum';
import Colors from '@constants/Colors';
import type {EditAccountDataPayload} from '@models/AccountData/EditAccountDataPayload';
import {convertToCamelCaseString} from '@utils/utilityFunctions';
import {CustomSimpleBackdrop} from '@components/CustomBackdrop';

import {BasicDetailsKey, BottomSheetModal} from './types';
import {SheetTitle} from '../SheetComponent';
import styles from './styles';
import CustomModal from '@components/CustomModal';
import {textInputStyles} from '@components/Inputs';

const EditDetailsSheet = ({
  sheetRef,
}: {
  sheetRef: React.RefObject<BottomSheetModal>;
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const userThingId = useSelector(userAccountThingIdSelector);
  const userAccount = useSelector(userAccountDataSelector);
  const [state, setState] = useState<Record<BasicDetailsKey, string>>({
    [BasicDetailsKey.USER_FULLNAME]: userAccount?.user_fullname || '',
    [BasicDetailsKey.USER_CURRENT_ORG]: userAccount?.user_current_org || '',
    [BasicDetailsKey.USER_CURRENT_ROLE]: userAccount?.user_current_role || '',
  });
  const ref = useRef(false);

  const {bottom} = useSafeAreaInsets();

  const onChangeText = useCallback(
    (type: BasicDetailsKey, value: string) => {
      console.log('ref.current', ref.current, type, value);
      setState(st => ({...st, [type]: value}));
    },
    [ref],
  );

  const present = useCallback(() => {
    setVisible(true);
  }, []);

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  useImperativeHandle(sheetRef, () => ({present, close} as any), [
    present,
    close,
  ]);

  const onSubmit = useCallback(async () => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: UserProfileAnalEventValue.USER_HEADER_SAVE_CLICK,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
    const editAccountDataPayload: EditAccountDataPayload = {
      thing_id: userThingId!,
      thing_type: ThingTypeEnum.ACCOUNT,
      attrs: [],
    };

    if (state.user_fullname !== userAccount?.user_fullname) {
      const editAccountDataPayloadAttr = [
        ManualImportDataFieldEnum.USER_FULLNAME,
        convertToCamelCaseString(state.user_fullname),
        'replace',
      ];
      editAccountDataPayload.attrs.push(editAccountDataPayloadAttr);
    }
    if (state.user_current_org !== userAccount?.user_current_org) {
      const editAccountDataPayloadAttr = [
        ManualImportDataFieldEnum.USER_CURRENT_ORG,
        convertToCamelCaseString(state.user_current_org),
        'replace',
      ];
      editAccountDataPayload.attrs.push(editAccountDataPayloadAttr);
    }
    if (state.user_current_role !== userAccount?.user_current_role) {
      const editAccountDataPayloadAttr = [
        ManualImportDataFieldEnum.USER_CURRENT_ROLE,
        convertToCamelCaseString(state.user_current_role),
        'replace',
      ];
      editAccountDataPayload.attrs.push(editAccountDataPayloadAttr);
    }
    setLoading(true);
    const response = await dispatch(
      editUserAccountData(editAccountDataPayload),
    );
    if (editUserAccountData.fulfilled.match(response)) {
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: UserProfileAnalEventValue.USER_HEADER_SAVE_CLICK_SUCCESS,
        thing_type: ThingTypeEnum.NA,
      };
      Analytics.trackEvent(analyticsEventPayload);
    } else if (editUserAccountData.rejected.match(response)) {
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: UserProfileAnalEventValue.USER_HEADER_SAVE_CLICK_FAILED,
        thing_type: ThingTypeEnum.NA,
      };
      Analytics.trackEvent(analyticsEventPayload);
    }
    setLoading(false);
    sheetRef.current?.close();
  }, [
    state.user_current_org,
    state.user_fullname,
    state.user_current_role,
    userThingId,
    userAccount?.user_fullname,
    userAccount?.user_current_org,
    userAccount?.user_current_role,
    sheetRef,
  ]);

  return (
    <CustomModal
      style={{
        width: '100%',
        padding: 20,
        borderRadius: 16,
        backgroundColor: Colors.background,
      }}
      visible={visible}
      onRequestClose={() => {
        setVisible(false);
      }}>
      <View>
        <SheetTitle title="Edit Basic Details" />
        <View style={styles.line} />
        <View style={styles.bottomSheetView}>
          <View style={styles.subView}>
            <View style={styles.inputContainer}>
              <Text style={styles.inputTitle}>Full Name</Text>
              <View style={styles.inputSubContainer}>
                <TextInput
                  editable={!loading}
                  value={state.user_fullname}
                  onChangeText={text =>
                    onChangeText(BasicDetailsKey.USER_FULLNAME, text)
                  }
                  style={[styles.input, textInputStyles.webInput]}
                />
              </View>
            </View>

            <View style={styles.inputContainer}>
              <Text style={styles.inputTitle}>Current Organization</Text>
              <View style={styles.inputSubContainer}>
                <TextInput
                  editable={!loading}
                  value={state.user_current_org}
                  onChangeText={text =>
                    onChangeText(BasicDetailsKey.USER_CURRENT_ORG, text)
                  }
                  style={[styles.input, textInputStyles.webInput]}
                />
              </View>
            </View>

            <View style={styles.inputContainer}>
              <Text style={styles.inputTitle}> Current Role</Text>
              <View style={styles.inputSubContainer}>
                <TextInput
                  editable={!loading}
                  value={state.user_current_role}
                  onChangeText={text =>
                    onChangeText(BasicDetailsKey.USER_CURRENT_ROLE, text)
                  }
                  style={[styles.input, textInputStyles.webInput]}
                />
              </View>
            </View>
          </View>

          <View style={styles.line} />
          <View style={[styles.bottomContainer, {paddingBottom: bottom || 10}]}>
            <Button
              disabled={loading}
              variant="outline"
              size="sm"
              style={styles.button}
              onPress={onSubmit}>
              {loading ? (
                <ActivityIndicator color={Colors.primary} size={14} />
              ) : (
                <Text style={styles.buttonText}>Save Details</Text>
              )}
            </Button>
          </View>
        </View>
      </View>
    </CustomModal>
  );
};

export default EditDetailsSheet;
