/*eslint-disable */
import React, {useCallback, useMemo} from 'react';
import type {FC} from 'react';
import {useSelector} from 'react-redux';
import {View, StyleSheet} from 'react-native';
import {resolveImageAssetSource, timeago} from '@utils/utilityFunctions';
import {
  createAmaDataselector,
  feedDataItemSelector,
} from '@redux/selectors/feedSelector';
import type {FeedData} from '@models/Feed/FeedData';
import FeedCardBody from './FeedCardBody';
import FeedCardFooter from './FeedCardFooter';
import FeedCardHeader from './FeedCardHeader';
import NavigationService from '@services/navigationService';
import {
  EventTypeEnum,
  FeedEnagagementAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import {postLabelSelector} from '@redux/selectors/metaSelector';
import AMAService from '@services/amaService';
import {createBlockedUserSelector} from '@redux/selectors/userAccountSelectors';
import Analytics, {IEventData, IExtraData} from '@wyse/analytics';
import ProfileOptionSheet from '@components/Modals/ProfileOptionSheet';
import {MetaDataFlair} from '@models/MetaData/metaDataFlair';
import {AuthScreens} from '@navigations/ScreenNames';
import LocalStorage from '@services/storage';
import {useRoute} from '@react-navigation/native';
import Colors from '@constants/Colors';

const anonImage = require('@assets/anon-incognito.png');
const anonymousURI = resolveImageAssetSource(anonImage).uri;
const randomAvatarImage = require('@assets/profile-unvailable-avatar.png');
const randomAvatarURI = resolveImageAssetSource(randomAvatarImage).uri;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    paddingVertical: 20,
   
    backgroundColor: Colors.white,
  },
});

type FeedCardProps = {
  thingId: string;
  loggedInUserId: string;
  currentProfileUserId?: string;
  feedCardIndex?: number;
  isFeedDetailCard: boolean;
  isProfileCard: boolean;
  seeMore: boolean | null;
  navigation: any | null;
  handleComment: any;
  handleShare: (data: FeedData) => void;
  hideCommunityHeader?: boolean;
};

const date = new Date();

const FeedCard: FC<FeedCardProps> = ({
  thingId,
  hideCommunityHeader = false,
  loggedInUserId,
  currentProfileUserId,
  feedCardIndex,
  isFeedDetailCard,
  isProfileCard,
  seeMore,
  handleComment,
  handleShare,
}) => {
  const route = useRoute();
  const postLabels = useSelector(postLabelSelector);
  const blockedUsers = useSelector(createBlockedUserSelector);
  const amaData = useSelector(createAmaDataselector(thingId));
  const feedData = useSelector(
    feedDataItemSelector(thingId),
    (prev, next) =>
      prev?.title === next?.title &&
      prev?.data[0]?.value === next?.data[0]?.value,
  );

  const showLiveFeed = useMemo(() => {
    if (!feedData) return false;
    let postLabel: MetaDataFlair | null = null;
    if (feedData.flairs[0]) {
      postLabel = postLabels.filter(
        label => label.thing_id == feedData.flairs[0],
      )?.[0] as MetaDataFlair;
    }
    const isAma = postLabel && postLabel.title == 'AMA';

    if (isAma && !!amaData) {
      const date = new Date();
      if (date >= new Date(amaData?.start) && date < new Date(amaData.end)) {
        return true;
      }
    }
    return false;
  }, [feedData?.flairs, amaData?.end, amaData?.start]);

  if (!feedData) return null;

  if (
    !!currentProfileUserId &&
    currentProfileUserId != loggedInUserId &&
    feedData?.anon_post
  ) {
    return null;
  }

  const redirectToFeedDetailScreen = () => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: FeedEnagagementAnalEventValue.POST_CLICK,
      thing_type: ThingTypeEnum.POST,
      thing_id: feedData.thing_id,
    };
    Analytics.trackEvent(analyticsEventPayload);
    NavigationService.getInstance().openFeedDetails(
      thingId,
      feedCardIndex,
      isProfileCard,
    );
  };

  const redirectToProfileScreen = () => {
    const extraData = {
      postId: feedData.thing_id,
    };
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: FeedEnagagementAnalEventValue.PROFILE_BANNER_CLICK_POST,
      thing_type: ThingTypeEnum.PROFILE,
      thing_id: feedData.author_mini.thing_id,
      extra_data: JSON.stringify(extraData),
    };
    Analytics.trackEvent(analyticsEventPayload);
    // NavigationService.getInstance().openProfileDetails(
    //   feedData.author_mini.thing_id,
    // );
    // console.log('openSheet before');
    // ProfileOptionSheet.openSheet({
    //   ...feedData.author_mini,
    //   anon_post: feedData.anon_post,
    // });
  };

  const redirectToCommunityDetailScreen = async () => {
    if (!loggedInUserId) {
      await LocalStorage.setData('redirect', route);
      NavigationService.getInstance().replaceToAuth({
        page: AuthScreens.OtpScreen,
      });
      return;
    }
    /**
     * PostId and CommunityId to be updates in analytics event, currently saving postid only -> community Id can be fetched from Post
     */
    const extraData: IExtraData = {
      postId: feedData.thing_id,
    };
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: FeedEnagagementAnalEventValue.COMMUNITY_BANNER_CLICK,
      thing_id: feedData.community_id[0],
      thing_type: ThingTypeEnum.COMMUNITY,
      extra_data: JSON.stringify(extraData),
    };
    Analytics.trackEvent(analyticsEventPayload);
    NavigationService.getInstance().openCommunityDetails(
      feedData.community_id[0],
    );
  };

  const share = useCallback(async () => {
    if (!loggedInUserId) {
      await LocalStorage.setData('redirect', route);
      NavigationService.getInstance().replaceToAuth({
        page: AuthScreens.OtpScreen,
      });
      return;
    }
    const analyticsEventErrorPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: FeedEnagagementAnalEventValue.POST_SHARE_CLICK,
      thing_type: ThingTypeEnum.POST,
      thing_id: feedData.thing_id,
    };
    Analytics.trackEvent(analyticsEventErrorPayload);
    handleShare(feedData);
  }, [feedData, handleShare, route]);

  const navigateToDMScreen = async () => {
    // try {
    //   const firstUserDocId = await FirestoreService.getUserDocId(
    //     userAccountData.thing_id,
    //   );
    //   try {
    //     const secondUserDocId = await FirestoreService.getUserDocId(
    //       feedData.author_mini.thing_id,
    //     );
    //     RootNavigation.navigate('ChatScreenHome', {
    //       first_user_DocId: firstUserDocId,
    //       second_user_DocId: secondUserDocId,
    //       feedFlairs: feedData.flairs,
    //       feedTitle: feedData.title,
    //       feedBodyData: feedData.data,
    //     });
    //   } catch {
    //     ToasterService.showToast(
    //       `DB_FS async issue- Cannot send message to ${feedData.author_mini.thing_id}`,
    //       '#9B51E0',
    //     );
    //   }
    // } catch {
    //   ToasterService.showToast(
    //     'DB_FS async issue- Cannot send message from logged in user',
    //     '#9B51E0',
    //   );
    // }
  };

  const label =
    feedData.flairs?.[0] &&
    (postLabels.filter(
      label => label.thing_id == feedData.flairs[0],
    )?.[0] as any);

  if (blockedUsers.includes(feedData.author_mini.thing_id)) return null;

  return (
    <View style={styles.container}>
      {showLiveFeed ? <AMAService.Listener id={feedData.thing_id} /> : null}
      <FeedCardHeader
        profileOptions={{
          ...feedData.author_mini,
          anon_post: feedData.anon_post,
        }}
        moreOptions={{
          thingId: thingId,
          authorId: feedData.author_mini.thing_id,
          communityId: feedData.community_id?.[0],
          title: feedData.title,
          avatar: feedData.author_mini.avatar,
          user_fullname: feedData.author_mini.user_fullname,
          role: feedData.author_mini.user_current_role,
          org: feedData.author_mini.user_current_org,
          anon_post: feedData.anon_post,
        }}
        hideCommunityHeader={hideCommunityHeader}
        authorImage={
          feedData.anon_post
            ? anonymousURI
            : !!!feedData.author_mini.avatar
            ? randomAvatarURI
            : feedData.author_mini.avatar
        }
        authorFullName={feedData.author_mini.user_fullname}
        authorCurrentRole={feedData.author_mini.user_current_role}
        authorCurrentOrgName={feedData.author_mini.user_current_org}
        communityName={feedData.community_name}
        communityId={feedData.community_id[0]}
        feedCreatedAt={timeago(feedData.created_at)}
        postLabelThingId={feedData.flairs}
        isAnonPost={feedData.anon_post}
        //Changes for Infinite Navigation
        redirectToCommunityDetail={redirectToCommunityDetailScreen}
        postThingId={feedData.thing_id}
        userThingId={feedData.author_mini.thing_id}
        feedCardIndex={feedCardIndex}
        handleShare={handleShare}
      />
      <FeedCardBody
        label={label}
        bodyTitle={feedData.title}
        bodyData={feedData.data}
        seeMore={seeMore}
        redirectToFeedDetailScreen={redirectToFeedDetailScreen}
        isFeedDetailCard={isFeedDetailCard}
        postId={feedData.thing_id}
      />
      <FeedCardFooter
        handleComment={handleComment}
        feedCardIndex={feedCardIndex!}
        isFeedDetailCard={isFeedDetailCard}
        userId={loggedInUserId}
        postId={feedData.thing_id}
        navigateToDMScreen={navigateToDMScreen}
        isProfileCard={isProfileCard}
        handleShare={share}
      />
    </View>
  );
};

export default FeedCard;
