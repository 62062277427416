import React, {memo, useCallback, useEffect, useState} from 'react';
import type {FC} from 'react';
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {Button} from 'native-base';
import {useSelector} from 'react-redux';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import {WINDOW_WIDTH} from '@gorhom/bottom-sheet';
import {SheetTitle} from '@components/Modals/SheetComponent';
import {DateInput, Input} from '@components/Inputs';
import CustomModal from '@components/CustomModal';
import {
  addEditEducationVisibleSelector,
  selectedEducationIndexSelector,
  selectedEducationSelector,
  userAccountDataSelector,
} from '@redux/selectors/userAccountSelectors';
import {dispatch} from '@redux/store';
import {
  cleaarAddEditEducationState,
  closeAddEditEducation,
} from '@redux/slices/userAccountDataSlice';
import {editUserAccountData} from '@redux/actions/userAccount';
import {ThingTypeEnum} from '@constants/constantsEnum';
import Colors from '@constants/Colors';
import {
  getDateFromDateInstance,
  getDateInstanceFromDate,
  getDateMonthString,
} from '@utils/utilityFunctions';
import {FontFamily, FontSize, FontWeight, LineHeight} from '@utils/sizes';
import type {LinkedInParsedDataEducation} from '@models/AccountData/LinkedInModels/LinkedInParsedDataEducation';
import type {EditAccountDataPayload} from '@models/AccountData/EditAccountDataPayload';

const styles = StyleSheet.create({
  subContainer: {
    alignSelf: 'stretch',
    justifyContent: 'space-between',
  },
  container: {width: '100%', height: '100%'},
  content: {
    position: 'absolute',
    alignSelf: 'stretch',
    height: '60%',
    bottom: 0,
    left: 0,
    width: '100%',
    right: 0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: '#fff',
  },
  line: {alignSelf: 'stretch', height: 1, backgroundColor: Colors.background},
  button: {
    borderColor: Colors.primary,
    alignSelf: 'stretch',
    borderRadius: 8,
  },
  buttonText: {
    color: Colors.primary,
    fontSize: FontSize.title,
    lineHeight: LineHeight.title,
    fontWeight: FontWeight.medium,
    fontFamily: FontFamily.lato_regular,
  },
  bottomContainer: {
    alignSelf: 'stretch',
    paddingHorizontal: 16,
    paddingTop: 12,
  },
  handlecontainer: {
    padding: 10,
  },
  handle: {
    alignSelf: 'center',
    width: (7.5 * WINDOW_WIDTH) / 100,
    height: 4,
    borderRadius: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
});

const AddEducationBottomSheet: FC = () => {
  const [loading, setLoading] = useState(false);
  const visible = useSelector(addEditEducationVisibleSelector);
  const accountData = useSelector(userAccountDataSelector);
  const selectedEducation = useSelector(selectedEducationSelector);
  const selectedEducationIndex = useSelector(selectedEducationIndexSelector);
  const {bottom = 0} = useSafeAreaInsets();
  const [picketVisible, setPickerVisible] = useState(false);
  const [type, setType] = useState<'from' | 'to'>();

  const [institute, setInstitute] = useState('');
  const [degree, setDegree] = useState('');
  const [field, setField] = useState('');
  const [from, setFrom] = useState<Date>();
  const [to, setTo] = useState<Date>();
  const [_, setCurrent] = useState(false);

  useEffect(() => {
    if (visible && !!selectedEducation) {
      if (!!selectedEducation.school?.name)
        setInstitute(selectedEducation.school?.name);
      if (!!selectedEducation.degree_name)
        setDegree(selectedEducation.degree_name);
      if (!!selectedEducation.date?.end)
        setTo(getDateFromDateInstance(selectedEducation.date.end));
      if (!!selectedEducation.date?.start)
        setFrom(getDateFromDateInstance(selectedEducation.date.start));
      if (!!selectedEducation.field_of_study) {
        setField(selectedEducation.field_of_study);
      }
      if (!selectedEducationIndex && selectedEducationIndex === 0) {
        setCurrent(true);
      }
    } else {
      if (!visible) {
        dispatch(cleaarAddEditEducationState());
        setInstitute('');
        setDegree('');
        setField('');
        setFrom(undefined);
        setTo(undefined);
        setCurrent(false);
        setType(undefined);
        setPickerVisible(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, selectedEducation]);

  const deps = [
    institute,
    degree,
    from,
    to,
    field,
    selectedEducationIndex,
    !!selectedEducation,
    !accountData,
  ];
  const onSubmit = useCallback(async () => {
    const payload: LinkedInParsedDataEducation = {
      date: {
        start: getDateInstanceFromDate(from!),
        end: getDateInstanceFromDate(to!),
      },
      school: {
        name: institute,
        logo: !!selectedEducation
          ? selectedEducation.school?.logo || null
          : null,
      },
      degree_name: degree,
      field_of_study: field,
    };

    let updatedLinkedinParsedData = accountData?.linkedin_parsed!;

    let editAccountDataPayload: EditAccountDataPayload = {
      thing_id: accountData!.thing_id!,
      thing_type: ThingTypeEnum.ACCOUNT,
      attrs: [],
    };
    if (!!selectedEducation && selectedEducationIndex !== undefined) {
      if (payload.degree_name) {
        updatedLinkedinParsedData.education![
          selectedEducationIndex
        ].degree_name = payload.degree_name;
      }
      if (payload!.field_of_study) {
        updatedLinkedinParsedData.education![
          selectedEducationIndex
        ].field_of_study = payload.field_of_study;
      }
      if (payload.school!.name) {
        updatedLinkedinParsedData.education![
          selectedEducationIndex
        ].school!.name = payload.school!.name;
      }
      if (payload.date!.start) {
        updatedLinkedinParsedData.education![
          selectedEducationIndex!
        ].date!.start!.month = payload.date!.start!.month;
        updatedLinkedinParsedData.education![
          selectedEducationIndex
        ].date!.start!.year = payload.date!.start!.year;
      }
      if (payload!.date!.end) {
        updatedLinkedinParsedData.education![
          selectedEducationIndex
        ].date!.end!.month = payload.date!.end!.month;
        updatedLinkedinParsedData.education![
          selectedEducationIndex
        ].date!.end!.year = payload.date!.end!.year;
      }
    } else {
      const linkedinParsedDataEducation: LinkedInParsedDataEducation = {
        date: payload.date,
        school: payload.school,
        degree_name: payload.degree_name,
        field_of_study: payload.field_of_study,
      };

      updatedLinkedinParsedData.education = [
        linkedinParsedDataEducation,
        ...(updatedLinkedinParsedData?.education || []),
      ];
    }
    const editAccountDataPayloadAttr = [
      'linkedin_parsed',
      updatedLinkedinParsedData,
      'replace',
    ];
    editAccountDataPayload.attrs.push(editAccountDataPayloadAttr);
    setLoading(true);
    await dispatch(editUserAccountData(editAccountDataPayload));
    dispatch(closeAddEditEducation());
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const disabled = !institute || !degree || !field || !from || !to;

  return (
    <View style={{alignSelf: 'stretch'}}>
      <CustomModal
        visible={visible}
        onRequestClose={() => {
          dispatch(closeAddEditEducation());
        }}
        containerStyle={{height: '70%'}}
        style={{alignSelf: 'stretch', flex: 1, backgroundColor: '#fff'}}>
        <View style={styles.handlecontainer}>
          <View style={styles.handle} />
        </View>
        <SheetTitle
          title={!selectedEducation ? 'Add Education' : 'Edit Educaton'}
        />
        <View style={styles.line} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{padding: 16}}>
          <View style={styles.subContainer}>
            <Input
              onChangeText={setInstitute}
              value={institute}
              title="Institute Name"
            />
            <Input
              onChangeText={setDegree}
              value={degree}
              title="Degree Name"
            />
            <Input
              onChangeText={setField}
              value={field}
              title="Field of Study"
            />
            <DateInput
              onPress={() => {
                setType('from');
                setPickerVisible(true);
              }}
              value={getDateMonthString(from)}
              title="From"
            />
            <DateInput
              onPress={() => {
                setType('to');
                setPickerVisible(true);
              }}
              value={getDateMonthString(to)}
              title="To"
            />
            {/* {selectedEducationIndex === 0 ? (
              <CheckBoxInput
                title="I Currently study here"
                value={current}
                onValueChange={() => {
                  setCurrent(c => !c);
                }}
              />
            ) : null} */}
          </View>
        </ScrollView>
        <View style={styles.line} />
        <View style={[styles.bottomContainer, {paddingBottom: bottom || 10}]}>
          <Button
            disabled={disabled || loading}
            variant="outline"
            size="sm"
            style={[
              styles.button,
              disabled && {borderColor: Colors.veryLightText},
            ]}
            onPress={onSubmit}>
            {loading ? (
              <ActivityIndicator color={Colors.primary} size={14} />
            ) : (
              <Text
                style={[
                  styles.buttonText,
                  disabled && {color: Colors.veryLightText},
                ]}>
                Save Details
              </Text>
            )}
          </Button>
        </View>
        <DateTimePickerModal
          isVisible={picketVisible}
          mode="date"
          onConfirm={date => {
            setPickerVisible(false);
            if (type === 'from') {
              setFrom(date);
            } else {
              setTo(date);
            }
            // onToDateChange(event, date);
          }}
          onCancel={() => {
            setType(undefined);
            setPickerVisible(false);
          }}
        />
      </CustomModal>
    </View>
  );
};

export default memo(AddEducationBottomSheet);
