import React, {useMemo} from 'react';
import type {FC} from 'react';
import Animated, {useAnimatedStyle} from 'react-native-reanimated';
import LayoutContext from './LayoutContext';
import {FlatList, Platform, Pressable, StyleSheet, View} from 'react-native';
import Colors from '@constants/Colors';
import CustomAvatar from '@components/Avatar';
import {useRoute} from '@react-navigation/native';
import {ChatScreenParams} from './types';
import {CustomText} from '@components/Texts';
import {
  resolveImageAssetSource,
  usePressDebounce,
} from '@utils/utilityFunctions';
import NavigationService from '@services/navigationService';
import DatabaseService from '@services/DatabaseService';
import FirestoreService from '@services/firestoreService';
import {ProfileUunvailableAvatar} from '@assets';
const anonymousURI = resolveImageAssetSource(ProfileUunvailableAvatar).uri;
import {FontSize} from '@utils/sizes';
import Message from './Message';

type ChatListProps = {
  userId: string;
};

const styles = StyleSheet.create({
  container: {flex: 1, backgroundColor: Colors.white},
  footerContainer: {
    alignSelf: 'stretch',
    alignItems: 'center',
    paddingtop: 36,
    paddingBottom: 16,
  },
  textSpace: {
    marginTop: 8,
  },
  viewProfileButton: {
    marginTop: 8,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 8,
  },
  separator: {
    alignSelf: 'stretch',
    height: 4,
  },
  contentContainer: {
    paddingHorizontal: 16,
    paddingTop: 10,
  },
});

const ChatList: FC<ChatListProps> = ({userId}) => {
  const params = useRoute().params as ChatScreenParams;

  const messages = DatabaseService.useMessages(
    params?.conversation?.conversation_id!,
    params?.reciverId,
    FirestoreService.updateMsgReceived,
  );

  const toolBarHeight = LayoutContext.useToolBarHeight();
  const keyboardHeight = LayoutContext.useKeyboardHeight();

  const animatedStyle = useAnimatedStyle(
    () => ({
      paddingBottom:
        Platform.OS === 'android'
          ? toolBarHeight.value
          : Platform.OS == 'web'
          ? toolBarHeight.value + keyboardHeight.value + 80
          : toolBarHeight.value + keyboardHeight.value,
    }),
    [keyboardHeight, toolBarHeight],
  );

  const onPressProfile = usePressDebounce(() => {
    NavigationService.getInstance().openProfileDetails(params.reciverId);
  }, 400);

  const avatar = params?.conversation?.user_avatar || anonymousURI;

  const footer = useMemo(() => {
    return (
      <View style={styles.footerContainer}>
        <CustomAvatar
          width={64}
          height={64}
          borderRadius={32}
          backgroundColor={Colors.background}
          source={avatar}
        />
        <CustomText
          size={FontSize.medium}
          style={styles.textSpace}
          color="text">
          {params?.conversation?.user_fullname}
        </CustomText>
        <Pressable style={styles.viewProfileButton} onPress={onPressProfile}>
          <CustomText color="veryLightText">View Profile</CustomText>
        </Pressable>
      </View>
    );
  }, [avatar, params?.conversation?.user_fullname, onPressProfile]);

  return (
    <Animated.View style={[styles.container, animatedStyle]}>
      {useMemo(
        () => (
          <FlatList
            initialNumToRender={15}
            inverted
            contentContainerStyle={styles.contentContainer}
            ItemSeparatorComponent={() => <View style={styles.separator} />}
            data={messages}
            renderItem={({item, index}) => (
              <Message
                diffMessage={
                  index === messages.length - 1
                    ? undefined
                    : messages[index + 1]
                }
                avatar={avatar}
                item={item}
                userId={userId}
              />
            )}
            ListFooterComponent={footer}
            keyExtractor={item => item._id}
          />
        ),
        [footer, messages, userId, avatar],
      )}
    </Animated.View>
  );
};

export default ChatList;
