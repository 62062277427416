/*eslint-disable */
import React, {useMemo} from 'react';
import type {FC} from 'react';
import {View, useWindowDimensions} from 'react-native';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {
  useRoute,
  type EventMapBase,
  type NavigationState,
  type ScreenListeners,
} from '@react-navigation/native';
import Analytics, {IEventData} from '@wyse/analytics';
import {
  CreatePostAnalEvent,
  EventTypeEnum,
  FeedEnagagementAnalEventValue,
  NotificationAnalEvent,
  SpacesAnalEventValue,
  ThingTypeEnum,
  UserProfileAnalEventValue,
} from '@constants/constantsEnum';
import {tabNavigatorOptions} from '@navigations/options';
import {SCREEN_SIZE, TAB_BAR_SIZE} from '@utils/sizes';
import {type TabScreens} from '@navigations/ScreenNames';

const Tab = createBottomTabNavigator();

const TabNavigatorContainer: FC<{children: any; userThingId: string}> = ({
  children,
  userThingId,
}) => {
  const route = useRoute();

  const params = route.params as {initialTab?: TabScreens} | undefined;

  const {width} = useWindowDimensions();
  const listener: ScreenListeners<NavigationState, EventMapBase> = useMemo(
    () => ({
      tabPress: e => {
        const tabName = e.target?.split('-')[0];
        const analyticsEventPayload: IEventData = {
          ev_typ: EventTypeEnum.ANALYTICS,
          ev_val: FeedEnagagementAnalEventValue.HOME_CLICK,
          thing_type: ThingTypeEnum.NA,
        };
        if (tabName === 'Spaces') {
          analyticsEventPayload.ev_val =
            SpacesAnalEventValue.SPACES_GLOBAL_CLICKED;
        } else if (tabName === 'Post') {
          analyticsEventPayload.ev_val =
            CreatePostAnalEvent.CREATE_POST_GLOBAL_CLICK;
        } else if (tabName === 'Profile') {
          analyticsEventPayload.ev_val =
            UserProfileAnalEventValue.PROFILE_GLOBAL_CLICKED;
        } else if (tabName == 'Notification') {
          // Need to pass num of unread count
          analyticsEventPayload.ev_val =
            NotificationAnalEvent.NOTIFICATION_GLOBAL_CLICKED;
        }
        Analytics.trackEvent(analyticsEventPayload);
      },
    }),
    [userThingId],
  );

  const isBig = width > SCREEN_SIZE + TAB_BAR_SIZE;

  return useMemo(
    () => (
      <Tab.Navigator
        initialRouteName={params?.initialTab}
        screenListeners={listener}
        tabBar={isBig ? () => <View /> : undefined}
        screenOptions={tabNavigatorOptions}>
        {children}
      </Tab.Navigator>
    ),
    [isBig, listener, params?.initialTab, children],
  );
};

export default TabNavigatorContainer;
