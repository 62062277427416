/*eslint-disable */
type AResponse<T> = void | Promise<T>;
type SuccessCB = (result?: unknown) => unknown;
type ErrorCB = (error?: Error) => unknown;

export interface InitSDKOptions {
  devKey: string;
  appId?: string; // iOS only
  isDebug?: boolean;
  onInstallConversionDataListener?: boolean;
  onDeepLinkListener?: boolean;
  timeToWaitForATTUserAuthorization?: number; // iOS only
  manualStart?: boolean;
}

const getAppsFlyerUID = (callback: (error: any, uuid: string) => void) => {};

const initSdk = (
  options: InitSDKOptions,
  successC: SuccessCB,
  errorC: ErrorCB,
) => {};

const logEvent = (
  eventName: string,
  eventValues: object,
  successC: SuccessCB,
  errorC: ErrorCB,
) => {};

export default {logEvent, initSdk, getAppsFlyerUID};
