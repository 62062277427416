import React, {createRef, useMemo} from 'react';
import type {FC} from 'react';
import {StyleSheet, View} from 'react-native';
import Animated from 'react-native-reanimated';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {
  BottomSheetModal,
  useBottomSheetDynamicSnapPoints,
} from '@gorhom/bottom-sheet';
import {CustomSimpleBackdrop} from '@components/CustomBackdrop';
import Colors from '@constants/Colors';
import IconTextButton from '@components/IconTextButton';
import {SheetTitle} from './SheetComponent';
import DeviceService, {OpenPickerResponse} from '@services/DeviceService';
import {MessageType} from '@models/Conversation/Message';

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    overflow: 'hidden',
  },
  line: {alignSelf: 'stretch', height: 1, backgroundColor: Colors.background},
});

class _MessageOptionSheet {
  private resolve?: (
    value: OpenPickerResponse | PromiseLike<OpenPickerResponse> | undefined,
  ) => void;
  public sheetRef = createRef<BottomSheetModal>();
  public openSheet() {
    return new Promise<OpenPickerResponse>(resolve => {
      this.resolve = resolve;
      this.sheetRef.current?.present();
    });
  }
  public resolveSheet = (data: OpenPickerResponse | undefined) => {
    this.resolve?.(data);
    this.resolve = undefined;
  };
}

const MessageOptionSheet = new _MessageOptionSheet();

const SheetComponent: FC<{bottom?: number}> = ({bottom}) => {
  return (
    <View style={[styles.container, {paddingBottom: (bottom || 20) + 10}]}>
      <SheetTitle title="Select Option" />
      <View style={styles.line} />
      <IconTextButton
        onPress={async () => {
          MessageOptionSheet.sheetRef.current?.close();
          const image = await DeviceService.openPicker(MessageType.Image, 1);
          MessageOptionSheet.resolveSheet(image);
        }}
        text="Image"
        Icon="profile-deselect"
      />
      <IconTextButton
        onPress={async () => {
          MessageOptionSheet.sheetRef.current?.close();
          const video = await DeviceService.openPicker(MessageType.Video, 1);
          MessageOptionSheet.resolveSheet(video);
        }}
        text="Video"
        Icon="profile-deselect"
      />
      <IconTextButton
        onPress={() => {
          MessageOptionSheet.sheetRef.current?.close();
        }}
        text="Document"
        Icon="profile-deselect"
      />
    </View>
  );
};

export const MessageOptionSheetComponent: FC = () => {
  const {bottom} = useSafeAreaInsets();

  const {
    animatedSnapPoints,
    animatedHandleHeight,
    handleContentLayout,
    animatedContentHeight,
  } = useBottomSheetDynamicSnapPoints(['CONTENT_HEIGHT']);

  return useMemo(
    () => (
      <BottomSheetModal
        backdropComponent={props => (
          <CustomSimpleBackdrop
            sheetRef={MessageOptionSheet.sheetRef}
            {...props}
          />
        )}
        snapPoints={animatedSnapPoints}
        handleHeight={animatedHandleHeight}
        contentHeight={animatedContentHeight}
        ref={MessageOptionSheet.sheetRef}>
        <Animated.View
          style={{alignSelf: 'stretch'}}
          onLayout={handleContentLayout}>
          <SheetComponent {...{bottom}} />
        </Animated.View>
      </BottomSheetModal>
    ),
    [
      animatedSnapPoints,
      animatedHandleHeight,
      handleContentLayout,
      animatedContentHeight,
      bottom,
    ],
  );
};

export default MessageOptionSheet;
