import React, {useEffect, useState} from 'react';
import type {FC} from 'react';
import {
  Dimensions,
  KeyboardAvoidingView,
  Modal,
  Platform,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';
import type {StyleProp, ViewStyle} from 'react-native';
import Animated, {
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

type CustomModalProps = {
  visible: boolean;
  onRequestClose: () => void;
  disableBackdropPress?: boolean;
  style?: StyleProp<ViewStyle>;
  children?: any;
  containerStyle?: StyleProp<ViewStyle>;
  enableKeyboardView?: boolean;
  animationType?: 'slide' | 'fade';
};

const styles = StyleSheet.create({
  container: {width: '100%', height: '100%'},
  fadeContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  insideContainer: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
  },
  fadeInsideContainer: {
    position: 'absolute',
    borderRadius: 16,
    width: '60%',
    overflow: 'hidden',
  },
});

const {height} = Dimensions.get('window');

const CustomModal: FC<CustomModalProps> = props => {
  const {
    visible,
    onRequestClose,
    disableBackdropPress = false,
    style,
    children,
    containerStyle,
    animationType = 'slide',
    // enableKeyboardView = false,
  } = props;
  const value = useSharedValue(0);
  const [_visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible) {
      setVisible(true);
      value.value = withTiming(1);
    } else {
      value.value = withTiming(0, {}, () => {
        runOnJS(setVisible)(false);
      });
    }
  }, [visible, value]);

  const backgroundStyle = useAnimatedStyle(
    () => ({
      opacity: value.value,
    }),
    [],
  );

  const animatedViewStyle = useAnimatedStyle(() => {
    return {
      transform: [{translateY: interpolate(value.value, [0, 1], [height, 0])}],
    };
  }, [animationType]);

  return (
    <Modal transparent visible={_visible} onRequestClose={onRequestClose}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'height' : undefined}
        style={[
          animationType == 'fade' ? styles.fadeContainer : styles.container,
        ]}>
        <Pressable
          disabled={disableBackdropPress}
          onPress={onRequestClose}
          style={{flex: 1, width: '100%'}}>
          <Animated.View
            style={[{flex: 1, backgroundColor: '#00000060'}, backgroundStyle]}
          />
        </Pressable>
        <Animated.View
          style={[
            animationType == 'fade'
              ? styles.fadeInsideContainer
              : styles.insideContainer,
            containerStyle,
            animationType == 'fade' ? backgroundStyle : animatedViewStyle,
          ]}>
          <View style={style}>{children ? children : null}</View>
        </Animated.View>
      </KeyboardAvoidingView>
    </Modal>
  );
};

export default CustomModal;
