import React, {useEffect} from 'react';
import {View, Dimensions, StyleSheet, Image} from 'react-native';
import {FontFamily, FontSize} from '@utils/sizes';
import Colors from '@constants/Colors';
import NavigationService from '@services/navigationService';
import {AuthScreens} from '@navigations/ScreenNames';
import {CustomText} from '@components/Texts';

const {width, height} = Dimensions.get('window');
const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: width,
    alignSelf: 'center',
    marginTop: height * 0.22,
    top: width * 0.5 - 130 / 2,
  },
  textContainer: {
    alignItems: 'center',
    width: width * 0.91,
    marginBottom: 24,
  },
  subHeading: {
    color: '#666666',
    marginTop: 4,
    textAlign: 'center',
  },
  heading: {
    color: '#222222',
  },
  button: {
    backgroundColor: Colors.primary,
    borderRadius: 8,
    width: width * 0.83,
  },
  image: {
    marginBottom: 16,
  },
});

export const WyseGreetingScreen = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      NavigationService.getInstance().replaceToAuth({
        page: AuthScreens.SelectSpacesSignupScreen,
      });
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <View style={{flex: 1, backgroundColor: '#FFFFFF'}}>
      <View style={styles.container}>
        <Image
          source={require('@assets/PartyPopper.png')}
          style={styles.image}
          resizeMode="contain"
        />
        <View style={styles.textContainer}>
          <CustomText
            size={FontSize.medium}
            fontFamily={FontFamily.FigtreeMedium}
            style={styles.heading}>
            Congratulations Account Created
          </CustomText>
          <CustomText size={FontSize.verySmall} style={styles.subHeading}>
            Welcome to bewyse, Get ready to connect with top professional spaces
          </CustomText>
        </View>
      </View>
    </View>
  );
};

export default WyseGreetingScreen;
