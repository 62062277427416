import auth from '@libs/Firebase/auth';
import ConstantsEnum from '@constants/constantsEnum';
import LocalStorage from './storage';
import ToasterService from './toasterService';

const signInWithOTP = (verificationId: any, verificationCode: any) => {
  try {
    const credential = auth.PhoneAuthProvider.credential(
      verificationId,
      verificationCode,
    );
    return auth().signInWithCredential(credential);
  } catch (err) {
    console.log(err);
  }
};

const getAccessToken = async () => {
  const token = await auth().currentUser?.getIdToken(false);
  console.log('token', token);
  return token;
};

const getUserUUid = () => {
  const uuid = auth().currentUser?.uid;
  return uuid;
};

const getRefreshedAccessToken = async () => {
  const token = await auth().currentUser?.getIdToken(true);
  return token;
};

const signOutUser = async () => {
  await auth().signOut();
  LocalStorage.setData(
    ConstantsEnum.LocalStorageKeysEnum.USER_ACCOUNT_DATA,
    null,
  );
  ToasterService.showToast('Successfully Signed out!!', '#27AE60');
  return;
};

const AuthenticationService = {
  signInWithOTP,
  getAccessToken,
  getRefreshedAccessToken,
  getUserUUid,
  signOutUser,
};

export default AuthenticationService;
