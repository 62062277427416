import React, {useEffect} from 'react';
import {View, Text, ImageBackground, Dimensions} from 'react-native';
const {width, height} = Dimensions.get('window');
export const WelcomeOnboard = ({navigation}: {navigation: any}) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      navigation.navigate('MainScreen');
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <View>
      <View
        style={{
          width: width * 0.61,
          alignSelf: 'center',
          top: height * 0.404,
        }}>
        <Text
          style={{
            fontSize: 24,
            fontWeight: '600',
            lineHeight: 36,
            textAlign: 'center',
            color: '#181818',
          }}>
          Welcome onboard Nikhilesh
        </Text>
      </View>
      <View
        style={{width: width * 0.73, alignSelf: 'center', top: height * 0.44}}>
        <Text
          style={{
            fontSize: 14,
            fontWeight: '500',
            lineHeight: 20,
            textAlign: 'center',
            color: '#4F4F4F',
          }}>
          You are now part of an élite club of professionals that believe in
          growing and learning together in their careers
        </Text>
      </View>

      <View
        style={{
          position: 'absolute',
          width: width * 0.3,
          height: width * 0.3,
          alignSelf: 'center',
          top: height * 0.24,
        }}>
        <ImageBackground
          source={{
            uri: 'https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
          }}
          borderRadius={width * 0.15}
          resizeMode="cover"
          style={{flex: 1, width: '100%'}}
        />
      </View>
    </View>
  );
};

export default WelcomeOnboard;
