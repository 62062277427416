import React, {FC, memo} from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {faCircle} from '@fortawesome/free-solid-svg-icons/faCircle';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {getDefaultHeaderHeight} from '@react-navigation/elements';
import Icon from 'react-native-vector-icons/Ionicons';
import SimpleLineIcon from 'react-native-vector-icons/SimpleLineIcons';
import NavigationService from '@services/navigationService';
import {Button} from 'native-base';

import {FontFamily, FontSize, FontWeight, LineHeight} from '@utils/sizes';
import Colors from '@constants/Colors';
import {CustomIcon, Icons} from '@components/icon';
import {CustomText} from '@components/Texts';

const styles = StyleSheet.create({
  headerTopContainer: {alignSelf: 'stretch'},
  iconButtonContainr: {
    width: 24,
    height: '100%',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  headerContainer: {
    paddingHorizontal: 16,
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
  },
  headerTitleContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  headerTitleText: {
    fontWeight: FontWeight.bold,
    fontSize: FontSize.heading,
    lineHeight: LineHeight.heading,
    color: Colors.text,
    fontFamily: FontFamily.lato_regular,
  },
  filterByIconContainer: {
    width: 30,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  badge: {
    position: 'absolute',
    top: 10,
    right: 5,
  },
  bottomContainer: {
    alignSelf: 'stretch',
    height: 2,
  },
  backIconContainer: {
    height: '100%',
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 2,
  },
  communityBtn: {
    backgroundColor: Colors.white,
    borderRadius: 8,
    borderColor: Colors.lightText,
    borderWidth: 1,
    alignSelf: 'center',
  },
  communityBtnText: {
    fontWeight: FontWeight.medium,
    fontSize: FontSize.small,
    lineHeight: LineHeight.small,
    textAlign: 'center',
    color: Colors.lightText,
    fontFamily: FontFamily.lato_regular,
  },
});
type IconProps = {
  onPress?: () => void;
};

export const BellIcon: FC<IconProps> = ({onPress}) => (
  <Pressable
    style={styles.iconButtonContainr}
    onPress={
      !!onPress
        ? onPress
        : () => NavigationService.getInstance().openNotification()
    }>
    <Icon
      size={20}
      name="notifications-outline"
      onPress={onPress}
      color="#4F4F4F"
    />
  </Pressable>
);

export const FilterBy = ({
  onPress,
  isFilterApplied,
}: {
  onPress: () => void;
  isFilterApplied?: boolean;
}) => (
  <Pressable
    onPress={onPress}
    style={({pressed}) => [
      styles.filterByIconContainer,
      {
        opacity: pressed ? 0.4 : 1,
      },
    ]}>
    {isFilterApplied && (
      <View style={styles.badge}>
        <FontAwesomeIcon icon={faCircle} color="red" size={5} />
      </View>
    )}
    <SimpleLineIcon color={Colors.lightText} name="equalizer" size={20} />
  </Pressable>
);

export const ChatIcon: FC<IconProps> = ({onPress}) => (
  <Pressable
    onPress={
      !!onPress
        ? onPress
        : () => NavigationService.getInstance().openConversations()
    }>
    <View style={styles.iconButtonContainr}>
      <CustomIcon size={20} name={Icons.Chats} color="#4F4F4F" />
    </View>
  </Pressable>
);

export const SearchIcon: FC<IconProps> = ({onPress}) => (
  <Pressable {...{onPress}} style={styles.iconButtonContainr}>
    <Icon size={20} name="search-outline" color="#4F4F4F" />
  </Pressable>
);

export const CancelIcon: FC<IconProps> = ({onPress}) => (
  <Pressable {...{onPress}} style={styles.iconButtonContainr}>
    <CustomIcon size={14} name="remove" color="#4F4F4F" />
  </Pressable>
);

export const SettingIcon: FC<IconProps> = ({onPress}) => (
  <Pressable {...{onPress}} style={styles.iconButtonContainr}>
    <Icon size={20} name="settings-outline" color="#4F4F4F" />
  </Pressable>
);

export const CreateCommunityBtn: FC<IconProps> = ({onPress}) => (
  <Button size="sm" style={styles.communityBtn} onPress={onPress}>
    <CustomText size={FontSize.small} fontFamily={FontFamily.FigtreeSemiBold}>
      Create Space
    </CustomText>
  </Button>
);

export const HeaderBackButton: FC<IconProps> = ({onPress}) => {
  return (
    <Pressable style={[styles.backIconContainer]} onPress={onPress}>
      <Icon name="arrow-back" color="#000" size={23} />
    </Pressable>
  );
};

export const HeaderRemoveButton: FC<IconProps> = ({onPress}) => {
  return (
    <Pressable style={[styles.backIconContainer]} onPress={onPress}>
      <CustomIcon name={Icons.Remove} color="#000" size={12} />
    </Pressable>
  );
};

type Fun = () => React.ReactNode;

export const AddIconButton: FC<IconProps> = ({onPress}) => (
  <Pressable onPress={onPress} style={styles.iconButtonContainr}>
    <Icon size={20} name="add-circle-outline" color="#4F4F4F" />
  </Pressable>
);

type HeaderProps = {
  headerLeft?: () => React.ReactNode;
  headerRight?: () => React.ReactNode;
  title?: Fun | string;
  hideBottomBar?: boolean;
  bottomBarColor?: string;
};

const Header: FC<HeaderProps> = ({
  headerLeft,
  headerRight,
  title,
  hideBottomBar,
  bottomBarColor = Colors.background,
}) => {
  const {top} = useSafeAreaInsets();
  const layout = useWindowDimensions();
  const height =
    Platform.OS == 'android'
      ? 42 + top || 0
      : Platform.OS == 'web'
      ? 48
      : getDefaultHeaderHeight(layout, false, top);
  return (
    <View style={styles.headerTopContainer}>
      <View
        style={[
          styles.headerContainer,
          !!headerLeft && {paddingLeft: 4},
          {
            height,
            paddingTop: top || 0,
          },
        ]}>
        {headerLeft?.()}
        <View style={styles.headerTitleContainer}>
          {typeof title === 'string' ? (
            <CustomText
              color="title"
              size={FontSize.heading}
              fontFamily={FontFamily.FigtreeBold}>
              {title}
            </CustomText>
          ) : !!title ? (
            title()
          ) : null}
        </View>
        {headerRight?.()}
      </View>
      {!hideBottomBar && (
        <View
          style={[styles.bottomContainer, {backgroundColor: bottomBarColor}]}
        />
      )}
    </View>
  );
};

export default memo(Header);
