import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import ConstantsEnum from '../../constants/constantsEnum';
import AnalyticsService from '../../services/analyticsServices';
import {AnalyticsEventPayload} from '../../models/Analytics/AnalyticsEventPayload';

export interface Analytics {
  createAnalyticsEventStatus: string;
}

const initialState: Analytics = {
  createAnalyticsEventStatus: ConstantsEnum.ApiStatusEnum.IDLE,
};

export const createAnalyticsEvent = createAsyncThunk(
  'analytics/createAnalyticsEvent',
  async (payload: AnalyticsEventPayload, thunkAPI) => {
    thunkAPI.dispatch(createAnalyticsEventPending());
    AnalyticsService.createAnalyticsEvent(payload)
      .then(() => {
        thunkAPI.dispatch(createAnalyticsEventFulFilled());
      })
      .catch(error => {
        if (error.code !== 'ERR_INTERNAL_ASSERTION') {
          thunkAPI.dispatch(createAnalyticsEventRejected(error.message));
        }
      });
  },
);

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: initialState,
  reducers: {
    createAnalyticsEventFulFilled: state => {
      state.createAnalyticsEventStatus = ConstantsEnum.ApiStatusEnum.SUCCEEDED;
    },
    createAnalyticsEventPending: state => {
      state.createAnalyticsEventStatus = ConstantsEnum.ApiStatusEnum.LOADING;
    },
    createAnalyticsEventRejected: state => {
      state.createAnalyticsEventStatus = ConstantsEnum.ApiStatusEnum.FAILED;
    },
  },
});

export const {
  createAnalyticsEventFulFilled,
  createAnalyticsEventPending,
  createAnalyticsEventRejected,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;
