/*eslint-disable */
import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import type {FC} from 'react';
import {Platform, StyleSheet, Text, View} from 'react-native';
import {Button as Btn} from 'native-base';
import {SheetTitle} from '@components/Modals/SheetComponent';
import AuthenticationService from '@services/authenticationService';
import NavigationService from '@services/navigationService';
import Colors from '@constants/Colors';
import type {EditAccountDataPayload} from '@models/AccountData/EditAccountDataPayload';
import Versions from '../../../../package.json';
import {useSelector} from 'react-redux';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {ThingTypeEnum} from '@constants/constantsEnum';
import {dispatch} from '@redux/store';
import {editUserAccountData} from '@redux/actions/userAccount';
import {ActivityIndicator} from 'react-native-paper';
import {Linking} from 'react-native';
import Analytics from '@wyse/analytics';
import CustomModal from '@components/CustomModal';
import {FontFamily} from '@utils/sizes';

const styles = StyleSheet.create({
  subContainer: {
    alignSelf: 'stretch',
    justifyContent: 'space-between',
  },
  handlecontainer: {
    padding: 10,
    alignSelf: 'stretch',
  },
  handle: {
    alignSelf: 'center',
    height: 4,
    borderRadius: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  sheetContent: {
    paddingTop: 8,
    alignSelf: 'stretch',
    marginBottom: 20,
    paddingHorizontal: 20,
  },
  sheetDescription: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 20,
    color: Colors.lightText,
  },
  buttonContainer: {width: '65%', alignSelf: 'center'},
  button: {
    backgroundColor: Colors.primary,
    borderRadius: 8,
    marginBottom: 10,
    height: 40,
    borderWidth: 0,
  },
  buttonOutline: {
    borderColor: Colors.primary,
    borderRadius: 8,
    height: 40,
    marginBottom: 10,
  },
  versionText: {
    color: Colors.lightText,
  },
  line: {alignSelf: 'stretch', height: 1, backgroundColor: Colors.background},
});

type BottomSheetModalRef = {
  present: () => void;
  open: () => void;
};

const Context = createContext<React.RefObject<BottomSheetModalRef>>(0 as any);

export const LogoutBottomSheetProvider: FC<{
  children: React.ReactElement;
}> = props => {
  const sheetRef = useRef<BottomSheetModalRef>(null);
  const {children} = props;
  return <Context.Provider value={sheetRef}>{children}</Context.Provider>;
};

export const useLogoutBottomSheetRef = () => useContext(Context);

const LogoutBottomSheet = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const userThingId = useSelector(userAccountThingIdSelector);
  const [visible, setVisible] = useState(false);
  const [isActiveDeactivate, setIsActiveDeactivate] = useState(false);

  const present = useCallback(() => {
    console.log('open modal');
    setVisible(true);
  }, []);

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      close,
      present,
    }),
    [close, present],
  );

  const logout = useCallback(async () => {
    try {
      setLoading(true);
      await AuthenticationService.signOutUser();
      Analytics.reset();
      setVisible(false);
      NavigationService.getInstance().replaceToAuth();
    } catch (error) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openEmail = useCallback(async () => {
    Linking.openURL('mailto:contact@bewyse.in');
  }, []);

  const deactivate = useCallback(async () => {
    try {
      setDeleting(true);
      await AuthenticationService.signOutUser();
      let editAccountDataPayload: EditAccountDataPayload = {
        thing_id: userThingId!,
        thing_type: ThingTypeEnum.ACCOUNT,
        attrs: [['deleted', true, 'replace']],
      };
      await dispatch(editUserAccountData(editAccountDataPayload));
      setVisible(false);
      NavigationService.getInstance().replaceToAuth();
    } catch (error) {
      setDeleting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userThingId]);

  return (
    <CustomModal
      animationType="fade"
      visible={visible}
      onRequestClose={() => {
        setVisible(false);
      }}
      style={{alignSelf: 'stretch', flex: 1, backgroundColor: '#fff'}}>
      {/* <View style={styles.handlecontainer}>
        <View style={styles.handle} />
      </View> */}

      <SheetTitle title="Options" />
      <View style={styles.line} />
      <View style={styles.sheetContent}>
        <Text style={styles.sheetDescription}>
          {isActiveDeactivate
            ? 'Your account will be permanently deleted after a grace period of 15 days.'
            : 'Please choose options from below -'}
        </Text>
      </View>
      <View style={styles.buttonContainer}>
        {!isActiveDeactivate && (
          <Btn
            disabled={loading || deleting}
            variant="outline"
            size="sm"
            style={styles.button}
            onPress={openEmail}>
            {loading ? (
              <ActivityIndicator size={14} color={Colors.white} />
            ) : (
              <Text
                style={{
                  color: '#F4F4F4',
                  fontFamily: FontFamily.lato_regular,
                }}>
                Contact Us
              </Text>
            )}
          </Btn>
        )}
        {!isActiveDeactivate && (
          <Btn
            disabled={loading || deleting}
            variant="outline"
            size="sm"
            style={styles.buttonOutline}
            onPress={logout}>
            {loading ? (
              <ActivityIndicator size={14} color={Colors.white} />
            ) : (
              <Text
                style={{
                  color: '#9B51E0',
                  fontFamily: FontFamily.lato_regular,
                }}>
                Logout
              </Text>
            )}
          </Btn>
        )}
        <Btn
          variant="outline"
          size="sm"
          disabled={loading || deleting}
          style={styles.buttonOutline}
          onPress={() => {
            if (!isActiveDeactivate) {
              setIsActiveDeactivate(true);
              return;
            }
            deactivate();
          }}>
          {deleting ? (
            <ActivityIndicator size={14} color={Colors.primary} />
          ) : (
            <Text
              style={{
                color: '#9B51E0',
                fontFamily: FontFamily.lato_regular,
              }}>
              {isActiveDeactivate ? 'Confirm' : 'Delete Account'}
            </Text>
          )}
        </Btn>
      </View>
      <View
        style={{
          marginVertical: 5,
          alignSelf: 'center',
        }}>
        <Text style={styles.versionText}>
          {Platform.OS == 'ios'
            ? `version-1.${Versions.version_ios}.${Versions.build_ios}`
            : 'version-1.0.75.' + (parseInt(Versions.version_android) - 60)}
        </Text>
      </View>
    </CustomModal>
  );
});

export default LogoutBottomSheet;
