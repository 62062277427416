import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {CommunityData} from '@models/Community/CommunityData';
import type {CommunityMember} from '@models/Community/CommunityMember';
import ConstantsEnum, {
  ApiStatusEnum,
  JoinEventEnum,
} from '@constants/constantsEnum';
import type {CommunityJoinEventPayload} from '@models/Community/CommunityJoinEventPayload';
import {
  fetchAllCommunityList,
  fetchCommunityFeed,
  fetchCommunityMembers,
  joinCommunityEventHandler,
} from '@redux/actions/community';
import ToasterService from '@services/toasterService';
import {DeletePostPayload} from '@models/Feed/DeletePostPayload';

export type CommunityFeedState = {
  communityId: string;
  feedPage: number;
  error?: any;
  feedLoadMoreStatus: ApiStatusEnum;
  totalPagesInFeed?: number;
  //Maintains initial fetchStatus for page 0
  fetchStatus: ApiStatusEnum;
};

export type CommunityMembersState = {
  communityId: string;
  membersPage: number;
  error?: any;
  membersLoadMoreStatus: ApiStatusEnum;
  totalPagesInMembers?: number;
  //Maintains initial fetchStatus for page 0
  fetchStatus: ApiStatusEnum;
};

const createCommunityFeedState = (communityId: string): CommunityFeedState => ({
  communityId: communityId,
  feedPage: 0,
  feedLoadMoreStatus: ApiStatusEnum.IDLE,
  fetchStatus: ApiStatusEnum.IDLE,
});

const createCommunityMembersState = (
  communityId: string,
): CommunityMembersState => ({
  communityId: communityId,
  membersPage: 0,
  membersLoadMoreStatus: ApiStatusEnum.IDLE,
  fetchStatus: ApiStatusEnum.IDLE,
});

export interface Community {
  allCommunityData: CommunityData[] | null;
  //Mapped communityId with FeedIds in particular community
  communityFeedIds: Record<string, string[]>;
  //mapped communityId with MemberIds in particular community
  communityMemberIds: Record<string, string[]>;
  communities: Record<string, CommunityData>;
  members: Record<string, CommunityMember>;
  /** Used for maintaining states like numberOfPages, currentPage in Feed, initial fetchStatus and errors  */
  communityFeedDetails: Record<string, CommunityFeedState>;
  /** Used for maintaining states like numberOfPages, currentPage in Members List, initial fetchStatus and errors  */
  communityMembersDetails: Record<string, CommunityMembersState>;
  joinCommuntiyStatus: ApiStatusEnum;
  joinedCommunitiesByUser: string[];
  status: ApiStatusEnum;
  error: any;
}

const initialState: Community = {
  allCommunityData: null,
  communityFeedIds: {},
  communityMemberIds: {},
  communities: {},
  members: {},
  communityFeedDetails: {},
  communityMembersDetails: {},
  joinCommuntiyStatus: ApiStatusEnum.IDLE,
  joinedCommunitiesByUser: [],
  status: ApiStatusEnum.IDLE,
  error: null,
};

export const communitySlice = createSlice({
  name: 'community',
  initialState: initialState,
  reducers: {
    addJoinedCommunitiesByUser: (state, action: PayloadAction<string[]>) => {
      state.joinedCommunitiesByUser = action.payload;
    },
    updateJoinedCommunitiesByUser: (
      state,
      action: PayloadAction<CommunityJoinEventPayload>,
    ) => {
      if (action.payload.action == JoinEventEnum.SUBSCRIBE) {
        state.joinedCommunitiesByUser.push(action.payload.resource_id);
      } else if (action.payload.action == JoinEventEnum.UNSUBSCRIBE) {
        const index = state.joinedCommunitiesByUser.indexOf(
          action.payload.resource_id,
        );
        if (index > -1) {
          state.joinedCommunitiesByUser.splice(index, 1);
        }
      }
    },

    resetCommunityFeedList: (state, action: PayloadAction<string>) => {
      const communityId = action.payload;

      state.communityFeedIds[communityId] = [];
    },
    resetCommunityMembersList: (state, action: PayloadAction<string>) => {
      const communityId = action.payload;

      state.communityMemberIds[communityId] = [];
    },
    setCommunityFeedPage: (
      state,
      action: PayloadAction<{communityId: string; page: number}>,
    ) => {
      state.communityFeedDetails[action.payload.communityId].feedPage =
        action.payload.page;
    },
    setCommunityMembersPage: (
      state,
      action: PayloadAction<{communityId: string; page: number}>,
    ) => {
      state.communityMembersDetails[action.payload.communityId].membersPage =
        action.payload.page;
    },
    deletePostFromCommunity: (
      state,
      action: PayloadAction<DeletePostPayload>,
    ) => {
      const {thing_id, community_id} = action.payload;
      if (state.communityFeedIds[community_id]) {
        state.communityFeedIds[community_id] = state.communityFeedIds[
          community_id
        ].filter(id => id !== thing_id);
      }
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchAllCommunityList.pending, state => {
      state.status = ApiStatusEnum.LOADING;
    });
    builder.addCase(fetchAllCommunityList.fulfilled, (state, action) => {
      state.status = ConstantsEnum.ApiStatusEnum.SUCCEEDED;
      state.allCommunityData = action.payload;
    });
    builder.addCase(fetchAllCommunityList.rejected, (state, action) => {
      state.status = ConstantsEnum.ApiStatusEnum.FAILED;
      state.error = action.payload;
      state.allCommunityData = null;
    });
    builder.addCase(fetchCommunityFeed.pending, (state, action) => {
      const {
        meta: {
          arg: {communityId, page},
        },
      } = action;

      if (!state.communityFeedDetails[communityId]) {
        state.communityFeedDetails[communityId] =
          createCommunityFeedState(communityId);
      }
      const feedState = state.communityFeedDetails[communityId];
      if (page == 0) {
        feedState.fetchStatus = ApiStatusEnum.LOADING;
      } else {
        feedState.feedLoadMoreStatus = ApiStatusEnum.LOADING;
      }
    });
    builder.addCase(fetchCommunityFeed.fulfilled, (state, action) => {
      const {
        payload: {results, num_pages},
        meta: {
          arg: {communityId, page},
        },
      } = action;
      // initialize the array if its not alreay exists
      const feedState = state.communityFeedDetails[communityId];
      if (!state.communityFeedIds[communityId]) {
        state.communityFeedIds[communityId] = [];
      }
      if (page == 0) {
        feedState.fetchStatus = ApiStatusEnum.SUCCEEDED;
      } else {
        feedState.feedLoadMoreStatus = ApiStatusEnum.SUCCEEDED;
      }
      feedState.totalPagesInFeed = num_pages;
      for (let i = 0; i < results.length; i++) {
        const feedData = results[i];
        state.communityFeedIds[communityId].push(feedData.thing_id);
      }
    });
    builder.addCase(fetchCommunityFeed.rejected, (state, action) => {
      const {
        meta: {
          arg: {communityId, page},
        },
      } = action;
      const feedState = state.communityFeedDetails[communityId];
      if (page == 0) {
        feedState.fetchStatus = ApiStatusEnum.FAILED;
      } else {
        feedState.feedLoadMoreStatus = ApiStatusEnum.FAILED;
      }
      feedState.error = action.payload;
    });
    builder.addCase(fetchCommunityMembers.pending, (state, action) => {
      const {
        meta: {
          arg: {communityId, page},
        },
      } = action;
      if (!state.communityMembersDetails[communityId]) {
        state.communityMembersDetails[communityId] =
          createCommunityMembersState(communityId);
      }
      const membersState = state.communityMembersDetails[communityId];
      if (page == 0) {
        state.communityMemberIds[communityId] = [];
        membersState.fetchStatus = ApiStatusEnum.LOADING;
      } else {
        membersState.membersLoadMoreStatus = ApiStatusEnum.LOADING;
      }
    });
    builder.addCase(fetchCommunityMembers.fulfilled, (state, action) => {
      const {
        payload: {results, num_pages},
        meta: {
          arg: {communityId, page},
        },
      } = action;
      const membersState = state.communityMembersDetails[communityId];
      if (page == 0) {
        membersState.fetchStatus = ApiStatusEnum.SUCCEEDED;
      } else {
        membersState.membersLoadMoreStatus = ApiStatusEnum.SUCCEEDED;
      }
      membersState.totalPagesInMembers = num_pages;

      // initialize the array if its not alreay exists
      if (!state.communityMemberIds[communityId]) {
        state.communityMemberIds[communityId] = [];
      }
      for (let i = 0; i < results.length; i++) {
        const memberData = results[i];
        state.communityMemberIds[communityId].push(memberData.thing_id);
        state.members[memberData.thing_id] = memberData;
      }
    });
    builder.addCase(fetchCommunityMembers.rejected, (state, action) => {
      const {
        meta: {
          arg: {communityId, page},
        },
      } = action;
      const membersState = state.communityMembersDetails[communityId];
      if (page == 0) {
        membersState.fetchStatus = ApiStatusEnum.FAILED;
      } else {
        membersState.membersLoadMoreStatus = ApiStatusEnum.FAILED;
      }
      membersState.error = action.payload;
    });
    builder.addCase(joinCommunityEventHandler.pending, state => {
      state.joinCommuntiyStatus = ApiStatusEnum.LOADING;
    });
    builder.addCase(
      joinCommunityEventHandler.fulfilled,
      (state, payloadAction) => {
        const {
          meta: {
            arg: {action},
          },
        } = payloadAction;
        state.joinCommuntiyStatus = ApiStatusEnum.SUCCEEDED;
        if (action == JoinEventEnum.SUBSCRIBE) {
          ToasterService.showToast(
            'Space joined successfully!!',
            '#27AE60',
            2000,
          );
        } else {
          ToasterService.showToast(
            'Space unjoined successfully!!',
            '#27AE60',
            2000,
          );
        }
      },
    );
    builder.addCase(joinCommunityEventHandler.rejected, state => {
      state.joinCommuntiyStatus = ApiStatusEnum.FAILED;
    });
  },
});

export const {
  addJoinedCommunitiesByUser,
  updateJoinedCommunitiesByUser,
  setCommunityFeedPage,
  resetCommunityFeedList,
  resetCommunityMembersList,
  setCommunityMembersPage,
  deletePostFromCommunity,
} = communitySlice.actions;

export default communitySlice.reducer;
