import React from 'react';
import type {FC} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import Colors from '@constants/Colors';
import {FontFamily} from '@utils/sizes';

const styles = StyleSheet.create({
  titleContainer: {
    alignSelf: 'stretch',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  titleText: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 24,
    color: Colors.text,
    fontFamily: FontFamily.lato_regular,
  },
});

type SheetTitleProps = {
  title: string;
};

export const SheetTitle: FC<SheetTitleProps> = ({title}) => {
  return (
    <View style={styles.titleContainer}>
      <Text style={styles.titleText}>{title}</Text>
    </View>
  );
};
