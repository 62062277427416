import ConstantsEnum from '../constants/constantsEnum';
import {AccountDataList} from '../models/AccountData/AccountDataList';
import {FeedList} from '../models/Feed/FeedList';
import {FeedPostByUserPayload} from '../models/Feed/FeedPostByUserPayload';
import AuthenticationService from './authenticationService';
import axiosInstance from './axios';

const getProfileDetailsFromThingId = async (thing_id: string) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.get<AccountDataList>('/nexum/get/list', {
    params: {
      thing_type: 'account',
      filter: JSON.stringify({
        thing_id: thing_id,
      }),
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchProfileFeedData = async (payload: FeedPostByUserPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.get<FeedList>('/nexum/get/list', {
    params: {
      thing_type: ConstantsEnum.ThingTypeEnum.POST,
      filter: JSON.stringify({
        author_id: payload.authorId,
      }),
      page: payload.page,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const ProfileService = {
  getProfileDetailsFromThingId,
  fetchProfileFeedData,
};

export default ProfileService;
