/* eslint-disable */
import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type {FC} from 'react';
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  Platform,
  Keyboard,
  // BackHandler,
} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import ContentLoader, {Rect, Circle} from 'react-content-loader/native';
import type {ListRenderItem} from 'react-native/types';
import {shallowEqual, useSelector} from 'react-redux';
import {Spinner as NativeSpinner} from 'native-base';
import {useNavigation, useRoute} from '@react-navigation/native';
import {dispatch} from '@redux/store';
import CommentCard from '@components/Card/CommentCard';
import FeedCard from '@components/Card/FeedCard/FeedCard';
import CommentModal, {
  CommentInputHeightContext,
  useCommentInputHeight,
} from '@components/Modals/CommentModal';
import Header, {HeaderBackButton} from '@components/Header/Header';
import DynamicLinkService from '@services/dynamicLinkService';
import ShareService from '@services/shareService';
import {
  userAccountNameSelector,
  userAccountThingIdSelector,
} from '@redux/selectors/userAccountSelectors';
import type {FeedData} from '@models/Feed/FeedData';
import type {CreateTextCommentPayload} from '@models/Comment/CreateTextCommentPayload';
import ConstantsEnum, {
  CommentParentScreenEnum,
  DynamicLinkThingTypeEnum,
  EventTypeEnum,
  FeedCardBodyTypeEnum,
  FeedEnagagementAnalEventValue,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import Colors from '@constants/Colors';
import {
  initFeedDetailsPage,
  setCommentsPage,
  setDeletePostStatusIdle,
} from '@redux/slices/feedSlice';
import {
  createCommentPageEndReachedSelected,
  createCommentsCountSelector,
  createCommentsLoadingSelector,
  createCommentsIdsSelector,
  createHasFeedSelector,
  createCommentsPageSelector,
  createShouldCallCommentsApiOnLaunch,
  createHasFeedDataSelector,
  createFeedDetailsLoadingSelector,
  createAmaDataselector,
  postDeleteStatusSelector,
  feedDataItemSelector,
} from '@redux/selectors/feedSelector';
import {createTextComment, getComments} from '@redux/actions/comments';
import {fetchFeedDataByThingId} from '@redux/actions/feed';
import NavigationService from '@services/navigationService';
import ToasterService from '@services/toasterService';
import Analytics, {IEventData} from '@wyse/analytics';
import Screen from '@components/Screen';
import BigScreenTabBar from '@components/BigScreenTabBar';
import {FontSize, SCREEN_SIZE, TAB_BAR_SIZE} from '@utils/sizes';
import {AppScreens} from '@navigations/ScreenNames';
import {refreshFeedOnNotificatonSelector} from '@redux/selectors/notificationSelector';
import {setRefreshFeedDetailsOnNotification} from '@redux/slices/notificationSlice';
import {FontFamily} from '@utils/sizes';
import {MetaDataFlair} from '@models/MetaData/metaDataFlair';
import {postLabelSelector} from '@redux/selectors/metaSelector';
import {withTokenRefresh} from '@utils/webhooks';
import AMAService from '@services/amaService';
import LocalStorage from '@services/storage';
import BigScreenRightBar from '@components/BigScreenRightBar';
import {CustomText} from '@components/Texts';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },

  headerContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12,

    backgroundColor: '#FFFFFF',
  },
  stretch: {alignSelf: 'stretch'},
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: '#00000040',
  },
});

type PageParams = {
  feedThingId?: string;
  isProfileCard: boolean;
  isDynamicLink?: boolean;
  feedDataIndex?: number;
  isFromComment?: boolean;
};

const Loader = () => {
  return (
    <View>
      <View
        style={{
          padding: 16,
          margin: 16,
          borderRadius: 10,
          backgroundColor: '#FEFDFF',
          shadowColor: '#000',
          shadowOffset: {width: 1, height: 3},
          shadowOpacity: 0.8,
          shadowRadius: 2,
          elevation: 5,
          borderWidth: 1,
          borderColor: '#F2F2F2',
        }}>
        <ContentLoader
          viewBox="0 0 476 300"
          height={200}
          backgroundColor="#bbbdbf"
          foregroundColor="#e8e8e8">
          <Rect x="96" y="8" width="120" height="20" rx="3" />
          <Rect x="96" y="45" width="80" height="20" rx="3" />
          <Rect x="0" y="96" width="410" height="12" rx="3" />
          <Rect x="0" y="150" width="380" height="12" rx="3" />
          <Rect x="0" y="210" width="178" height="12" rx="3" />
          <Circle cx="40" cy="40" r="40" />
        </ContentLoader>
      </View>
    </View>
  );
};
const PageHeader: FC<{userThingId?: string}> = ({userThingId}) => {
  //TODO - if FeedDetail initiated from Dynamic post, then would this work?
  const headerLeft = useCallback(() => {
    if (!userThingId) return null;
    return (
      <HeaderBackButton
        onPress={() => {
          NavigationService.getInstance().goBack();
        }}
      />
    );
  }, [userThingId]);

  return <Header title="" headerLeft={headerLeft} />;
};

/**
 * modal visibility
 */
const CommentModalContext = createContext<
  [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
    string | undefined,
    React.Dispatch<React.SetStateAction<string | undefined>>,
  ]
>([] as any);

const CommentModalProvider: FC<{children: any}> = ({children}) => {
  const params = useRoute().params as PageParams;

  const state = useState(!!params.isFromComment);
  const state2 = useState<string>();
  return (
    <CommentModalContext.Provider value={[...state, ...state2]}>
      {children}
    </CommentModalContext.Provider>
  );
};

const CommentsLabel: FC<{id: string}> = ({id}) => {
  const count = useSelector(createCommentsCountSelector(id));

  if (count <= 0) return null;

  return (
    <View style={{marginHorizontal: 16, marginTop: 8, alignSelf: 'stretch'}}>
      <CustomText
        size={FontSize.title}
        style={{
          color: '#333333',
        }}>
        Comments {count}
      </CustomText>
    </View>
  );
};

/**
 *
 *
 * List Header Component
 */
const ListHeaderComponent = () => {
  const params = useRoute().params as PageParams;
  const loading = useSelector(
    createFeedDetailsLoadingSelector(params.feedThingId),
  );
  const [_, setVisible] = useContext(CommentModalContext);
  const commentsLoading = useSelector(
    createCommentsLoadingSelector(params!.feedThingId!),
  );
  const userThingId = useSelector(userAccountThingIdSelector);
  const navigation = useNavigation();

  const handleShare = useCallback(async (data: FeedData) => {
    const link = await DynamicLinkService.createDynamicLink(
      data.thing_id,
      DynamicLinkThingTypeEnum.FEED_DETAIL,
    );
    const options = {
      message: `Check out this post on Wyse App ${data.title}: ${link} \n\nJoin the most meaningful discussions by industry leaders and your peers on Wyse app now.\nInnovation starts from conversation`,
      title: '',
    };
    await ShareService.openShare(options);
  }, []);

  return (
    <View style={{alignSelf: 'stretch'}}>
      {!!params.feedThingId && !loading && (
        <FeedCard
          thingId={params.feedThingId}
          feedCardIndex={params.feedDataIndex!}
          isFeedDetailCard
          isProfileCard={params.isProfileCard}
          seeMore={false}
          loggedInUserId={userThingId!}
          navigation={navigation}
          handleComment={() => {
            const analyticsEventPayload: IEventData = {
              ev_typ: EventTypeEnum.ANALYTICS,
              ev_val: FeedEnagagementAnalEventValue.POST_COMMENT_CLICK,
              thing_type: ThingTypeEnum.POST,
              thing_id: params.feedThingId,
            };
            Analytics.trackEvent(analyticsEventPayload);
            setVisible(true);
          }}
          handleShare={handleShare}
        />
      )}
      {loading && <Loader />}

      {/* {params.isDynamicLink && !!feed && (
          <FeedCardWithData
            feedData={fetchedFeedDataByThingId}
            feedCardIndex={params.feedDataIndex}
            isFeedDetailCard
            isProfileCard={isProfileCard}
            seeMore={false}
            commentsCount={commentsCount}
            userThingId={userAccountDataRed.linked_in}
            userAccountData={userAccountDataRed}
            navigation={navigation}
            handleComment={handleComment}
            handleShare={handleShare}
          />
        )} */}
      {!loading && !!params.feedThingId && (
        <CommentsLabel id={params.feedThingId} />
      )}
      {!loading && commentsLoading && (
        <NativeSpinner size="sm" color="#9B51E0" />
      )}
    </View>
  );
};

const ListFooterComponent = () => {
  const params = useRoute().params as PageParams;
  const commentsCount = useSelector(
    createCommentsCountSelector(params.feedThingId!),
  );
  const onEndReached = useSelector(
    createCommentPageEndReachedSelected(params.feedThingId!),
  );

  return (
    <View style={{alignSelf: 'stretch', alignItems: 'center'}}>
      {onEndReached ? null : !(commentsCount ?? commentsCount > 0) ? null : (
        <NativeSpinner size="sm" color="#9B51E0" />
      )}
    </View>
  );
};

const CommentModalWrapper: FC<{
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({setLoading, loading}) => {
  const params = useRoute().params as PageParams;
  const userThingId = useSelector(userAccountThingIdSelector);
  const userName = useSelector(userAccountNameSelector);
  const [visible, setVisible, parentCommentId, setParentCommentId] =
    useContext(CommentModalContext);

  const createComment = useCallback(
    async (commentText: string, anonComment: boolean) => {
      setLoading(true);
      const createTextCommentPayload: CreateTextCommentPayload = {
        thing_type: ThingTypeEnum.COMMENT,
        kwargs: {
          parent_thing_id: (parentCommentId
            ? parentCommentId
            : params.feedThingId)!,
          comment_type: FeedCardBodyTypeEnum.TXT,
          comment_text: commentText,
          author_id: userThingId!,
          author_name: userName!,
          anon_comment: anonComment,
        },
      };
      const payload = {
        createTextCommentPayload: createTextCommentPayload,
        isCommentReply: !!parentCommentId,
        feedDataId: params.feedThingId!,
        commentParentScreen: params.isProfileCard
          ? CommentParentScreenEnum.PROFILE
          : CommentParentScreenEnum.FEED,
      };
      const response = await dispatch(createTextComment(payload));
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: parentCommentId
          ? FeedEnagagementAnalEventValue.POST_REPLY_SUBMIT_CLICK
          : FeedEnagagementAnalEventValue.POST_COMMENT_SUBMIT_CLICK,
        thing_type: parentCommentId
          ? ThingTypeEnum.COMMENT
          : ThingTypeEnum.POST,
        thing_id: !!parentCommentId ? parentCommentId : params.feedThingId,
      };
      Analytics.trackEvent(analyticsEventPayload);
      if (createTextComment.fulfilled.match(response)) {
        setVisible(false);
        setLoading(false);
        if (parentCommentId) {
          setParentCommentId(undefined);
        }
        const analyticsEventSuccessPayload: IEventData = {
          ev_typ: EventTypeEnum.ANALYTICS,
          ev_val: parentCommentId
            ? FeedEnagagementAnalEventValue.POST_REPLY_SUCCESS
            : FeedEnagagementAnalEventValue.POST_COMMENT_SUCCESS,
          thing_type: parentCommentId
            ? ThingTypeEnum.COMMENT
            : ThingTypeEnum.POST,
          thing_id: !!parentCommentId ? parentCommentId : params.feedThingId,
        };
        Analytics.trackEvent(analyticsEventSuccessPayload);
        return response.meta.requestStatus;
      }
      ToasterService.showToast('Failed to add comment');
      setVisible(true);
      setLoading(false);
      const analyticsEventErrorPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: parentCommentId
          ? FeedEnagagementAnalEventValue.POST_REPLY_FAILED
          : FeedEnagagementAnalEventValue.POST_COMMENT_FAILED,
        thing_type: parentCommentId
          ? ThingTypeEnum.COMMENT
          : ThingTypeEnum.POST,
        thing_id: !!parentCommentId ? parentCommentId : params.feedThingId,
      };
      Analytics.trackEvent(analyticsEventErrorPayload);
      return 'rejected';
    },
    [
      parentCommentId,
      params.feedThingId,
      params.isProfileCard,
      userName,
      userThingId,
    ],
  );

  return (
    <CommentModal
      {...{loading}}
      isCommentReply={!!parentCommentId}
      createComment={createComment}
      visible={visible}
      onClose={() => {
        setVisible(false);
        setParentCommentId(undefined);
      }}
      onFocus={() => {
        setVisible(true);
      }}
    />
  );
};

const FeedDetailsInner: FC<PageParams> = ({isProfileCard, feedThingId}) => {
  const postId = feedThingId!;
  const userThingId = useSelector(userAccountThingIdSelector);
  const shouldCallApi = useSelector(
    createShouldCallCommentsApiOnLaunch(postId),
  );
  const feedData = useSelector(
    feedDataItemSelector(postId),
    (prev, next) =>
      prev?.title === next?.title &&
      prev?.data[0]?.value === next?.data[0]?.value,
  );
  const postLabels = useSelector(postLabelSelector);
  const [loading, setLoading] = useState(false);
  const keyboardHeight = useSharedValue(0);
  const heightValue = useCommentInputHeight();
  const data = useSelector(
    createCommentsIdsSelector(postId),
    shallowEqual as any,
  );
  const amaData = useSelector(createAmaDataselector(postId));
  const keyboardHidden = useRef(true);
  const [visible, setVisible, parentCommentId, setParentCommentId] =
    useContext(CommentModalContext);
  const initial = useRef(true);
  const loadingComments = useRef(false);
  const page = useSelector(createCommentsPageSelector(postId));
  const reloadOnNotificationPress = useSelector(
    refreshFeedOnNotificatonSelector,
  );

  useEffect(() => {
    (async () => {
      const data = await LocalStorage.getData<any>('redirect');
      console.log('data', data);
    })();
  }, []);
  const isAmaShowing = useMemo(() => {
    if (!feedData) return false;
    let postLabel: MetaDataFlair | null = null;
    if (feedData.flairs[0]) {
      postLabel = postLabels.filter(
        label => label.thing_id == feedData.flairs[0],
      )?.[0] as MetaDataFlair;
    }
    const isAma = postLabel && postLabel.title == 'AMA';

    if (isAma && !!amaData) {
      const date = new Date();
      if (date >= new Date(amaData?.start) && date < new Date(amaData?.end)) {
        return true;
      }
    }
    return false;
  }, [feedData?.flairs, amaData?.end, postLabels?.length, amaData?.start]);

  const renderItem = useCallback<ListRenderItem<string>>(
    ({item}) => (
      <CommentCard
        id={item}
        onReplyPress={(
          parentId: string,
          commentType: string,
          commentId: string,
        ) => {
          setParentCommentId(parentId);
          setVisible(true);
          const analyticsEventPayload: IEventData = {
            ev_typ: EventTypeEnum.ANALYTICS,
            ev_val:
              commentType == 'comment'
                ? FeedEnagagementAnalEventValue.POST_COMMENT_REPLY_CLICK
                : FeedEnagagementAnalEventValue.POST_REPLY_REPLY_CLICK,
            thing_type:
              commentType == 'comment'
                ? ThingTypeEnum.COMMENT
                : ThingTypeEnum.REPLY,
            thing_id: commentId,
          };
          Analytics.trackEvent(analyticsEventPayload);
        }}
      />
    ),
    [],
  );

  const getCommentsFunc = useCallback(
    async (id: string, p: number) => {
      loadingComments.current = true;
      await dispatch(
        getComments({
          postId: id,
          page: p,
          pass: true,
        }),
      );
      loadingComments.current = false;
    },
    [loadingComments],
  );

  useEffect(() => {
    if (reloadOnNotificationPress) {
      dispatch(setRefreshFeedDetailsOnNotification(false));
      getCommentsFunc(postId, page);
    }
    if (initial.current) {
      if (!shouldCallApi) return;
    }
    if (isAmaShowing) {
      return;
    }
    getCommentsFunc(postId, page);
  }, [
    shouldCallApi,
    postId,
    initial,
    isAmaShowing,
    reloadOnNotificationPress,
    page,
  ]);

  // TODO: bellow effect will stop user from going back to previous screen when comment is being added
  // useEffect(() => {
  //   if (loading && keyboardHidden) {
  //     const listner = BackHandler.addEventListener('hardwareBackPress', () => {
  //       return true;
  //     });
  //     return listner.remove;
  //   }
  // }, [loading, keyboardHidden]);

  useEffect(() => {
    const willShowListener = Keyboard.addListener(
      'keyboardWillShow',
      keyboard => {
        keyboardHeight.value = withTiming(keyboard.endCoordinates.height, {
          duration: keyboard.duration,
        });
      },
    );

    const didShowListener = Keyboard.addListener(
      'keyboardDidShow',
      keyboard => {
        keyboardHidden.current = false;
        if (Platform.OS === 'ios') return;
        keyboardHeight.value = withTiming(keyboard.endCoordinates.height, {
          duration: keyboard.duration,
        });
      },
    );

    const willHideListener = Keyboard.addListener(
      'keyboardWillHide',
      keyboard => {
        setVisible(false);
        keyboardHeight.value = withTiming(0, {
          duration: keyboard.duration,
        });
      },
    );

    const didHideListener = Keyboard.addListener(
      'keyboardDidHide',
      keyboard => {
        keyboardHidden.current = true;
        if (Platform.OS === 'ios') return;
        setVisible(false);
        keyboardHeight.value = withTiming(0, {
          duration: keyboard.duration,
        });
      },
    );
    return () => {
      willShowListener.remove();
      willHideListener.remove();
      didShowListener.remove();
      didHideListener.remove();
    };
  }, []);

  /**
   * keyExtractor
   */
  const keyExtractor = useCallback(
    (item: string) => `post-comment-${item}`,
    [],
  );

  const header = useMemo(() => <ListHeaderComponent />, []);
  const listFooter = useMemo(() => <ListFooterComponent />, []);

  const handleLoadMoreComments = useCallback(
    ({distanceFromEnd}: {distanceFromEnd: number}) => {
      if (isAmaShowing) return;
      if (distanceFromEnd <= 0) return;
      if (loadingComments.current) return;
      dispatch(setCommentsPage(postId));
    },
    [postId, isAmaShowing],
  );

  console.log('heightValue', heightValue, keyboardHeight);
  const animatedStyle = useAnimatedStyle(() => {
    console.log('heightValue', heightValue, keyboardHeight);
    return {
      flex: 1,
      marginBottom:
        Platform.OS === 'web'
          ? keyboardHeight.value
          : Platform.OS === 'android'
          ? heightValue.value
          : heightValue.value + keyboardHeight.value,
    };
  }, [heightValue, keyboardHeight]);

  return (
    <Screen
      RightComponent={BigScreenRightBar}
      screen={AppScreens.FeedDetail}
      LeftComponent={BigScreenTabBar}
      maxWidth={TAB_BAR_SIZE + SCREEN_SIZE}
      style={[styles.container, {backgroundColor: Colors.white}]}>
      <PageHeader {...{userThingId}} />
      {isAmaShowing ? <AMAService.Listener id={postId} /> : null}
      <Animated.View style={animatedStyle}>
        {useMemo(
          () => (
            <FlatList
              keyboardShouldPersistTaps="always"
              contentContainerStyle={[{paddingTop: 8}]}
              ListHeaderComponent={header}
              ListFooterComponent={listFooter}
              onEndReached={handleLoadMoreComments}
              onEndReachedThreshold={0.5}
              {...{data, renderItem, keyExtractor}}
            />
          ),
          [
            data,
            renderItem,
            listFooter,
            header,
            keyExtractor,
            handleLoadMoreComments,
          ],
        )}
      </Animated.View>
      {loading && <View style={styles.backdrop} />}
      {!!userThingId ? (
        <CommentModalWrapper {...{setLoading, loading}} />
      ) : null}
    </Screen>
  );
};

const FeedDetail = () => {
  const params = useRoute().params as PageParams;
  const hasFeed = useSelector(createHasFeedSelector(params.feedThingId));
  const value = useSharedValue(0);
  const hasFeedData = useSelector(
    createHasFeedDataSelector(params.feedThingId),
  );
  const postDeleteStatus = useSelector(postDeleteStatusSelector);
  const navigation = useNavigation();
  useEffect(() => {
    if (!params.feedThingId) return;
    dispatch(initFeedDetailsPage(params.feedThingId));
    if (hasFeedData) return;
    dispatch(fetchFeedDataByThingId(params.feedThingId));
    return () => {
      if (!params.feedThingId) return;
    };
  }, []);

  useEffect(() => {
    if (postDeleteStatus == ConstantsEnum.ApiStatusEnum.SUCCEEDED) {
      dispatch(setDeletePostStatusIdle());
      navigation.goBack();
    }
  }, [postDeleteStatus]);

  if (!params?.feedThingId || !hasFeed) {
    return null;
  }

  return (
    <CommentModalProvider>
      <CommentInputHeightContext.Provider value={value}>
        <FeedDetailsInner {...params} />
      </CommentInputHeightContext.Provider>
    </CommentModalProvider>
  );
};

export default withTokenRefresh(FeedDetail, true);
