import Colors from '@constants/Colors';
import {Platform} from 'react-native';

const useColors = () => {
  return {
    primary: Colors.primary,
    white: Colors.white,
    background: Colors.background,
  };
};

export const isWeb = Platform.OS == 'web';
export default useColors;
