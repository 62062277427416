import {
  StackActions,
  TabActions,
  createNavigationContainerRef,
} from '@react-navigation/native';
import {
  AppScreens,
  AuthScreens,
  MainScreens,
  TabScreens,
} from '@navigations/ScreenNames';
import type {Conversation} from '@models/Conversation';
import {ChatScreenParams} from '@components/Chat';

export const navigationRef = createNavigationContainerRef();

const NavigationService = (function () {
  class NavigationServiceInner {
    constructor() {}
    private get isReady() {
      return navigationRef.current?.isReady();
    }

    private replaceScreen(name: any, params?: object) {
      if (this.isReady) {
        navigationRef.current?.dispatch(StackActions.replace(name, params));
      }
    }

    private openScreen(name: any, params?: object) {
      if (this.isReady) {
        navigationRef.current?.dispatch(StackActions.push(name, params));
      }
    }

    goBack(count?: number) {
      if (this.isReady) {
        if (count == undefined) {
          navigationRef.current?.goBack();
          return;
        }
        navigationRef.current?.dispatch(StackActions.pop(count));
      }
    }

    cangoBack() {
      if (this.isReady) {
        return navigationRef.current?.canGoBack();
      }
      return false;
    }

    openTab(name: TabScreens, params?: object) {
      navigationRef.current?.dispatch(TabActions.jumpTo(name, params));
    }

    openHome(initialTab: TabScreens, params?: object) {
      navigationRef.current?.dispatch(
        StackActions.push(AppScreens.Tabs, {
          ...params,
          initialTab,
        }),
      );
    }

    replaceToApp(params?: object) {
      this.replaceScreen(MainScreens.App, params);
    }

    replaceToAuth(params?: {page: AuthScreens}) {
      this.replaceScreen(MainScreens.Auth, params);
    }

    openFeedDetails(
      thingId: string,
      index?: number,
      isProfileCard: boolean = false,
      isFromComment: boolean = false,
      isDynamicLink: boolean = false,
    ) {
      this.openScreen(AppScreens.FeedDetail, {
        feedDataIndex: index,
        feedThingId: thingId,
        isProfileCard,
        isFromComment,
        isDynamicLink,
      });
    }

    openImageView(postTitle: string, imageUrl: string) {
      this.openScreen(AppScreens.ImageView, {
        imageUrl,
        postTitle,
      });
    }

    openCreatePost(communityId?: string) {
      if (!communityId) {
        this.openTab(TabScreens.Post);
        return;
      }
      this.openScreen(AppScreens.CreatePost, {communityId});
    }

    openCommunityDetails(communityThingId: string) {
      this.openScreen(AppScreens.CommunityDetail, {communityThingId});
    }

    openConversations() {
      this.openScreen(AppScreens.Conversations);
    }
    openNotification() {
      this.openScreen(AppScreens.Notifications);
    }

    openProfileDetails(profileId: string) {
      this.openScreen(AppScreens.Profile, {profileThingId: profileId});
    }

    openChat(
      reciverId: string,
      conversation: Conversation,
      role?: string | null,
      org?: string | null,
    ) {
      this.openScreen(AppScreens.Chat, {
        conversation,
        conversationId: conversation.conversation_id,
        reciverId,
        role,
        org,
      } as ChatScreenParams);
    }

    openSpaces(params: any) {
      this.openScreen(AppScreens.SelectSpaces, params);
    }

    openInitiateChat(
      reciverId: string,
      name: string,
      avatar: string,
      role?: string,
      org?: string,
    ) {
      this.openScreen(AppScreens.InitiateChat, {
        id: reciverId,
        name,
        avatar,
        role,
        org,
      });
    }
  }
  // making the object give singleton class every time
  var instance: NavigationServiceInner;

  return {
    getInstance: function () {
      if (instance == null) {
        instance = new NavigationServiceInner();
      }
      return instance!;
    },
  };
})();

export default NavigationService;
