import React from 'react';
import {View} from 'react-native';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {useSelector} from 'react-redux';
import Feed from '@screens/Home/Feed';
import Communities from '@screens/Discover/Communities';
import Post from '@screens/Post/Post';
import Profile from '@screens/Profile/Profile';
import Notifications from '@screens/Notificaions/Notifications';
import TabNavigatorContainer from '@components/TabNavigatorContainer';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {
  createPostTabOptions,
  homeTabOptions,
  notificationTabOptions,
  profileTabOptions,
  spacesTabOptions,
} from './options';
import {TabScreens} from './ScreenNames';

const Tab = createBottomTabNavigator();

const TabNavigator = () => {
  const userThingId = useSelector(userAccountThingIdSelector)!;
  return (
    <View style={{flex: 1}}>
      <TabNavigatorContainer {...{userThingId}}>
        <Tab.Screen
          options={homeTabOptions}
          name={TabScreens.Home}
          component={Feed}
        />
        <Tab.Screen
          options={spacesTabOptions}
          name={TabScreens.Spaces}
          component={Communities}
        />
        <Tab.Screen
          options={createPostTabOptions}
          name={TabScreens.Post}
          component={Post}
        />
        <Tab.Screen
          options={notificationTabOptions}
          name={TabScreens.Notification}
          component={Notifications}
          initialParams={{screen: 'tab'}}
        />
        <Tab.Screen
          options={profileTabOptions}
          name={TabScreens.Profile}
          component={Profile}
          initialParams={{
            screen: 'tab',
            profileThingId: userThingId,
            tab: 'details',
          }}
        />
      </TabNavigatorContainer>
    </View>
  );
};

export default TabNavigator;
