import {
  View,
  Text,
  TextInput,
  TouchableWithoutFeedback,
  Image as RNImage,
  KeyboardAvoidingView,
  Switch,
  Keyboard,
  useWindowDimensions,
  Pressable,
} from 'react-native';
import React, {useState, useEffect} from 'react';
import {Button as Btn, Modal, ScrollView} from 'native-base';
import Icon from 'react-native-vector-icons/Ionicons';
import {useSelector} from '@redux/store';
import {imageContainerSize} from '@utils/utilityFunctions';
import {MetaDataFlair} from '@models/MetaData/metaDataFlair';
import CreatePollModal from '@components/Modals/CreatePollModal';
import Polls from '@components/Polls';
import {PollInitialState} from '@models/Poll/PollsInitialState';
import {useRoute} from '@react-navigation/native';
import {
  userAccountNameSelector,
  userAccountThingIdSelector,
} from '@redux/selectors/userAccountSelectors';
import {createPostLabelSelector} from '@redux/selectors/metaSelector';
import NavigationService from '@services/navigationService';
import Analytics, {IEventData} from '@wyse/analytics';
import {
  EventTypeEnum,
  CreatePostAnalEvent,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import {openCamera, openPicker} from '@libs/ImagePicker';
import Screen from '@components/Screen';
import BigScreenTabBar from '@components/BigScreenTabBar';
import {useDropzone} from '@utils/dropzone';
import {withTokenRefresh} from '@utils/webhooks';
import {isWeb} from '@utils/useColors';
import {FontSize, SCREEN_SIZE} from '@utils/sizes';
import {TabScreens} from '@navigations/ScreenNames';
import {FontFamily} from '@utils/sizes';
import BigScreenRightBar from '@components/BigScreenRightBar';
import Header, {HeaderRemoveButton} from '@components/Header/Header';
import {CustomText} from '@components/Texts';
import Colors from '@constants/Colors';
import {Platform} from 'react-native';

const Post = ({}: {navigation: any}) => {
  const {width} = useWindowDimensions();
  const params = useRoute().params as {isStack?: boolean; communityId?: string};
  const userThingId = useSelector(userAccountThingIdSelector);
  const userName = useSelector(userAccountNameSelector);
  const postLabels = useSelector(createPostLabelSelector);
  const discussionLabel = postLabels.filter(
    label => label.title == 'Discussion',
  );

  const [selectedLabel, setSelectedLabel]: any = useState<string[]>([
    discussionLabel[0]?.thing_id,
  ]);
  const [postTitle, setPostTitle] = useState('');
  const [postBody, setPostBody] = useState('');
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File>();
  const [imageUri, setImageUri] = useState<string | null>(null);
  const [anonPost, setAnonPost] = useState<boolean>(false);
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);
  const [displaySelectedImage, setDisplaySelectedImage] =
    useState<boolean>(false);
  const [selectedImageHeight, setSelectedImageHeight] = useState<number>(0);
  const [selectedImageWidth, setSelectedImageWidth] = useState<number>(0);
  const [openPollModal, setOpenPollModal] = useState<boolean>(false);
  const initialPoll: PollInitialState = {
    question: null,
    options: [null, null],
    answer: null,
  };

  const {open: opEn} = useDropzone({
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0];

      if (!file) return;

      var reader = new FileReader();

      //Read the contents of Image File.
      reader.readAsDataURL(file);
      reader.onload = function () {
        //Initiate the JavaScript Image object.
        var image = new Image();

        if (!reader.result) return;
        //Set the Base64 string return from FileReader as source.
        image.src = reader.result as string;

        //Validate the File Height and Width.
        image.onload = function () {
          console.log(
            'createObjectURL ',
            image.src,
            image.width + ' ' + image.height,
          );
          setImageUri(reader.result as string);
          if (image.width) {
            setSelectedImageWidth(image.width);
          }
          if (image.height) {
            setSelectedImageHeight(image.height);
          }
          setFile(file);
          setOpen(false);
        };
      };

      // const reader = new FileReader();
      // reader.onload = e => {
      //   // Do whatever you want with the file contents
      //   if (!e.target?.result) return;
      //   const img = new Image();

      //   img.src = objectUrl;
      //   setImageUri(img.uri);
      //   if (img.width) {
      //     setSelectedImageWidth(width);
      //   }
      //   if (img.height) {
      //     setSelectedImageHeight(img.height);
      //   }
      // };
      // reader.readAsDataURL(file);
    },
  });

  const actualWidth = width > SCREEN_SIZE ? SCREEN_SIZE : width;

  const [polls, setPolls] = useState<PollInitialState>(initialPoll);

  useEffect(() => {
    Keyboard.addListener('keyboardDidShow', event => {
      setKeyboardHeight(event.endCoordinates.height);
    });
    Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardHeight(0);
    });
  }, []);

  useEffect(() => {
    setDisplaySelectedImage(true);
  }, [selectedImageHeight]);

  const onGalleryPress = async () => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: CreatePostAnalEvent.CREATE_POST_GALLERY_CLICK,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
    try {
      const result = await openPicker({
        mediaType: 'photo',
      });
      setDisplaySelectedImage(false);
      //TODO nul check
      const uri = result.sourceURL;
      if (!uri) return;
      const uploadedImageHeight: number = result.height;
      const uploadedImageWidth: number = result.width;
      setImageUri(uri);
      const uploadedImageConatinerSize = imageContainerSize(
        uploadedImageHeight,
        uploadedImageWidth,
        width,
      );
      setSelectedImageHeight(uploadedImageConatinerSize.containerHeight);
      setSelectedImageWidth(uploadedImageConatinerSize.containerWidth);
      setOpen(false);
    } catch (error) {}
  };

  const onCameraPress = async () => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: CreatePostAnalEvent.CREATE_POST_CAMERA_CLICK,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
    try {
      const result = await openCamera({
        mediaType: 'photo',
      });
      const uri = result.sourceURL;
      if (!uri) return;
      const uploadedImageHeight: number = result.height;
      const uploadedImageWidth: number = result.width;
      setImageUri(uri);
      const uploadedImageConatinerSize = imageContainerSize(
        uploadedImageHeight,
        uploadedImageWidth,
        width,
      );
      setSelectedImageHeight(uploadedImageConatinerSize.containerHeight);
      setSelectedImageWidth(uploadedImageConatinerSize.containerWidth);
      setOpen(false);
    } catch (error) {}
  };

  const onLabelSelect = (labelThingId: string) => {
    if (!selectedLabel.includes(labelThingId)) {
      setSelectedLabel([labelThingId]);
    } else {
      const newLabels = selectedLabel.filter(
        (item: any) => item != labelThingId,
      );
      setSelectedLabel(newLabels);
    }
  };

  const showLabels = (array: MetaDataFlair[]) =>
    array.map((item: MetaDataFlair, index: number) => (
      <View style={{height: 22}} key={index}>
        <Btn
          variant="solid"
          size="sm"
          style={{
            backgroundColor: selectedLabel.includes(item.thing_id)
              ? item.color
              : '#F2F2F2',
            borderRadius: 4,
          }}
          onPress={() => onLabelSelect(item.thing_id)}>
          <CustomText
            size={FontSize.small}
            fontFamily={FontFamily.FigtreeMedium}>
            {item.title.toUpperCase()}
          </CustomText>
        </Btn>
      </View>
    ));

  const navigateToSpacePage = () => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: CreatePostAnalEvent.CREATE_POST_NEXT_CLICK,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
    NavigationService.getInstance().openSpaces({
      flairs: selectedLabel,
      title: postTitle,
      body: postBody,
      defaultSpace: params?.communityId,
      authorName: userName,
      authorId: userThingId,
      anonymousPost: anonPost,
      imageUri: imageUri,
      resetCreatePostData: resetData,
      polls: polls,
      file,
    });
  };

  const addOption = () => {
    if (polls.options.length < 5) {
      setPolls(prevState => ({
        ...prevState,
        options: [...prevState.options, null],
      }));
    }
  };

  const onChange = (text: any, index: any) => {
    if (text === '') {
      text = null;
    }
    setPolls(prevState => ({
      ...prevState,
      options: [
        ...prevState.options.slice(0, index),
        text,
        ...prevState.options.slice(index + 1),
      ],
    }));
  };
  const onToggleChange = (val: any) => {
    if (val) {
      const analyticsEventPayload: IEventData = {
        ev_typ: EventTypeEnum.ANALYTICS,
        ev_val: CreatePostAnalEvent.CREATE_POST_ANONYMOUS_TOGGLE,
        thing_type: ThingTypeEnum.NA,
      };
      Analytics.trackEvent(analyticsEventPayload);
    }
    setAnonPost(val);
  };

  const handleAttachment = () => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: CreatePostAnalEvent.CREATE_POST_ATTACH_CLICK,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
    setOpen(true);
    opEn();
  };

  const removeAttachment = () => {
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: CreatePostAnalEvent.CREATE_POST_ATTACH_REMOVED,
      thing_type: ThingTypeEnum.NA,
    };
    Analytics.trackEvent(analyticsEventPayload);
    setImageUri(null);
  };
  const resetData = () => {
    setPostBody('');
    setImageUri('');
    setPostTitle('');
    setAnonPost(false);
    setSelectedLabel([]);
  };

  return (
    <Screen
      RightComponent={BigScreenRightBar}
      LeftComponent={BigScreenTabBar}
      style={{flex: 1}}>
      <Header
        headerLeft={() => (
          <HeaderRemoveButton
            onPress={() => {
              if (!!params?.isStack) {
                if (NavigationService.getInstance().cangoBack()) {
                  NavigationService.getInstance().goBack();
                  return;
                }
                NavigationService.getInstance().openHome(TabScreens.Home);
              }
              NavigationService.getInstance().openTab(TabScreens.Home);
              if (!isWeb) {
                Keyboard.dismiss();
              }
            }}
          />
        )}
        title="Create Post"
        headerRight={() => (
          <View style={{height: '100%', justifyContent: 'center'}}>
            <Btn
              size="sm"
              style={{
                height: '60%',
                backgroundColor:
                  selectedLabel.length > 0 &&
                  postTitle !== '' &&
                  postBody !== ''
                    ? '#9B51E0'
                    : '#E0E0E0',
                borderRadius: 8,
              }}
              disabled={
                selectedLabel.length > 0 && postTitle !== '' && postBody !== ''
                  ? false
                  : true
              }
              onPress={() => navigateToSpacePage()}>
              <CustomText
                color={
                  (selectedLabel.length > 0 &&
                  postTitle !== '' &&
                  postBody !== ''
                    ? Colors.white
                    : '#BDBDBD') as any
                }>
                Next
              </CustomText>
            </Btn>
          </View>
        )}
      />

      <ScrollView
        showsVerticalScrollIndicator={true}
        keyboardShouldPersistTaps="handled">
        <View
          style={{
            marginBottom: keyboardHeight / 2,
          }}>
          <View
            style={{
              backgroundColor: '#FFFFFF',
              width: '100%',
              alignSelf: 'center',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingHorizontal: 16,
              paddingVertical: 8,
            }}>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <View style={{marginRight: 8}}>
                <Switch
                  trackColor={{false: '#E0E0E0', true: '#9B51E0'}}
                  thumbColor="#FFFFFF"
                  onValueChange={(val: any) => onToggleChange(val)}
                  value={anonPost}
                />
              </View>
              <View>
                <CustomText size={FontSize.title}>
                  {anonPost ? `Post Anonymously` : `Post as ${userName}`}
                </CustomText>
              </View>
            </View>

            <Pressable
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
              onPress={handleAttachment}>
              <CustomText color="primary" size={FontSize.title}>
                Attach
              </CustomText>
              <Icon name="attach" size={22} color={Colors.primary} />
            </Pressable>
          </View>
          <View
            style={{
              width: '100%',
              alignSelf: 'center',
              backgroundColor: '#FFFFFF',
              paddingHorizontal: 16,
              paddingVertical: 12,
              marginBottom: 8,
            }}>
            <View style={{marginBottom: 10}}>
              <CustomText>Choose type of post</CustomText>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}>
              {showLabels(postLabels)}
            </View>
          </View>
          <View
            style={{
              paddingHorizontal: 8,
              // height: '60%',
              width: '100%',
              alignSelf: 'center',
            }}>
            <View
              style={{
                paddingHorizontal: 0,
                paddingTop: 2,
                paddingBottom: 2,
                marginBottom: 8,
                backgroundColor: '#FFFFFF',
                borderRadius: 8,
                borderWidth: 0.5,
                borderColor: '#F2F2F2',
              }}>
              <TextInput
                placeholder="An Interesting title"
                placeholderTextColor="#BDBDBD"
                selectionColor="#e8d6f8"
                style={{
                  paddingHorizontal: 16,
                  paddingVertical: 10,
                  fontWeight: '700',
                  fontFamily:
                    Platform.OS == 'web' ? 'Figtree' : FontFamily.FigtreeBold,
                  fontSize: 16,
                  lineHeight: 24,
                  color: '#4F4F4F',
                  maxHeight: 86,
                }}
                value={postTitle}
                multiline={true}
                onChangeText={text => setPostTitle(text)}
                maxLength={500}
              />
            </View>
            {/* <ScrollView
              contentContainerStyle={{
                backgroundColor: '#FFFFFF',
                // alignItems: 'flex-start',
                borderWidth: 1,
                borderColor: postBody === '' ? '#F2F2F2' : '#9B51E0',
                borderRadius: 8,
              }}> */}
            <View>
              <TextInput
                placeholder="Add Body Text"
                placeholderTextColor="#BDBDBD"
                selectionColor="#e8d6f8"
                cursorColor={'#bdbdbd'}
                style={{
                  paddingVertical: 16,
                  paddingHorizontal: 16,
                  fontWeight: '400',
                  fontSize: 14,
                  lineHeight: 20,
                  color: '#4F4F4F',
                  fontFamily:
                    Platform.OS == 'web' ? 'Figtree' : FontFamily.FigtreeBold,
                  backgroundColor: '#FFFFFF',
                  borderRadius: 8,
                  height: 200,
                  textAlignVertical: 'top',
                }}
                autoComplete="off"
                autoFocus={false}
                multiline={true}
                // value={postBody}
                onChangeText={text => setPostBody(text)}
              />
              {imageUri !== null && (
                <View
                  style={{
                    marginTop: 5,
                    width: '100%',
                  }}>
                  <View
                    style={{
                      width: '100%',
                      alignItems: 'flex-end',
                    }}>
                    {imageUri !== null && (
                      <Icon
                        name="close-outline"
                        size={23}
                        onPress={removeAttachment}
                        color="#d7b9f3"
                      />
                    )}
                  </View>
                  {imageUri && (
                    <RNImage
                      source={{uri: imageUri}}
                      style={{
                        height:
                          (actualWidth * selectedImageHeight) /
                          selectedImageWidth,
                        width:
                          selectedImageWidth > actualWidth
                            ? actualWidth
                            : selectedImageHeight,
                        display: displaySelectedImage ? 'flex' : 'none',
                      }}
                      // resizeMethod="resize"
                      resizeMode="contain"
                    />
                  )}
                </View>
              )}
              {!polls.options.some(ele => ele === null) && (
                <Polls
                  polls={polls}
                  setPolls={setPolls}
                  initialPoll={initialPoll}
                />
              )}
            </View>
            {/* </ScrollView> */}
          </View>
          {!isWeb ? (
            <TouchableWithoutFeedback onPress={() => setOpen(false)}>
              <Modal
                isOpen={open}
                onClose={() => setOpen(false)}
                safeAreaTop={true}
                overlayVisible={false}>
                <KeyboardAvoidingView behavior="padding" style={{flex: 1}}>
                  <Modal.Content
                    maxWidth={width}
                    {...{marginBottom: -1, marginTop: 'auto'}}>
                    <Modal.Body
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        backgroundColor: '#181818',
                        width: width,
                      }}>
                      <TouchableWithoutFeedback onPress={onGalleryPress}>
                        <View
                          style={{
                            width: 68,
                            marginRight: 20,
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                          }}>
                          <Icon
                            name="image-outline"
                            size={23}
                            color="#FFFFFF"
                            style={{marginRight: 8}}
                          />
                          <Text
                            style={{
                              fontSize: 11,
                              fontWeight: '400',
                              lineHeight: 24,
                              color: '#FFFFFF',
                            }}>
                            Gallery
                          </Text>
                        </View>
                      </TouchableWithoutFeedback>
                      <TouchableWithoutFeedback onPress={onCameraPress}>
                        <View
                          style={{
                            width: 68,
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            marginRight: 20,
                          }}>
                          <Icon
                            name="camera-outline"
                            size={23}
                            color="#FFFFFF"
                            style={{marginRight: 8}}
                          />
                          <Text
                            style={{
                              fontSize: 11,
                              fontWeight: '400',
                              lineHeight: 24,
                              color: '#FFFFFF',
                            }}>
                            Camera
                          </Text>
                        </View>
                      </TouchableWithoutFeedback>
                      {/* <TouchableWithoutFeedback
                      onPress={() => {
                        setOpen(false);
                        setOpenPollModal(true);
                      }}>
                      <View
                        style={{
                          width: 68,
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                        }}>
                        <FontAwesomeIcon
                          icon={faSquarePollVertical}
                          color="#FFFFFF"
                          size={23}
                          style={{marginRight: 8}}
                        />
                        <Text
                          style={{
                            fontSize: 11,
                            fontWeight: '400',
                            lineHeight: 24,
                            color: '#FFFFFF',
                          }}>
                          Poll
                        </Text>
                      </View>
                    </TouchableWithoutFeedback> */}
                    </Modal.Body>
                  </Modal.Content>
                </KeyboardAvoidingView>
              </Modal>
            </TouchableWithoutFeedback>
          ) : null}

          <CreatePollModal
            isOpen={openPollModal}
            setIsOpen={setOpenPollModal}
            polls={polls}
            setPolls={setPolls}
            initialPoll={initialPoll}
            onChange={onChange}
            addOption={addOption}
          />
        </View>
      </ScrollView>
    </Screen>
  );
};

export default withTokenRefresh(Post);
