import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import type {FC} from 'react';
import {
  StyleSheet,
  View,
  TextInput,
  Platform,
  KeyboardAvoidingView,
  Pressable,
  ActivityIndicator,
} from 'react-native';
import {shallowEqual, useSelector} from 'react-redux';
import type {StyleProp, ViewStyle, LayoutChangeEvent} from 'react-native';
import {useRoute} from '@react-navigation/native';
import Icon from 'react-native-vector-icons/Ionicons';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Animated, {interpolate, useAnimatedStyle} from 'react-native-reanimated';
import {getMessage, useInputDebounce} from '@utils/utilityFunctions';
import {FontFamily, FontSize, LineHeight} from '@utils/sizes';
import {CustomIcon} from '@components/icon';
// import MessageOptionSheet from '@components/Modals/MessageOptionSheet';
import Colors from '@constants/Colors';
import DatabaseService from '@services/DatabaseService';
import FirestoreService from '@services/firestoreService';
import type {OpenPickerResponse} from '@services/DeviceService';
import {userAccountNameIdAvatarSelector} from '@redux/selectors/userAccountSelectors';
import LayoutContext from './LayoutContext';
import {ChatScreenParams} from './types';
import ToasterService from '@services/toasterService';
import {textInputStyles} from '@components/Inputs';
import {isWeb} from '@utils/useColors';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    borderTopColor: Colors.light,
    borderTopWidth: 1,
    backgroundColor: Colors.white,
    bottom: 0,
    left: 0,
    right: 0,
  },
  primary: {
    flex: 1,
    paddingVertical: 8,
  },
  primaryInner: {
    flexDirection: 'row',
    flex: 1,
  },
  textInputContainre: {
    flex: 1,
    justifyContent: 'center',
    borderWidth: 1,
    padding: 12,
    minHeight: LineHeight.regular,
    textAlignVertical: 'center',
    maxHeight: 100,
    borderRadius: 14,
    borderColor: Colors.chatBorder,
    backgroundColor: Colors.light,
  },
  textInput: {
    alignSelf: 'stretch',
    paddingTop: 0,
    color: Colors.text,
    fontFamily: isWeb ? 'Figtree' : FontFamily.FigtreeRegular,
    fontSize: FontSize.regular,
  },
  pinButton: {
    width: 46,
    height: 46,
    justifyContent: 'center',
    alignItems: 'center',
  },
  sendContainer: {
    width: 46,
    height: 46,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pressedSecodContainer: {
    opacity: 0.5,
    width: 46,
    height: 46,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

type ChatInputProps = {
  style?: StyleProp<ViewStyle>;
  senderDocId?: string;
  reciverDocId?: string;
};

const {First, Second} = Platform.select({
  ios: {
    First: KeyboardAvoidingView,
    Second: View,
  },
  default: {
    First: View,
    Second: KeyboardAvoidingView,
  },
});

const ChatInputWrapper: FC<{children: any; style?: StyleProp<ViewStyle>}> =
  memo(({children, style}) => {
    const toolBarHeight = LayoutContext.useToolBarHeight();
    const keyboardValue = LayoutContext.useKeyboardValue();
    const {bottom} = useSafeAreaInsets();

    const animatedStyle = useAnimatedStyle(() => {
      return {
        paddingBottom: interpolate(keyboardValue.value, [0, 1], [bottom, 0]),
      };
    }, [bottom, keyboardValue]);

    const onLayout = useCallback(
      (event: LayoutChangeEvent) => {
        toolBarHeight.value = event.nativeEvent.layout.height;
      },
      [toolBarHeight],
    );

    return (
      <First
        style={[styles.container, style as any]}
        onLayout={Platform.OS === 'android' ? onLayout : undefined}
        keyboardVerticalOffset={Platform.OS === 'android' ? 20 : 0}
        behavior={'padding'}>
        <Second
          onLayout={Platform.OS === 'ios' ? onLayout : undefined}
          keyboardVerticalOffset={Platform.OS === 'android' ? 20 : 0}
          behavior={'height'}
          style={[styles.primary]}>
          {children}
          <Animated.View style={animatedStyle} />
        </Second>
      </First>
    );
  });

const ChatInput: FC<ChatInputProps> = props => {
  const realm = DatabaseService.useRealm();
  const [
    data,
    // setData
  ] = useState<OpenPickerResponse>();
  const {style, senderDocId, reciverDocId} = props;
  const params = useRoute().params as ChatScreenParams;
  const userAccount = useSelector(
    userAccountNameIdAvatarSelector,
    shallowEqual as any,
  );
  const inputRef = useRef<TextInput>(null);
  const [text, setText] = useState('');
  const isExists = useRef(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!params?.conversation?.conversation_id) return;

    (async () => {
      const exists = await FirestoreService.doesConversationExists(
        params.conversation?.conversation_id,
      );
      setLoading(false);
      isExists.current = exists;
    })();
  }, [params?.conversation?.conversation_id]);

  const onPressSend = useCallback(async () => {
    try {
      let conversationId = params?.conversation?.conversation_id;
      // if (!isExists.current) {
      //   conversationId = await FirestoreService.initializeChatRoom({
      //     firstUserDocId: senderDocId!,
      //     firstUserData: userAccount,
      //     secondUserDocId: senderDocId!,
      //     secondUserData: {
      //       avatar: params?.conversation?.user_avatar!,
      //       user_fullname: params?.conversation?.user_fullname!,
      //       thing_id: params?.conversation?.user_thing_id!,
      //     },
      //     feedThingId: params?.feedThingId,
      //   });
      // }
      if (!conversationId) return;

      const message = getMessage(userAccount!.thing_id, text, data);

      const localMessage = await DatabaseService.addMessage(
        realm,
        conversationId,
        message,
      );
      setText('');
      inputRef.current?.clear();
      await FirestoreService.createNewMessage(
        conversationId,
        message._id,
        message,
      );
      DatabaseService.updateMessage(realm, () => {
        localMessage.sent = true;
      });
      FirestoreService.updateUserConvRefLastMessage(
        senderDocId!,
        conversationId,
        message,
      );
      FirestoreService.updateUserConvRefLastMessage(
        reciverDocId!,
        conversationId,
        message,
      );
      // const hasExcepted = await FirestoreService.isMsgRequestAccepted(
      //   reciverDocId!,
      //   conversationId,
      // );
      // if (!hasExcepted) {
      //   FirestoreService.updateUserConvReqLastMessage(
      //     reciverDocId!,
      //     conversationId,
      //     message,
      //   );
      // }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    text,
    isExists,
    senderDocId,
    params?.conversation?.user_avatar,
    params?.conversation?.user_fullname,
    params?.conversation?.user_thing_id,
    reciverDocId,
    params?.feedThingId,
    params?.conversation?.conversation_id,
    data,
    realm,
    userAccount?.thing_id,
  ]);

  return (
    <ChatInputWrapper style={style}>
      <View style={styles.primaryInner}>
        <Pressable
          onPress={async () => {
            ToasterService.showToast('Feature comming soon!');
            return;
            // inputRef.current?.blur();
            // const response = await MessageOptionSheet.openSheet();
            // console.log('response', response);
            // setData(response);
          }}
          style={styles.pinButton}>
          <CustomIcon size={24} name="pin-big" color={Colors.black} />
        </Pressable>
        <Pressable
          onPress={() => inputRef.current?.focus()}
          style={styles.textInputContainre}>
          <TextInput
            editable={!!params?.accepted || loading}
            ref={inputRef}
            accessible
            placeholder="Enter here"
            placeholderTextColor={Colors.veryLightText}
            multiline
            onChangeText={useInputDebounce(str => setText(str), 300)}
            style={[styles.textInput, textInputStyles.webInput]}
            // value={text}
          />
        </Pressable>
        <Pressable
          onPress={onPressSend}
          disabled={!params?.accepted || text.trim() === '' || loading}
          style={({pressed}) =>
            pressed ? styles.pressedSecodContainer : styles.sendContainer
          }>
          {loading ? (
            <ActivityIndicator color={Colors.text} />
          ) : (
            <Icon color={Colors.text} name="send-outline" size={20} />
          )}
        </Pressable>
      </View>
    </ChatInputWrapper>
  );
};

export default ChatInput;
