/*eslint-disable */
import React, {useEffect, useMemo, useState} from 'react';
import type {FC} from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {Pressable} from 'native-base';
import Hyperlink from 'react-native-hyperlink';
import type {FeedPostDetailData} from '@models/Feed/FeedPostDetailData';
import type {MetaDataFlair} from '@models/MetaData/metaDataFlair';
import {FeedCardBodyTypeEnum} from '@constants/constantsEnum';
import NavigationService from '@services/navigationService';
import FeedImage from './FeedImage';
import {FontFamily, FontSize} from '@utils/sizes';
import ConstantsEnum from '../../../constants/constantsEnum';
import DisplayPolls from '../../DisplayPolls';
import {useSelector} from 'react-redux';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {AuthScreens} from '@navigations/ScreenNames';
import LocalStorage from '@services/storage';
import {useRoute} from '@react-navigation/native';
import {CustomText} from '@components/Texts';

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  titleContainer: {
    flex: 1,
    marginBottom: 4,
  },
  stretch: {
    alignSelf: 'stretch',
    paddingRight: 18,
  },

  linkStyle: {color: '#2980b9'},
  imageContainer: {
    alignSelf: 'stretch',
    marginVertical: 12,
    alignItems: 'center',
  },
  // width and height for the image will be done by setNativeProps method
  image: {
    flex: 1,
    borderRadius: 8,
  },
});

type TruncatedTextProps = {
  text: string | null;
  isFeedDetailCard: boolean | null;
  redirectToFeedDetailScreen: () => void | null;
};

const TruncatedText: FC<TruncatedTextProps> = ({
  text,
  isFeedDetailCard,
  redirectToFeedDetailScreen,
}) => {
  const {isLongText, truncatedText} = useMemo(() => {
    const isLong = (text?.length || 0) > 350;
    return {
      isLongText: isLong,
      truncatedText: text?.slice(0, 350),
    };
  }, [text]);

  return (
    <View style={styles.stretch}>
      {isFeedDetailCard ? (
        <Hyperlink linkDefault linkStyle={styles.linkStyle}>
          <CustomText color="lightText" fontFamily={FontFamily.FigtreeRegular}>
            {text}
          </CustomText>
        </Hyperlink>
      ) : !isLongText ? (
        <Hyperlink linkDefault linkStyle={styles.linkStyle}>
          <CustomText color="lightText" fontFamily={FontFamily.FigtreeRegular}>
            {text}
          </CustomText>
        </Hyperlink>
      ) : (
        <Hyperlink linkDefault linkStyle={styles.linkStyle}>
          <CustomText color="lightText" fontFamily={FontFamily.FigtreeRegular}>
            {truncatedText}
            <CustomText
              fontFamily={FontFamily.FigtreeSemiBold}
              color="text"
              onPress={redirectToFeedDetailScreen}>
              {' ...See More'}
            </CustomText>
          </CustomText>
        </Hyperlink>
      )}
    </View>
  );
};

type FeedCardProps = {
  bodyTitle: string | null;
  bodyData: FeedPostDetailData[];
  seeMore?: boolean | null;
  redirectToFeedDetailScreen: () => void;
  isFeedDetailCard: boolean | null;
  postId: string;
  label?: MetaDataFlair;
};

const FeedCardBody: FC<FeedCardProps> = ({
  bodyTitle,
  bodyData,
  seeMore,
  redirectToFeedDetailScreen,
  isFeedDetailCard,
  postId,
  label,
}) => {
  const route = useRoute();
  const logedInUser = useSelector(userAccountThingIdSelector);
  const showTextBody =
    !!bodyData?.[0]?.key &&
    (bodyData[0].key == FeedCardBodyTypeEnum.TEXT ||
      bodyData[0].key == FeedCardBodyTypeEnum.TXT);

  const showPollBody =
    !!bodyData?.[0]?.key &&
    bodyData[0].key == ConstantsEnum.FeedCardBodyTypeEnum.POLL;

  const shoeSecondBody =
    !!bodyData?.[1]?.key && bodyData[1].key == FeedCardBodyTypeEnum.MEDIA_IMG;

  return (
    <Pressable
      style={styles.container}
      disabled={!seeMore}
      onPress={redirectToFeedDetailScreen}>
      <View style={styles.titleContainer}>
        {!!bodyTitle && (
          <CustomText
            color="text"
            size={FontSize.heading}
            fontFamily={FontFamily.FigtreeBold}>
            {bodyTitle}
          </CustomText>
        )}

        {/* {label && <Label postLabel={label} />} */}
      </View>
      {showTextBody && (
        <TruncatedText
          text={bodyData[0]!.value}
          {...{isFeedDetailCard, redirectToFeedDetailScreen}}
        />
      )}

      {showPollBody && <DisplayPolls postId={postId} />}

      {shoeSecondBody && (
        <View style={styles.imageContainer}>
          <FeedImage
            onPress={async () => {
              if (!logedInUser) {
                await LocalStorage.setData('redirect', route);
                NavigationService.getInstance().replaceToAuth({
                  page: AuthScreens.OtpScreen,
                });
                return;
              }
              NavigationService.getInstance().openImageView(
                bodyTitle!,
                bodyData[1].value,
              );
            }}
            uri={bodyData[1].value}
            style={styles.image}
          />
        </View>
      )}
    </Pressable>
  );
};

export default FeedCardBody;
