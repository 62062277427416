import React, {createContext, memo, useContext, useEffect} from 'react';
import type {FC} from 'react';
import {Platform, View} from 'react-native';
import type {StyleProp, ViewStyle} from 'react-native';
import Colors from '@constants/Colors';
import Animated, {
  useSharedValue,
  withRepeat,
  withTiming,
  cancelAnimation,
} from 'react-native-reanimated';
import LinearGradient from '@libs/LinearGradient';

export const LOADING_DATA: ArrayLike<number> = [1, 2, 3, 4, 5, 6];
export const LOADING_DATA_LARGE: ArrayLike<number> = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
];

export const LOADING_SECTION_DATA_LARGE: {
  title: string;
  data: any[];
}[] = [{title: '', data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}];
const backgroundColor = '#00000050';

const Circle = ({
  size,
  style,
}: {
  size: number;
  style?: StyleProp<ViewStyle>;
}) => {
  return (
    <Animated.View
      style={[
        {
          width: size,
          height: size,
          borderRadius: size / 2,
          backgroundColor,
        },
        style,
      ]}
    />
  );
};

export const FeedListLoaderContext = createContext<
  Animated.SharedValue<number>
>(0 as any);

export const ListLoaderProvider: FC<{children: any; loading: boolean}> = ({
  children,
  loading,
}) => {
  const value = useSharedValue(1);
  useEffect(() => {
    if (loading) {
      value.value = withRepeat(
        withTiming(0.3, {duration: 1000}),
        Infinity,
        true,
      );
      return () => {
        cancelAnimation(value);
      };
    }
  }, [loading, value]);
  return (
    <FeedListLoaderContext.Provider value={value}>
      {children}
    </FeedListLoaderContext.Provider>
  );
};

export const useFeedListLoaderValue = () => useContext(FeedListLoaderContext);

export const ConversactionLoaderItem = () => {
  return (
    <View
      style={{
        alignSelf: 'stretch',
        height: 80,
        padding: 16,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
      }}>
      <Circle size={48} />
      <View style={{flex: 1, paddingLeft: 8}}>
        <Animated.View
          style={[
            {
              borderRadius: 2,
              marginLeft: 5,
              width: '60%',
              height: 16,
              backgroundColor,
            },
          ]}
        />
        <Animated.View
          style={[
            {
              marginTop: 6,
              borderRadius: 2,
              marginLeft: 5,
              width: '40%',
              height: 12,
              backgroundColor: '#00000030',
            },
          ]}
        />
      </View>
    </View>
  );
};
export const FeedListLoaderItem: FC<{item: number}> = ({item}) => {
  const opacity = useFeedListLoaderValue();

  return (
    <View
      style={{
        alignSelf: 'stretch',
        marginBottom: 8,
        backgroundColor: '#FFFFFF',
      }}
      key={'loader-' + item}>
      <Animated.View style={[{alignSelf: 'stretch', opacity}]}>
        <LinearGradient
          colors={['#00000070', 'rgba(255,255,255,0)']}
          start={{x: Platform.OS == 'ios' ? -2.5 : -15, y: 0}}
          end={{x: 1, y: 0}}
          style={{
            height: 35,
            alignSelf: 'stretch',
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 5,
            paddingVertical: 8,
          }}>
          <Circle size={20} />
          <Animated.View
            style={[
              {
                borderRadius: 2,
                marginLeft: 5,
                width: '40%',
                height: 14,
                backgroundColor,
              },
            ]}
          />
        </LinearGradient>
        <View
          style={{
            alignSelf: 'stretch',
            paddingTop: 5,
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 10,
            marginBottom: 18,
          }}>
          <Circle size={40} style={{marginRight: 8}} />
          <View style={{flex: 1, justifyContent: 'center'}}>
            <View
              style={{
                width: '30%',
                height: 14,
                borderRadius: 2,
                backgroundColor,
                marginBottom: 8,
              }}
            />
            <View
              style={{
                width: '40%',
                height: 12,
                borderRadius: 2,
                backgroundColor: '#00000030',
              }}
            />
          </View>
        </View>
        <View
          style={{
            alignSelf: 'stretch',

            paddingHorizontal: 10,
          }}>
          <View
            style={[
              {
                width: '40%',
                height: 16,
                backgroundColor,
                marginBottom: 8,
                borderRadius: 2,
              },
            ]}
          />
          <View
            style={{
              width: '90%',
              height: 13,
              borderRadius: 2,
              backgroundColor: '#00000030',
              marginBottom: 2,
            }}
          />
          <View
            style={{
              width: '30%',
              height: 13,
              borderRadius: 2,
              marginBottom: 16,
              backgroundColor: '#00000030',
            }}
          />
        </View>
      </Animated.View>
    </View>
  );
};

const FeedListLoader: FC<{style?: StyleProp<ViewStyle>}> = ({style}) => {
  const opacity = useSharedValue(1);

  useEffect(() => {
    opacity.value = withRepeat(
      withTiming(0.3, {duration: 1000}),
      Infinity,
      true,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View
      style={[
        {flex: 1, paddingTop: 2, backgroundColor: Colors.lightBackground},
        style,
      ]}>
      {(LOADING_DATA as any).map(item => (
        <View
          style={{
            alignSelf: 'stretch',
            marginBottom: 8,
            backgroundColor: '#FFFFFF',
          }}
          key={'loader-' + item}>
          <Animated.View style={[{alignSelf: 'stretch', opacity}]}>
            <LinearGradient
              colors={['#00000070', 'rgba(255,255,255,0)']}
              start={{x: Platform.OS == 'ios' ? -2.5 : -15, y: 0}}
              end={{x: 1, y: 0}}
              style={{
                height: 35,
                alignSelf: 'stretch',
                flexDirection: 'row',
                alignItems: 'center',
                paddingHorizontal: 5,
                paddingVertical: 8,
              }}>
              <Circle size={20} />
              <Animated.View
                style={[
                  {
                    borderRadius: 2,
                    marginLeft: 5,
                    width: '40%',
                    height: 14,
                    backgroundColor,
                  },
                ]}
              />
            </LinearGradient>
            <View
              style={{
                alignSelf: 'stretch',
                paddingTop: 5,
                flexDirection: 'row',
                alignItems: 'center',
                paddingHorizontal: 10,
                marginBottom: 18,
              }}>
              <Circle size={40} style={{marginRight: 8}} />
              <View style={{flex: 1, justifyContent: 'center'}}>
                <View
                  style={{
                    width: '30%',
                    height: 14,
                    borderRadius: 2,
                    backgroundColor,
                    marginBottom: 8,
                  }}
                />
                <View
                  style={{
                    width: '40%',
                    height: 12,
                    borderRadius: 2,
                    backgroundColor: '#00000030',
                  }}
                />
              </View>
            </View>
            <View
              style={{
                alignSelf: 'stretch',

                paddingHorizontal: 10,
              }}>
              <View
                style={[
                  {
                    width: '40%',
                    height: 16,
                    backgroundColor,
                    marginBottom: 8,
                    borderRadius: 2,
                  },
                ]}
              />
              <View
                style={{
                  width: '90%',
                  height: 13,
                  borderRadius: 2,
                  backgroundColor: '#00000030',
                  marginBottom: 2,
                }}
              />
              <View
                style={{
                  width: '30%',
                  height: 13,
                  borderRadius: 2,
                  marginBottom: 16,
                  backgroundColor: '#00000030',
                }}
              />
            </View>
          </Animated.View>
        </View>
      ))}
    </View>
  );
};

export default memo(FeedListLoader);

export const ProfileDetailLoader: FC = () => {
  const opacity = 0.5;

  return (
    <View
      style={{
        alignSelf: 'stretch',
        marginBottom: 8,
        backgroundColor: '#FFFFFF',
      }}
      key={'loader-profile'}>
      <Animated.View style={[{alignSelf: 'stretch', opacity}]}>
        <LinearGradient
          colors={['#00000070', 'rgba(255,255,255,0)']}
          start={{x: Platform.OS == 'ios' ? -2.5 : -15, y: 0}}
          end={{x: 1, y: 0}}
          style={{
            height: 35,
            alignSelf: 'stretch',
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 5,
            paddingVertical: 8,
          }}></LinearGradient>
        <View
          style={{
            alignSelf: 'stretch',
            paddingTop: 5,
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 10,
            marginBottom: 18,
          }}>
          <Circle size={40} style={{marginRight: 8}} />
          <View style={{flex: 1, justifyContent: 'center'}}>
            <View
              style={{
                width: '30%',
                height: 14,
                borderRadius: 2,
                backgroundColor,
                marginBottom: 8,
              }}
            />
            <View
              style={{
                width: '40%',
                height: 12,
                borderRadius: 2,
                backgroundColor: '#00000030',
              }}
            />
          </View>
        </View>
      </Animated.View>
    </View>
  );
};
