/*eslint-disable */
import React, {useMemo} from 'react';
import {View, Text, Dimensions, Pressable, StyleSheet} from 'react-native';
import {Progress} from 'native-base';
const {width} = Dimensions.get('window');
import {PollOptionData} from '@models/Feed/PollOptionData';
import {UpVotePollPayload} from '@models/Feed/UpVotePollPayload';
import {votePollOption} from '@redux/actions/feed';
import {dispatch, useSelector} from '@redux/store';
import {addPollVotes} from '@redux/slices/feedSlice';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import ToasterService from '@services/toasterService';
import Feather from 'react-native-vector-icons/Feather';
import {
  userPollVotesSelector,
  feedDataItemSelector,
  pollVotesSelector,
} from '@redux/selectors/feedSelector';
import Colors from '@constants/Colors';
import {CustomIcon, Icons} from './icon';
import {CustomText} from './Texts';
import {FontFamily, FontSize} from '@utils/sizes';

const styles = StyleSheet.create({
  optionContainer: {
    alignSelf: 'stretch',
    height: 44,
    borderWidth: 1,
    borderRadius: 8,
    overflow: 'hidden',
    marginBottom: 8,
  },
  dot: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: Colors.background,
  },
  optionInfoContainer: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 12,
  },
  optionText: {flex: 1, paddingHorizontal: 8},
});

const Option = ({
  text,
  optionId,
  postId,
  selected,
  votePercentage,
  isLast,
}: {
  text: string;
  postId: string;
  optionId: string;
  selected?: string;
  isLast: boolean;
  votePercentage: number;
}) => {
  const userThingId = useSelector(userAccountThingIdSelector);

  const voteOption = (optionId: string) => {
    const payload: UpVotePollPayload = {
      thing_id: postId,
      option_id: optionId,
      user_tid: userThingId!,
    };
    if (selected === undefined) {
      dispatch(addPollVotes(payload));
      dispatch(votePollOption(payload));
    } else {
      ToasterService.showToast('Cannot vote more than once', '#EED202', 2500);
    }
  };

  return (
    <Pressable
      disabled={selected == optionId}
      style={
        (({hovered}) => [
          styles.optionContainer,
          {
            borderColor:
              selected === optionId ? Colors.primary : Colors.chatBorder,
            backgroundColor:
              selected === optionId
                ? '#F7EFFF'
                : hovered
                ? Colors.hover
                : Colors.white,
          },
          isLast && {marginBottom: 0},
        ]) as any
      }
      onPress={() => voteOption(optionId)}>
      {/* <Progress
        value={isVoted ? votePercentage : 0}
        width={'100%'}
        height={44}
        bg="#FFFFFF"
        _filledTrack={{bg: selected !== optionId ? '#efe3fa' : '#d5b6f2'}}
        style={{borderColor: '#9B51E0', borderWidth: 2}}></Progress> */}
      <View style={styles.optionInfoContainer}>
        <View
          style={[
            styles.dot,
            selected == optionId && {backgroundColor: Colors.white},
          ]}>
          {selected == optionId ? (
            <CustomIcon
              name={Icons.CheckMark}
              color={Colors.primary}
              size={23}
            />
          ) : null}
        </View>
        <View style={styles.optionText}>
          <CustomText
            size={FontSize.title}
            color={selected == optionId ? 'primary' : 'lightText'}
            fontFamily={FontFamily.FigtreeMedium}>
            {text}
          </CustomText>
        </View>
        {!!selected ? (
          <CustomText
            size={FontSize.title}
            color={selected == optionId ? 'primary' : 'lightText'}
            fontFamily={FontFamily.FigtreeMedium}>
            {votePercentage}%
          </CustomText>
        ) : null}
      </View>
    </Pressable>
  );
};

const DisplayPolls = ({postId}: {postId: string}) => {
  const userPollVotes = useSelector(userPollVotesSelector);
  const pollFeedData = useSelector(
    feedDataItemSelector(postId),
    (prev, next) =>
      prev?.title === next?.title &&
      prev?.data[0]?.value === next?.data[0]?.value,
  );
  const pollVotes = useSelector(pollVotesSelector(postId));

  const isSelected = userPollVotes[postId];
  const votePercentages = useMemo(() => {
    if (!isSelected) return [0, 0, 0, 0];
    const values = Object.values(pollVotes);
    const votes = values.reduce((a, b) => a + b);

    if (votes == 0) return [0, 0, 0, 0];
    return values.map(vote => Math.round((vote / votes) * 100));
  }, [pollVotes, isSelected]);

  return (
    <View
      style={{
        alignSelf: 'stretch',
        paddingVertical: 12,
      }}>
      {!!pollFeedData &&
        !!pollFeedData.data[0] &&
        pollFeedData.data[0].dict!.options.map(
          (item: PollOptionData, index: number) => (
            <Option
              votePercentage={votePercentages[index]}
              selected={isSelected}
              text={item.body}
              postId={postId}
              isLast={pollFeedData.data[0].dict!.options.length - 1 == index}
              key={index}
              optionId={item.id}
            />
          ),
        )}
    </View>
  );
};

export default DisplayPolls;
