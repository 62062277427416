import remoteConfig from '@libs/Firebase/remote-config';

const fetchAndActivateRemoteConfig = async () => {
  try {
    console.log('fetching data');
    await remoteConfig().reset?.();
    const config = await remoteConfig().fetchAndActivate();
    if (config) {
      console.log('Configs were retrieved from the backend and activated.');
    } else {
      console.log(
        'No configs were fetched from the backend, and the local configs were already activated',
      );
    }
  } catch (error) {}
};

const fetchAppVersion = (appVersionType: string) => {
  return remoteConfig().getValue(appVersionType).asString();
};

const RemoteConfigService = {
  fetchAndActivateRemoteConfig,
  fetchAppVersion,
};

export default RemoteConfigService;
