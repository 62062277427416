import axiosInstance from './axios';
import AuthenticationService from './authenticationService';
import type {MetaData} from '@models/MetaData/metaData';
import {OrganisationData} from '@models/MetaData/organisationData';

const fetchMetaData = async () => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.get<MetaData>('/nexum/get/meta', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchOrganistation = async (name: string) => {
  return axiosInstance
    .get<OrganisationData[]>(
      'https://autocomplete.clearbit.com/v1/companies/suggest?query=:' + name,
    )
    .then(response => {
      return response.data.map(obj => obj.name);
    });
};

const MetaDataService = {
  fetchMetaData,
  fetchOrganistation,
};

export default MetaDataService;
