// import Vibrant from 'node-vibrant';
// import LocalStorage from '@services/storage';

// function loadImage(src: string): Promise<HTMLImageElement> {
//   return new Promise((resolve, reject) => {
//     const img = new Image();
//     img.setAttribute('crossOrigin', '');
//     img.addEventListener('load', () => {
//       resolve(img);
//     });
//     img.addEventListener('error', reject);
//     img.src = src;
//   });
// }

// function analyzeImage(img: HTMLImageElement) {
//   const {width, height} = img;
//   const canvas = document.createElement('canvas');
//   canvas.height = height;
//   canvas.width = width;
//   const context = canvas.getContext?.('2d');
//   if (context === null) {
//     return;
//   }
//   context.drawImage(img, 0, 0);
//   const imageData = context.getImageData(0, 0, width, height);
//   console.log(`Image Data`, imageData);
// }

export const getColor = async (url: string, id: string) => {
  console.log('getColor', url, id);
  return 'rgba(249, 217, 200, 0.30)';
  // try {

  //   // const data = await LocalStorage.getData(`color-${id}`);
  //   // if (!!data) return data;
  //   // const response = await loadImage(url);
  //   // console.log('analyzeImage', analyzeImage(response));
  //   // LocalStorage.setData(`color-${id}`, response.Vibrant);
  //   // return response.Vibrant;
  // } catch (error) {
  //   console.log('error', error);
  //   return undefined;
  // }
};
