/*eslint-disable */
import React from 'react';
import type {FC} from 'react';
import {View, StyleSheet, Pressable} from 'react-native';

import Colors from '@constants/Colors';
import {FontFamily, FontSize, FontWeight, LineHeight} from '@utils/sizes';
import type {LinkedInParsedDataEducation} from '@models/AccountData/LinkedInModels/LinkedInParsedDataEducation';
import CustomAvatar from '../Avatar';
import {CustomIcon} from '../icon';
import {useSelector} from 'react-redux';
import {
  userAccountDataSelector,
  userAccountThingIdSelector,
} from '@redux/selectors/userAccountSelectors';
import {CustomText, RegularThinText, TitleThinText} from '@components/Texts';
import {RandomAvatar} from '@assets';
import {resolveAssetSource} from '@utils/image-web';
const anonymousURI = resolveAssetSource(RandomAvatar).uri;

const styles = StyleSheet.create({
  container: {
    padding: 16,
    borderRadius: 10,
    backgroundColor: Colors.white,
    marginBottom: 10,
  },
  topContainer: {marginRight: 8, alignSelf: 'stretch', flexDirection: 'row'},
  detailsContainer: {
    flex: 1,
    paddingHorizontal: 8,
  },
  flex: {
    flex: 1,
  },
  title: {
    letterSpacing: 0.3,
  },
  schoolNameText: {
    letterSpacing: 0.3,
    marginTop: 4,
  },

  yearText: {
    letterSpacing: 0.3,
    marginTop: 2,
  },
});

const monthMap = new Map([
  [1, 'Jan'],
  [2, 'Feb'],
  [3, 'Mar'],
  [4, 'Apr'],
  [5, 'May'],
  [6, 'Jun'],
  [7, 'Jul'],
  [8, 'Aug'],
  [9, 'Sep'],
  [10, 'Oct'],
  [11, 'Nov'],
  [12, 'Dec'],
]);

export const EducationDetailsCard: FC<{
  userId: string;
  educationDetail: LinkedInParsedDataEducation;
  index: number;
  onPressEdit?: () => void;
}> = ({userId, educationDetail, index, onPressEdit}) => {
  const loggedInUserId = useSelector(userAccountThingIdSelector);
  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <CustomAvatar
          height={36}
          backgroundColor={Colors.lightBackground}
          width={36}
          resizeMode="contain"
          borderRadius={18}
          source={
            educationDetail.school?.logo
              ? educationDetail.school.logo
              : anonymousURI
          }
        />

        <View style={styles.detailsContainer}>
          <View style={styles.flex}>
            <CustomText color={'text'} style={styles.title}>
              {educationDetail.degree_name && educationDetail.degree_name}
            </CustomText>

            <CustomText color={'lightText'} style={styles.schoolNameText}>
              {educationDetail.school?.name}
            </CustomText>
            <CustomText color={'veryLightText'} style={styles.yearText}>
              {educationDetail.date?.start!.month &&
              educationDetail.date?.start!.year
                ? monthMap.get(educationDetail.date?.start!.month) +
                  ' ' +
                  educationDetail.date?.start!.year
                : educationDetail.date?.start!.year
                ? educationDetail.date?.start!.year
                : index == 0
                ? 'Present'
                : ''}{' '}
              -{' '}
              {educationDetail.date?.end!.month &&
              educationDetail.date?.end!.year
                ? monthMap.get(educationDetail.date?.end!.month!) +
                  ' ' +
                  educationDetail.date?.end!.year!
                : educationDetail.date?.end!.year!
                ? educationDetail.date?.end!.year!
                : index == 0
                ? 'Present'
                : 'Unavailable'}
            </CustomText>
          </View>
        </View>
        {loggedInUserId == userId && (
          <Pressable onPress={onPressEdit}>
            <CustomIcon size={15} name="edit" color={Colors.lightText} />
          </Pressable>
        )}
      </View>
    </View>
  );
};
