import React, {
  createContext,
  useRef,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import type {FC} from 'react';
import {BottomSheetModal, BottomSheetView} from '@gorhom/bottom-sheet';
import {View, Text, StyleSheet} from 'react-native';
import {Button} from 'native-base';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {CustomSimpleBackdrop} from '@components/CustomBackdrop';
import {FontFamily, FontSize, FontWeight, LineHeight} from '@utils/sizes';
import Colors from '@constants/Colors';
import {SheetTitle} from './SheetComponent';

const styles = StyleSheet.create({
  bottomSheetView: {
    alignSelf: 'stretch',
  },
  bottomContainer: {
    alignSelf: 'stretch',
    paddingHorizontal: 16,
    paddingTop: 12,
  },
  line: {alignSelf: 'stretch', height: 1, backgroundColor: Colors.background},
  button: {
    borderColor: Colors.primary,
    alignSelf: 'stretch',
    borderRadius: 8,
    marginTop: 10,
  },
  buttonText: {
    color: Colors.primary,
    fontSize: FontSize.title,
    lineHeight: LineHeight.title,
    fontWeight: FontWeight.medium,
    fontFamily: FontFamily.lato_regular,
  },
  sheetDescription: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 20,
    color: Colors.lightText,
    fontFamily: FontFamily.lato_regular,
  },
  sheetContent: {
    paddingTop: 8,
    alignSelf: 'stretch',
    marginBottom: 5,
    paddingHorizontal: 20,
  },
});

const NotificationConfirmContext = createContext<
  React.RefObject<BottomSheetModal>
>(0 as any);

export const useNotificationConfirmSheetRef = () =>
  useContext(NotificationConfirmContext);

const NotificationConfirmSheet = ({
  sheetRef,
}: {
  sheetRef: React.RefObject<BottomSheetModal>;
}) => {
  const [
    loading,
    // setLoading
  ] = useState(false);
  //   const userThingId = useSelector(userAccountThingIdSelector);
  //   const userAccount = useSelector(userAccountDataSelector);

  const {bottom} = useSafeAreaInsets();

  const onSubmit = useCallback(async () => {
    //setLoading(true);
    //await dispatch(editUserAccountData(editAccountDataPayload));
    //setLoading(false);
    sheetRef.current?.close();
  }, [
    // userThingId,

    sheetRef,
  ]);

  return (
    <BottomSheetModal
      enablePanDownToClose={!loading}
      enableHandlePanningGesture={!loading}
      snapPoints={[275 + bottom]}
      backdropComponent={props => (
        <CustomSimpleBackdrop
          disabled={loading}
          {...props}
          sheetRef={sheetRef}
        />
      )}
      ref={sheetRef}>
      <BottomSheetView>
        <SheetTitle title="Notification option" />
        <View style={styles.line} />
        <View style={styles.sheetContent}>
          <Text style={styles.sheetDescription}>
            Are you sure you want to turn of notification?
          </Text>
        </View>
        <View style={styles.bottomSheetView}>
          <View style={[styles.bottomContainer, {paddingBottom: 10}]}>
            <Button
              disabled={loading}
              variant="outline"
              size="sm"
              style={styles.button}
              onPress={onSubmit}>
              <Text style={styles.buttonText}>Yes</Text>
            </Button>
            <Button
              disabled={loading}
              variant="outline"
              size="sm"
              style={styles.button}
              onPress={onSubmit}>
              <Text style={styles.buttonText}>No</Text>
            </Button>
          </View>
        </View>
      </BottomSheetView>
    </BottomSheetModal>
  );
};

export const NotificationConfirmSheetProvider: FC<{
  children: React.ReactElement;
}> = props => {
  const sheetRef = useRef<BottomSheetModal>(null);
  const {children} = props;
  return (
    <NotificationConfirmContext.Provider value={sheetRef}>
      {useMemo(() => children, [children])}
      <NotificationConfirmSheet sheetRef={sheetRef} />
    </NotificationConfirmContext.Provider>
  );
};
