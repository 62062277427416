/*eslint-disable */
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import type {FC} from 'react';
import {
  StyleSheet,
  View,
  useWindowDimensions,
  Pressable,
  Animated,
  Platform,
} from 'react-native';
import {TabView, SceneMap} from 'react-native-tab-view';
import type {SceneRendererProps, NavigationState} from 'react-native-tab-view';
import Colors from '@constants/Colors';
import {useSelector} from 'react-redux';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import CustomAvatar from '@components/Avatar';
import {Text} from 'react-native';
import {
  convertToCamelCaseString,
  resolveImageAssetSource,
} from '@utils/utilityFunctions';
import {CustomIcon} from '@components/icon';
import {userAccountDataSelector} from '@redux/selectors/userAccountSelectors';
import Header, {
  ChatIcon,
  HeaderBackButton,
  SettingIcon,
} from '@components/Header/Header';
import LogoutBottomSheet, {
  LogoutBottomSheetProvider,
  useLogoutBottomSheetRef,
} from '@components/Modals/LogoutBottomSheet';
import {FontFamily, FontSize, FontWeight, LineHeight} from '@utils/sizes';
import {useEditDetailsSheetRef} from '@components/Modals/EditDetailsSheet';
import UserPosts from './UserPosts';
import {useNavigation, useRoute} from '@react-navigation/native';
import {
  createProfileDetailLoadingSelector,
  createProfileDetailSelector,
  createUserAccountDataSelector,
  createUserAvatarSelector,
  createUserCurrentOrgSelector,
  createUserCurrentRoleSelector,
  createUserNameSelector,
} from '@redux/selectors/profileSelector';
import {dispatch} from '@redux/store';
import {fetchProfileDetails} from '@redux/actions/profile';
import NavigationService from '@services/navigationService';
import UserDetails from './ProfileDetails';
import {ProfileDetailLoader} from '@components/FeedListLoader';
import {
  DynamicLinkThingTypeEnum,
  EventTypeEnum,
  ThingTypeEnum,
  UserProfileAnalEventValue,
} from '@constants/constantsEnum';
import DynamicLinkService from '@services/dynamicLinkService';
import ShareService from '@services/shareService';
import Screen from '@components/Screen';
import BigScreenTabBar from '@components/BigScreenTabBar';
import {withTokenRefresh} from '@utils/webhooks';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {isWeb} from '@utils/useColors';
import Analytics, {IEventData, IExtraData} from '@wyse/analytics';
import BigScreenRightBar from '@components/BigScreenRightBar';
import {CustomText} from '@components/Texts';

const profileUnavialableImage = require('@assets/profile-unvailable-avatar.png');
const profileUnavialableURI = resolveImageAssetSource(
  profileUnavialableImage,
).uri;

const styles = StyleSheet.create({
  container: {flex: 1, backgroundColor: Colors.tabBackground},
  headerContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: '#FFFFFF',
  },
  stretch: {alignSelf: 'stretch'},
  profileContainer: {
    padding: 16,

    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: Colors.white,
  },
  editButton: {
    padding: 5,
    marginLeft: 5,
    marginBottom: -5,
  },
  tabContainer: {
    marginTop: 2,
    alignSelf: 'stretch',
    height: 38,
    flexDirection: 'row',
    backgroundColor: Colors.white,
  },
  tabBottomBar: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    height: 2,
    backgroundColor: Colors.primary,
    borderRadius: 1,
  },
  nameText: {
    marginRight: 15,
  },
  tabButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 2,
    borderBottomColor: Colors.background,
  },
  tabText: {},
  userRoleText: {
    paddingRight: 80,
  },
});

const UserAvatar: FC<{profileThingId: string}> = ({profileThingId}) => {
  const avatar = useSelector(createUserAvatarSelector(profileThingId));
  return (
    <CustomAvatar
      height={60}
      width={60}
      borderRadius={56}
      source={!!avatar ? avatar : profileUnavialableURI}
    />
  );
};

const UserName: FC<{profileThingId: string}> = ({profileThingId}) => {
  const name = useSelector(createUserNameSelector(profileThingId));
  return (
    <CustomText
      size={FontSize.medium}
      color="lightText"
      fontFamily={FontFamily.FigtreeBold}
      style={styles.nameText}>
      {name ? convertToCamelCaseString(name) : ''}
    </CustomText>
  );
};

const UserProfile: FC<{profileThingId: string}> = ({profileThingId}) => {
  const accountData = useSelector(userAccountDataSelector);
  const userThingId = useSelector(userAccountThingIdSelector);
  const currentProfileUserName = useSelector(
    createUserNameSelector(profileThingId),
  );
  const sheetRef = useEditDetailsSheetRef();
  const handleProfileShare = useCallback(async () => {
    const link = await DynamicLinkService.createDynamicLink(
      profileThingId,
      DynamicLinkThingTypeEnum.PROFILE,
    );
    const options = {
      message: `Check out ${currentProfileUserName}'s profile on Wyse App : ${link} \n\nJoin the most meaningful discussions by industry leaders and your peers on Wyse app now.\nInnovation starts from conversation`,
      title: '',
    };
    await ShareService.openShare(options);
  }, [currentProfileUserName, profileThingId]);

  /** ProfileDetail willl be undefined untill fetched once
   * To check whether to call the api for fetching profile data
   */
  const profileDetail = useSelector(
    createProfileDetailSelector(profileThingId),
  );
  const profileDetailLoading = useSelector(
    createProfileDetailLoadingSelector(profileThingId),
  );
  const userCurrentOrg = useSelector(
    createUserCurrentOrgSelector(profileThingId),
  );
  const userCurrentRole = useSelector(
    createUserCurrentRoleSelector(profileThingId),
  );

  const loading = useRef(false);
  /**
   * functional effect to fetch profile details data
   */
  useEffect(() => {
    if (!profileThingId) return;
    (async () => {
      if (!!loading.current) return;
      loading.current = true;
      if (!profileDetail) {
        await dispatch(fetchProfileDetails(profileThingId));
      }
      loading.current = false;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileThingId, !profileDetail]);

  if (!profileThingId) {
    return null;
  } else {
    if (!accountData) return null;
  }

  if (profileDetailLoading) {
    return <ProfileDetailLoader />;
  } else {
    return (
      <View style={styles.profileContainer}>
        <View style={{marginRight: 16, alignSelf: 'center'}}>
          <UserAvatar profileThingId={profileThingId} />
        </View>
        <View style={{flex: 1}}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <UserName profileThingId={profileThingId} />
            {!!userThingId &&
              !!profileThingId &&
              userThingId == profileThingId && (
                <Pressable
                  style={styles.editButton}
                  onPress={() => {
                    const analyticsEventPayload: IEventData = {
                      ev_typ: EventTypeEnum.ANALYTICS,
                      ev_val: UserProfileAnalEventValue.USER_HEADER_EDIT_CLICK,
                      thing_type: ThingTypeEnum.NA,
                    };
                    Analytics.trackEvent(analyticsEventPayload);
                    sheetRef.current?.present();
                  }}>
                  <CustomIcon size={14} name="edit" color={Colors.lightText} />
                </Pressable>
              )}
          </View>
          {userCurrentRole && userCurrentOrg ? (
            <View style={{margin: 2}}>
              <CustomText color="lightText" style={styles.userRoleText}>
                {`${userCurrentRole} | ${userCurrentOrg}`}
              </CustomText>
            </View>
          ) : null}
        </View>
        {!!userThingId && !!profileThingId && userThingId != profileThingId && (
          <Pressable onPress={handleProfileShare}>
            <View
              style={{
                marginTop: 15,
                marginRight: 20,
              }}>
              <CustomIcon size={20} name="share" color="#4F4F4F" />
            </View>
          </Pressable>
        )}
      </View>
    );
  }
};

const ProfileHeader: FC<{userId: string}> = ({userId}) => {
  const params = useRoute().params as any;
  const sheetRef = useLogoutBottomSheetRef();
  const loggedInUserId = useSelector(userAccountThingIdSelector);

  const accountData = useSelector(createUserAccountDataSelector(userId));

  const headerLeft = useCallback(
    () =>
      params?.screen === 'tab' ? (
        <HeaderBackButton
          onPress={() => NavigationService.getInstance().goBack()}
        />
      ) : (
        <HeaderBackButton
          onPress={() => NavigationService.getInstance().goBack()}
        />
      ),
    [params?.screen],
  );

  const deps = [
    loggedInUserId == userId,
    accountData?.user_fullname,
    accountData?.avatar,
  ];
  const headerRight = useCallback(() => {
    if (loggedInUserId == userId)
      return (
        <SettingIcon
          onPress={() => {
            console.log(sheetRef.current);
            sheetRef.current?.present();
          }}
        />
      );
    if (!!accountData)
      return (
        <ChatIcon
          onPress={() => {
            NavigationService.getInstance().openChat(
              userId,
              {
                user_fullname: accountData?.user_fullname,
                user_avatar: accountData?.avatar,
                user_thing_id: userId,
              } as any,
              accountData?.user_current_role,
              accountData?.user_current_org,
            );
          }}
        />
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  // if (loggedInUserId == userId) {
  //   return <Header headerRight={headerRight} title="Profile" />;
  // }
  return <Header headerRight={headerRight} headerLeft={headerLeft} title="" />;
};

const useGetRoutes = () => {
  const params = useRoute().params as any;
  let routes: any = [];
  routes = [
    {key: 'details', title: 'Details', active: params?.tab == 'details'},
    {key: 'posts', title: 'Posts', active: params?.tab == 'posts'},
  ];

  return routes as {key: string; title: string; active: boolean}[];
};

const TabBar: FC<
  SceneRendererProps & {
    navigationState: NavigationState<any>;
  }
> = props => {
  const {width, height} = useWindowDimensions();
  const {top, bottom} = useSafeAreaInsets();
  const isLandscape = width > height;
  const actualWidth = isLandscape ? width - (top || 0) - (bottom || 0) : width;
  console.log('props', props);
  const routes = useGetRoutes();
  console.log('routes', routes);
  return (
    <View style={[styles.tabContainer]}>
      {routes.map((route, index) => (
        <Pressable
          key={route.key}
          onPress={() => {
            props.jumpTo(route.key);
          }}
          style={[
            styles.tabButton,

            isWeb &&
              route.active && {
                borderBottomColor: Colors.primary,
              },
          ]}>
          <CustomText
            color={
              props.navigationState.index === index ? 'primary' : 'lightText'
            }>
            {route.title}
          </CustomText>
        </Pressable>
      ))}
      {isWeb ? null : (
        <Animated.View
          style={[
            styles.tabBottomBar,
            {
              width: actualWidth / 2,
              transform: [
                {
                  translateX: props.position.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, actualWidth / 2],
                  }),
                },
              ],
            },
          ]}
        />
      )}
    </View>
  );
};
const Sheet = () => {
  const ref = useLogoutBottomSheetRef();
  if (isWeb) {
    return <LogoutBottomSheet ref={ref} />;
  }
  return <LogoutBottomSheet />;
};

const ProfileTabs: FC = () => {
  console.log('Profile');
  const navigation = useNavigation();
  const layout = useWindowDimensions();
  const userThingId = useSelector(userAccountThingIdSelector)!;
  const route = useRoute();
  const params = route.params as any;
  const routes = useGetRoutes();

  useEffect(() => {
    const newParams = {...params};
    if (!newParams.profileThingId) {
      return;
    }

    if (!newParams.tab) {
      navigation.setParams({
        ...newParams,
        tab: 'details',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let profileThingId = '';

  if (params?.profileThingId) {
    profileThingId = params.profileThingId;
  } else {
    profileThingId = userThingId;
  }

  console.log('route', route);
  /**
   * Scene rednerer
   */
  useEffect(() => {
    const extraData: IExtraData = {
      selfProfile: profileThingId == userThingId,
    };
    const analyticsEventPayload: IEventData = {
      ev_typ: EventTypeEnum.ANALYTICS,
      ev_val: UserProfileAnalEventValue.PROFILE_VIEW_IMP,
      thing_type: ThingTypeEnum.PROFILE,
      thing_id: profileThingId,
      extra_data: JSON.stringify(extraData),
    };
    Analytics.trackEvent(analyticsEventPayload);
  }, []);
  const renderScene = useMemo(() => {
    return SceneMap({
      details: () => <UserDetails userId={profileThingId!} />,
      posts: () => <UserPosts userId={profileThingId!} />,
    });
  }, [profileThingId]);

  return (
    <LogoutBottomSheetProvider>
      <Screen
        RightComponent={BigScreenRightBar}
        LeftComponent={BigScreenTabBar}
        style={styles.container}>
        <ProfileHeader userId={profileThingId} />
        <UserProfile profileThingId={profileThingId} />
        <TabView
          lazy
          animationEnabled={Platform.OS !== 'web'}
          renderTabBar={props => <TabBar {...props} />}
          navigationState={{
            index: params?.tab ? (params?.tab == 'details' ? 0 : 1) : 0,
            routes,
          }}
          renderScene={renderScene}
          onIndexChange={index => {
            navigation.setParams({
              ...params,
              tab: index == 0 ? 'details' : 'posts',
            });
          }}
          initialLayout={{width: layout.width}}
        />
        <Sheet />
      </Screen>
    </LogoutBottomSheetProvider>
  );
};

export default withTokenRefresh(ProfileTabs);
