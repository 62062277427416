import axiosInstance from './axios';
import {NotificationList} from '@models/Notification/NotificationList';
import {NotificationReadResponse} from '@models/Notification/NotificationReadResponse';

const fetchUserNotificationsList = async (payload: {
  page: number;
  userId: string;
}) => {
  return axiosInstance.get<NotificationList>('/nexum/get/notifications', {
    params: {
      user_tid: payload.userId,
      page: payload.page,
    },
    headers: {
      Authorization: 'khuljasimsim',
    },
  });
};

const updateNotificationReadStatus = async (payload: {
  notificationId: string;
}) => {
  return axiosInstance.get<NotificationReadResponse>(
    '/nexum/notification/read',
    {
      params: {
        notification_id: payload.notificationId,
      },
      headers: {
        Authorization: 'khuljasimsim',
      },
    },
  );
};

const NotificationService = {
  fetchUserNotificationsList,
  updateNotificationReadStatus,
};

export default NotificationService;
