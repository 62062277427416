/* eslint-disable */
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type {FC} from 'react';
import {
  ActivityIndicator,
  Pressable,
  Platform,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
  Dimensions,
  Image,
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import {
  MaterialTopTabBarProps,
  createMaterialTopTabNavigator,
} from '@react-navigation/material-top-tabs';
import {
  type Route,
  SceneMap,
  type SceneRendererProps,
  type NavigationState,
  TabView,
} from 'react-native-tab-view';
import {useSelector} from 'react-redux';
import {useNavigation, useRoute} from '@react-navigation/native';
import Colors from '@constants/Colors';
import CustomAvatar from '@components/Avatar';
import {
  FontSize,
  FontWeight,
  LineHeight,
  SCREEN_SIZE,
  TAB_BAR_SIZE,
} from '@utils/sizes';
import {
  communityAvatarColorSelector,
  createCommunitySelector,
} from '@redux/selectors/metaSelector';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';
import {dispatch} from '@redux/store';
import {updateJoinedCommunitiesByUser} from '@redux/slices/communitySlice';
import {joinCommunityEventHandler} from '@redux/actions/community';
import {createCommunitiesJoinedByUserSelector} from '@redux/selectors/communitySelector';
import {
  convertToCamelCaseString,
  resolveImageAssetSource,
} from '@utils/utilityFunctions';
import CommunityFeed from '../CommunityFeed';
import CommunityMembers from '../CommunityMembers';
import {AppScreens, CommunityTabs} from '@navigations/ScreenNames';
import Animated from 'react-native-reanimated';
import LinearGradient from '@libs/LinearGradient';

import {
  DynamicLinkThingTypeEnum,
  JoinEventEnum,
  ThingTypeEnum,
} from '@constants/constantsEnum';
import type {CommunityJoinEventPayload} from '@models/Community/CommunityJoinEventPayload';
import {CustomIcon} from '@components/icon';
import FastImage from '@libs/FastImage';
import DynamicLinkService from '@services/dynamicLinkService';
import ShareService from '@services/shareService';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import CommunityLayoutContext from '../CommunitJoin';
import {TitleText} from '@components/Texts';
import NavigationService from '@services/navigationService';
import Screen from '@components/Screen';
import BigScreenTabBar from '@components/BigScreenTabBar';
import {withTokenRefresh} from '@utils/webhooks';

import defaultBanner from '@assets/banner.png';

const {width} = Dimensions.get('window');
const COMMUNITY_AVATAR_SIZE = 56;
const COMMUNITY_BANNER_SIZE = 72;
import unavailableCommunityImage from '@assets/logo.png';

const unavailableCommunityURI = resolveImageAssetSource(
  unavailableCommunityImage,
).uri;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.tabBackground,
  },
  headerContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: '#FFFFFF',
  },
  stretch: {alignSelf: 'stretch'},
  detailsContainer: {
    alignSelf: 'stretch',
    backgroundColor: Colors.white,
  },
  editButton: {},

  tabBottomBar: {
    bottom: 0,
    position: 'absolute',
    height: 2,
    backgroundColor: Colors.primary,
    borderRadius: 1,
  },
  nameText: {
    fontWeight: FontWeight.bold,
    fontSize: FontSize.medium,
    lineHeight: LineHeight.heading,
    color: '#181818',
    marginRight: 15,
  },
  tabButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 2,
    borderBottomColor: Colors.background,
  },
  tabText: {
    fontSize: FontSize.regular,
    fontWeight: FontWeight.regular,
    lineHeight: LineHeight.regular,
  },
  userRoleText: {
    fontWeight: FontWeight.regular,
    fontSize: FontSize.small,
    lineHeight: LineHeight.small,
    color: Colors.lightText,
  },
  headLineText: {
    fontWeight: FontWeight.regular,
    fontSize: 10,
    lineHeight: 14,
    color: '#666666',
  },
  descriptionText: {
    fontWeight: FontWeight.regular,
    fontSize: FontSize.small,
    lineHeight: LineHeight.small,
    color: Colors.veryLightText,
  },
  backButton: {
    position: 'absolute',
    left: 16,
    width: 24,
    height: 24,

    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    backgroundColor: '#ffffffbf',
  },
  shareButton: {
    position: 'absolute',
    right: 16,
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    backgroundColor: '#ffffffbf',
  },
  headerInfoContainer: {
    position: 'absolute',
    left: 50,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarContainer: {
    position: 'absolute',
    left: 16,
    top: 56,
  },
  avatarBorderContainer: {
    borderWidth: 2,
    height: COMMUNITY_AVATAR_SIZE,
    width: COMMUNITY_AVATAR_SIZE,
    overflow: 'hidden',
    borderColor: Colors.white,
    borderRadius: COMMUNITY_AVATAR_SIZE / 2,
  },
  membersText: {
    fontWeight: FontWeight.regular,
    fontSize: FontSize.small,
    lineHeight: LineHeight.small,
    color: Colors.lightText,
    marginTop: 12,
  },
  tabContainer: {
    alignSelf: 'stretch',
    height: 38,
    flexDirection: 'row',
    position: 'sticky',
    top: 0,
    backgroundColor: Colors.white,
  } as any,
  line: {
    alignSelf: 'stretch',
    height: 2,
    backgroundColor: Colors.tabBackground,
  },
  backgroundView: {
    flex: 1,
    backgroundColor: Colors.white,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightBackground,
  },
  headerBackgroundContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  joinButton: {
    backgroundColor: Colors.primary,
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 8,
    width: 80,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  joinButtonText: {
    fontWeight: FontWeight.medium,
    fontSize: FontSize.small,
    lineHeight: LineHeight.small,
    textAlign: 'center',
  },
  joinButtonContainer: {
    right: 16,
    position: 'absolute',
    alignSelf: 'flex-end',
    top: COMMUNITY_BANNER_SIZE + 8,
    justifyContent: 'center',
  },
  onJoinOptionContainer: {
    alignSelf: 'stretch',
    marginTop: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  onJoinOptionButton: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    width: width * 0.44,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    flexDirection: 'row',
    backgroundColor: Colors.white,
    borderColor: Colors.primary,
    borderWidth: 1,
  },
  onJoinOptionButtonBody: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  onJoinOptionButtonText: {
    fontWeight: FontWeight.medium,
    fontSize: FontSize.small,
    lineHeight: LineHeight.small,
    marginLeft: 4.66,
  },
  botomContainer: {width: '100%', paddingHorizontal: 16},
  joinUnJoinButton: {
    flex: 1,
    borderRadius: 8,
    overflow: 'hidden',
  },
  flexCenter: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  joinedButton: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: Colors.lightBackground,
    justifyContent: 'center',
    alignItems: 'center',
  },
  joinedText: {marginLeft: 8},
  createPostButton: {
    width: 44,
    height: 44,
    borderRadius: 22,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primary,
    alignSelf: 'flex-end',
  },
});

const getHeaderHeight = () =>
  COMMUNITY_AVATAR_SIZE / 2 + COMMUNITY_BANNER_SIZE + 8;

const CommunityMetaDetails: FC<{id: string}> = ({id}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {top} = useSafeAreaInsets();
  const communityDetails = useSelector(createCommunitySelector(id));
  const communityColor = useSelector(communityAvatarColorSelector(id));
  const communities = useSelector(createCommunitiesJoinedByUserSelector);
  const navigation = useNavigation();
  const userThingId = useSelector(userAccountThingIdSelector);
  const isJoined = useMemo(
    () => communities.includes(id),
    [communities.length],
  );

  const joinUnjoinGroup = useCallback(async () => {
    const action = isJoined
      ? JoinEventEnum.UNSUBSCRIBE
      : JoinEventEnum.SUBSCRIBE;
    setIsLoading(true);
    const payload: CommunityJoinEventPayload = {
      resource_id: id,
      thing_type: ThingTypeEnum.COMMUNITY,
      action: action,
      //TODO - check null check
      user_id: userThingId!,
    };

    /** for api calls, state management and db updates are independent */
    await dispatch(joinCommunityEventHandler(payload));
    /** to update state */
    dispatch(updateJoinedCommunitiesByUser(payload));
    setIsLoading(false);
  }, [isJoined, id, userThingId]);

  const handleCommuntiyShare = useCallback(async () => {
    const link = await DynamicLinkService.createDynamicLink(
      id,
      DynamicLinkThingTypeEnum.COMMUNITY_DETAIL,
    );
    const options = {
      message: `Check out this Community on Wyse App ${communityDetails.title}: ${link} \n\nJoin the most meaningful discussions by industry leaders and your peers on Wyse app now.\nInnovation starts from conversation`,
      title: '',
    };
    await ShareService.openShare(options);
  }, []);

  useEffect(() => {
    // get commmunity details
    if (!communityDetails) {
      // dispatch()
    }
  }, []);

  if (!communityDetails) return null;

  return (
    <View style={[styles.detailsContainer]}>
      <View
        style={{
          alignSelf: 'stretch',
          height: getHeaderHeight() - 8,
          marginBottom: 12,
        }}>
        <View
          style={{
            width: '100%',
            height: COMMUNITY_BANNER_SIZE,
          }}>
          <FastImage
            source={
              communityDetails.banner
                ? {uri: communityDetails.banner}
                : defaultBanner
            }
            resizeMode="stretch"
            style={{flex: 1, width: '100%'}}
          />
        </View>
        <View style={styles.avatarContainer}>
          <View style={styles.avatarBorderContainer}>
            <CustomAvatar
              style={{flex: 1}}
              source={communityDetails.community_avatar!}
            />
          </View>
        </View>
        <CommunityLayoutContext.LayoutContainer
          style={styles.joinButtonContainer}>
          <Pressable
            style={[
              styles.joinButton,
              {backgroundColor: isJoined ? '#f3f3f3' : Colors.primary},
            ]}
            disabled={isLoading}
            onPress={joinUnjoinGroup}>
            {isLoading ? (
              <ActivityIndicator
                size={12}
                color={!isJoined ? '#FFFFFF' : Colors.primary}
              />
            ) : (
              <Text
                style={[
                  styles.joinButtonText,
                  {color: isJoined ? '#4f4f4f' : '#FFFFFF'},
                ]}>
                {isJoined ? 'Joined' : 'Join'}
              </Text>
            )}
          </Pressable>
        </CommunityLayoutContext.LayoutContainer>
      </View>
      <View
        style={{
          alignSelf: 'stretch',
          paddingHorizontal: 16,
          paddingTop: 12,
          paddingBottom: 12,
        }}>
        <View
          style={{
            alignSelf: 'stretch',
          }}>
          <Text style={styles.nameText}>
            {convertToCamelCaseString(communityDetails.title)}
          </Text>
        </View>
        <View
          style={{
            alignSelf: 'stretch',
          }}>
          <Text style={styles.headLineText}>
            {communityDetails.community_headline}
          </Text>
        </View>
        <Text style={styles.membersText}>
          {communityDetails.sub_count} Members
        </Text>
        {/*
          Below code creates bug in the code and scrols to the top of the screen
          every time join and unjoin is successfull
        **/}
        {/* {isJoined && (
          <View style={styles.onJoinOptionContainer}>
            <Button
              style={[
                styles.onJoinOptionButton,
                {
                  backgroundColor: Colors.primary,
                },
              ]}>
              <View style={styles.onJoinOptionButtonBody}>
                <CustomIcon size={11} name="edit" color={Colors.white} />
                <Text
                  style={[
                    styles.onJoinOptionButtonText,
                    {
                      color: Colors.white,
                    },
                  ]}>
                  Create Post
                </Text>
              </View>
            </Button>
            <Button
              style={styles.onJoinOptionButton}
              onPress={handleCommuntiyShare}>
              <View style={styles.onJoinOptionButtonBody}>
                <CustomIcon size={11} name="comment" color={Colors.primary} />
                <Text
                  style={[
                    styles.onJoinOptionButtonText,
                    {
                      color: Colors.primary,
                    },
                  ]}>
                  Invite
                </Text>
              </View>
            </Button>
          </View>
        )} */}
      </View>
      <View style={styles.line} />
      <Animated.View style={[styles.headerBackgroundContainer]}>
        <Animated.View
          style={[styles.backgroundView, {backgroundColor: communityColor}]}>
          <LinearGradient
            style={{flex: 1}}
            start={{x: Platform.OS == 'ios' ? -2.5 : -15, y: 0}}
            end={{x: 1, y: 0}}
            colors={[communityColor || '#00000030', Colors.white]}
          />
        </Animated.View>
        <Pressable
          style={[
            styles.backButton,
            {
              top: top + 8,
            },
          ]}
          onPress={navigation.goBack}>
          <Icon name="arrow-back" color={Colors.black} size={18} />
        </Pressable>

        <Pressable
          style={[
            styles.shareButton,
            {
              top: top + 8,
            },
          ]}
          onPress={handleCommuntiyShare}>
          <CustomIcon size={20} name="share" color="#000" />
        </Pressable>
      </Animated.View>
    </View>
  );
};

const TabText: FC<{
  name: string;
  index: number;
  activeIndex: number;
}> = ({name, index, activeIndex}) => {
  return (
    <Animated.Text
      style={[
        styles.tabText,
        {
          color: activeIndex === index ? Colors.primary : Colors.veryLightText,
        },
      ]}>
      {name}
    </Animated.Text>
  );
};

const TabBar: FC<MaterialTopTabBarProps> = props => {
  const {width} = useWindowDimensions();

  const actualWidth = width > SCREEN_SIZE ? SCREEN_SIZE : width;

  const animatedIndicatorStyle = useMemo(
    () => ({
      transform: [
        {
          translateX: props.state.index == 0 ? 0 : actualWidth / 2,
        },
      ],
    }),
    [actualWidth, props.state.index],
  );

  return (
    <View style={styles.tabContainer}>
      {props.state.routes.map((route, index) => (
        <Pressable
          key={route.key}
          onPress={() => props.jumpTo(route.key)}
          style={styles.tabButton}>
          <TabText
            name={route.name!}
            index={index}
            activeIndex={props.state.index}
          />
        </Pressable>
      ))}
      <Animated.View
        style={[
          styles.tabBottomBar,
          {
            width: actualWidth / 2,
          },
          animatedIndicatorStyle,
        ]}
      />
    </View>
  );
};

const BottomPart: FC<{id: string}> = ({id}) => {
  const userThingId = useSelector(userAccountThingIdSelector);
  const communities = useSelector(createCommunitiesJoinedByUserSelector);
  const [isLoading, setIsLoading] = useState(false);
  const {bottom} = useSafeAreaInsets();
  const isJoined = useMemo(
    () => communities.includes(id),
    [communities.length],
  );
  const [showJoinButton, setShowJoinButton] = useState(!isJoined);

  const lastJoinStatus = useRef(isJoined);
  const joinUnjoinGroup = useCallback(async () => {
    const action = isJoined
      ? JoinEventEnum.UNSUBSCRIBE
      : JoinEventEnum.SUBSCRIBE;
    setIsLoading(true);
    const payload: CommunityJoinEventPayload = {
      resource_id: id,
      thing_type: ThingTypeEnum.COMMUNITY,
      action: action,
      //TODO - check null check
      user_id: userThingId!,
    };

    /** for api calls, state management and db updates are independent */
    await dispatch(joinCommunityEventHandler(payload));
    /** to update state */
    dispatch(updateJoinedCommunitiesByUser(payload));
    setIsLoading(false);
  }, [isJoined, id, userThingId]);

  useEffect(() => {
    console.log('isJoined', isJoined, lastJoinStatus);

    if (isJoined) {
      if (!lastJoinStatus.current) {
        lastJoinStatus.current = true;
        const timeout = setTimeout(() => {
          setShowJoinButton(false);
        }, 2000);
        return () => {
          console.log('return');
          if (timeout) clearTimeout(timeout);
        };
      }
    } else {
      lastJoinStatus.current = false;
      setShowJoinButton(true);
    }
  }, [isJoined]);

  console.log('showJoinButton', isJoined, showJoinButton);
  return (
    <CommunityLayoutContext.BottomContainer
      style={[
        styles.botomContainer,
        {
          height: (bottom || 0) + 44,
        },
      ]}>
      {showJoinButton ? (
        <Pressable
          disabled={isLoading || isJoined}
          onPress={joinUnjoinGroup}
          style={styles.joinUnJoinButton}>
          <LinearGradient
            start={{x: 0, y: 0}}
            end={{x: 1, y: 0}}
            colors={
              isJoined
                ? [Colors.lightBackground, Colors.lightBackground]
                : ['#9B51E0', '#8401FF']
            }
            style={styles.flexCenter}>
            {isLoading ? (
              <ActivityIndicator color={Colors.white} />
            ) : !isJoined ? (
              <TitleText color="white">Join</TitleText>
            ) : (
              <View style={styles.joinedButton}>
                <Icon name="checkmark-outline" color={Colors.lightText} />
                <TitleText style={styles.joinedText} color="lightText">
                  Joined
                </TitleText>
              </View>
            )}
          </LinearGradient>
        </Pressable>
      ) : (
        <Pressable
          onPress={() => {
            // TODO: Navigate To Create Post
            NavigationService.getInstance().openCreatePost(id);
          }}
          style={styles.createPostButton}>
          <CustomIcon size={20} name="edit-icon" color={Colors.white} />
        </Pressable>
      )}
    </CommunityLayoutContext.BottomContainer>
  );
};
type Params = {
  communityThingId?: string;
};

const Tab = createMaterialTopTabNavigator();

const CommunityDetail: FC = () => {
  const {communityThingId} = (useRoute().params || {}) as Params;
  const {top} = useSafeAreaInsets();

  return (
    <Screen
      screen={AppScreens.CommunityDetail}
      LeftComponent={BigScreenTabBar}
      style={styles.container}
      maxWidth={SCREEN_SIZE + TAB_BAR_SIZE}>
      <CommunityLayoutContext.Provider>
        <View style={[styles.container, {paddingTop: top}]}>
          <CommunityMetaDetails id={communityThingId!} />
          <Tab.Navigator
            screenOptions={{
              animationEnabled: false,
            }}
            tabBar={props => <TabBar {...props} />}>
            <Tab.Screen
              initialParams={{communityThingId}}
              name={CommunityTabs.Feed}
              component={CommunityFeed}
            />
            <Tab.Screen
              name={CommunityTabs.Members}
              initialParams={{communityThingId}}
              component={CommunityMembers}
            />
          </Tab.Navigator>
        </View>
      </CommunityLayoutContext.Provider>
    </Screen>
  );
};

export default CommunityDetail;
