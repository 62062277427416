/* eslint-disable */
import React, {memo, useCallback, useRef} from 'react';
import type {FC} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import type {ListRenderItem, SectionListData, StyleProp, ViewStyle} from 'react-native';
import {useSelector} from 'react-redux';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Colors from '@constants/Colors';
import Header, {HeaderBackButton} from '@components/Header/Header';
import {MediumThinText, TitleText} from '@components/Texts';
import {
  ConversactionLoaderItem,
  LOADING_SECTION_DATA_LARGE,
  ListLoaderProvider,
} from '@components/FeedListLoader';
import ConversationCard from '@components/Card/ConversationCard';
import {userAccountThingIdSelector} from '@redux/selectors/userAccountSelectors';

import {
  usePendingConversationsCount,
  usePendingConversationsLoading,
  usePenginConversations,
  useRequestPageVisiblity,
  useSetState,
} from './context';
import type {Conversation} from '@models/Conversation';
import {Button} from 'native-base';
import {SectionList} from 'react-native';
import {FontFamily} from '@utils/sizes';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    ...StyleSheet.absoluteFillObject,
  },
  stretch: {alignSelf: 'stretch'},
  backdrop: {
    flex: 1,
    backgroundColor: '#00000040',
  },
  contentContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Colors.white,
  },
  counTitleContainer: {
    alignSelf: 'stretch',
    height: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  line: {alignSelf: 'stretch', height: 1, backgroundColor: Colors.background},
  emptyContainer: {
    alignItems: 'center',
    alignSelf: 'stretch',
    marginTop: 150,
  },
  button: {
    backgroundColor: '#9B51E0',
    borderRadius: 8,
    // height: 30,
  },
});

const RequestsListWrapper: FC<{children: any}> = ({children}) => {
  const visible = useRequestPageVisiblity();

  if (!visible) return null;

  return (
    <View style={styles.container}>
      <View style={styles.backdrop} />
      <View style={styles.contentContainer}>{children}</View>
    </View>
  );
};

const PageHeader: FC = () => {
  const setState = useSetState();

  const close = useCallback(() => {
    setState(st => ({...st, requestPageVisible: false}));
  }, []);

  return (
    <Header
      title="Requests"
      headerLeft={() => <HeaderBackButton onPress={close} />}
    />
  );
};

const Title = () => {
  const pendingCount = usePendingConversationsCount();
  if (pendingCount === 0) return null;
  return (
    <View style={styles.stretch}>
      <View style={styles.counTitleContainer}>
        <MediumThinText color="lightText" fontFamily={FontFamily.lato_regular}>
          Pending ({pendingCount})
        </MediumThinText>
      </View>
      <Line />
    </View>
  );
};

const Line = ({
  style,
}: {
  style?: Omit<StyleProp<ViewStyle>, 'marginVertical'>;
}) => <View style={[styles.line, style]} />;

const Seperator = () => <Line />;

const Empty = () => {
  const setState = useSetState();
  return (
    <View style={styles.emptyContainer}>
      <Text style={{marginBottom: 20, fontFamily: FontFamily.lato_regular}}>
        You don't have any request till now.
      </Text>
      <Button
        style={styles.button}
        onPress={() => {
          setState(st => ({...st, requestPageVisible: false}));
        }}>
        <Text
          style={{
            color: '#F4F4F4',
            fontFamily: FontFamily.lato_regular,
          }}>
          Go Back
        </Text>
      </Button>
    </View>
  );
};

const List = () => {
  const isLoading = usePendingConversationsLoading();
  const loadingList = useRef<SectionList>(null);
  const list = useRef<SectionList>(null);
  const data = usePenginConversations();
  const userThingId = useSelector(userAccountThingIdSelector);
  const {bottom} = useSafeAreaInsets();
  const keyExtractor = useCallback(
    (item: number | Conversation, index: number) => {
      if (typeof item !== 'number') {
        return `pending-conversation-${item.conversation_id}-index-${index}`;
      }
      return `loading-${index}`;
    },
    [],
  );

  const renderItem = useCallback<ListRenderItem<number | Conversation>>(
    ({item}) => {
      if (typeof item !== 'number') {
        return <ConversationCard isRequestCard conversation={item} />;
      }
      return <ConversactionLoaderItem />;
    },
    [userThingId!],
  );

  const renderSectionHeader = useCallback(
    ({section}: {section: SectionListData<number | Conversation>}) => {
      if (typeof section !== 'number') {
        return (
          <View style={{alignSelf: 'stretch', backgroundColor: Colors.white}}>
            <TitleText
              style={{padding: 16}}
              color="text"
              fontFamily={FontFamily.lato_regular}>
              {section.title} ({section.data.length})
            </TitleText>
            <Line />
          </View>
        );
      }
      return null;
    },
    [],
  );
  const renderSectionFooter = useCallback(() => <Line />, []);

  return (
    <ListLoaderProvider loading={isLoading}>
      <SectionList
        renderSectionFooter={renderSectionFooter}
        renderSectionHeader={renderSectionHeader}
        sections={isLoading ? LOADING_SECTION_DATA_LARGE : data}
        contentContainerStyle={{paddingBottom: bottom || 10}}
        ItemSeparatorComponent={Seperator}
        renderItem={renderItem}
        ListEmptyComponent={Empty}
        keyExtractor={keyExtractor}
        ref={isLoading ? loadingList : list}
      />
    </ListLoaderProvider>
  );
};
const RequestList = () => {
  return (
    <RequestsListWrapper>
      <PageHeader />
      <List />
    </RequestsListWrapper>
  );
};

export default memo(RequestList);
