import axiosInstance from './axios';
import AuthenticationService from './authenticationService';
import {CommunityUtilPayload} from '@models/Community/CommunityUtilPayload';
import {FeedList} from '@models/Feed/FeedList';
import {ThingTypeEnum} from '@constants/constantsEnum';
import {CommunityMemberList} from '@models/Community/CommunityMembersList';
import {CommunityJoinEventPayload} from '@models/Community/CommunityJoinEventPayload';
import type {CommunityList} from '@models/Community/CommunityList';

const fetchAllCommunityList = async () => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.get<CommunityList>('/nexum/get/list', {
    params: {
      thing_type: 'community',
      filter: JSON.stringify({}),
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchCommunityFeedData = async (payload: CommunityUtilPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.get<FeedList>('/nexum/get/list', {
    params: {
      thing_type: ThingTypeEnum.POST,
      filter: JSON.stringify({
        community_id__contains: payload.communityId,
      }),
      page: payload.page,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchCommunityMembers = async (payload: CommunityUtilPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.get<CommunityMemberList>('/nexum/get/list/subscribers', {
    params: {
      thing_type: 'accountslug',
      filter: JSON.stringify({
        thing_type: ThingTypeEnum.COMMUNITY,
        thing_id: payload.communityId,
        relation_type: 'sub',
      }),
      page: payload.page,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const handleJoinEvent = async (payload: CommunityJoinEventPayload) => {
  const accessToken = await AuthenticationService.getAccessToken();
  return axiosInstance.get<any>('/nexum/follow', {
    params: {
      thing_type: payload.thing_type,
      action: payload.action,
      user_id: payload.user_id,
      resource_id: payload.resource_id,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const CommunityService = {
  fetchAllCommunityList,
  fetchCommunityMembers,
  fetchCommunityFeedData,
  handleJoinEvent,
};

export default CommunityService;
