export default {
  primary: '#9B51E0',
  primaryDark: '#2759ff',
  primaryLite: '#637aff99',
  black: '#000',
  white: '#ffffff',
  lightWhite: '#dbdbdb',

  accent: '#112233',
  green: '#60c5a8',
  green2: '#039a83',
  light: '#EEEEEE',
  dark: '#333333',
  gray: '#CCCCCC',
  otherGray: '#ffffff',
  red: '#ff2f68',
  lightRed: '#ff4f7e',
  darkRed: '#d9365e',
  purple: '#8f06e4',
  skyBlue: 'skyblue',
  yellow: '#f8c907',
  pink: '#ff4c98',
  gold: 'gold',
  line: '#282C35',
  darkGray: '#999999',
  veryDarkGrey: '#222222',
  mediumDarkGrey: '#666666',
  danger: '#EB5757',

  darkOverlayColor: 'rgba(0, 0, 0, 0.4)',
  darkOverlayColor2: 'rgba(0, 0, 0, 0.8)',
  lightOverlayColor: 'rgba(255, 255, 255, 0.6)',
  primaryAlpha: 'rgba(99, 122, 255, 0.15)',
  redAlpha: 'rgba(255, 84, 84, 0.15)',
  greenAlpha: 'rgba(96, 197, 168, 0.15)',
  purpleAlpha: 'rgba(146, 6, 228, 0.15)',
  // bags background colors
  bag1Bg: '#ea7a72',
  bag2Bg: '#c2c5d1',
  bag3Bg: '#82a7c9',
  bag4Bg: '#d49d8f',
  bag5Bg: '#ccd9c6',
  bag6Bg: '#767676',
  bag7Bg: '#d1c8c3',
  bag8Bg: '#dca47f',
  bag9Bg: '#eb849c',
  bag10Bg: '#979dc1',
  bag11Bg: '#c7d3c0',

  chatBorder: '#DDDDDD',
  text: '#22283A',
  title: '#181818',
  lightText: '#4D5779',
  veryLightText: '#828282',

  background: '#F2F2F2',
  backgroundDark: '#1c1c1c',
  hover: '#F7EFFF99',
  lightBackground: '#F5F5F5',
  tabBackground: '#F9F9F9',
};
