import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {
  ApiStatusEnum,
  JoinEventEnum,
  LocalStorageKeysEnum,
} from '../../constants/constantsEnum';
import {AccountData} from '../../models/AccountData/AccountData';
import {
  fetchProfileDetails,
  fetchProfileFeedData,
} from '@redux/actions/profile';
import LocalStorage from '@services/storage';
import {CommunityJoinEventPayload} from '@models/Community/CommunityJoinEventPayload';
import {DeletePostPayload} from '@models/Feed/DeletePostPayload';

export type ProfileDetailsState = {
  profileId: string;
  profileAccountData?: AccountData | null;
  error?: any;
  fetchStatus: ApiStatusEnum;
};

export type ProfileFeedState = {
  profileId: string;
  feedPage: number;
  error?: any;
  feedLoadMoreStatus: ApiStatusEnum;
  totalPagesInFeed?: number;
  //Maintains initial fetchStatus for page 0
  fetchStatus: ApiStatusEnum;
};

const createProfileDetailsState = (profileId: string): ProfileDetailsState => ({
  profileId: profileId,
  fetchStatus: ApiStatusEnum.IDLE,
});

const createProfileFeedState = (profileId: string): ProfileFeedState => ({
  profileId: profileId,
  feedPage: 0,
  feedLoadMoreStatus: ApiStatusEnum.IDLE,
  fetchStatus: ApiStatusEnum.IDLE,
});
export interface Profile {
  /** Mapped profileId with FeedIds from particular Profile */
  profileFeedIds: Record<string, string[]>;
  /** mapped Profile Details(AccountData) with profileIds*/
  profileDetails: Record<string, ProfileDetailsState>;
  profileFeeds: Record<string, ProfileFeedState>;
  documentIds: Record<string, string>;
}

const initialState: Profile = {
  profileFeedIds: {},
  profileDetails: {},
  profileFeeds: {},
  documentIds: {},
};

export const profileSlice = createSlice({
  name: 'community',
  initialState: initialState,
  reducers: {
    resetProfileFeedList: (state, action: PayloadAction<string>) => {
      const profileId = action.payload;

      state.profileFeedIds[profileId] = [];
    },
    setProfileFeedPage: (
      state,
      action: PayloadAction<{profileId: string; page: number}>,
    ) => {
      state.profileFeeds[action.payload.profileId].feedPage =
        action.payload.page;
    },

    addCurrentUserProfileDetail: (
      state,
      action: PayloadAction<AccountData>,
    ) => {
      const accountData = action.payload;
      if (!state.profileDetails[accountData.thing_id!]) {
        state.profileDetails[accountData.thing_id!] = createProfileDetailsState(
          accountData.thing_id!,
        );
      }
      const profileDetailState = state.profileDetails[accountData.thing_id!];
      profileDetailState.fetchStatus = ApiStatusEnum.SUCCEEDED;
      profileDetailState.profileAccountData = accountData;
    },
    setDocumentId: (
      state,
      action: PayloadAction<{id: string; docId: string}>,
    ) => {
      const {id, docId} = action.payload;
      if (id && docId) {
        state.documentIds[id] = docId;
      } else {
        console.warn(
          `Some parameters are missing id-> ${id}, docId-> ${docId}`,
        );
      }
    },
    updateCurrentUserJoinedCommunities: (
      state,
      action: PayloadAction<CommunityJoinEventPayload>,
    ) => {
      const currentUserThingId = action.payload.user_id;
      const profileDetailState = state.profileDetails[currentUserThingId];
      if (!!profileDetailState.profileAccountData?.data) {
        if (
          !!profileDetailState.profileAccountData.communities &&
          action.payload.action == JoinEventEnum.SUBSCRIBE
        ) {
          profileDetailState.profileAccountData.communities.push(
            action.payload.resource_id,
          );
        } else if (
          !!profileDetailState.profileAccountData.communities &&
          action.payload.action == JoinEventEnum.UNSUBSCRIBE
        ) {
          const index =
            profileDetailState.profileAccountData.communities.indexOf(
              action.payload.resource_id,
            );
          if (index > -1) {
            profileDetailState.profileAccountData.communities.splice(index, 1);
          }
        }
      }
      LocalStorage.setData(
        LocalStorageKeysEnum.USER_ACCOUNT_DATA,
        profileDetailState.profileAccountData,
      );
    },

    addCreatedPostIdInLoggedInUserFeed: (
      state,
      action: PayloadAction<{loggedInUserId: string; feedId: string}>,
    ) => {
      const userId = action.payload.loggedInUserId;
      const feedId = action.payload.feedId;
      if (!state.profileFeedIds[userId]) {
        state.profileFeedIds[userId] = [];
      }
      state.profileFeedIds[userId].unshift(feedId);
    },

    deletePostFromProfile: (
      state,
      action: PayloadAction<DeletePostPayload>,
    ) => {
      const {thing_id, user_tid} = action.payload;
      if (state.profileFeedIds[user_tid]) {
        state.profileFeedIds[user_tid] = state.profileFeedIds[user_tid].filter(
          id => id !== thing_id,
        );
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProfileDetails.pending, (state, action) => {
      const profileId = action.meta.arg;
      if (!state.profileDetails[profileId]) {
        state.profileDetails[profileId] = createProfileDetailsState(profileId);
      }
      const profileDetailState = state.profileDetails[profileId];
      profileDetailState.fetchStatus = ApiStatusEnum.LOADING;
    });
    builder.addCase(fetchProfileDetails.fulfilled, (state, action) => {
      const profileId = action.meta.arg;
      const accountData = action.payload;
      const profileDetailState = state.profileDetails[profileId];
      profileDetailState.fetchStatus = ApiStatusEnum.SUCCEEDED;
      profileDetailState.profileAccountData = accountData;
    });
    builder.addCase(fetchProfileDetails.rejected, (state, action) => {
      const profileId = action.meta.arg;
      const profileDetailState = state.profileDetails[profileId];
      profileDetailState.fetchStatus = ApiStatusEnum.FAILED;
      profileDetailState.error = action.payload;
    });
    builder.addCase(fetchProfileFeedData.pending, (state, action) => {
      const {
        meta: {
          arg: {authorId, page},
        },
      } = action;

      if (!state.profileFeeds[authorId]) {
        state.profileFeeds[authorId] = createProfileFeedState(authorId);
      }
      const profileFeedState = state.profileFeeds[authorId];
      if (page == 0) {
        profileFeedState.fetchStatus = ApiStatusEnum.LOADING;
      } else {
        profileFeedState.feedLoadMoreStatus = ApiStatusEnum.LOADING;
      }
    });
    builder.addCase(fetchProfileFeedData.fulfilled, (state, action) => {
      const {
        payload: {results, num_pages},
        meta: {
          arg: {authorId, page},
        },
      } = action;
      const profileId = authorId;
      const feedState = state.profileFeeds[profileId];
      if (page == 0) {
        state.profileFeedIds[profileId] = [];
        feedState.fetchStatus = ApiStatusEnum.SUCCEEDED;
      } else {
        feedState.feedLoadMoreStatus = ApiStatusEnum.SUCCEEDED;
      }
      feedState.totalPagesInFeed = num_pages;
      feedState.feedPage = page;

      for (let i = 0; i < results.length; i++) {
        const feedData = results[i];
        state.profileFeedIds[profileId].push(feedData.thing_id);
      }
    });
    builder.addCase(fetchProfileFeedData.rejected, (state, action) => {
      const {
        meta: {
          arg: {authorId, page},
        },
      } = action;
      const profileId = authorId;
      const feedState = state.profileFeeds[profileId];
      if (page == 0) {
        feedState.fetchStatus = ApiStatusEnum.FAILED;
      } else {
        feedState.feedLoadMoreStatus = ApiStatusEnum.FAILED;
      }
      feedState.error = action.payload;
    });
  },
});

export const {
  setDocumentId,
  setProfileFeedPage,
  resetProfileFeedList,
  addCurrentUserProfileDetail,
  addCreatedPostIdInLoggedInUserFeed,
  updateCurrentUserJoinedCommunities,
  deletePostFromProfile,
} = profileSlice.actions;

export default profileSlice.reducer;
