import {addCommunityColorsMap} from '@redux/slices/metaDataSlice';
import {dispatch} from '@redux/store';
import {createAsyncThunk} from '@reduxjs/toolkit';
import MetaDataService from '@services/metaDataService';
import {getColor} from '@utils/ImageColors';

export const fetchMetaData = createAsyncThunk(
  'metaData/fetchMetaData',
  async (_, {rejectWithValue}) => {
    try {
      const response = await MetaDataService.fetchMetaData();
      console.log('response', response.data);
      const colorMap: Record<string, string> = {};
      for (let i = 0; i < response.data.community.length; i++) {
        const element = response.data.community[i];
        const avatar = element.community_avatar;
        if (!avatar) continue;
        const res = await getColor(avatar, element.thing_id);
        colorMap[element.thing_id] = res;
      }
      dispatch(addCommunityColorsMap(colorMap));
      console.log('response', response.data);
      return response.data;
    } catch (error) {
      console.log('error', error);
      return rejectWithValue(error.message);
    }
  },
);

export const fetchOrganistationData = createAsyncThunk(
  'metaData/fetchOrganistationData',
  async (payload: string, {rejectWithValue}) => {
    try {
      const response = await MetaDataService.fetchOrganistation(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
