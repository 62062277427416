import type {RootState} from '@redux/store';

export const notificationSelector = (state: RootState) =>
  state.notifications.notifications;

export const notificationStatusSelector = (state: RootState) =>
  state.notifications.fetchAllNotificationStatus;

export const notificationbyIdSelector =
  (notificationId: string) => (state: RootState) =>
    state.notifications.notifications[notificationId];

export const notificationReadStatusByIdSelector =
  (notificationId: string) => (state: RootState) => {
    if (!notificationId) return null;
    return state.notifications.notifications[notificationId].is_read;
  };

export const notificationPagesAndTotalNumbersSelector = (state: RootState) => [
  state.notifications.page,
  state.notifications.totalNumberOfPagesInNotification,
];

export const notificationEndReachedSelector = (state: RootState) => {
  if (!state.notifications.totalNumberOfPagesInNotification) return true;
  return (
    state.notifications.page + 1 >=
    state.notifications.totalNumberOfPagesInNotification
  );
};
export const refreshFeedOnNotificatonSelector = (state: RootState) =>
  state.notifications.refreshFeedDetailsOnNotification;

export const unreadNotificationCountSelector = (state: RootState) =>
  state.notifications.unreadCount;
