import {StyleSheet} from 'react-native';
import Colors from '@constants/Colors';
import {FontFamily, FontSize, FontWeight, LineHeight} from '@utils/sizes';

const styles = StyleSheet.create({
  bottomSheetView: {
    alignSelf: 'stretch',

    paddingTop: 12,
  },
  subView: {
    alignSelf: 'stretch',
    paddingTop: 8,
    paddingHorizontal: 16,
  },
  inputContainer: {
    alignSelf: 'stretch',
    marginBottom: 20,
  },
  inputTitle: {
    fontSize: FontSize.regular,
    fontWeight: FontWeight.regular,
    lineHeight: LineHeight.regular,
    color: Colors.lightText,
    fontFamily: FontFamily.lato_regular,
  },
  inputSubContainer: {
    alignSelf: 'stretch',
    height: 38,
    marginTop: 4,
  },
  input: {
    fontSize: FontSize.medium,
    fontWeight: FontWeight.regular,
    color: Colors.lightText,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightText,
  },
  bottomContainer: {
    alignSelf: 'stretch',
    paddingHorizontal: 16,
    paddingTop: 12,
  },
  line: {alignSelf: 'stretch', height: 1, backgroundColor: Colors.background},
  button: {
    borderColor: Colors.primary,
    alignSelf: 'stretch',
    borderRadius: 8,
  },
  buttonText: {
    color: Colors.primary,
    fontSize: FontSize.title,
    lineHeight: LineHeight.title,
    fontWeight: FontWeight.medium,
    fontFamily: FontFamily.lato_regular,
  },
});

export default styles;
