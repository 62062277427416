/* eslint-disable */
import {View, Text, Button} from 'react-native';
import React from 'react';

const CommunitySearch = ({}: {navigation: any}) => {
  return (
    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <Text>Space Detail screen</Text>
    </View>
  );
};

export default CommunitySearch;
