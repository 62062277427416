import React, {useMemo, useState} from 'react';
import type {FC} from 'react';
import {
  View,
  useWindowDimensions,
  type ViewProps,
  Pressable,
  StyleSheet,
  Linking,
} from 'react-native';
import {FontSize, SCREEN_SIZE} from '@utils/sizes';
import {AppScreens, TabScreens} from '@navigations/ScreenNames';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Colors from '@constants/Colors';
import {CustomText} from './Texts';
import {isWeb} from '@utils/useColors';

type ScreenProps = ViewProps & {
  LeftComponent?: React.FunctionComponent<{screen?: AppScreens | TabScreens}>;
  RightComponent?: React.FunctionComponent<{screen?: AppScreens | TabScreens}>;
  /**
   * defaults to 420
   */
  maxWidth?: number;
  screen?: AppScreens | TabScreens;
};

const styles = StyleSheet.create({
  loginContainer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    borderTopWidth: 1,
    borderTopColor: Colors.background,
    backgroundColor: Colors.white,
    padding: 16,
    paddingTop: 20,
  },
  getButton: {
    marginTop: 20,
    paddingHorizontal: 20,
    paddingVertical: 8,
    borderRadius: 20,
    backgroundColor: Colors.primary,
  },
  notNowButton: {
    marginTop: 10,
    marginBottom: 10,
    paddingHorizontal: 20,

    borderRadius: 20,
    paddingVertical: 8,
  },
});
const SignInComponent = () => {
  const [showGetApp, setShowGetApp] = useState(true);

  if (showGetApp) {
    return (
      <View style={styles.loginContainer}>
        <View style={{alignSelf: 'stretch', alignItems: 'center'}}>
          <CustomText size={FontSize.title}>
            Discover more posts related to salaries and careers on the app
          </CustomText>
          <Pressable
            onPress={() => {
              Linking.openURL('https://bewyse.in/');
            }}
            style={styles.getButton}>
            <CustomText color="white">Get the Wyse app</CustomText>
          </Pressable>
          <Pressable
            style={
              (({hovered}) => [
                styles.notNowButton,
                hovered && {backgroundColor: Colors.hover},
              ]) as any
            }
            onPress={() => {
              setShowGetApp(false);
            }}>
            <CustomText color="primary">Not Now</CustomText>
          </Pressable>
        </View>
      </View>
    );
  }

  return null;
};
const Screen: FC<ScreenProps> = props => {
  const {
    children,
    style,
    screen,
    LeftComponent,
    maxWidth = SCREEN_SIZE,
    RightComponent,
    ...rest
  } = props;
  const {top, bottom} = useSafeAreaInsets();
  const {width, height} = useWindowDimensions();

  const isLandscape = width > height;
  const actualWidth = isLandscape ? width - (top || 0) - (bottom || 0) : width;
  const isBig = actualWidth >= maxWidth;

  return useMemo(() => {
    if (!isBig)
      return (
        <View style={style} {...rest}>
          {children}
          {isWeb ? <SignInComponent /> : null}
        </View>
      );

    return (
      <View
        style={{flex: 1, flexDirection: 'row', justifyContent: 'center'}}
        {...rest}>
        {!!LeftComponent ? <LeftComponent screen={screen} /> : null}
        <View
          style={[
            {
              height: '100%',
              width: actualWidth <= SCREEN_SIZE ? '100%' : SCREEN_SIZE,
            },
          ]}>
          {children}
        </View>
        {!!RightComponent ? <RightComponent /> : null}
      </View>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isBig,
    style,
    actualWidth,
    maxWidth,
    LeftComponent,
    RightComponent,
    children,
  ]);
};

export default Screen;
